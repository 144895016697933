import * as React from "react";
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { TablePagination } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useGlobalContext } from "../../context/GlobalContext";

export default function CustomPagination(props) {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const { dispatch } = useGlobalContext();
  React.useEffect(()=>{
    if(Array.from(apiRef.current.getSelectedRows()).length > 0){
      dispatch({
        type: "SET_ROW_SELECT",
        payload: {
          rowSelect: false,
        },
      });
    }else{
      dispatch({
        type: "SET_ROW_SELECT",
        payload: {
          rowSelect: true,
        },
      });
    }
  },[Array.from(apiRef.current.getSelectedRows()).length > 0])
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={"space-between"}
      width="100%">
      <Box display="flex" alignItems="center">
        <Button
          startIcon={<DownloadIcon />}
          size="small"
          variant="outlined"
          sx={{ textTransform: "none" }}
          onClick={() => apiRef.current.exportDataAsCsv()}
          disabled={Array.from(apiRef.current.getSelectedRows()).length > 0 ? false : true}>
          Export
        </Button>
      </Box>
      <Box display="flex" alignItems="center">
        <TablePagination
          rowsPerPageOptions={props?.rowsPerPageOptions ? props?.rowsPerPageOptions : [5, 10, 25, 50, 100]}
          component="div"
          count={apiRef.current.state.pagination.rowCount}
          defaultPage={1}
          rowsPerPage={apiRef.current.state.pagination.pageSize}
          page={page}
          onPageChange={(event, value) => apiRef.current.setPage(value)}
          onRowsPerPageChange={(event, value) =>
            apiRef.current.setPageSize(value.props.value)
          }
          // labelRowsPerPage={"Listing per Page"}
          labelDisplayedRows={(props) => {
          	return props.count ? `Showing ${props.from}-${props.to} of ${props.count}` : "";
          }}
          // ActionsComponent={null}
          sx={{
            "& .MuiTablePagination-select": {
              background: "#FBFBFF",
              border: "1px solid #A6A6A6",
              borderRaidus: "4px",
            },
            "& .MuiInputBase-input": {
              borderRadius: "4px",
            },
            "& .MuiTablePagination-actions":
              {
                display: "none",
              },
          }}
        />
        <Pagination
          color="primary"
          count={pageCount}
          page={page + 1}
          variant="outlined"
          shape="rounded"
          onChange={(event, value) => apiRef.current.setPage(value - 1)}
          defaultPage={1}
          showFirstButton
          showLastButton
          
        />
      </Box>
    </Box>
  );
}
