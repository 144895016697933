import { Roles } from "../config";
import { Documents, Login, Users } from "../Pages";
import Layout from "../Pages/Layout";
import DocMaster from "../Pages/DocMaster";
import Branch from "../Pages/Branch";
import CKYCUser from "../Pages/CKYCUser";
import DistrictMaster from "../Pages/DistrictMaster";
import UploadMaster from "../Pages/UploadMaster";
import CKYCUpload from "../Pages/CKYCUpload";
import CreateCustomer from "../Pages/CreateCustomer";
import UpdateCustomers from "../Pages/UpdateCustomers";

import NewCustomersPage from "../Pages/NewCustomers";
import ManageClients from "../Pages/ManageClients";
import CKYCFileGenerated from "../Pages/CKYCFileGenerated";
import CKYCFirstResponse from "../Pages/CKYCFirstResponse";
import CKYCSecondResponse from "../Pages/CKYCSecondResponse";
import Dashboard from "../Pages/Dashboard";
import AutomationDashboard from "../Pages/AutomationDashboard";

import PasswordChangePage from "../Pages/PasswordChangePage";
import BulkSearch from "../Pages/BulkSearch";
import BulkDownload from "../Pages/BulkDownload";
import MISReports from "../Pages/MISReports";
import LoginForm from "../components/Login/LoginForm";

const OTHER_ROUTES = [
  {
    component: PasswordChangePage,
    path: "/change-password",
    title: "Home",
    exact: true,
    // permission: [Roles.ADMIN, Roles.MAKER, Roles.CHECKER, Roles.SUPERADMIN],
  },
  {
    component: Login,
    path: "/login",
    title: "Home",
    exact: true,
    children: [
      {
        component: LoginForm,
        path: "",
        title: "Login",
      },
    ]
  },
  {
    component: Login,
    path: "/",
    title: "Home",
    exact: true,
    children: [
      {
        component: LoginForm,
        path: "",
        title: "Login",
      },
    ]
  }
];

const ADMIN_ROUTES = [
  {
    component: Layout,
    path: "/",
    title: "Home",
    exact: true,
    permission: [Roles.ADMIN],
    children: [
      {
        component: Dashboard,
        path: "/",
        title: "Dashboard",
        permission: [Roles.ADMIN],
      },
      {
        component: Users,
        path: "/users",
        title: "Manage Users",
        permission: [Roles.ADMIN],
      },
      {
        component: Documents,
        path: "/documents",
        title: "Manage Users",
        permission: [Roles.ADMIN],
      },
      {
        component: DocMaster,
        path: "/doc-master",
        title: "Manage Users",
        permission: [Roles.ADMIN],
      },
      {
        component: Branch,
        path: "/branch",
        title: "Manage Branch",
        permission: [Roles.ADMIN],
      },
      {
        component: Users,
        path: "/",
        title: "Manage Users",
        permission: [Roles.ADMIN],
      },
      {
        component: CKYCUser,
        path: "/ckyc-user",
        title: "Manage Users",
        permission: [Roles.ADMIN],
      },
      {
        component: DistrictMaster,
        path: "/district-master",
        title: "Manage Users",
        permission: [Roles.ADMIN],
      },
      {
        component: UploadMaster,
        path: "/upload-master",
        title: "Upload Master",
        permission: [Roles.ADMIN],
      },
      {
        component: CreateCustomer,
        path: "/create-customer",
        title: "Create Customer",
        permission: [Roles.ADMIN],
      },
      {
        component: Dashboard,
        path: "/dashboard",
        title: "Dashboard",
        permission: [Roles.ADMIN],
      },
      {
        component: AutomationDashboard,
        path: "/automation-dashboard",
        title: "Dashboard",
        permission: [Roles.ADMIN],
      },
      {
        component: MISReports,
        path: "/mis-reports",
        title: "MIS Reports",
        permission: [Roles.SUPERADMIN],
      },
    ],
  },
];
const MAKER_ROUTES = [
  {
    component: Layout,
    path: "/",
    title: "Home",
    exact: true,
    permission: [Roles.MAKER],
    children: [
      {
        component: Dashboard,
        path: "/",
        title: "Dashboard",
        permission: [Roles.MAKER],
      },
      {
        component: NewCustomersPage,
        path: "/new-customers",
        title: "New Customers",
        permission: [Roles.MAKER],
      },
      {
        component: UpdateCustomers,
        path: "/update-customers",
        title: "Update Customers",
        permission: [Roles.MAKER],
      },
      {
        component: NewCustomersPage,
        path: "/",
        title: "New Customers",
        permission: [Roles.MAKER],
      },
      {
        component: Dashboard,
        path: "/dashboard",
        title: "Dashboard",
        permission: [Roles.MAKER],
      },
      {
        component: AutomationDashboard,
        path: "/automation-dashboard",
        title: "Dashboard",
        permission: [Roles.MAKER],
      },
      {
        component: BulkSearch,
        path: "/bulk-search",
        title: "BulK Search",
        permission: [Roles.MAKER],
      },
      {
        component: BulkDownload,
        path: "/bulk-download",
        title: "BulK Download",
        permission: [Roles.MAKER],
      },
      {
        component: MISReports,
        path: "/mis-reports",
        title: "MIS Reports",
        permission: [Roles.SUPERADMIN],
      },
    ],
  },
];

const CHECKER_ROUTES = [
  {
    component: Layout,
    path: "/",
    title: "Home",
    exact: true,
    permission: [Roles.CHECKER],
    children: [
      {
        component: Dashboard,
        path: "/",
        title: "Dashboard",
        permission: [Roles.CHECKER],
      },
      {
        component: NewCustomersPage,
        path: "/new-customers",
        title: "New Customers",
        permission: [Roles.CHECKER],
      },
      {
        component: NewCustomersPage,
        path: "/",
        title: "New Customers",
        permission: [Roles.CHECKER],
      },
      {
        component: CKYCUpload,
        path: "/ckyc-upload",
        title: "New Customers",
        permission: [Roles.CHECKER],
      },
      {
        component: CKYCFileGenerated,
        path: "/ckyc-file-generated",
        title: "CKYC File Generated",
        permission: [Roles.CHECKER],
      },
      {
        component: CKYCFirstResponse,
        path: "/ckyc-first-response",
        title: "Ckyc First Response",
        permission: [Roles.CHECKER],
      },
      {
        component: CKYCSecondResponse,
        path: "/ckyc-second-response",
        title: "Ckyc Second Response",
        permission: [Roles.CHECKER],
      },
      {
        component: Dashboard,
        path: "/dashboard",
        title: "Dashboard",
        permission: [Roles.CHECKER],
      },
      {
        component: AutomationDashboard,
        path: "/automation-dashboard",
        title: "Dashboard",
        permission: [Roles.CHECKER],
      },
      {
        component: BulkSearch,
        path: "/bulk-search",
        title: "BulK Search",
        permission: [Roles.MAKER],
      },
      {
        component: BulkDownload,
        path: "/bulk-download",
        title: "BulK Download",
        permission: [Roles.MAKER],
      },
      {
        component: MISReports,
        path: "/mis-reports",
        title: "MIS Reports",
        permission: [Roles.SUPERADMIN],
      },
    ],
  },
];

const SUPER_ADMIN_ROUTES = [
  {
    component: Layout,
    path: "/",
    title: "Home",
    exact: true,
    permission: [Roles.SUPERADMIN],
    children: [
      {
        component: Dashboard,
        path: "/",
        title: "Dashboard",
        permission: [Roles.SUPERADMIN],
      },
      {
        component: ManageClients,
        path: "/manage-clients",
        title: "Create Customer",
        permission: [Roles.SUPERADMIN],
      },
      {
        component: DocMaster,
        path: "/doc-master",
        title: "Manage Users",
        permission: [Roles.SUPERADMIN],
      },
      {
        component: DistrictMaster,
        path: "/district-master",
        title: "Manage Users",
        permission: [Roles.SUPERADMIN],
      },
      {
        component: UploadMaster,
        path: "/upload-master",
        title: "Upload Master",
        permission: [Roles.SUPERADMIN],
      },
      {
        component: Dashboard,
        path: "/dashboard",
        title: "Dashboard",
        permission: [Roles.SUPERADMIN],
      },
      {
        component: AutomationDashboard,
        path: "/automation-dashboard",
        title: "Dashboard",
        permission: [Roles.SUPERADMIN],
      },
      {
        component: MISReports,
        path: "/mis-reports",
        title: "MIS Reports",
        permission: [Roles.SUPERADMIN],
      },
    ],
  },
];

export default [
  ...MAKER_ROUTES,
  ...ADMIN_ROUTES,
  ...CHECKER_ROUTES,
  ...SUPER_ADMIN_ROUTES,
  ...OTHER_ROUTES,
];
