import React from "react";
import { Box, Button } from "@mui/material";
import Text from "./Text";
export default ({ openNewCKYCForm }) => {
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems="center"
      my="16px"
      justifyContent={"center"}>
      <Text fontWeight="500" fontSize="16px">
        KYC Not Registered
      </Text>
      <Text mt="10px" mb="16px" fontSize="16px">
        Do you want to create a new user
      </Text>
      <Button onClick={openNewCKYCForm} size="small" variant="contained">
        <Text fontSize="14px" fontWeight="500">
          Register New CKYC
        </Text>
      </Button>
    </Box>
  );
};
