import React from "react";
import styled from "styled-components";
import { Card } from "@mui/material";

const StyledFooter = styled(Card)`
  display: flex;
  position: ${(props) => (props.normal ? "relative" : "absolute")};
  bottom: 0;
  padding: 16px;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-left: -24px;
`;

const StickyFooter = (rest) => {
  return <StyledFooter {...rest} />;
};

export default StickyFooter;
