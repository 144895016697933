import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";

import SelectInput from "../../Form-Components/SelectInput";
import PasswordInput from "../../Form-Components/PasswordInput";
import TextInput from "../../Form-Components/TextInput";
import CheckBox from "../../Form-Components/CheckBox";
import RadioGroup from "../../Form-Components/RadioGroup";
import DatePicker from "../../Form-Components/DatePicker";

const NewCustomerForm = (props) => {
  const {
    formElements,
    formProps,
    dropdownOptions,
    hideElements,
    disableElements,
  } = props;
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    control,
    isDirty,
    watch,
    getValues,
    error,
    errors,
  } = formProps;
  const [proofValidation, setProofValidation] = useState({});
  const [currentProofValidation, setCurrentProofValidation] = useState({});
  const AddressProofValue = watch("addressProof");
  const CurrentAddressProof = watch("currentAddressProof");

  const AddressProofValidation = [
    {
      id: "04",
      label: "Proof of Possession of Aadhaar",
      pattern: /^\d{12}$/,
    },
    {
      id: "05",
      label: "Passport",
      pattern: /^[A-Za-z]{1}[0-9]{7}$/,
    },
    {
      id: "06",
      label: "Driving License",
      pattern: /^[A-Z]{2}[0-9]{13}$/,
    },
    {
      id: "07",
      label: "Voters Identity Card",
      pattern: /^[A-Za-z]{3}[0-9]{7}$/,
    },
    {
      id: "08",
      label: "NREGA Job Card",
      pattern: /^\d{2}\/\d{4}\/\d{5}$/,
    },
    {
      id: "35",
      label: "National Population Register Letter",
      pattern: "",
    },
    {
      id: "36",
      label: "E-KYC Authentication",
      pattern: "",
    },
    {
      id: "37",
      label: "Offline Verification of Aadhaar",
      pattern: /^\d{12}$/,
    },
  ];
  useEffect(() => {
    const Address = AddressProofValidation?.find(
      (items) => items?.id === AddressProofValue
    );
    const currentAddress = AddressProofValidation?.find(
      (items) => items?.id === CurrentAddressProof
    );
    setProofValidation(Address);
    setCurrentProofValidation(currentAddress);
  }, [AddressProofValue, CurrentAddressProof]);

  const DropDownValidation = (dropdownKey, filterKey) => {
    return dropdownOptions[dropdownKey]?.filter(
      (items) => !["", null, undefined]?.includes(items?.[filterKey])
    );
  };

  const dropDownFilterArray = ["addressProof"];
  return (
    <Box>
      <Box
        display="flex"
        flexDirection={"column"}
        // p="24px"
        sx={{ overflowY: "auto", width: "100%" }}
      >
        <Box
          display="flex"
          // ml="-5%"
          // justifyContent="flex-end"
          sx={{ flexWrap: "wrap" }}
        >
          {formElements.map((item) => {
            const itemDisabled = disableElements?.includes(item.key);
            if (hideElements?.includes(item.key)) {
              return null;
            }
            return (
              <React.Fragment>
                {item.type === "emptyDiv" && (
                  <Box mr="1rem" width={item.width} />
                )}
                {item.type === "radioGroup" && (
                  <RadioGroup
                    width={item.width || "30%"}
                    label={item.label}
                    fieldkey={item.key}
                    errorText={item.errorText}
                    placeholder={"Enter text"}
                    control={control}
                    disabled={item?.disabled || itemDisabled}
                    options={item.options}
                    register={register}
                    mandatory={item.mandatory}
                    setValue={setValue}
                    infoText={item.infoText}
                  />
                )}
                {item.type === "checkbox" && (
                  <CheckBox
                    width={item.width || "30%"}
                    label={item.label}
                    fieldkey={item.key}
                    errorText={item.errorText}
                    placeholder={"Enter text"}
                    control={control}
                    register={register}
                    setValue={setValue}
                    mandatory={item.mandatory}
                  />
                )}
                {item.type === "dropdown" && item.key !== "employeeName" && (
                  <React.Fragment key={item.key}>
                    <SelectInput
                      width={item.width || "30%"}
                      // label={item.label}
                      value={getValues()?.[item.key]}
                      label={
                        (item.renderLabel &&
                          item.renderLabel(getValues(item.watchKey))) ||
                        item.label
                      }
                      fieldkey={item.key}
                      disabled={itemDisabled}
                      errorText={item.errorText}
                      placeholder={"Enter text"}
                      control={control}
                      register={register}
                      mandatory={item.mandatory}
                      setValue={setValue}
                      options={
                        dropdownOptions && dropdownOptions[item.key]
                          ? dropDownFilterArray?.includes(item.key)
                            ? DropDownValidation(item.key, item.filterKey)
                            : dropdownOptions[item.key]
                          : item.options
                      }
                      valueRenderer={(option) => item.valueRenderer(option)}
                      
                      displayName={(option) => item.displayName(option)}
                      watch={watch}
                    />
                  </React.Fragment>
                )}
                {item.type === "dropdown" && item.key === "employeeName" && (
                  <React.Fragment key={item.key}>
                    <SelectInput
                      width={item.width || "30%"}
                      // label={item.label}
                      value={getValues()?.[item.key]}
                      label={
                        (item.renderLabel &&
                          item.renderLabel(getValues(item.watchKey))) ||
                        item.label
                      }
                      fieldkey={item.key}
                      disabled={itemDisabled}
                      errorText={item.errorText}
                      placeholder={"Enter text"}
                      control={control}
                      register={register}
                      mandatory={item.mandatory}
                      setValue={setValue}
                      options={
                        dropdownOptions && dropdownOptions["employeeNameNew"]
                          ? dropdownOptions["employeeNameNew"]?.filter(
                              (item) => item?.roleId == 3
                            )
                          : item.options
                      }
                      // valueRenderer={(option) => item.valueRenderer(option)}
                      valueRenderer={(option) => {
                        if (!isNaN(getValues()?.[item.key])) {
                          return item.valueIdRenderer(option);
                        } else {
                          return item.valueRenderer(option);
                        }
                      }}
                      displayName={(option) => item.displayName(option)}
                      watch={watch}
                    />
                  </React.Fragment>
                )}
                {item.type === "text" && item.isRegex === "alphanumeric" && (
                  <TextInput
                    width={item.width || "30%"}
                    label={
                      (item.renderLabel &&
                        item.renderLabel(getValues(item.watchKey))) ||
                      item.label
                    }
                    fieldkey={item.key}
                    errorText={errors?.[item?.key]?.message}
                    fieldErrorText={item.errorText}
                    placeholder={"Enter text"}
                    control={control}
                    register={register}
                    disabled={itemDisabled}
                    mandatory={item.mandatory}
                    renderLabel={item.renderLabel}
                    watch={watch}
                    watchKey={item.watchKey}
                    getValues={getValues}
                    pattern={item.pattern}
                    isNumber={item.number}
                    isRegex={/^[a-zA-Z0-9]*$/}
                    setValue={setValue}
                    value={getValues()?.[item?.key]}
                  />
                )}
                {item.type === "text" && item.isRegex === "alphaspace" && (
                  <TextInput
                    width={item.width || "30%"}
                    label={
                      (item.renderLabel &&
                        item.renderLabel(getValues(item.watchKey))) ||
                      item.label
                    }
                    fieldkey={item.key}
                    errorText={errors?.[item?.key]?.message}
                    fieldErrorText={item.errorText}
                    placeholder={"Enter text"}
                    control={control}
                    register={register}
                    disabled={itemDisabled}
                    mandatory={item.mandatory}
                    renderLabel={item.renderLabel}
                    watch={watch}
                    watchKey={item.watchKey}
                    getValues={getValues}
                    pattern={item.pattern}
                    isNumber={item.number}
                    isRegex={/^[a-zA-Z\s]*$/}
                    setValue={setValue}
                    value={getValues()?.[item?.key]}
                  />
                )}
                {item.type === "text" &&
                  item.isRegex === "alphanumericspace" && (
                    <TextInput
                      width={item.width || "30%"}
                      label={
                        (item.renderLabel &&
                          item.renderLabel(getValues(item.watchKey))) ||
                        item.label
                      }
                      fieldkey={item.key}
                      errorText={errors?.[item?.key]?.message}
                      fieldErrorText={item.errorText}
                      placeholder={"Enter text"}
                      control={control}
                      register={register}
                      disabled={itemDisabled}
                      mandatory={item.mandatory}
                      renderLabel={item.renderLabel}
                      watch={watch}
                      watchKey={item.watchKey}
                      getValues={getValues}
                      pattern={item.pattern}
                      isNumber={item.number}
                      isRegex={/^[a-zA-Z0-9\s]*$/}
                      setValue={setValue}
                      value={getValues()?.[item?.key]}
                    />
                  )}
                {item.type === "text" && item.isRegex === "numeric" && (
                  <TextInput
                    width={item.width || "30%"}
                    label={
                      (item.renderLabel &&
                        item.renderLabel(getValues(item.watchKey))) ||
                      item.label
                    }
                    fieldkey={item.key}
                    errorText={errors?.[item?.key]?.message}
                    fieldErrorText={item.errorText}
                    placeholder={"Enter text"}
                    control={control}
                    register={register}
                    disabled={itemDisabled}
                    mandatory={item.mandatory}
                    renderLabel={item.renderLabel}
                    watch={watch}
                    watchKey={item.watchKey}
                    getValues={getValues}
                    pattern={item.pattern}
                    isNumber={item.number}
                    isRegex={/^[0-9]*$/}
                    setValue={setValue}
                    value={getValues()?.[item?.key]}
                  />
                )}
                {item.type === "text" &&
                  item.isRegex === "addressProofIdNumber" && (
                    <TextInput
                      width={item.width || "30%"}
                      label={
                        (item.renderLabel &&
                          item.renderLabel(getValues(item.watchKey))) ||
                        item.label
                      }
                      fieldkey={item.key}
                      errorText={errors?.[item?.key]?.message}
                      fieldErrorText={item.errorText}
                      placeholder={""}
                      control={control}
                      register={register}
                      disabled={itemDisabled}
                      mandatory={item.mandatory}
                      renderLabel={item.renderLabel}
                      watch={watch}
                      watchKey={item.watchKey}
                      getValues={getValues}
                      pattern={proofValidation && proofValidation?.pattern}
                      isNumber={item.number}
                      isRegex={["01", "10"].includes(AddressProofValue) ? null : /^[a-zA-Z0-9]*$/}
                      setValue={setValue}
                      value={getValues()?.[item?.key]}
                      isAadhar={["01", "10"].includes(AddressProofValue) ? true : false}
                    />
                  )}
                {item.type === "text" &&
                  item.isRegex === "personaPan" && (
                    <TextInput
                      width={item.width || "30%"}
                      label={
                        (item.renderLabel &&
                          item.renderLabel(getValues(item.watchKey))) ||
                        item.label
                      }
                      fieldkey={item.key}
                      errorText={errors?.[item?.key]?.message}
                      fieldErrorText={item.errorText}
                      placeholder={""}
                      control={control}
                      register={register}
                      disabled={itemDisabled}
                      mandatory={watch("pesonalForm16") === "Yes" ? false : true}
                      renderLabel={item.renderLabel}
                      watch={watch}
                      watchKey={item.watchKey}
                      getValues={getValues}
                      pattern={proofValidation && proofValidation?.pattern}
                      isNumber={item.number}
                      isRegex={["01", "10"].includes(AddressProofValue) ? null : /^[a-zA-Z0-9]*$/}
                      setValue={setValue}
                      value={getValues()?.[item?.key]}
                    />
                  )}
                {item.type === "text" &&
                  item.isRegex === "currentAddressProofIdNumber" && (
                    <TextInput
                      width={item.width || "30%"}
                      label={
                        (item.renderLabel &&
                          item.renderLabel(getValues(item.watchKey))) ||
                        item.label
                      }
                      fieldkey={item.key}
                      errorText={errors?.[item?.key]?.message}
                      fieldErrorText={item.errorText}
                      placeholder={""}
                      control={control}
                      register={register}
                      disabled={itemDisabled}
                      mandatory={item.mandatory}
                      renderLabel={item.renderLabel}
                      watch={watch}
                      watchKey={item.watchKey}
                      getValues={getValues}
                      pattern={
                        currentProofValidation &&
                        currentProofValidation?.pattern
                      }
                      isNumber={item.number}
                      isRegex={["01", "10"].includes(CurrentAddressProof) ? null : /^[a-zA-Z0-9]*$/}
                      setValue={setValue}
                      value={getValues()?.[item?.key]}
                      isAadhar={["01", "10"].includes(CurrentAddressProof) ? true : false}
                    />
                  )}
                {item.type === "text" && !item.isRegex && (
                  <TextInput
                    width={item.width || "30%"}
                    label={
                      (item.renderLabel &&
                        item.renderLabel(getValues(item.watchKey))) ||
                      item.label
                    }
                    fieldkey={item.key}
                    errorText={errors?.[item?.key]?.message}
                    fieldErrorText={item.errorText}
                    placeholder={"Enter text"}
                    control={control}
                    register={register}
                    disabled={itemDisabled}
                    mandatory={item.mandatory}
                    renderLabel={item.renderLabel}
                    watch={watch}
                    watchKey={item.watchKey}
                    pattern={item.pattern}
                    isNumber={item.number}
                    isRegex={item.regex}
                    getValues={getValues}
                    setValue={setValue}
                    value={getValues()?.[item?.key]}
                    isAadhar={item.isAadhar}
                  />
                )}
                {item.type === "date" && (
                  <DatePicker
                    width={item.width || "30%"}
                    label={item.label}
                    fieldkey={item.key}
                    // error={}
                    error={errors && errors[item.key]}
                    errorText={errors?.[item?.key]?.message}
                    placeholder={"Enter text"}
                    control={control}
                    register={register}
                    mandatory={item.mandatory}
                    format={"DD-MM-YYYY"}
                    setValue={setValue}
                    getValues={getValues}
                    maxDate={new Date()}
                    value={getValues()?.[item.key]}
                  />
                )}
                {item.type === "password" && (
                  <PasswordInput
                    width={item.width || "30%"}
                    label={item.label}
                    fieldkey={item.key}
                    errorText={item.errorText}
                    placeholder={"Enter text"}
                    control={control}
                    register={register}
                    mandatory={item.mandatory}
                  />
                )}
              </React.Fragment>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default NewCustomerForm;
