import { Box, Switch } from "@mui/material";
// import { DeleteOutlineIcon, EditIcon } from "@mui/base";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";

// const HeaderWrapper = (params) => {
//   return (
//     <Box display="flex" flexWrap="wrap">
//       <Text fontSize="16px" semiBold fontWeight="600">
//         {params.colDef.headerName}
//       </Text>
//     </Box>
//   );
// };

export const getColumns = ({ onToggleActive, onDeleteClick, onEditClick }) => {
  return [
    {
      field: "documentName",
      headerName: "Document Name",
      // flex: 1,
      flex: 1,
      renderCell:(params) =>
      <span title={params.value}>{params.value?.length > 20 ? `${params.value?.substr(0,20)}...`:params.value}</span>
    },
    // {
    //   field: "isAccountLevel",
    //   headerName: "Account Level",
    //   flex: 0.8,
    //   // renderHeader: (params) => HeaderWrapper(params),
    //   renderCell: (params) => (params.value === true ? "Yes" : "No"),
    // },
    {
      field: "documentCode",
      headerName: "Document Code",
      flex: 1,
      // renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "identityCode",
      headerName: "Identity Proof Code",
      flex: 1,
      // renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "addressProofCode",
      headerName: "Address Proof Code",
      flex: 1,
      // renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.6,
      disableExport: true,
      renderCell: (params) => {
        return (
          <Switch
            checked={params.value}
            onChange={(e) => onToggleActive(e, params.row.id)}
            defaultChecked
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      disableExport: true,
      renderCell: (params) => {
        return (
          <Box display="flex" justifyContent={"space-between"}>
            <EditIcon
              onClick={() => onEditClick(params.row.id)}
              sx={{
               
                cursor: "pointer",
                marginRight: "8px",
              }}
            />
            <DeleteOutlineIcon
              onClick={() => onDeleteClick(params.row.id)}
              sx={{ color: "red", cursor: "pointer", marginLeft: "8px" }}
            />
          </Box>
        );
      },
    },
  ];
};
