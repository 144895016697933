// import { DeleteOutlineIcon, EditIcon } from "@mui/base";
import moment from "moment";
import Text from "../../components/Atoms/Text";

const statusColor = {
  "Pending Mapping": "#8D8D8D",
  "In Progress": "#5B58FF",
  "On Hold": "#EE8E53",
  "CKYC Rejected": "#EA0000",
  "CKYC Found": "green",
  Audited: "green",
};

export const renderCKYCStatus = (value) => {
  return value ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        color: statusColor[value] || "#FF4D4D",
        fontSize: "14px",
      }}
    >
      <div
        style={{
          marginRight: "6px",
          height: "6px",
          width: "6px",
          borderRadius: "6px",
          backgroundColor: statusColor[value] || "#FF4D4D",
        }}
      />
      {value}
    </div>
  ) : (
    ""
  );
};

export const getColumns = ({ onDocketClick, onToggleActive, activeTab }) => {
  return [
    {
      field: "docketNo",
      headerName: "Docket No",
      // flex: 1,
      flex: 0.7,
      renderCell: (params) => (
        <Text /*color="#0400E1"*/ fontSize="14px" semiBold fontWeight="400">
          {params.value}
         
        </Text>
      ),
    },
    {
      field: "customerNumber",
      headerName: "Customer No",
      flex: 0.9,
      // renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "customerOpenDate",
      headerName: "Customer opening date",
      flex: 1,
      renderCell: (params) => {
        const date = params.row.customerOpenDate;
        if (date) {
          return moment(date).format("lll");
        }
        return "";
      },
      // renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: activeTab === 0 ? "name" : "entity_name",
      headerName: activeTab === 0 ? "Name" : "Name of Entity",
      flex: 0.9,
      // renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: activeTab === 0 ? "dob" : "DATE_OF_INCORPORATION",
      headerName: activeTab === 0 ? "Dob" : "Date Of Incorporation",
      flex: 1.2,
      // renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "pan",
      headerName: "PanNo",
      flex: 0.7,
      // renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "mobileNumber",
      headerName: "Mobile No",
      flex: 0.8,
      renderCell: (params) => {
        const mobileCode = params.row?.MOB_CODE || "";
        const mobile = params.row?.MOB_NUM;
        // return `${mobileCode} ${mobile}`;
        return ` ${mobile}`;
      },
      // ren
      // renderHeader: (params) => HeaderWrapper(params),
    },
    // {
    // 	field: "status",
    // 	headerName: "Status",
    // 	flex: 0.8,
    // 	renderCell: (params) => renderCKYCStatus(params.row.status),
    // },
    // {
    // 	field: "active",
    // 	headerName: "Active",
    // 	flex: 0.6,
    // 	disableExport: true,
    // 	renderCell: (params) => {
    // 		return (
    // 			<Switch
    // 				checked={params.value}
    // 				onChange={(e) => onToggleActive(e, params.row.id)}
    // 				defaultChecked
    // 			/>
    // 		);
    // 	},
    // },
    // {
    // 	field: "actions",
    // 	headerName: "Actions",
    // 	sortable: false,
    // 	disableExport: true,
    // 	renderCell: (params) => {
    // 		return (
    // 			<Box display="flex" justifyContent={"space-between"}>
    // 				<EditIcon
    // 					onClick={() => onEditClick(params.row.id)}
    // 					sx={{ marginRight: "4px", cursor: "pointer", marginRight: "8px" }}
    // 				/>
    // 				<DeleteOutlineIcon
    // 					onClick={() => onDeleteClick(params.row.id)}
    // 					sx={{ color: "red", cursor: "pointer", marginLeft: "8px" }}
    // 				/>
    // 			</Box>
    // 		);
    // 	},
    // },
  ];
};
