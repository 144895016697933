import { Box, Card } from "@mui/material";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router";

import loginImage from "../assets/images/login-left.png";
import syntizenFullSizeLogo from "../assets/images/forgo.png";
import Text from "../components/Atoms/Text";
import { useGlobalContext } from "../context/GlobalContext";

export default function Login() {
  const { authUser } = useGlobalContext();
  const navigate = useNavigate();
  const is_pwd_reset = localStorage.getItem("is_pwd_reset");

  useEffect(() => {
    if (is_pwd_reset + "" == "true") navigate("/");
    if (authUser) navigate("/home");
  }, []);

  return (
    <React.Fragment>
      <Box
        flexDirection={"column"}
        style={{ width: "100%", height: "100vh", background: "#F0F2F5" }}
        display="flex"
        justifyContent={"center"}
        alignItems="center"
      >
        <Card
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            width: "62%",
            maxWidth: "998px",
            maxHeight: "588px",
            padding: "40px 84px",
            borderRadius: "10px",
          }}
        >
          <Box display="flex" flexDirection={"row"}>
            <Box flexGrow={1} display="flex" alignItems="center">
              <img
                style={{ maxWidth: "420px", minHeight: "420px" }}
                src={loginImage}
                alt="login image"
              />
            </Box>
            <Box display="flex" style={{ marginLeft: "5rem", width: "100%" }}>
              <Box display="flex" width="100%" flexDirection={"column"}>
                <div className="logoContainer">
                  <img
                    className="logo"
                    src={syntizenFullSizeLogo}
                    style={{ width: "150px", marginLeft: -19 }}
                    alt="Forgo Logo"
                  />
                  <Text mb={"8px"} mt={"4px"}>
                    FORGO CKYC PORTAL
                  </Text>
                  <Outlet />
                </div>
              </Box>
            </Box>
          </Box>
        </Card>
        {/* <Box mt={2} alignItems="center" display={"flex"}>
					<img src={m2pLogo} alt="M2P Logo" />
					<Text ml={"8px"}>
						2022 All Rights Reserved. Powered By M2PFintech
					</Text>
				</Box> */}
      </Box>
    </React.Fragment>
  );
}
