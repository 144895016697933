import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Drawer,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styled from "styled-components";
import { useMutation, useQuery, useQueries } from "@tanstack/react-query";
import { getCountries } from "../../apis/branches";

import Text from "../Atoms/Text";
import TextInput from "../Form-Components/TextInput";
import SelectInput from "../Form-Components/SelectInput";
import StickyFooter from "../Atoms/StickyFooter";
import Modal from "../Molecules/Modal";
import { useEffect } from "react";
import { getValue } from "@testing-library/user-event/dist/utils";

const schema = yup
  .object()
  .shape({
    branch_name: yup.string().max(255).required("Required field"),
    branch_code: yup.string().max(255).required("Required field"),
    branch_manager: yup.string().max(255).required("Required field"),
    // contact_number: yup.string().max(255).required("Required field"),
    contact_number: yup.string().matches(/^[6-9]\d{9}$/, {
      message: "Please enter valid number.",
      excludeEmptyString: false,
    }),
    pincode: yup.string().max(255).required("Required field"),
    country: yup.string().max(255).required("Required field"),
    state: yup.string().max(255).required("Required field"),
    city: yup.string().max(255).required("Required field"),
    address: yup.string().max(255).required("Required field"),
  })
  .required();

const AddBranch = ({
  open,
  toggleDrawer,
  states,
  onSubmit,
  selectedBranch,
  isLoading,
}) => {
  const {
    data: countries,
    isLoading: countriesLoading,
    error: countriesError,
    refetch: fetchCountries,
  } = useQuery(["countries"], getCountries, {
    select: (response) => response.data,
    manual: true,
  });

  const submit = (data) => {
    onSubmit(data);
  };
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    control,
    formState,
    watch,
    getValues,
    error,
    ...rest
  } = useForm({
    defaultValues: {
      branch_name:selectedBranch ? selectedBranch?.brName :"",
      branch_code: selectedBranch ? selectedBranch?.brCode :"",
      branch_manager: selectedBranch?.brManager,
      contact_number: selectedBranch?.contNumber,
      state: selectedBranch?.state
        ? states?.filter((item) => item.id === selectedBranch?.state).length > 0
          ? selectedBranch?.state
          : ""
        : "",
      country: selectedBranch?.country ? countries?.filter((item) => item?.id === selectedBranch?.country).length > 0
          ? selectedBranch?.country : ""
        : countries?.find((item) => item.code === "IN" && item.name === "India")?.id,
      city: selectedBranch?.city,
      pincode: selectedBranch?.pincode,
      address: selectedBranch?.address,
    },
    resolver: yupResolver(schema),
  });
  // const stateWatcher = watch("state", selectedBranch?.stateName);
  // const cityWatcher = watch("city", selectedBranch?.pincodeId);

  const keys = [
    "branch_name",
    "city",
    "pincode",
    "state",
    "country",
    "address",
    "branch_code",
    "branch_manager",
    "contact_number",
  ];
  const filterKeys = keys.filter((items) => watch(items) == "")?.length;
  
  const [isFormReset, setIsFormReset] = useState(false);
  //  console.log("filterd keys==",filterKeys)

  useEffect(() => {
    if (countries && countries?.length > 0) {
      setValue(
        "country",
        selectedBranch?.country
          ? countries?.filter((item) => item?.id === selectedBranch?.country)
              .length > 0
            ? selectedBranch?.country
            : ""
          : countries?.find(
              (item) => item.code === "IN" && item.name === "India"
            )?.id
      );
    }
  }, [countries, isFormReset]);
const obj = {
  branch_name:"",
    city:"",
    pincode:"",
    state:"",
    country:"",
    address:"",
    branch_code:"",
    branch_manager:"",
    contact_number:"",
}
  useEffect(() => {
    if (isFormReset) {
      reset();
      setIsFormReset(false);
    }
  }, [isFormReset, reset]);

  const handleReset = () => {
    setIsFormReset(true);
  };
  return (
    <Modal
      title={selectedBranch?.brId ? "Edit Branch" : "Add Branch"}
      open={open}
      toggleDrawer={toggleDrawer}
    >
      <form
        style={{ display: "flex", flexDirection: "column" }}
        onSubmit={handleSubmit(submit)}
      >
        <Box
          display="flex"
          flexDirection={"column"}
          sx={{
            overflowY: "auto",
            width: "100%",
          }}
        >
          <Box p="24px" display="flex" sx={{ width: "90%", flexWrap: "wrap" }}>
            <TextInput
              label={"Branch Name"}
              fieldkey="branch_name"
              errorText="This Field  is required"
              placeholder="Enter text"
              control={control}
              register={register}
              mandatory
              isRegex={/^[a-zA-Z\s]*$/}
              setValue={setValue}
              value={getValues()?.["branch_name"]}
            />
            <TextInput
              label={"Branch Code"}
              fieldkey="branch_code"
              errorText="Branch code is required"
              placeholder="Branch code"
              control={control}
              register={register}
              mandatory
              isRegex={/^[a-zA-Z0-9\s]*$/}
              setValue={setValue}
              value={getValues()?.["branch_code"]}
            />
            <TextInput
              label={"Branch Manager"}
              fieldkey="branch_manager"
              errorText="Branch Manager name is required"
              placeholder="Branch Manager"
              control={control}
              register={register}
              mandatory
              isRegex={/^[a-zA-Z0-9\s]*$/}
              setValue={setValue}
              value={getValues()?.["branch_manager"]}
            />
            <TextInput
              label={"Contact Number"}
              fieldkey="contact_number"
              errorText="Contact Number is required"
              placeholder="Contact Number"
              control={control}
              register={register}
              mandatory
              isRegex={/^[0-9]*$/}
              isLimit={11}
              setValue={setValue}
              value={getValues()?.["contact_number"]}
            />
            <TextInput
              label={"City"}
              fieldkey="city"
              errorText="City is required"
              placeholder="City"
              control={control}
              register={register}
              mandatory
              isRegex={/^[a-zA-Z0-9\s]*$/}
              setValue={setValue}
              value={getValues()?.["city"]}
              // disabled
            />
            <TextInput
              label={"Pin Code"}
              fieldkey="pincode"
              errorText="Pincode is required"
              placeholder="Pincode"
              control={control}
              register={register}
              mandatory
              isRegex={/^[0-9]*$/}
              isLimit={7}
              setValue={setValue}
              value={getValues()?.["pincode"]}
              // disabled
            />
            <SelectInput
              label={"State"}
              fieldkey="state"
              errorText="State is required"
              placeholder="State"
              control={control}
              register={register}
              mandatory
              setValue={setValue}
              options={states}
              valueRenderer={(option) => option?.id}
              displayName={(option) => option?.name}
              watch={watch}
            />
            {/* <TextInput
							label={"Country"}
							fieldkey="country"
							errorText="Country is required"
							placeholder="Country"
							control={control}
							register={register}
							mandatory
							disabled
						/> */}
            <SelectInput
              label={"Country"}
              fieldkey="country"
              errorText="Country is required"
              placeholder="Country"
              control={control}
              register={register}
              mandatory
              setValue={setValue}
              options={countries}
              valueRenderer={(option) => option?.id}
              displayName={(option) => option?.name}
              watch={watch}
              disabled
            />
            <TextInput
              label={"Address"}
              fieldkey="address"
              errorText="Address is required"
              placeholder="Address"
              control={control}
              register={register}
              mandatory
              fullWidth
              setValue={setValue}
              value={getValues()?.["address"]}
            />
            {/* <SelectInput
							label={"City"}
							fieldkey="city"
							errorText="City is required"
							placeholder="City"
							control={control}
							register={register}
							mandatory
							options={cities}
							setValue={setValue}
							valueRenderer={(option) => option.pinCodeId}
							displayName={(option) => option.city}
						/> */}
          </Box>
          <StickyFooter>
            <React.Fragment>
              <Box pr="55px">
                <Button
                  onClick={handleReset}
                  sx={{ marginRight: "8px" }}
                  variant="outlined"
                >
                  {selectedBranch?.brId ? "Reset" : "Clear"}
                </Button>
                <LoadingButton
                  loading={formState.isSubmitting || isLoading}
                  type={"submit"}
                  size="small"
                  variant="contained"
                  color="primary"
                  disableElevation
                  style={{ textTransform: "none" }}
                  disabled={filterKeys > 0 ? true : false}
                >
                  <Text>Submit</Text>
                </LoadingButton>
              </Box>
            </React.Fragment>
          </StickyFooter>
        </Box>
      </form>
    </Modal>
  );
};

export default AddBranch;
