import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { Switch } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { isError, useMutation, useQuery } from "@tanstack/react-query";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";

import DataTable from "../components/Molecules/DataTable";
import PageHeader from "../components/Molecules/PageHeader";
import AddUser from "../components/Organisms/AddUser";
import {
  deactivated,
  activate,
  selectAll,
  users,
  uploadUser,
  addNewUser,
} from "../assets/icons";
import DeletePopUp from "../components/Molecules/PopupModal";
import {
  createUser,
  getUsers,
  deleteUser,
  getRoles,
  getUser,
} from "../apis/users";
import { useGlobalContext } from "../context/GlobalContext";

const pageTitle = "Manage Users";

const Users = () => {
  const [addUserPanel, setAddUserPanel] = React.useState(false);
  const [tableData, setTableDate] = React.useState([]);
  const [openDeletePopup, toggleDeletePopup] = React.useState(false);
  const {
    state: { currentUser },
    dispatch,
  } = useGlobalContext();
  const [selectedUser, setSelectedUser] = React.useState(null);
  //const [filter, updateFilter] = React.useState(null);


  const [filter, updateFilter] = React.useState({
    condition: "",
    page: 0,
    perPageSize: 5
  });

  const [search, updateSearch] = React.useState(null);

  const {
    data,
    isLoading: usersLoading,
    refetch,
    error: usersEror,
  } = useQuery(["users", { filter, search }], getUsers, {
    manual: true,
    retry: false,
    select: (response) => response.data,
  });

  const { data: roles } = useQuery(["roles"], getRoles, {
    retry: false,
    staleTime: 24 * 60 * 6000,
  });

  const { refetch: refetchUser } = useQuery(
    ["user", { id: selectedUser?.id }],
    getUser,
    {
      retry: false,
      staleTime: 1 * 60 * 60,
      enabled: false,
      onSuccess: (response) => {
        dispatch({
          type: "SET_CURRENT_USER",
          payload: response.users,
        });
      },
    }
  );

  const { mutate: createOrUpdateUser, isLoading } = useMutation(
    (userData) => createUser(userData),
    {
      onSuccess: (response) => {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response?.message,
            severity: response.isError ? "error" : "success",
          },
        });
        if (!response.isError) {
          refetch();
          setAddUserPanel(false);
          // updateFilter({});
          if (response?.user?.id === currentUser?.id && selectedUser) {
            refetchUser();
          }
          setSelectedUser(null);
        }
      },
      onError: (error) => {
        const { errors } = error.response.data;
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text:
              Object.keys(errors).length > 0
                ? `${Object.keys(errors)[0]} ${Object.values(errors)[0]}`
                : error,
            severity: "error",
          },
        });
      },
    }
  );

  const { mutate: onDeleteUser, isLoading: isDeleting } = useMutation(
    (id) => deleteUser(id),
    {
      onSuccess: (response) => {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response?.message,
            severity: response?.isError ? "error" : "success",
          },
        });
        toggleDeletePopup(false);
        setSelectedUser(null);
        refetch();
      },
      onError: (error) => {
        const { errors } = error.response.data;
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text:
              Object.keys(errors).length > 0
                ? `${Object.keys(errors)[0]} ${Object.values(errors)[0]}`
                : error,
            severity: "error",
          },
        });
      },
    }
  );

  const toggleDrawer = () => {
    if (addUserPanel) {
      setSelectedUser(null);
    }
    setAddUserPanel(!addUserPanel);
  };

  const onEditClick = async (id) => {
    const user = data.items.find((item) => item.id === id);
    setSelectedUser(user);
    toggleDrawer();
  };

  const onDeleteClick = (id) => {
    const user = data.items.find((item) => item.id === id);
    setSelectedUser(user);
    toggleDeletePopup(true);
  };

  const updateStatus = async (checked, id) => {
    createOrUpdateUser({
      user: {
        status: checked ? 1 : 0,
        id: id,
      },
    });
  };
  const onToggleActive = async (e, id) => {
    let checked = e.target.checked;
    updateStatus(checked, id);
  };

  const onStatusFilter = (status) => {
    updateFilter({ ...filter, ...status });
    refetch();
  };

  const onRoleFilter = (status) => {
    const role = roles?.data?.find((item) => item.roleName === status.roleFilter);


    updateFilter({ ...filter, roleFilter: role?.id });
    refetch();
  };

  useEffect(() => {
    refetch()
  }, [filter])


  let searchTimeout;

  const searchUsers = (query) => {
    clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      updateSearch(query);
    }, 500);
  };

  const onSearch = (e) => {
    const val = e.target.value;
    searchUsers(val);
  };

  const leftSideActions = [
    {
      options: [
        {
          label: "All Status",
          Icon: selectAll,
          onClick: () => onStatusFilter({ statusFilter: null }),
        },
        {
          label: "Active",
          Icon: activate,
          onClick: () => onStatusFilter({ statusFilter: 1 }),
        },
        {
          label: "InActive",
          Icon: deactivated,
          onClick: () => onStatusFilter({ statusFilter: 0 }),
        },
      ],
      defaultText: "Active",
      isDropdown: true,
      groupTitle: "Status",
    },
    {
      options: [
        {
          label: "All Roles",
          Icon: selectAll,
          onClick: () => onRoleFilter({ roleFilter: null }),
        },
        {
          label: "Admin",
          Icon: users,
          onClick: () => onRoleFilter({ roleFilter: "Admin" }),
        },
        {
          label: "Checker",
          Icon: users,
          onClick: () => onRoleFilter({ roleFilter: "Checker" }),
        },
        {
          label: "Maker",
          Icon: users,
          onClick: () => onRoleFilter({ roleFilter: "Maker" }),
        },
      ],
      defaultText: "Admin",
      isDropdown: true,
      groupTitle: "Role",
    },
  ];

  const columns = [
    // {
    // 	field: "id",
    // 	headerName: "id",
    // 	flex: 0.25,
    // },
    {
      field: "username",
      headerName: "Username",
      flex: 0.7,
      renderCell: (params) =>
        <span title={params.value}>{params.value?.length > 8 ? `${params.value?.substr(0, 8)}...` : params.value}</span>
    },
    {
      field: "firstName",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => {
        const firstName = params.row.firstName || "";
        const lastName = params.row.lastName || "";
        const full = firstName + ' ' + lastName;
        //  return `${firstName} ${lastName}`;
        return <span title={full}>{full}</span>
      },
    },
    { field: "email", headerName: "Email", minWidth: 400 },
    {
      field: "branch",
      headerName: "Branch",
      flex: 0.5,
      valueFormatter: (params) => params?.value?.branchName || "",
      renderCell: (params) => <div title={params?.value?.branchName}>{params.value?.branchName?.length > 7 ? `${params.value?.branchName?.substr(0, 7)}...` : params.value?.branchName}</div>,
    },
    { field: "mobileNo", headerName: "Mobile", flex: 0.8 },
    {
      field: "role",
      headerName: "Role",
      flex: 0.5,
      valueFormatter: (params) => params?.value?.roleName || "",
      renderCell: (params) => {
        const role = params?.value?.roleName || "";
        return <span title={role}>{role}</span>
      }
    },
    {
      field: "designation",
      headerName: "Designation",
      flex: 0.5,
      valueFormatter: (params) => params?.value?.designationName || "",
      renderCell: (params) => {
        const designation = params?.value?.designationName || "";
       
        return <span title={designation}>{designation?.length > 4 ? `${designation ?.substr(0, 4)}...`:designation}</span>
      }
    },

    // { field: "col7", headerName: "Last Login", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      disableExport: true,
      renderCell: (params) => {
        return (
          <Switch
            checked={params.value}
            onChange={(e) => onToggleActive(e, params.row.id)}
          // defaultChecked
          />
        );
      },
    },
    {
      field: "col9",
      headerName: "Actions",
      widht: "550px",
      sortable: false,
      disableExport: true,
      renderCell: (params) => {
        return (
          <Box display="flex" justifyContent={"space-between"}>
            <EditIcon
              onClick={() => onEditClick(params.row.id)}
              sx={{ marginRight: "4px", cursor: "pointer", marginRight: "8px" }}
            />
            <DeleteOutlineIcon
              onClick={() => onDeleteClick(params.row.id)}
              sx={{ color: "red", cursor: "pointer", marginLeft: "8px" }}
            />
          </Box>
        );
      },
    },
  ];

  const rightSideActions = [
    // {
    // 	icon: EditIcon,
    // 	options: [
    // 		{ label: "Add New", Icon: addNewUser, onClick: toggleDrawer },
    // 		{ label: "Upload", Icon: uploadUser, EditIcon, onClick: null },
    // 	],
    // 	title: "Add New",
    // 	onClick: null,
    // 	isSplit: true,
    // },
    {
      defaultText: "Add New",
      isDropdown: false,
      isButton: true,
      icon: <PersonAddAltIcon />,
      title: "Add New",
      onClick: toggleDrawer,
      color: "contained",
    },
  ];

  const CreateOrEditUser = (data) => {
    createOrUpdateUser({
      user: {
        ...(selectedUser?.id && { id: selectedUser.id }),
        username: data.user_name,
        firstName: data.first_name,
        empCode: data?.empCode,
        lastName: data.last_name,
        email: data.email,
        mobileNo: data.mobile,
        branchId: data?.branch,
        roleId: data.role,
        designationId: data?.designationId,
      },
    });
  };
  const [totalCount, setTotalCount] = React.useState(0);
  useEffect(() => {
    if (data?.totalCount >= 0) {
      setTotalCount(data?.totalCount);
    }
  }, [data?.totalCount]);

  useEffect(() => {
    if (data?.items?.length >= 0) {
      setTableDate(data?.items)
    }
  }, [data?.items])

  return (
    <React.Fragment>
      <PageHeader
        toggleDrawer={toggleDrawer}
        leftSideActions={[...leftSideActions, ...rightSideActions]}
        // rightSideActions={rightSideActions}
        pageTitle={pageTitle}
        isSearch={true}
        searchOnChange={onSearch}
        totalCount={totalCount}
      />
      <Box sx={{ padding: "10px 20px", height: "100%" }}>
        <DataTable
          // loading={usersLoading}
          rows={tableData}
          columns={columns}
          totalCount={totalCount}
          getRowId={(row) => row.id}
          pageSize={filter.perPageSize}
          onPageSize={(pageSize) => {
            updateFilter((fil) => ({
              ...fil,
              perPageSize: pageSize,
            }));
          }}
          onPage={(page) => {
            updateFilter((fil) => ({
              ...fil,
              page: page,
            }));
          }}
        />
        {addUserPanel && (
          <AddUser
            onSubmit={CreateOrEditUser}
            open={addUserPanel}
            isLoading={isLoading}
            toggleDrawer={toggleDrawer}
            selectedUser={selectedUser}
            roles
          />
        )}
      </Box>
      {openDeletePopup && (
        <DeletePopUp
          title="Delete User"
          closePopup={() => toggleDeletePopup(false)}
          openDeletePopup={openDeletePopup}
          onDelete={() => onDeleteUser(selectedUser.id)}
          isLoading={isDeleting}
        />
      )}
    </React.Fragment>
  );
};

export default Users;
