import React from "react";
import { Box, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQuery, useQueries } from "@tanstack/react-query";

import Text from "../../Atoms/Text";
import TextInput from "../../Form-Components/TextInput";
import SelectInput from "../../Form-Components/SelectInput";
import DatePicker from "../../Form-Components/DatePicker";
import StickyFooter from "../../Atoms/StickyFooter";
import Modal from "../../Molecules/Modal";
import PasswordInput from "../../Form-Components/PasswordInput";
import { getUsers } from "../../../apis/users";
import { useEffect } from "react";
import { useState } from "react";
import useDropdownOptions from "../../../hooks/useDropdownOptions";
import { formatDate } from "./formUtils";

const fields = [
  {
    key: "branch",
    label: "Branch",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: false,
    valueRenderer: (option) => option.brCode,
    displayName: (option) => `${option.brName}`,
  },
  {
    key: "status",
    label: "Status",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: false,
    valueRenderer: (option) => option.id,
    displayName: (option) => `${option.status}`,
  },
  {
    key: "accountType",
    label: "Account Type",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: false,
    options: [
      { id: "01", type: "Normal" },
      { id: "02", type: "Minor" },
      { id: "03", type: "Simplified (for low-risk customers)" },
      { id: "04", type: "OTP Based E-KYC" },
    ],
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
  },
  {
    key: "mobileNo",
    label: "Mobile Number",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
  },
  {
    key: "aadhar",
    label: "Aadhaar Number",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
  },
  {
    key: "pan",
    label: "PAN No",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
  },
  {
    key: "customerNumber",
    label: "Customer Number",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
  },
  {
    key: "customerName",
    label: "Customer Name",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
  },
  {
    key: "fromDate",
    label: "From Date",
    placeholder: "Enter text",
    errorText: "required",
    type: "date",
    mandatory: false,
  },
  {
    key: "toDate",
    label: "To Date",
    placeholder: "********",
    errorText: "required",
    type: "date",
    mandatory: false,
  },
];

const CustomerFilter = (props) => {
  const {
    toggleDrawer,
    open,
    isLoading,
    onSubmit,
    filters,
    statuses,
    currentRole,
    showStatuses = true,
    formReset
  } = props;
  const { dropdownOptions } = useDropdownOptions();
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    control,
    formState,
    watch,
    getValues,
    error,
  } = useForm({
    defaultValues: {
      toDate: filters?.toDate ? formatDate(filters?.toDate) : null,
      fromDate: filters?.fromDate ? formatDate(filters?.fromDate) : null,
      branch: filters?.branch,
      status: filters?.status,
      accountType: filters?.accountType,
      mobileNo: filters?.mobileNo,
      aadhar: filters?.aadhar,
      pan: filters?.pan,
      customerNumber: filters?.customerNumber,
      customerName: filters?.customerName,
    },
    // resolver: yupResolver(schema),
  });

  // const submit = (data) => {
  // 	// onSubmit(data);
  // };

  // useQuery(["ckycUsers", { filter: null, search: null }], getUsers, {
  // 	manual: true,
  // 	onSuccess: (response) => {
  // 		setDropDownOptions({ dmsUserName: response.data.items });
  // 	},
  // });

  // const resetFilters = () => {
  //   reset();
  //   onSubmit({}, false);
  //   // toggleDrawer();
  // };

  const newDropdowns = {
    ...dropdownOptions,
    status: statuses,
  };
  const checkerStatus = currentRole === "checker"  ? newDropdowns?.status  :'';


  const statusFilterOptions = ["Pending Mapping", "In Progress", "On Hold", "CKYC Rejected", "CKYC Approved", "CKYC Found And Download Failed", "CKYC Found"]
  let obj = {
    toDate: null,
    fromDate: null,
    branch: "",
    status: "",
    accountType: "",
    mobileNo: "",
    aadhar: "",
    pan: "",
    customerNumber: "",
    customerName: "",
  };
  const [isFormReset, setIsFormReset] = useState(false);
  const [fromDateValue, setFromDateValue] = React.useState(null);
  const [endDateValue, setEndDateValue] = React.useState(null);

  useEffect(() => {
    if (isFormReset) {
      reset(obj);
      setIsFormReset(false);
    }
  }, [isFormReset, reset]);

  const handleReset = () => {
    setIsFormReset(true);
  };
  const fromDate = watch("fromDate")
  const toDate = watch("toDate")
  const handleFormReset = ()=>{
    handleReset();
  }
  useEffect(()=>{
    handleFormReset();
  },[formReset])
  return (
    <Modal title={"Filter"} open={open} toggleDrawer={toggleDrawer} 
    
    >
      <form
        style={{ display: "flex", flexDirection: "column" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          display="flex"
          flexDirection={"column"}
          p="24px"
          sx={{ overflowY: "auto", width: "100%" }}
        >
          <Box display="flex" sx={{ width: "100%", flexWrap: "wrap" }}>
            {fields.map((item) => {
              if (!showStatuses && item.key === "status") return;
              return (
                <React.Fragment>
                  {item.type === "dropdown" && item.key === "status" && (
                    <SelectInput
                      label={item.label}
                      fieldkey={item.key}
                      errorText={item.errorText}
                      placeholder={item.placeholder}
                      control={control}
                      register={register}
                      mandatory={item.mandatory}
                      setValue={setValue}
                      options={currentRole === "checker" ? checkerStatus : newDropdowns[item?.key]?.filter(items =>statusFilterOptions.includes(items?.status))}
                      valueRenderer={(option) => item.valueRenderer(option)}
                      displayName={(option) => item.displayName(option)}
                      watch={watch}
                    />
                  )}
                  {item.type === "dropdown" && item.key !== "status" &&(
                    <SelectInput
                      label={item.label}
                      fieldkey={item.key}
                      errorText={item.errorText}
                      placeholder={item.placeholder}
                      control={control}
                      register={register}
                      mandatory={item.mandatory}
                      setValue={setValue}
                      options={newDropdowns[item?.key] || item?.options}
                      valueRenderer={(option) => item.valueRenderer(option)}
                      displayName={(option) => item.displayName(option)}
                      watch={watch}
                    />
                  )}
                  {item.type === "text" && (
                    <TextInput
                      label={item.label}
                      fieldkey={item.key}
                      errorText={item.errorText}
                      placeholder={item.placeholder}
                      control={control}
                      register={register}
                      mandatory={item.mandatory}
                      setValue={setValue}
                      value={getValues()?.[item.key]}
                    />
                  )}
                  {item.type === "password" && (
                    <PasswordInput
                      label={item.label}
                      fieldkey={item.key}
                      errorText={item.errorText}
                      placeholder={item.placeholder}
                      control={control}
                      register={register}
                      mandatory={item.mandatory}
                    />
                  )}
                  {item.type === "date" && item.key === "fromDate" &&(
                    
                    <DatePicker
                      // width={item.width || "30%"}
                      label={item.label}
                      fieldkey={item.key}
                      errorText={item.errorText}
                      placeholder={item.placeholder}
                      control={control}
                      register={register}
                      mandatory={item.mandatory}
                      setValue={setValue}
                      format={"YYYY-MM-DD"}
                       maxDate={new Date()}
                      value={getValues()?.[item.key]}
                      //value = {fromDateValue}
                    
                    />
                  )}
                 {item.type === "date" && item.key === "toDate" && (
  <DatePicker
    // width={item.width || "30%"}
    label={item.label}
    fieldkey={item.key}
    errorText={item.errorText}
    placeholder={item.placeholder}
    control={control}
    register={register}
    mandatory={item.mandatory}
    setValue={setValue}
    maxDate={new Date()} 
    format={"YYYY-MM-DD"}

    minDate = {fromDate}
    disabled={!fromDate}
    value={getValues()?.[item.key]}
    //value = {endDateValue}
  />
)}

                </React.Fragment>
              );
            })}
          </Box>
        </Box>
        <StickyFooter>
          <React.Fragment>
            <Button
              onClick={handleReset}
              sx={{ marginRight: "8px" }}
              variant="outlined"
            >
              {/* {selectedUser?.get("ckycUserId") ? "Reset" : "Clear"} */}
              {"Clear"}
            </Button>
            <LoadingButton
              loading={formState.isSubmitting || isLoading}
              type={"submit"}
              size="small"
              variant="contained"
              color="primary"
              disableElevation
              style={{ textTransform: "none" }}
            >
              <Text>Submit</Text>
            </LoadingButton>
          </React.Fragment>
        </StickyFooter>
      </form>
    </Modal>
  );
};

export default CustomerFilter;
