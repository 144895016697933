import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Text from "../Atoms/Text";
const TextInput = (props) => {
  const {
    label,
    fieldkey,
    errorText,
    placeholder,
    control,
    register,
    mandatory,
    fullWidth,
    disabled,
    width,
    watch,
    renderLabel,
    watchKey,
    getValues,
    pattern,
    isNumber,
    isRegex,
    isLimit,
    isPan,
    value: parentValue,
    onChangeValue = () => {},
    isAadhar
  } = props;
  const regex = new RegExp(isRegex);
  const [displayLabel, setDisplayLabel] = useState(label);
  const watchLabel = renderLabel && watch && watch(watchKey);
  const [value, setValue] = useState("");
  useEffect(() => {
    setDisplayLabel(label);
  }, [label]);

  useEffect(() => {
    if (renderLabel && watchLabel) {
      setDisplayLabel(renderLabel(getValues && getValues(watchKey)));
    }
  }, [watchLabel]);
  useEffect(() => {
    if (
      (parentValue && regex.test(parentValue)) ||
      (!isRegex && parentValue) ||
      !parentValue
    ) {
      setValue(parentValue);
    }
  }, [parentValue]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      key={fieldkey}
      sx={{
        marginTop: "1rem",
        width: fullWidth ? "92%" : width || "45%",
        marginRight: "1rem",
      }}
    >
      <Text mandatory={mandatory} mb="4px">
        {displayLabel || label}
      </Text>
      <Controller
        render={({field, fieldState: { error } }) => (
          <Box>
            <TextField
              type={isNumber ? "number" : "string"}
              sx={{ width: "100%" }}
              inputRef={field.ref}
              {...register(fieldkey, {
                required: mandatory
                  ? `${displayLabel || label} is required`
                  : false,
                pattern: {
                  value: pattern,
                  message: `${displayLabel || label} is not valid.`,
                },
                // validate: (val) => {
                //   if (pattern) {
                //     const regx = new RegExp(pattern);
                //     if (regx.test(val)) {
                //       return false;
                //     }

                //     return `${displayLabel || label} is not valid.`;
                //   }
                // },
                onChange: (event) => {
                  onChangeValue();
                  const currentValue = event.target.value;
                  const lastValue = currentValue.substr(
                    currentValue?.length - 1
                  );
                  if (isPan) {
                    if (currentValue.length < 6) {
                      if (!/^[a-zA-Z]*$/.test(lastValue)) {
                        return;
                      } else {
                        setValue(currentValue);
                      }
                    } else if (
                      currentValue.length >= 6 &&
                      currentValue.length <= 9
                    ) {
                      if (!/^[0-9]*$/.test(lastValue)) {
                        return;
                      } else {
                        setValue(currentValue);
                      }
                    } else if (currentValue.length === 10) {
                      if (!/^[a-zA-Z]*$/.test(lastValue)) {
                        return;
                      } else {
                        setValue(currentValue);
                      }
                    }
                  }
                  else {
                    if (!regex.test(currentValue)) {
                      return;
                    } else {
                      if (isLimit && currentValue.length >= isLimit) {
                        return;
                      } else {
                        setValue(currentValue);
                      }
                    }
                  }
                },
              })}
              id={fieldkey}
              // defaultValue={defaultValue}
              size="small"
              // errorText={error?.message && errorText && isTouched}
              // value={field.value}
              value={value ? value : ""}
              //helperText={isTouched && error && errorText}
              placeholder={placeholder || ""}
              disabled={disabled}
              autoComplete="new-password"
              
              InputProps={isPan ? { style: { textTransform: "uppercase" } } : isAadhar ? {
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="search"
                      edge="start"
                      size="small"
                      style={{fontSize: "16px", backgroundColor: "transparent", marginRight: "-12px", zIndex: 0}}
                    >
                      XXXX-XXXX-
                    </IconButton>
                  </InputAdornment>
                ),
              } : {}}
            />
            {/* <TextareaAutosize 
          /> */}
            {error && errorText && (
              <Text
                mt={"4px"}
                letterSpacing="0.4px"
                fontSize="12px"
                ml={"16px"}
                color="#d32f2f"
              >
                {error?.message ?? errorText}
              </Text>
            )}
          </Box>
        )}
        name={fieldkey}
        control={control}
        // defaultValue={defaultValue}
      />
    </Box>
  );
};

export default TextInput;
