import React from "react";
import { Box, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQuery, useQueries } from "@tanstack/react-query";

import Text from "../Atoms/Text";
import TextInput from "../Form-Components/TextInput";
import SelectInput from "../Form-Components/SelectInput";
import StickyFooter from "../Atoms/StickyFooter";
import Modal from "../Molecules/Modal";
import PasswordInput from "../Form-Components/PasswordInput";
import { getUsers } from "../../apis/users";
import { viewBraches } from "../../apis/branches";
import { useEffect } from "react";
import { useState } from "react";
import CustomerAccordon from "../Molecules/CustomerAccordon";

const initialValues = (selectedUser, clientConfig) => {
  return {
    clientName: selectedUser.get("clientName", ""),
    email: selectedUser.get("email", ""),
    mobileNo: selectedUser.get("mobileNo", ""),
    username: selectedUser.get("username", ""),
    // clientUniqueId: selectedUser.get("clientUniqueId", ""),
    hostinger: clientConfig && clientConfig?.getIn(["sftp", "host"], ""),
    port: clientConfig && clientConfig?.getIn(["sftp", "port"], ""),
    sftpUsername: clientConfig && clientConfig?.getIn(["sftp", "username"], ""),
    sftpPassword: clientConfig && clientConfig?.getIn(["sftp", "password"], ""),
    path: clientConfig && clientConfig?.getIn(["sftp", "remotePath"], ""),
    pollInterval:
      clientConfig && clientConfig?.getIn(["sftp", "pollInterval"], ""),
    job: selectedUser.get("job", ""),
    jobfrequency: selectedUser.get("job_period", ""),
    //
    baseUrl:
      clientConfig && clientConfig?.getIn(["api", "syntizenBaseUrl"], ""),
    apiUsername: clientConfig && clientConfig?.getIn(["api", "username"], ""),
    apiPassword: clientConfig && clientConfig?.getIn(["api", "password"], ""),
    careContact: selectedUser.get("customerCareContactNo", ""),
    careEmail: selectedUser.get("customerCareEmail", ""),
  };
};

const AddClient = ({
  open,
  toggleDrawer,
  states,
  onSubmit,
  selectedUser,
  isLoading,
  clientConfig,
}) => {
  const [isAutomation, setIsAutomation] = useState(false);
  const schema = yup
    .object()
    .shape({
      conditionJob: yup.boolean().default(isAutomation),
      clientName: yup
        .string()
        .test("no-spaces", "Client Name Required", (value) => {
          if (value && value.trim().length === 0) {
            return false;
          }
          return true;
        })
        .max(255)
        .required("Client Name Required"),
      mobileNo: yup
        .string()
        .test("test_mobile", "Enter valid phone number", (value) =>
          /^[6-9][0-9]{9}$/.test(value)
        ),

      email: yup.string().email().max(255).required("Valid Email Required"),
      username: yup
        .string()
        .test("no-spaces", "Username is required", (value) => {
          if (value && value.trim().length === 0) {
            return false;
          }
          return true;
        })
        .max(255)
        .required("Username is required"),
      // username: yup.string().max(255).required("User Name Required"),
      careContact: yup
        .string()
        .test("test_mobile_number", "Enter valid phone number", (value) =>
          /^[6-9][0-9]{9}$/.test(value)
        ),
      careEmail: yup
        .string()
        .email("Customer Care Email must be a valid mail")
        .max(255)
        .required("Valid Email Required"),
      // pollInterval: yup
      //   .string()
      //   .test(
      //     "test_poll_interval",
      //     "Poll Interval should be greater than 0",
      //     (value) => (value.length > 0 ? parseInt(value) > 0 : true)
      //   ),
      jobfrequency: yup.string().when("conditionJob", {
        is: true,
        then: yup.string().required("Job Frequency Required"),
      }),
      job: yup.string().required("Job Required"),
      // jobfrequency: yup.string().required("Required field"),
      hostinger: yup.string().when("conditionJob", {
        is: true,
        then: yup.string().max(255).required("Host Required"),
        otherwise: yup.string().notRequired(),
      }),
      port: yup.string().when("conditionJob", {
        is: true,
        then: yup.string().max(255).required("Port Required"),
      }),
      sftpUsername: yup.string().when("conditionJob", {
        is: true,
        then: yup.string().max(255).required("UserName Required"),
      }),
      sftpPassword: yup.string().when("conditionJob", {
        is: true,
        then: yup.string().max(255).required("Password Required"),
      }),
      path: yup.string().when("conditionJob", {
        is: true,
        then: yup.string().max(255).required("Part Required"),
      }),
      pollInterval: yup.string().when("conditionJob", {
        is: true,
        then: yup
          .string()
          .test(
            "test_poll_interval",
            "Poll Interval should be greater than 0",
            (value) => (value.length > 0 ? parseInt(value) > 0 : true)
          )
          .max(255)
          .required("Poll INRequired"),
      }),
      // apiPassword:yup.string().when("conditionJob", {
      //   is: true,
      //   then: yup.string().max(255).required("Password Required")
      // }),
      // apiUsername: yup.string().when("conditionJob", {
      //   is: true,
      //   then: yup.string().max(255).required("UserName Required")
      // }),
      // baseUrl: yup.string().when("conditionJob", {
      //   is: true,
      //   then: yup.string().max(255).required("Url Required")
      // }),
      baseUrl: yup.string().max(255).required("Base Url Required"),
      apiPassword: yup.string().max(255).required("Password Required"),
      apiUsername: yup.string().max(255).required("UserName Required"),
      // password: yup.string().max(255).required("Required field"),
    })
    .required();

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    control,
    formState,
    watch,
    getValues,
    error,
  } = useForm({
    defaultValues: initialValues(selectedUser, clientConfig),
    resolver: yupResolver(schema),
  });

  const submit = (data) => {
    onSubmit(data);
  };

  const dropDownOptions = { job: ["MANUAL", "AUTOMATION"] };
  const jobOptions = ["15", "30", "60", "2"];
  const jobValue = watch("job");
  useEffect(() => {
    if (jobValue === "AUTOMATION") {
      setIsAutomation(true);
    } else if (jobValue === "MANUAL") {
      setIsAutomation(false);
    }
  }, [jobValue]);

  const fields = [
    {
      key: "clientName",
      label: "Client Name",
      placeholder: "Enter text",
      errorText: "Client Name Required",
      type: "text",
      mandatory: true,
      isRegex: "alphanumericspace",
    },
    {
      key: "username",
      label: "Username",
      placeholder: "Enter text",
      errorText: "Username Required",
      type: "text",
      mandatory: true,
      isRegex: "alphanumericspace",
    },
    // {
    // 	key: "clientUniqueId",
    // 	label: "Client Unique Id",
    // 	placeholder: "Enter text",
    // 	errorText: "required",
    // 	type: "text",
    // 	mandatory: true,
    // },
    {
      key: "email",
      label: "Email",
      placeholder: "Enter text",
      errorText: "Valid Email Required",
      type: "text",
      mandatory: true,
    },
    {
      key: "mobileNo",
      label: "Mobile",
      placeholder: "Enter text",
      errorText: "Mobile Number Required",
      type: "text",
      mandatory: true,
      isRegex: "numeric",
    },
    {
      key: "job",
      label: "Job",
      placeholder: "Select job type",
      errorText: "Job Required",
      type: "dropdown",
      mandatory: true,
      valueRenderer: (option) => option,
      displayName: (option) => option,
    },
    {
      key: "jobfrequency",
      label: "Job Frequency (min)",
      placeholder: "Enter text",
      errorText: "Job Frequency Required",
      type: "dropdown",
      mandatory: isAutomation,
      valueRenderer: (option) => option,
      displayName: (option) => option,
    },
  ];

  const [isFormReset, setIsFormReset] = useState(false);

  useEffect(() => {
    if (isFormReset) {
      reset();
      setIsFormReset(false);
    }
  }, [isFormReset, reset]);

  useEffect(() => {
    if (clientConfig) {
      reset(initialValues(selectedUser, clientConfig));
    }
  }, [clientConfig]);

  const handleReset = () => {
    setIsFormReset(true);
  };

  return (
    <Modal
      title={selectedUser?.get("id") ? "Edit Client" : "Add Client"}
      open={open}
      toggleDrawer={toggleDrawer}
    >
      <form
        style={{ display: "flex", flexDirection: "column" }}
        onSubmit={handleSubmit(submit)}
      >
        <Box
          display="flex"
          flexDirection={"column"}
          p="24px"
          sx={{ overflowY: "auto" }}
        >
          <CustomerAccordon title={"Client Details"}>
            <Box display="flex" sx={{ width: "100%", flexWrap: "wrap" }}>
              {fields.map((item) => {
                return (
                  <React.Fragment>
                    {item.type === "dropdown" &&
                      item.key !== "jobfrequency" && (
                        <SelectInput
                          label={item.label}
                          fieldkey={item.key}
                          errorText={item.errorText}
                          placeholder={item.placeholder}
                          control={control}
                          register={register}
                          mandatory={item.mandatory}
                          setValue={setValue}
                          autocomplete={`new-${item.label}`}
                          options={dropDownOptions[item.key] || jobOptions}
                          valueRenderer={(option) => item.valueRenderer(option)}
                          displayName={(option) => item.displayName(option)}
                          watch={watch}
                          disabled={item.disabled}
                        />
                      )}
                    {item.type === "dropdown" &&
                      item.key === "jobfrequency" &&
                      isAutomation && (
                        <SelectInput
                          label={item.label}
                          fieldkey={item.key}
                          errorText={item.errorText}
                          placeholder={item.placeholder}
                          control={control}
                          register={register}
                          mandatory={item.mandatory}
                          setValue={setValue}
                          autocomplete={`new-${item.label}`}
                          options={jobOptions}
                          valueRenderer={(option) => item.valueRenderer(option)}
                          displayName={(option) => item.displayName(option)}
                          watch={watch}
                        />
                      )}
                    {item.type === "text" &&
                      item.isRegex === "alphanumericspace" && (
                        <TextInput
                          label={item.label}
                          fieldkey={item.key}
                          errorText={item.errorText}
                          placeholder={item.placeholder}
                          control={control}
                          register={register}
                          mandatory={item.mandatory}
                          isRegex={/^[a-zA-Z0-9\s]*$/}
                          setValue={setValue}
                          disabled={
                            item.key === "username" && selectedUser?.get("id")
                              ? true
                              : false
                          }
                          value={getValues()?.[item.key]}
                        />
                      )}
                    {item.type === "text" && item.isRegex === "numeric" && (
                      <TextInput
                        label={item.label}
                        fieldkey={item.key}
                        errorText={item.errorText}
                        placeholder={item.placeholder}
                        control={control}
                        register={register}
                        mandatory={item.mandatory}
                        isRegex={/^[0-9]*$/}
                        setValue={setValue}
                        value={getValues()?.[item.key]}
                      />
                    )}
                    {item.type === "text" && !item.isRegex && (
                      <TextInput
                        label={item.label}
                        fieldkey={item.key}
                        errorText={item.errorText}
                        placeholder={item.placeholder}
                        control={control}
                        register={register}
                        mandatory={item.mandatory}
                        isNumber={item.isNumber}
                        setValue={setValue}
                        value={getValues()?.[item.key]}
                      />
                    )}
                    {item.type === "password" && (
                      <PasswordInput
                        label={item.label}
                        fieldkey={item.key}
                        errorText={item.errorText}
                        placeholder={item.placeholder}
                        control={control}
                        register={register}
                        mandatory={item.mandatory}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </Box>
          </CustomerAccordon>
          <Box mt="24px">
            <CustomerAccordon title={"SFTP Configuration "}>
              <Box display="flex" sx={{ width: "100%", flexWrap: "wrap" }}>
                <TextInput
                  label={"Host"}
                  fieldkey="hostinger"
                  errorText={isAutomation ? "Host Required" : null}
                  placeholder="Enter text"
                  control={control}
                  register={register}
                  mandatory={isAutomation}
                  setValue={setValue}
                  value={getValues()?.["hostinger"]}
                />
                <TextInput
                  label={"Port"}
                  fieldkey="port"
                  errorText={isAutomation ? "Port Required" : null}
                  placeholder="Enter text"
                  control={control}
                  register={register}
                  mandatory={isAutomation}
                  setValue={setValue}
                  value={getValues()?.["port"]}
                />
                <TextInput
                  label={"Username"}
                  fieldkey="sftpUsername"
                  errorText={isAutomation ? "Username Required" : null}
                  placeholder="Enter text"
                  control={control}
                  register={register}
                  mandatory={isAutomation}
                  setValue={setValue}
                  value={getValues()?.["sftpUsername"]}
                />

                <PasswordInput
                  label={"Password"}
                  fieldkey="sftpPassword"
                  errorText={isAutomation ? "Password Required" : null}
                  placeholder="Enter text"
                  control={control}
                  register={register}
                  mandatory={isAutomation}
                />
                <TextInput
                  label={"Path"}
                  fieldkey="path"
                  errorText={isAutomation ? "Path Required" : null}
                  placeholder="Enter text"
                  control={control}
                  register={register}
                  mandatory={isAutomation}
                  setValue={setValue}
                  value={getValues()?.["path"]}
                />
                <TextInput
                  label={"Poll Interval"}
                  fieldkey="pollInterval"
                  errorText={
                    isAutomation
                      ? "Poll Interval should be greater than 0"
                      : null
                  }
                  placeholder="Enter text"
                  control={control}
                  register={register}
                  mandatory={isAutomation}
                  setValue={setValue}
                  value={getValues()?.["pollInterval"]}
                />
              </Box>
            </CustomerAccordon>
          </Box>
          <Box mt="24px">
            <CustomerAccordon title={"API Configuration "}>
              <Box display="flex" sx={{ width: "100%", flexWrap: "wrap" }}>
                <TextInput
                  label={"Base URL"}
                  fieldkey="baseUrl"
                  errorText={"Base URL Required"}
                  placeholder="Enter text"
                  control={control}
                  fullWidth
                  register={register}
                  mandatory={true}
                  setValue={setValue}
                  value={getValues()?.["baseUrl"]}
                />
                <TextInput
                  label={"Username"}
                  fieldkey="apiUsername"
                  errorText={"Username Required"}
                  placeholder="Enter text"
                  control={control}
                  register={register}
                  mandatory={true}
                  setValue={setValue}
                  value={getValues()?.["apiUsername"]}
                />
                <PasswordInput
                  label={"Password"}
                  fieldkey="apiPassword"
                  errorText={"Password Required"}
                  placeholder="Enter text"
                  control={control}
                  register={register}
                  mandatory={true}
                  setValue={setValue}
                  value={getValues()?.["apiPassword"]}
                />
              </Box>
            </CustomerAccordon>
          </Box>
          <Box mt="24px">
            <CustomerAccordon title={"Customer Care"}>
              <Box display="flex" sx={{ width: "100%", flexWrap: "wrap" }}>
                <TextInput
                  label={"Customer Care Email"}
                  fieldkey="careEmail"
                  errorText={"Valid Email Required"}
                  placeholder="Enter text"
                  control={control}
                  register={register}
                  mandatory={true}
                  setValue={setValue}
                  value={getValues()?.["careEmail"]}
                />
                <TextInput
                  label={"Customer Care Contact No"}
                  fieldkey="careContact"
                  errorText={"Valid Mobile Number Required"}
                  placeholder="Enter text"
                  control={control}
                  register={register}
                  mandatory={true}
                  isRegex={/^[0-9]*$/}
                  setValue={setValue}
                  value={getValues()?.["careContact"]}
                />
              </Box>
            </CustomerAccordon>
          </Box>
          <Box display="flex" justifyContent="flex-end" mt="48px">
            <React.Fragment>
              <Button
                // onClick={() => reset()}
                onClick={handleReset}
                sx={{ marginRight: "8px" }}
                variant="outlined"
              >
                {selectedUser?.get("id") ? "Reset" : "Clear"}
              </Button>
              <LoadingButton
                loading={formState.isSubmitting || isLoading}
                type={"submit"}
                size="small"
                variant="contained"
                color="primary"
                disableElevation
                style={{ textTransform: "none" }}
              >
                <Text>Submit</Text>
              </LoadingButton>
            </React.Fragment>
          </Box>
        </Box>
      </form>
    </Modal>
  );
};

export default AddClient;
