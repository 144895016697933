import Immutable from "immutable";
import axiosInstance from "./axios";
import moment from "moment";

export const getClients = async ({ queryKey }) => {
	const [_key, { filter, search, isDropdown }] = queryKey;
	const pageSize = filter.perPageSize || 10;
	const fils = { ...filter };
	let baseUrl = `/clients/list/?perPage=${isDropdown ? "" : pageSize}&offset=${filter?.page ? filter?.page : 0}`;
	delete fils.page;
	delete fils.perPageSize;
	delete fils.condition;
	let filtered = baseUrl;
	if (filter?.statusFilter >= 0 && filter.statusFilter != null && Object?.keys(filter)?.length > 0) {
		let val = Object.keys(fils);
		filtered = `${baseUrl}&${val}=${filter[val]}`;
	}
	if (![null, "", undefined].includes(search))
		filtered = `${baseUrl}&search=${search}`;

	const response = await axiosInstance.get(filtered);
	return Immutable.fromJS(response.data);
};

export const getStates = async () => {
	const response = await axiosInstance.get("/ckycUsers/getStates");
	return Immutable.fromJS(response.data);
};

export const getCities = async ({ queryKey }) => {
	const [, { state }] = queryKey;
	try {
		const response = await axiosInstance.get(
			`/ckycUsers/getCities?stateName=${state}`
		);
		return response.data;
	} catch (e) {}
};

export const getClientConfig = async ({ queryKey }) => {
	const [, { clientUniqueId }] = queryKey;
	try {
		const response = await axiosInstance.get(
			`/clients/getConfig?clientUniqueId=${clientUniqueId}`
		);
		return Immutable.fromJS(response.data);
	} catch (e) {}
};

export const deleteCkycUser = async (data) => {
	const response = await axiosInstance.delete(`/ckycUsers/delete`, data);
	return response.data;
};

export const addOrEditClient = async (data) => {
	let response;
	if (data?.client?.id && !data?.client?.isDeleted)
		response = await axiosInstance.put(`/clients/edit`, data);
	else if (data?.client?.isDeleted) {
		response = await axiosInstance.delete(`/clients/delete`, {
			data: { id: data.client.id },
		});
	} else response = await axiosInstance.post(`/clients/create`, data);
	return response.data;
};

export const activeClient = async (data) => {
	console.log("manage client active state", data)
	return await axiosInstance.put(`clients/deactivateClient`, data);
}


export const editBranch = async (data) => {
	const response = await axiosInstance.post(`/ckycUsers/edit`, data);
	return response.data;
};

export const addMISReports = async ({ queryKey }) => {
	const clientId = localStorage.getItem("cliendid")
	const fromDate = moment(queryKey?.fromDate).format('YYYY-MM-DD')
	const endDate = moment(queryKey?.endDate).format('YYYY-MM-DD')
	console.log("fromdate======jjk",fromDate,endDate)
	try {
	  const response = await axiosInstance.get(`/customers/report?client_id=${queryKey?.client ? queryKey?.client : clientId}&perPage=500&offset=0&fromDate=${fromDate}&endDate=${endDate}`);
	  return response;
	} catch (e) {
	  console.log("Error:", e.message);
	}
  };
  
export const getClientsList = async () =>{
	const response = await axiosInstance.get("/clients/list/?perPage=500&offset=0");
	return response.data;
}
