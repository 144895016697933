import React, { useEffect } from "react";
import { Box, TextField, Button, Card, FormHelperText } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Text from "../Atoms/Text";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import { passwordStrength } from "check-password-strength";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import styled from "styled-components";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { changePassword } from "../../apis/auth";
import { useGlobalContext } from "../../context/GlobalContext";
import Modal from "../Molecules/Modal";
import StickyFooter from "../Atoms/StickyFooter";

const strengthColor = {
  "Too weak": { color: "#d32f2f", width: "10%", text: "Weak", status: "error" },
  Weak: { color: "#d32f2f", width: "25%", text: "Weak", status: "error" },
  Medium: {
    color: "#ed6c02",
    width: "75%",
    text: "Average",
    status: "warning",
  },
  Strong: {
    color: "#2e7d32",
    width: "100%",
    text: "Strong",
    status: "success",
  },
};

const StyledStrengthMeter = styled.div`
  height: 5px;
  width: ${(props) => strengthColor[props.strength.value].width};
  transition: 500ms width;
  background: ${(props) => strengthColor[props.strength.value].color};
  border-radius: 4px;
`;

const StyledOutlinedInput = styled(OutlinedInput)`
  border: 2px solid red !important;
`;

const defaultOptions = [
  {
    id: 0,
    value: "Too weak",
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    value: "Weak",
    minDiversity: 2,
    minLength: 6,
  },
  {
    id: 2,
    value: "Medium",
    minDiversity: 4,
    minLength: 8,
  },
  {
    id: 3,
    value: "Strong",
    minDiversity: 4,
    minLength: 10,
  },
];

export default function ChangePassword({ resetNewPassword, ...props }) {
  const [showPassword, setShowPassword] = React.useState({
    pwd: false,
    confirmPwd: false,
    oldPwd: false,
  });
  const [passwordText, setPasswordText] = React.useState({
    pwd: "",
    confirmPwd: "",
    oldPwd: "",
  });
  
  const [error, setError] = React.useState({ text: "", isError: false });
  const [strength, setStrength] = React.useState(null);
  const [passWordMatched, SetPasswordMatched] = React.useState(false);
  const[buttomDisable,setButtonDisable] = React.useState(true)
  const {
    state: { openPasswordChangeModal, currentUser },
    dispatch,
  } = useGlobalContext();
  const navigate = useNavigate();

  const closePanel = () => {
    dispatch({
      type: "TOGGLE_PASSWORD_MODAL",
      payload: {
        openPasswordChangeModal: false,
      },
    });
  };

  const { mutate: onChangePassword, isLoading: changingPassword } = useMutation(
    (id) => changePassword(id),
    {
      onSuccess: (response) => {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.data.message,
            severity: response?.data?.isError ? "error" : "success",
          },
        });
        if (!response?.data?.isError) {
          if (resetNewPassword) {
            navigate("/");
          } else closePanel();
        }
      },
      onError: (error) => {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: "Something went wrong in our side, Please try again later",
            severity: "error",
          },
        });
      },
    }
  );

  const onNewPasswordChange = (e) => {
    const val = e.target.value;
   
    const bothSame = passwordText.oldPwd && passwordText.oldPwd?.trim() === e.target.value?.trim();
    setError({
      text: "Old Password and New Password should not be same",
      isError: bothSame,
    });

    // const pwdMatch =  passwordText.pwd?.trim() !== passwordText.confirmPwd?.trim();
    // setError({
    //   text: "confirm wd and  New Password not same",
    //   isError: pwdMatch,
    // })
    // const newPwdError = passwordText.pwd?.trim().length < 8 || !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/.test(passwordText.pwd?.trim());
    // setError({
    //   text: "New pwd should be atleast 8 charcters,one lower case,uppercase and one number",
    //   isError: newPwdError,
    // });
    setPasswordText({ ...passwordText, pwd: val });
    if (val) {
      const score = passwordStrength(val, defaultOptions);
      setStrength(score);
    }
  };

  const onConfirmPwdChange = (e) => {
   
    const val = e.target.value;
    setPasswordText({ ...passwordText, confirmPwd: val });
    // SetPasswordMatched(val === passwordText.pwd);
  };
  useEffect(()=>{
    SetPasswordMatched(passwordText.confirmPwd === passwordText.pwd);
  },[passwordText.confirmPwd, passwordText.oldPwd, passwordText.pwd])

  const onSubmit = (e) => {
    e.preventDefault();

    if (passwordText.oldPwd?.trim() === passwordText.pwd?.trim()) {
      setError({
        text: "Old Password and New Password should not be same",
        isError: true,
      });
      dispatch({
        type: "SET_TOAST",
        payload: {
          open: true,
          text: "Old Password and New Password should not be same",
          severity: "error",
        },
      });
    }
   else if (passwordText.pwd?.trim() !== passwordText.confirmPwd?.trim()) {
      setError({
        text: "Confirm Password and New Password not matched",
        isError: true,
      });
      dispatch({
        type: "SET_TOAST",
        payload: {
          open: true,
          text: "Confirm Password and New Password not matched",
          severity: "error",
        },
      });
    }
    else if (passwordText.pwd?.trim().length < 8 || !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/g.test(passwordText.pwd?.trim())) {
      setError({
        text: "New Password should be at least 8 characters long,one upper case and onelower case,one number",
        isError: true,
      });
      dispatch({
        type: "SET_TOAST",
        payload: {
          open: true,
          text: "New Password should be at least 8 characters long,one upper case and onelower case,one number",
          severity: "error",
        },
      });
    }  
    else {
      setError({
        text: "",
        isError: false,
      });
      const requestData = {
        id: JSON.parse(localStorage.getItem("id")),
        oldPassword: passwordText.oldPwd,
        password: passwordText.pwd,
        is_pwd_reset: true,
      };
      onChangePassword(requestData);
    }
  };
useEffect(()=>{
  setButtonDisable(true)
  if (passwordText?.confirmPwd !== "" && passwordText?.oldPwd !== "" && passwordText?.pwd !== "") {
    if (!/^\s*$/.test(passwordText?.confirmPwd) && !/^\s*$/.test(passwordText?.oldPwd) && !/^\s*$/.test(passwordText?.pwd)) {
        setButtonDisable(false);
    }
}
},[passwordText?.confirmPwd, passwordText?.oldPwd, passwordText?.pwd, buttomDisable])
  const DisplayContent = () => {
    return (
      <React.Fragment>
        <Box p="32px" pb="0">
          <Text semiBold color="#494E59" fontSize="18px">
            Password
          </Text>
          <Text color="#707070" fontSize="14px" mt="8px">
            Please enter your old password to change your password
          </Text>
        </Box>
        <form onSubmit={onSubmit}>
          <Box p="32px">
            <Box display="flex" flexDirection="column">
              <Text mb="4px">Old Password</Text>

              <OutlinedInput
                focues
                id="old-password"
                defaultValue=""
                size="small"
                type={showPassword.oldPwd ? "text" : "password"}
                textFieldProps={{ shrink: false }}
                autoComplete="old-password"
                placeholder="Old Password"
                onChange={(e) => {
                  const bothSame = passwordText.pwd && passwordText.pwd?.trim() === e.target.value?.trim()
                    setError({
                      text: "Old Password and New Password should not be sameeeee",
                      isError: bothSame,
                    })
                   
                  
                  setPasswordText({
                    ...passwordText,
                    oldPwd: e.target.value,
                  });
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          oldPwd: !showPassword.oldPwd,
                        })
                      }
                      // onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {!showPassword.oldPwd ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
            <Box display="flex" mt={"16px"} flexDirection="column">
              <Text mb="4px">New Password</Text>
              <OutlinedInput
                focues
                id="new-password"
                defaultValue=""
                size="small"
                type={showPassword.pwd ? "text" : "password"}
                textFieldProps={{ shrink: false }}
                onChange={onNewPasswordChange}
                autoComplete="new-password"
                placeholder="New Password"
                color={strength && strengthColor[strength.value].status}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          pwd: !showPassword.pwd,
                        })
                      }
                      // onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {!showPassword.pwd ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Box mt={"4px"} alignItems="center" display="flex">
                <InfoOutlinedIcon
                  sx={{
                    fontSize: "12px",
                    color: strength && strengthColor[strength.value].color,
                    marginTop: "2px",
                  }}
                />
                <Text
                  color={strength && strengthColor[strength.value].color}
                  
                  ml={"4px"}
                  fontSize="10px">
                  Min 8 characters, with upper, lowercase, number, symbol
                </Text>
              </Box>
              {error.isError && (
                <Box mt="4px" alignItems="center" display="flex">
                  <InfoOutlinedIcon
                    sx={{
                      fontSize: "12px",
                      marginTop: "2px",
                    }}
                    color="error"
                  />
                  <FormHelperText error id="accountId-error">
                    <Text ml="4px" fontSize="10px">
                      {error.text}
                    </Text>
                  </FormHelperText>
                </Box>
              )}
              {strength && (
                <Box
                  mt={"8px"}
                  alignItems="center"
                  justifyContent="space-between"
                  display="flex">
                  <Box width="85%">
                    <StyledStrengthMeter strength={strength} />
                  </Box>
                  <Text
                    color={strengthColor[strength.value].color}
                    ml={"4px"}
                    fontSize="10px">
                    {strengthColor[strength.value].text}
                  </Text>
                </Box>
              )}
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              sx={{ marginTop: "16px" }}>
              <Text mb="4px">Confirm Password</Text>
              <OutlinedInput
                id="confirm-password"
                defaultValue=""
                size="small"
                type={showPassword.confirmPwd ? "text" : "password"}
                onChange={onConfirmPwdChange}
                autoComplete="confirm-password"
                placeholder="Confirm Password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          confirmPwd: !showPassword.confirmPwd,
                        })
                      }
                      // onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {!showPassword.confirmPwd ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
            {passwordText.confirmPwd.length > 0 && (
              <Box mt={"4px"} alignItems="center" display="flex">
                {passWordMatched && (
                  <CheckCircleOutlineIcon
                    sx={{
                      fontSize: "12px",
                      color: strength && strengthColor[strength.value].color,
                    }}
                  />
                )}
                {!passWordMatched && (
                  <CancelOutlinedIcon
                    sx={{
                      fontSize: "12px",
                      color: "red",
                    }}
                  />
                )}
                <Text
                  color={
                    passWordMatched
                      ? strength && strengthColor[strength.value].color
                      : "red"
                  }
                  ml={"4px"}
                  fontSize="10px">
                  {passWordMatched
                    ? "Password matched successfully"
                    : "Password doesn't matches the above"}
                </Text>
              </Box>
            )}
          </Box>
          <StickyFooter normal={resetNewPassword}>
            <React.Fragment>
              <LoadingButton
                loading={false}
                size="small"
                variant="outlined"
                color="primary"
                disableElevation
                onClick={closePanel}
                style={{ textTransform: "none", marginRight: "10px" }}>
                <Text>Cancel</Text>
              </LoadingButton>
              <LoadingButton
                loading={changingPassword}
                type={"submit"}
                size="small"
                variant="contained"
                color="primary"
                disableElevation
                disabled={passwordText.pwd?.trim().length < 8 || !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/g.test(passwordText.pwd?.trim()) ||  !(passWordMatched && passwordText !== "" && !buttomDisable && !error?.isError ) }
                style={{ textTransform: "none" }}>
                <Text>Change Password</Text>
              </LoadingButton>
            </React.Fragment>
          </StickyFooter>
        </form>
      </React.Fragment>
    );
  };
  return !resetNewPassword ? (
    <Modal
      title={"Change Password"}
      open={openPasswordChangeModal}
      toggleDrawer={closePanel}>
      {DisplayContent()}
    </Modal>
  ) : (
    <Card>{DisplayContent()}</Card>
  );
}
