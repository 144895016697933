import { Box } from "@mui/material";
import React, { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useMutation, useQuery } from "@tanstack/react-query";
import Immutable from "immutable";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import {
  getDocuments,
  createOrEditDocument,
  deleteDocument,
} from "../apis/docMaster";
import DataTable from "../components/Molecules/DataTable";
import PageHeader from "../components/Molecules/PageHeader";
import AddDocument from "../components/Organisms/AddDocument";
import {
  deactivated,
  activate,
  selectAll,
  uploadUser,
  addNewUser,
} from "../assets/icons";
import DeletePopUp from "../components/Molecules/PopupModal";
import { useGlobalContext } from "../context/GlobalContext";
import { getColumns } from "../utils/tableHeaders/docMaster";

const pageTitle = "Doc Master";

const DocMaster = () => {
  const [addUserPanel, setAddUserPanel] = React.useState(false);
  const [docMasterData, setDocMasterData] = React.useState([]);
  const [openDeletePopup, toggleDeletePopup] = React.useState(false);
  const { dispatch } = useGlobalContext();
  const [selectedDoc, setSelectedDoc] = React.useState(null);
  // const [filter, updateFilter] = React.useState({});
  const [filter, updateFilter] = React.useState({
    condition: "",
    page: 0,
    perPageSize: 5,
  });
  const [totalCount, setTotalCount] = React.useState(0);
  const [search, updateSearch] = React.useState(null);

  const { data, refetch } = useQuery(
    ["docs", { filter, search }],
    getDocuments,
    {
      manual: true,
      select: (response) => response.get("data", []),
      retry: false,
    }
  );

  const { mutate: addOrUpdateDoc, isLoading } = useMutation(
    (userData) => createOrEditDocument(userData),
    {
      onSuccess: (response) => {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.message,
            severity: response.isError ? "error" : "success",
          },
        });
        // updateFilter({});
        if (!response.isError) {
          setAddUserPanel(false);
          toggleDeletePopup(false);
          refetch();
        }
      },
      onError: (error) => {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: error.response.data.message,
            severity: "error",
          },
        });
      },
    }
  );

  const onSubmit = (data) => {
    let requestData = data;
    if (selectedDoc?.get("id")) {
      requestData["id"] = selectedDoc.get("id");
    }
    requestData = { document: requestData };
    addOrUpdateDoc(requestData);
  };

  const onDeleteUser = () => {
    const requestData = {
      id: selectedDoc.get("id"),
    };
    addOrUpdateDoc(requestData);
    setSelectedDoc(null);
  };

  const toggleDrawer = () => {
    if (addUserPanel) {
      setSelectedDoc(null);
    }
    setAddUserPanel(!addUserPanel);
  };

  const onEditClick = async (id) => {
    const user = data.get("items").find((item) => item.get("id") === id);
    setSelectedDoc(user);
    toggleDrawer();
  };

  const onDeleteClick = (id) => {
    const user = data.get("items").find((item) => item.get("id") === id);
    setSelectedDoc(user);
    toggleDeletePopup(true);
  };

  const onToggleActive = (e, id) => {
    let checked = e.target.checked;
    let requestData = {
      id: id,
      status: checked ? 1 : 0,
    };
    requestData = { document: requestData };
    addOrUpdateDoc(requestData);
  };

  // const onFilter = (status) => {
  //   updateFilter(status);
  //   refetch();
  // };

  const onFilter = (status) => {
    updateFilter({ ...filter, ...status });
    refetch();
  };

  let searchTimeout;

  const searchUsers = (query) => {
    clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      updateSearch(query);
    }, 500);
  };

  const onSearch = (e) => {
    const val = e.target.value;
    searchUsers(val);
  };

  const rightSideActions = [
    // {
    // 	icon: EditIcon,
    // 	options: [
    // 		{ label: "Add New", Icon: addNewUser, onClick: toggleDrawer },
    // 		{ label: "Upload", Icon: uploadUser, EditIcon, onClick: null },
    // 	],
    // 	title: "Add New",
    // 	onClick: null,
    // 	isSplit: true,
    // },
    {
      defaultText: "Add New",
      isDropdown: false,
      isButton: true,
      icon: <PersonAddAltIcon />,
      title: "Add New",
      onClick: toggleDrawer,
      color: "contained",
    },
  ];

  const leftSideActions = [
    {
      options: [
        {
          label: "All Status",
          Icon: selectAll,
          onClick: () => onFilter({ statusFilter: null }),
        },
        {
          label: "Active",
          Icon: activate,
          onClick: () => onFilter({ statusFilter: 1 }),
        },
        {
          label: "InActive",
          Icon: deactivated,
          onClick: () => onFilter({ statusFilter: 0 }),
        },
      ],
      defaultText: "Activate",
      isDropdown: true,
    },
  ];

  useEffect(() => {
    if (data?.get("totalCount") >= 0) {
      setTotalCount(data?.get("totalCount"));
    }
  }, [data?.get("totalCount")]);
  useEffect(() => {
    if (data?.get("items")?.toJS()?.length >= 0)
      setDocMasterData(data?.get("items")?.toJS());
  }, [data?.get("items")]);
  return (
    <React.Fragment>
      <PageHeader
        toggleDrawer={toggleDrawer}
        leftSideActions={[...leftSideActions, ...rightSideActions]}
        // rightSideActions={rightSideActions}
        pageTitle={pageTitle}
        isSearch={true}
        searchOnChange={onSearch}
        totalCount={totalCount}
      />
      <Box sx={{ padding: "15px", height: "100%" }}>
        <DataTable
          loading={false}
          getRowId={(row) => row.id}
          pageSize={filter.perPageSize}
          totalCount={totalCount}
          columns={getColumns({ onToggleActive, onDeleteClick, onEditClick })}
          rows={docMasterData}
          onPageSize={(pageSize) => {
            updateFilter((fil) => ({
              ...fil,
              perPageSize: pageSize,
            }));
          }}
          onPage={(page) => {
            updateFilter((fil) => ({
              ...fil,
              page: page,
            }));
          }}
        />
        {addUserPanel && (
          <AddDocument
            open={addUserPanel}
            toggleDrawer={toggleDrawer}
            onSubmit={onSubmit}
            selectedDoc={selectedDoc || Immutable.Map()}
            isLoading={isLoading}
          />
        )}
      </Box>
      {openDeletePopup && (
        <DeletePopUp
          title="Delete Document"
          closePopup={() => toggleDeletePopup(false)}
          openDeletePopup={openDeletePopup}
          onDelete={() => onDeleteUser()}
          isLoading={isLoading}
        />
      )}
    </React.Fragment>
  );
};

export default DocMaster;
