import { Box, Switch } from "@mui/material";
// import { DeleteOutlineIcon, EditIcon } from "@mui/base";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import Text from "../../components/Atoms/Text";

const HeaderWrapper = (params) => {
  return (
    <Box display="flex" flexWrap="wrap">
      <Text fontSize="14px" semiBold fontWeight="600">
        {params.colDef.headerName}
      </Text>
    </Box>
  );
};

export const getColumns = ({ onToggleActive, onDeleteClick, onEditClick }) => {
  return [
    {
      field: "dmsUserName",
      headerName: "DMS User",
      // flex: 1,
      minWidth: 300,
    },
    {
      field: "fiCode",
      headerName: "FI Code",
      width: 180,
      renderHeader: (params) => HeaderWrapper(params),
    },
    { field: "version", headerName: "Version", width: 100 },
    {
      field: "instUserId",
      headerName: "Inst. User ID",
      width: 180,
      renderHeader: (params) => {
        return (
          <Box display="flex" flexWrap="wrap">
            <Text fontSize="16px" semiBold fontWeight="600">
              {"Inst. User ID"}
            </Text>
          </Box>
        );
      },
    },
    {
      field: "instBranchCode",
      headerName: "Inst. Branch Code",
      width: 180,
      renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "instRegionCode",
      headerName: "Inst. Region Code",
      width: 180,
      renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "ckycBranchCode",
      headerName: "CKYC Branch Code",
      width: 180,
      renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "ckycRegionCode",
      headerName: "CKYC Region Code",
      width: 180,
      renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      disableExport: true,
      renderCell: (params) => {
        return (
          <Switch
            checked={params.value}
            onChange={(e) => onToggleActive(e, params.row.ckycUserId)}
            defaultChecked
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 90,
      sortable: false,
      disableExport: true,
      renderCell: (params) => {
        return (
          <Box display="flex" justifyContent={"space-between"} width="60%">
            <EditIcon
              onClick={() => onEditClick(params.row.ckycUserId)}
              sx={{ cursor: "pointer", marginRight: "8px" }}
            />
            <DeleteOutlineIcon
              onClick={() => onDeleteClick(params.row.ckycUserId)}
              sx={{ color: "red", cursor: "pointer", marginLeft: "8px" }}
            />
          </Box>
        );
      },
    },
  ];
};
