import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CustomerAccordon from "../../Molecules/CustomerAccordon";
import TextInput from "../../Form-Components/TextInput";
import SelectInput from "../../Form-Components/SelectInput";
import RadioGroup from "../../Form-Components/RadioGroup";
import DatePicker from "../../Form-Components/DatePicker";
import { useFieldArray } from "react-hook-form";
import MinorPermanentAddresss from "./MinorPermanentAddresss";
import MinorCurrentAddress from "./MinorCurrentAddress";
import MinorKYCVerification from "./MinorKYCVerification";
import MinorDetailsUpload from "./MinorDetailsUpload";
import {
  getInitialDefaultDocs,
  getInitialIdentityDocs,
  getUniqOptions,
} from "./NewCustomerAccount";
import { addressDocs, defaultDocs, identityDocs } from "./formElements";
import Text from "../../Atoms/Text";
import MinorFlowRelatedPersonDetails from "./MinorFlowRelatedPersonDetails";

const getFatherSpouseLabel = (val) => {
  return val === "01" ? "Father" : "Spouse";
};

const MinorDetails = (props) => {
  const { formProps, dropdownOptions, disableElements, files } = props;

  const { control, register, setValue, getValues, watch } = formProps;

  const fatherSpouseFlagWatcher = watch("relatedFatherSpouseFlag", "01");

  const fatherSpouse = getFatherSpouseLabel(fatherSpouseFlagWatcher);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "minorDetails",
  });
  const [panFormCondition, setPanFormCondition] = React.useState([]);
  const [fromDateValue, setFromDateValue] = React.useState(null);

  const emptyDocs = {
    commonDocs: getInitialDefaultDocs(
      [],
      getUniqOptions(
        [
          ...dropdownOptions["addressProof"],
          ...dropdownOptions["identityProof"],
        ],
        "documentCode"
      ),
      defaultDocs?.filter((ele) => ele.id !== 69),
      true
    ),
    addrDocs: getInitialIdentityDocs(
      [],
      dropdownOptions["addressProof"],
      addressDocs,
      [],
      "address"
    ),
    identityDocs: getInitialIdentityDocs(
      [],
      dropdownOptions["identityProof"],
      identityDocs,
      [],
      "identity"
    ),
  };

  const comDocs = getInitialDefaultDocs(
    files,
    getUniqOptions(
      [...dropdownOptions["addressProof"], ...dropdownOptions["identityProof"]],
      "documentCode"
    ),
    defaultDocs?.filter((ele) => ele.id !== 69),
    true
  );
  const addrDocs = getInitialIdentityDocs(
    files,
    dropdownOptions["addressProof"],
    addressDocs,
    [],
    "address"
  );

  const indtityDocs = getInitialIdentityDocs(
    files,
    dropdownOptions["identityProof"],
    identityDocs,
    [],
    "identity"
  );

  const handleFieldValue = async (index, type) => {
    const formValue = await getValues()?.minorDetails;
    if (type === "district") {
      // const clearTime = setTimeout(async () => {
      //   const districtPromises = formValue.map(async (item, index) => {
      //     const districtsData = await NewDistrictsByState(item?.relatedPermState);
      //     return { district: districtsData }; // Make sure districtsData is clean
      //   });
      //   const resolvedDistrictsOptions = await Promise.all(districtPromises);
      //   setdistrictsOptions(resolvedDistrictsOptions);
      //   clearTimeout(clearTime);
      // }, 10);
    } else if (type === "sameAddress") {
      if (formValue?.[index]?.relatedPermSameAsPermanant) {
        setValue(
          `minorDetails[${index}].AddressProof`,
          formValue?.[index]?.relatedAddressProof
        );
        setValue(
          `minorDetails[${index}].addressNumber`,
          formValue?.[index]?.relatedAddressProofIdNumber
        );
        setValue(
          `minorDetails[${index}].relatedAddressline1`,
          formValue?.[index]?.relatedPermAddressline1
        );
        setValue(
          `minorDetails[${index}].relatedAddressline2`,
          formValue?.[index]?.relatedPermAddressline2
        );
        setValue(
          `minorDetails[${index}].relatedAddressline3`,
          formValue?.[index]?.relatedPermAddressline3
        );
        setValue(
          `minorDetails[${index}].relatedCountry`,
          formValue?.[index]?.relatedPermCountry
        );
        setValue(
          `minorDetails[${index}].relatedState`,
          formValue?.[index]?.relatedPermState
        );
        setValue(
          `minorDetails[${index}].relatedDistrict`,
          formValue?.[index]?.relatedPermDistrict
        );
        setValue(
          `minorDetails[${index}].relatedVillage`,
          formValue?.[index]?.relatdPermvillage
        );
        setValue(
          `minorDetails[${index}].relatedPincode`,
          formValue?.[index]?.relatedPermPincode
        );
      } else {
        setValue(`minorDetails[${index}].AddressProof`, "");
        setValue(`minorDetails[${index}].addressNumber`, "");
        setValue(`minorDetails[${index}].relatedAddressline1`, "");
        setValue(`minorDetails[${index}].relatedAddressline2`, "");
        setValue(`minorDetails[${index}].relatedAddressline3`, "");
        setValue(`minorDetails[${index}].relatedCountry`, "");
        setValue(`minorDetails[${index}].relatedState`, "");
        setValue(`minorDetails[${index}].relatedDistrict`, "");
        setValue(`minorDetails[${index}].relatedVillage`, "");
        setValue(`minorDetails[${index}].relatedPincode`, "");
      }
    } else if (type === "employeeName") {
      const employeeName = await formValue?.[index]?.kycVerificationName;
      const employeeData = await dropdownOptions["employeeNameNew"]?.filter(
        (item, index) => item.dmsUserId === employeeName ?? item
      );
      const designationOptions = await dropdownOptions["employeeName"]?.filter(
        (item, index) => item.id === employeeName ?? item
      );
      setValue(
        `minorDetails[${index}].kycVerifiEmpCode`,
        designationOptions?.[0]?.empCode
      );
      setValue(
        `minorDetails[${index}].kycVerificationDesignation`,
        designationOptions?.[0]?.designation?.designationName
      );
      setValue(
        `minorDetails[${index}].kycVerifiBranch`,
        designationOptions?.[0]?.branch?.branchName
      );
      setValue(`minorDetails[${index}].orgName`, employeeData?.[0]?.instName);
      setValue(`minorDetails[${index}].orgCode`, employeeData?.[0]?.fiCode);
    } else {
      const clearTime = setTimeout(() => {
        const panFormValueArray = formValue?.map((item, index) => {
          return {
            panValue: item?.minorForm16Finished === "Y" ? true : false,
            // formValue: item?.minorPan ? true : false,
          };
        });
        setPanFormCondition(panFormValueArray);
        clearTimeout(clearTime);
      }, 10);
    }
  };

  useEffect(() => {
    const formValue = getValues()?.minorDetails;
    const clearTime = setTimeout(() => {
      const panFormValueArray = formValue?.map((item, index) => {
        return {
          panValue: item?.minorForm16Finished === "Y" ? true : false,
          // formValue: item?.minorPan ? true : false,
        };
      });
      setPanFormCondition(panFormValueArray);
      clearTimeout(clearTime);
    }, 10);
  }, [])
  const addDelFlagOptions = [
    { code: "01", name: "Addition of Related Person" },
   { code: "02", name: "Deletion of Related Person" },
    // {
    //   code: "03",
    //   name: "Modification of Related",
    // },
  ]
  if(watch("applicationType") === "01"){
    addDelFlagOptions.splice(1, 1)
  }
  return (
    <Box>
      <Box mt={"16px"} mb={"20px"}>
        {fields?.map((item, index) => (
          <Box sx={{ mb: 5 }} key={item.id}>
            <Box sx={{ display: "flex", justifyContent: "end", mb: 1 }}>
              <Button
                onClick={() => remove(index)}
                variant="outlined"
                size="small"
                sx={{
                  ml: 1,
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "#FCE0E1",
                    border: "1px solid #EF3C42",
                  },
                  mr: 1,
                  color: "#EF3C42",
                  background: "#FCE0E1",
                  border: "1px solid #EF3C42",
                }}
              >
                Delete
              </Button>
            </Box>
            <Box sx={{ mb: 1 }}>
              <CustomerAccordon title={"Details of Related Person"}>
                <Box display="flex" flexWrap="wrap">
                  <SelectInput
                    label={"Relationship Type"}
                    fieldkey={`minorDetails[${index}].relationshipTitle`}
                    errorText="Relationship Type is required"
                    error={true}
                    placeholder="State"
                    control={control}
                    register={register}
                    setValue={setValue}
                    options={
                      dropdownOptions &&
                      dropdownOptions["relationshipTitle"].filter((item) =>
                        ["1", "2", "3"]?.includes(item?.code)
                      )
                    }
                    valueRenderer={(option) => option?.id}
                    displayName={(option) => option?.name}
                    width="30%"
                    watch={watch}
                    mandatory={true}
                  />
                  <SelectInput
                    label={"Addition/Deletion Flag"}
                    fieldkey={`minorDetails[${index}].addDelFlag`}
                    errorText="Addition/Deletion Flag is required"
                    placeholder="State"
                    control={control}
                    register={register}
                    mandatory={true}
                    setValue={setValue}
                    options={addDelFlagOptions}
                    valueRenderer={(option) => option?.code}
                    displayName={(option) => option?.name}
                    width="30%"
                    watch={watch}
                  />
                  <TextInput
                    label={"CKYC Number"}
                    fieldkey={`minorDetails[${index}].ckycNo`}
                    errorText="This Field  is required"
                    placeholder={"Enter text"}
                    control={control}
                    register={register}
                    mandatory={false}
                    width="30%"
                    setValue={setValue}
                    value={getValues()?.minorDetails?.[index]?.ckycNo}
                  />
                  <Box display="flex" width="100%">
                    <SelectInput
                      label={"Related Person Prefix"}
                      fieldkey={`minorDetails[${index}].title`}
                      errorText="This Field  is required"
                      placeholder="State"
                      control={control}
                      register={register}
                      mandatory={true}
                      setValue={setValue}
                      options={["Mr", "Mrs", "Minor", "Mis", "Dr"]}
                      valueRenderer={(option) => option}
                      displayName={(option) => option}
                      width="15%"
                      watch={watch}
                    />
                  </Box>
                  <TextInput
                    label={"Related Person First Name"}
                    fieldkey={`minorDetails[${index}].relatedPersonFirstName`}
                    errorText="This Field  is required"
                    placeholder={"Enter text"}
                    control={control}
                    register={register}
                    mandatory={true}
                    width="30%"
                    setValue={setValue}
                    value={
                      getValues()?.minorDetails?.[index]?.relatedPersonFirstName
                    }
                  />
                  <TextInput
                    label={"Related Person Middle Name"}
                    fieldkey={`minorDetails[${index}].relatedPersonMiddleName`}
                    errorText="This Field  is required"
                    placeholder={"Enter text"}
                    control={control}
                    register={register}
                    width="30%"
                    setValue={setValue}
                    value={
                      getValues()?.minorDetails?.[index]
                        ?.relatedPersonMiddleName
                    }
                  />
                  <TextInput
                    label={"Related Person Last Name"}
                    fieldkey={`minorDetails[${index}].relatedPersonLastName`}
                    errorText="This Field  is required"
                    placeholder={"Enter text"}
                    control={control}
                    register={register}
                    mandatory={false}
                    width="30%"
                    setValue={setValue}
                    value={
                      getValues()?.minorDetails?.[index]?.relatedPersonLastName
                    }
                  />
                  <Box display="flex" width="100%">
                    <SelectInput
                      label={"Maiden Prefix"}
                      fieldkey={`minorDetails[${index}].maidenTitle`}
                      errorText="This Field  is required"
                      placeholder="State"
                      control={control}
                      register={register}
                      mandatory={false}
                      setValue={setValue}
                      options={["Mr", "Mrs", "Minor", "Mis", "Dr"]}
                      valueRenderer={(option) => option}
                      displayName={(option) => option}
                      width="15%"
                      watch={watch}
                    />
                  </Box>
                  <TextInput
                    label={"Related Person Maiden First Name"}
                    fieldkey={`minorDetails[${index}].maidenFirstName`}
                    errorText="This Field  is required"
                    placeholder={"Enter text"}
                    control={control}
                    register={register}
                    mandatory={false}
                    width="30%"
                    setValue={setValue}
                    value={getValues()?.minorDetails?.[index]?.maidenFirstName}
                  />
                  <TextInput
                    label={"Related Person Maiden Middle Name"}
                    fieldkey={`minorDetails[${index}].maidenMiddleName`}
                    errorText="This Field  is required"
                    placeholder={"Enter text"}
                    control={control}
                    register={register}
                    width="30%"
                    setValue={setValue}
                    value={getValues()?.minorDetails?.[index]?.maidenMiddleName}
                  />
                  <TextInput
                    label={"Related Person Maiden Last Name"}
                    fieldkey={`minorDetails[${index}].maidenLastName`}
                    errorText="This Field  is required"
                    placeholder={"Enter text"}
                    control={control}
                    register={register}
                    mandatory={false}
                    width="30%"
                    setValue={setValue}
                    value={getValues()?.minorDetails?.[index]?.maidenLastName}
                  />
                 <MinorFlowRelatedPersonDetails setFromDateValue={setFromDateValue} fromDateValue={fromDateValue} panFormCondition={panFormCondition}                   formProps={formProps}
                  dropdownOptions={dropdownOptions}
                  index={index}
                  handleFieldValue={handleFieldValue}/>
                </Box>
              </CustomerAccordon>
            </Box>
            <Box mt={"16px"} mb={"20px"}>
              <CustomerAccordon
                title={"Proof of Identity and Permanent Address"}
              >
                <MinorPermanentAddresss
                  formProps={formProps}
                  dropdownOptions={dropdownOptions}
                  index={index}
                  handleFieldValue={handleFieldValue}
                />
              </CustomerAccordon>
            </Box>
            <Box mt={"16px"} mb={"20px"}>
              <CustomerAccordon title={"Current Address Details"}>
                <MinorCurrentAddress
                  formProps={formProps}
                  dropdownOptions={dropdownOptions}
                  index={index}
                  handleFieldValue={handleFieldValue}
                />
              </CustomerAccordon>
            </Box>
            <Box mt={"16px"} mb={"20px"}>
              <CustomerAccordon title={"KYC Verification"}>
                <MinorKYCVerification
                  formProps={formProps}
                  dropdownOptions={dropdownOptions}
                  index={index}
                  handleFieldValue={handleFieldValue}
                />
              </CustomerAccordon>
            </Box>
            <Box mt={"16px"} mb={"20px"}>
              <CustomerAccordon title={"Related Person Document Upload"}>
                <MinorDetailsUpload
                  formProps={formProps}
                  index={index}
                  files={files}
                  dropdownOptions={dropdownOptions}
                  getUniqOptions={getUniqOptions}
                  docs={{ comDocs, addrDocs, indtityDocs }}
                />
              </CustomerAccordon>
            </Box>
          </Box>
        ))}
        <Box mt={"40px"}>
          <Button
            variant="outlined"
            color="error"
            size="medium"
            sx={{
              ml: 1,
              "&.MuiButtonBase-root:hover": {
                bgcolor: "#FCE0E1",
                border: "1px solid #EF3C42",
              },
              mr: 1,
              color: "#EF3C42",
              background: "#FCE0E1",
              border: "1px solid #EF3C42",
            }}
            onClick={() =>
              append({
                // customerNo: "",
                relationshipTitle: "",
                addDelFlag: "",
                ckycNo: "",
                title: "",
                relatedPersonFirstName: "",
                relatedPersonMiddleName: "",
                relatedPersonLastName: "",
                maidenTitle: "",
                maidenFirstName: "",
                maidenMiddleName: "",
                maidenLastName: "",
                relatedFatherSpouseFlag: "",
                fatherSpousePrefix: "",
                fFirstName: "",
                fMiddleName: "",
                fLastName: "",
                motherPrefix: "",
                mfirstName: "",
                mMiddleName: "",
                mLastName: "",
                minorGender: "",
                minorDob: "",
                minorPan: "",
                minorForm16Finished: "",
                nationality: "",
                // Prrof of Identity and permanent Address

                relatedUID: "",
                relatedVoterCard: "",
                NregaJobCard: "",
                nationalPopRegister: "",
                passportNo: "",
                passportExpCode: "",
                drivingLicenseNo: "",
                drivingExpCode: "",
                otherId: "",
                otherProofNumber: "",
                smaDocCode: "",
                smaIdentNumber: "",
                ekycAuthentication: "",

                relatedAddressProof: "",
                relatedAddressProofIdNumber: "",
                relatedProofOfAddresIfOthers: "",
                relatedPermAddressline1: "",
                relatedPermAddressline2: "",
                relatedPermAddressline3: "",
                relatedPermCountry: "IN",
                relatedPermState: "",
                relatedPermDistrict: "",
                relatdPermvillage: "",
                relatedPermPincode: "",
                // Prrof of Identity and current Address
                relatedPermSameAsPermanant: "",
                AddressProof: "",
                addressNumber: "",
                relatedAddressline1: "",
                relatedAddressline2: "",
                relatedAddressline3: "",
                relatedCountry: "IN",
                relatedState: "",
                relatedDistrict: "",
                relatedVillage: "",
                relatedPincode: "",
                // KYC Verification
                typeOfDocSubmitted: "",
                kycVerificationName: "",
                kycVerificationDesignation: "",
                kycVerifiBranch: "",
                kycVerifiEmpCode: "",
                orgName: "",
                orgCode: "",
                kycVerifiDate: "",
                relatedDateOfDeclarion: "",
                relatedPlaceOfDeclarion: "",
                addressDocs: emptyDocs.addrDocs,
                commonDocs: emptyDocs.commonDocs,
                identityDocs: emptyDocs.identityDocs,
              })
            }
          >
            <AddCircleOutlineIcon sx={{ color: "#EF3C42", marginRight: "5px" }} />
            {"Add More Related Person"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MinorDetails;
