import React from "react";
import { Box, TextField, FormHelperText } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useMutation} from "@tanstack/react-query";
import {
  validateCaptcha,
} from "react-simple-captcha";
import CachedIcon from "@mui/icons-material/Cached";

import Text from "../Atoms/Text";
import { loginUserFn } from "../../apis/auth";
import { useGlobalContext } from "../../context/GlobalContext";
import Captcha from "../Molecules/Captcha";
import jwt_decode from "jwt-decode";

const schema = yup
  .object()
  .shape({
    email: yup
      .string()
      // .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    password: yup.string().max(255).required("Password is required"),
    captch_text: yup.string().required(),
  })
  .required();

export default function LoginForm() {
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const { dispatch } = useGlobalContext();

  const { handleSubmit, register, setValue, control, formState } =
    useForm({
      defaultValues: { email: "", password: "", captch_text: "" },
      resolver: yupResolver(schema),
    });

  const { mutate: loginUser, isLoading } = useMutation(
    (userData) => loginUserFn(userData),
    {
      onSuccess: (response) => {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text:
              response?.user?.is_pwd_reset === false
                ? "Please change your password to continute further"
                : "You have successfully logged in",
            severity: "success",
          },
        });
        dispatch({
          type: "SET_LAST_LOGIN",
          payload: response.user.lastLogin,
        });
        dispatch({
          type: "TOGGLE_IS_LOGGEDIN",
        });
        const decodedToken = jwt_decode(response?.user?.token);
        console.log("decodetoken",decodedToken)
        localStorage.setItem("roles", JSON.stringify(decodedToken?.roleName));
        localStorage.setItem("is_pwd_reset",response?.user?.is_pwd_reset);
        localStorage.setItem("id", JSON.stringify(decodedToken?.id));
        localStorage.setItem("cliendid", JSON.stringify(decodedToken?.client_id));
        localStorage.setItem("job",JSON.stringify(decodedToken?.job))
        if (response?.user?.is_pwd_reset === false) {
          localStorage.setItem("path",`/change-password?q=${response.user.reset_token}`);
          navigate(`/change-password?q=${response.user.reset_token}`);
        } else {
          navigate("/");
        }
      },
      onError: (error) => {
        reloadCaptcha();
        setValue("captch_text", "");
        const { errors } = error.response.data;
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text:
              Object.keys(errors).length > 0
                ? `${Object.keys(errors)[0]} ${Object.values(errors)[0]}`
                : error,
            severity: "error",
          },
        });
      },
    }
  );

  // const { data, error: usersEror } = useQuery(["user"], getUser, {
  // 	manual: true,
  // 	retry: false,
  // });

  const doSubmit = (data) => {
    if (validateCaptcha(data.captch_text) === true) {
      return true;
    } else {
      dispatch({
        type: "SET_TOAST",
        payload: {
          open: true,
          text: "Captcha did not match",
          severity: "error",
        },
      });
      setValue("captch_text", "");
      return false;
    }
  };

  const onSubmit = (data) => {
    if (doSubmit(data))
      loginUser({ user: { username: data.email, password: data.password } });
  };

  const reloadCaptcha = () => {
    const reloadElement = document.getElementById("reload_href");
    if (reloadElement) reloadElement.click();
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <form
        style={{ display: "flex", flexDirection: "column" }}
        onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" sx={{ marginTop: "1rem" }}>
          <Text mb="4px">Username</Text>
          <Controller
            render={({ field, fieldState: { isTouched, error } }) => (
              <TextField
                {...field}
                {...register("email")}
                id="email"
                defaultValue=""
                size="small"
                error={isTouched && error}
                helperText={isTouched && error && "Please enter the username"}
                placeholder="Username"
              />
            )}
            name="email"
            control={control}
          />
        </Box>
        <Box display="flex" flexDirection="column" sx={{ marginTop: "1rem" }}>
          <Text mb="4px">Password</Text>
          <Controller
            render={({ field, fieldState: { isTouched, error } }) => (
              <>
                <OutlinedInput
                  {...field}
                  {...register("password")}
                  id="passwprd"
                  error={isTouched && error}
                  helperText={isTouched && error && "Please enter the password"}
                  size="small"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end">
                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {!!error && (
                  <Box ml="14px">
                    <FormHelperText error id="accountId-error">
                      {"Please enter the password"}
                    </FormHelperText>
                  </Box>
                )}
              </>
            )}
            name="password"
            control={control}
          />

          <Box
            display="flex"
            mt="4px"
            justifyContent={"flex-end"}
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/forget")}>
            <Text>Forgot Password?</Text>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          sx={{ marginTop: "1rem", width: "50%" }}>
          <Text mb="4px">Captcha</Text>
          <Box
            display="flex"
            mr="-40px"
            ml={"2px"}
            justifyContent="start"
            alignItems="center">
            <Captcha />
            <CachedIcon
              onClick={reloadCaptcha}
              sx={{ ml: "8px", cursor: "pointer" }}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" sx={{ marginTop: "1rem" }}>
          <Text mb="4px">Type the text displayed above</Text>
          <Controller
            render={({ field, fieldState: { isTouched, error } }) => (
              <TextField
                {...field}
                id="captch_text"
                error={ error}
                helperText={
                  error && "Please enter the valid captcha"
                }
                {...register("captch_text")}
                defaultValue=""
                size="small"
                placeholder="Captcha"
              />
            )}
            name="captch_text"
            control={control}
          />
        </Box>
        <LoadingButton
          loading={formState.isSubmitting || isLoading}
          type={"submit"}
          variant="contained"
          sx={{ marginTop: "1rem" }}
          color="primary"
          disableElevation
          style={{ textTransform: "none" }}>
          <Text>Log in</Text>
        </LoadingButton>
      </form>
    </Box>
  );
}
