import { Box, Button } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Text from "../Atoms/Text";

export default () => {
  const navigate = useNavigate();
  const is_pwd_reset = localStorage.getItem("is_pwd_reset");
  const path = localStorage.getItem("path");

  useEffect(() => {
    return () => {
      if (path) localStorage.clear();
    };
  }, []);

  
  // useEffect(() => {
  //   if(path){
  //     navigate(path);
  //   }
  // }, []); 
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent={"center"}
      height="100vh"
      alignItems="center"
      width="100%"
    >
      <Text mb="16px" fontSize="16px">
        {is_pwd_reset + "" == "false"
          ? "Please set your password"
          : "The page you are looking for is not exist."}
      </Text>
      <Button
        variant="contained"
        onClick={() => {
          navigate(is_pwd_reset + "" == "false" ? path : "/");
          if (is_pwd_reset + "" != "false") {
            localStorage.clear();
            window.location.href = "/";
          }
        }}
      >
        {is_pwd_reset + "" == "false" ? "Set Password" : "Go To Home"}
      </Button>
    </Box>
  );
};
