import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import Text from "../Atoms/Text";
import { useNavigate } from "react-router-dom";
import Icon from "../Atoms/Icon";
import {
  activate,
  addNewUser,
  branchActive,
  branch,
  ckycUsersActive,
  ckycUsers,
  deactivated,
  districtActive,
  districtMaster,
  docMasterActive,
  docMaster,
  documents,
  selectAll,
  turingActive,
  turing,
  uploadActive,
  uploadMaster,
  uploadUser,
  usersActive,
  users,
  dashboardIcon,
  dashboardActiveIcon,
  bulkMaster,
  bulkActive,
  bulkDownloadActive,
  bulkDownloadMaster,
  vector,
  vectoractive
} from "../../assets/icons";
import { useGlobalContext } from "../../context/GlobalContext";

const drawerWidth = 220;

const openedMixin = (theme) => ({
  width: drawerWidth,
  height: "100%",
  top: "107px",
  // borderTopRightRadius: "15px",
  // borderBottomRightRadius: "15px",
  border: "none",
  background: "#EEEEEE",

  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  height: "100%",
  top: "107px",
  // zIndex: 10,
  overflowY: "unset !important",
  border: "none",
  background: "#EEEEEE",
  // background: "#FBFBFF",
  // borderTopRightRadius: "15px",
  // borderBottomRightRadius: "15px",
  width: `calc(${theme.spacing(7)} + 6px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 6px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // marginTop: "-24px",
  marginTop: "-12px",
  minHeight: "32px !important",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

// const Drawer = styled(MuiDrawer, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   width: drawerWidth,
//   flexShrink: 0,
//   whiteSpace: "nowrap",
//   boxSizing: "border-box",
//   // position: "relative",
//   height: "100%",
//   background: "red",
//   // top: "111x",
//   marginTop: "0px",
//   ...(open && {
//     ...openedMixin(theme),
//     "& .MuiDrawer-paper": openedMixin(theme),
//   }),
//   ...(!open && {
//     ...closedMixin(theme),
//     "& .MuiDrawer-paper": closedMixin(theme),
//   }),
// }));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  overflowX: "hidden",
  height: "100vh",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      marginTop: "0px", // Adjust the marginTop value as needed
      position: "relative", // Add this line
      top: "0px",
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      marginTop: "0px", // Adjust the marginTop value as needed
      position: "relative", // Add this line
      top: "0px",
    },
  }),
}));


const StyledList = styled(List)({
  // selected and (selected + hover) states
  padding: "10px 10px",
  "&& .Mui-selected, && .Mui-selected:hover": {
    backgroundColor: "#3f81f4",
    borderRadius: "10px",
    "&, & .MuiListItemIcon-root": {
      color: "white",
    },
  },
});

// TODO: Need to build this dynamically based on role
const adminRoutes = [
  {
    label: "Dashboard",
    route: "/dashboard",
    active: dashboardActiveIcon,
    inactive: dashboardIcon,
  },
  {
    label: "Manage Users",
    route: "/users",
    active: usersActive,
    inactive: users,
  },
  {
    label: "Manage Branch",
    route: "/branch",
    inactive: branch,
    active: branchActive,
  },
  {
    label: "CKYC Mapping",
    route: "/ckyc-user",
    inactive: ckycUsers,
    active: ckycUsersActive,
  },
  {
    label: "Doc Master",
    route: "/doc-master",
    active: docMasterActive,
    inactive: docMaster,
  },
  {
    label: "District Master",
    route: "/district-master",
    active: districtActive,
    inactive: districtMaster,
  },
  {
    label: "Upload Master",
    route: "/upload-master",
    active: uploadActive,
    inactive: uploadMaster,
  },
  {
    label: "Create Customer",
    route: "/create-customer",
    active: usersActive,
    inactive: users,
  },
  {
    label: "MIS Reports",
    route: "/mis-reports",
    active: vectoractive,
    inactive: vector,
  },
];

const makerRoutes = [
  {
    route: "/dashboard",
    label: "Dashboard",
    active: dashboardActiveIcon,
    inactive: dashboardIcon,
  },
  // {
  //   label: "Automation Dashboard",
  //   route: "/automation-dashboard",
  //   active: uploadActive,
  //   inactive: uploadMaster,
  // },
  {
    label: "Bulk Search",
    route: "/bulk-search",
    active: bulkActive,
    inactive: bulkMaster,
  },
  {
    label: "Bulk Download",
    route: "/bulk-download",
    active: bulkDownloadActive,
    inactive: bulkDownloadMaster,
  },

  {
    label: "New Customers",
    route: "/new-customers",
    active: docMasterActive,
    inactive: docMaster,
  },
  {
    label: "Update Customers",
    route: "/update-customers",
    active: usersActive,
    inactive: users,
  },
  {
    label: "MIS Reports",
    route: "/mis-reports",
    active: vectoractive,
    inactive: vector,
  },
];

const checkerRoutes = [
  {
    route: "/dashboard",
    label: "Dashboard",
    active: dashboardActiveIcon,
    inactive: dashboardIcon,
  },
  // {
  //   label: "Automation Dashboard",
  //   route: "/automation-dashboard",
  //   active: uploadActive,
  //   inactive: uploadMaster,
  // },
  {
    label: "Docket Listing",
    route: "/new-customers",
    active: docMasterActive,
    inactive: docMaster,
  },
  {
    label: "CKYC Upload",
    route: "/ckyc-upload",
    active: docMasterActive,
    inactive: docMaster,
  },
  {
    label: "CKYC File Generated",
    route: "/ckyc-file-generated",
    active: docMasterActive,
    inactive: docMaster,
  },
  {
    route: "/ckyc-first-response",
    label: "CKYC First Response",
    inactive: branch,
    active: branchActive,
  },

  {
    route: "/ckyc-second-response",
    label: "CKYC Second Response",
    active: districtActive,
    inactive: districtMaster,
  },
  {
    label: "Bulk Search",
    route: "/bulk-search",
    active: bulkActive,
    inactive: bulkMaster,
  },
  {
    label: "Bulk Download",
    route: "/bulk-download",
    active: bulkDownloadActive,
    inactive: bulkDownloadMaster,
  },
  {
    label: "MIS Reports",
    route: "/mis-reports",
    active: vectoractive,
    inactive: vector,
  },
];

const superAdminRoutes = [
  {
    route: "/dashboard",
    label: "Dashboard",
    active: dashboardActiveIcon,
    inactive: dashboardIcon,
  },
  // {
  //   label: "Automation Dashboard",
  //   route: "/automation-dashboard",
  //   active: uploadActive,
  //   inactive: uploadMaster,
  // },
  {
    label: "Manage Clients",
    route: "/manage-clients",
    active: usersActive,
    inactive: users,
  },
  {
    label: "Doc Master",
    route: "/doc-master",
    active: docMasterActive,
    inactive: docMaster,
  },
  {
    label: "District Master",
    route: "/district-master",
    active: districtActive,
    inactive: districtMaster,
  },
  {
    label: "Upload Master",
    route: "/upload-master",
    active: uploadActive,
    inactive: uploadMaster,
  },
  {
    label: "MIS Reports",
    route: "/mis-reports",
    active: vectoractive,
    inactive: vector,
  },
];

const routes = {
  admin: adminRoutes,
  maker: makerRoutes,
  checker: checkerRoutes,
  superadmin: superAdminRoutes,
};

export default function MiniDrawer() {
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const navigate = useNavigate();
  const {
    state: { currentUser },
  } = useGlobalContext();
  const [allowedRoutes, setAllowedRoutes] = React.useState(routes["admin"]);

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  const onListSelect = (index, route) => {
    setSelectedIndex(index);
    navigate(route);
  };

  const updateSelectedRoute = (newRoutes) => {
    const pathName = window.location.pathname;
    const selected = newRoutes.findIndex((item) => item.route === pathName);
    if (selected != -1){
      setSelectedIndex(selected)
    }
  };

  React.useEffect(() => {
    const role =
      currentUser?.role?.role_name ||
      (window.localStorage.getItem("roles") &&
        JSON.parse(window.localStorage.getItem("roles")));
    if (role) {
      const updatedRoutes = routes[role.toLowerCase()];
      setAllowedRoutes(updatedRoutes);
      updateSelectedRoute(updatedRoutes);
    }
  }, [currentUser?.role]);

  React.useEffect(() => {
    updateSelectedRoute(allowedRoutes);
  }, [ window.location.pathname]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100%", zIndex: 10 }}>
      <Box style={{ background: "red", height: "100%" }}>
        <Drawer variant="permanent" open={open} style={{ background: "white", height: "100%" }}>
          <Box
            style={{
              background: "#FBFBFF",
              marginTop: "12px",
              borderTopRightRadius: "15px",
              borderBottomRightRadius: "15px",
              height: "100%",
              // marginBottom: "138px",
            }}
          >
            <DrawerHeader style={{ minHeight: "32px" }}>
              <IconButton
                onClick={handleDrawerClose}
                style={{
                  background: "#3f81f4",
                  padding: 0,
                }}
              >
                {!open ? (
                  <ChevronRightIcon style={{ color: "white" }} />
                ) : (
                  <ChevronLeftIcon style={{ color: "white" }} />
                )}
              </IconButton>
            </DrawerHeader>
            <StyledList>
              {allowedRoutes.map((item, index) => (
                <Tooltip title={open ? "" : item.label} placement="right" arrow>
                  <ListItem
                    key={item.label}
                    disablePadding
                    sx={{
                      display: "block",
                      height: "45px",
                      borderRadius: "10px",
                      marginBottom: "6px",
                    }}
                    onClick={() => onListSelect(index, item.route)}
                    selected={selectedIndex === index}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 20,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 2 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {selectedIndex === index && (
                          <img
                            src={item.active}
                            style={{
                              height: "18px",
                              width: "18px",
                              color: "#494E59 ",
                            }}
                            alt = "icon img"
                          />
                        )}
                        {selectedIndex !== index && (
                          <img
                            src={item.inactive}
                            style={{ height: "18px", width: "18px" }}
                            alt = "icon img"
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        sx={{
                          opacity: open ? 1 : 0,
                          fontSize: "16px",
                          fontWeight: 500,
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        <Text fontSize="14px" mb={"2px"}>
                          {item.label}
                        </Text>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </Tooltip>
              ))}
            </StyledList>
          </Box>
        </Drawer>
      </Box>
    </Box>
  );
}
