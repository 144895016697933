import React from "react";
import { Box, TextField, Menu, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";

import Text from "../Atoms/Text";
import { useEffect } from "react";

const TextInput = (props) => {
  const {
    label,
    fieldkey,
    defaultValue,
    errorText,
    placeholder,
    control,
    register,
    mandatory,
    setValue,
    options,
    displayName,
    valueRenderer,
    disabled = false,
    width,
    onSelect,
    watch,
    value,
    showNone,
    setReportType,
    onChangeValue = () => {},
  } = props;

  const [selectedValue, setSelectedValue] = React.useState(null);
  const watchValue = watch && watch(fieldkey, null);

  // console.log(label, watchValue, watchValue && watchValue !== "");

  const restConfig = {
    ...((!watchValue || watchValue === "") && {
      displayEmpty: !watchValue || watchValue === "",
    }),

    ...((!watchValue || watchValue === "") && {
      renderValue: () =>
        selectedValue && selectedValue !== "" ? (
          selectedValue
        ) : (
          <p style={{ margin: 0, fontSize: "14px", opacity: 0.5 }}>
            {label ? `Select ` : "Select Option"}
          </p>
        ),
    }),
  };

  useEffect(() => {
    if (watchValue) setSelectedValue(watchValue);
    else {
      setValue(fieldkey, "");
      setSelectedValue("");
    }
  }, [watchValue]);

  const onShowNone = () => {
    setValue(fieldkey, "");
    setSelectedValue("");
  };
  // const memoizedOnChangeValue = useCallback(() => {
  //   onChangeValue();
  //   // ... (other logic in onChangeValue)
  // }, [selectedValue]);
  return (
    <Box
      display="flex"
      flexDirection="column"
      key={fieldkey}
      sx={{
        marginTop: "1rem",
        width: width || "45%",
        maxWidth: width || "45%",
        marginRight: "1rem",
      }}
    >
      <Text mandatory={mandatory} mb="4px">
        {label}
      </Text>
      <Controller
        render={({ field, fieldState: { error } }) => (
          <Box>
            <Select
              //  {...field}
              {...register(fieldkey, {
                required: mandatory
                  ? `${label} is required`
                  : false,
                // onChange: (e) => {
                //     onChangeValue()
                //     setValue(fieldkey, e.target.value);
                //     setReportType && setReportType(e.target.value);
                //      setSelectedValue(e.target.value);
                //      if (onSelect) onSelect(fieldkey, e.target.value);
                // }
              })}
              // displayEmpty={watchValue ? watchValue !== "" : false}
              // renderValue={() =>
              // 	selectedValue && selectedValue !== "" ? selectedValue : "Select"
              // }
              disabled={disabled}
              labelId="demo-select-small"
              id={fieldkey}
              value={value ? value : field?.value}
              rules={{ required: true }}
              name={fieldkey}
              // label="Age"

              sx={{ width: "100%" }}
              MenuProps={{
                sx: { width: "200px", height: "400px", maxHeight: "400px" },
              }}
              size="small"
              inputProps={{ shrink: false }}
              errorText={error && errorText}
              onChange={(e) => {
                onChangeValue();
                setValue(fieldkey, e.target.value);
                setReportType && setReportType(e.target.value);
                setSelectedValue(e.target.value);
                if (onSelect) onSelect(fieldkey, e.target.value);
              }}
              {...restConfig}
            >
              {showNone && (
                <MenuItem
                  style={{ whiteSpace: "normal" }}
                  size="small"
                  value={""}
                >
                  {`Select ${label}`}
                </MenuItem>
              )}
              {options &&
                options.map((option) => {
                  return (
                    <MenuItem
                      style={{ whiteSpace: "normal" }}
                      size="small"
                      value={valueRenderer && valueRenderer(option)}
                    >
                      {displayName(option)}
                    </MenuItem>
                  );
                })}
            </Select>
            {error && !selectedValue && errorText && (
              <Text
                mt={"4px"}
                letterSpacing="0.4px"
                fontSize="12px"
                ml={"16px"}
                color="#d32f2f"
              >
                {errorText}
              </Text>
            )}
          </Box>
        )}
        name={fieldkey}
        control={control}
        placeholder
        defaultValue={defaultValue ? defaultValue : null}
      />
    </Box>
  );
};

export default TextInput;
