import React from "react";
import { Box, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQuery, useQueries } from "@tanstack/react-query";
import Card from "@mui/material/Card";
import { useEffect } from "react";
import { useState } from "react";
import CardContent from "@mui/material/CardContent";
import PersonIcon from "@mui/icons-material/Person";
import dayjs from "dayjs";

import Text from "../../Atoms/Text";
import TextInput from "../../Form-Components/TextInput";
import SelectInput from "../../Form-Components/SelectInput";
import DatePicker from "../../Form-Components/DatePicker";
import StickyFooter from "../../Atoms/StickyFooter";
import Modal from "../../Molecules/Modal";
import PasswordInput from "../../Form-Components/PasswordInput";
import { getUsers } from "../../../apis/users";
import useDropdownOptions from "../../../hooks/useDropdownOptions";
import { formatDate } from "./formUtils";

const CustomerFilter = (props) => {
  const {
    toggleDrawer,
    open,
    isLoading,
    onSubmit,
    filters,
    statuses,
    status,
    selectedCustomer,
  } = props;

  console.log(selectedCustomer);
  const remarksList = selectedCustomer?.remarksList || [];
  console.log("remarsklist",remarksList)
  return (
    <Modal
      title={`${selectedCustomer?.status || ""} Remarks`}
      open={open}
      toggleDrawer={toggleDrawer}>
      <Box
        display="flex"
        flexDirection={"column"}
        sx={{ overflowY: "auto", width: "100%" }}>
        {remarksList.length == 0 && (
          <Box p="24px">
            <Text fontSize="16px">No remarks</Text>
          </Box>
        )}
        {remarksList
              ?.sort(function (a, b) {
                return new Date(b.createdDate) - new Date(a.createdDate);
              })
              ?.map((item, index) => {
                return remarksList.length - 1 !== index ? (
          
            <Box sx={{ margin: "12px 16px", position: "relative" }}>
              <Card>
                <CardContent
                  display="flex"
                  flexWrap="wrap"
                  width="100%"
                  sx={{
                    padding: "16px",
                  }}>
                  {index === 0 && (
                    <Box
                      sx={{
                        position: "absolute",
                        color: "white",
                        top: "4px",
                        right: "-2px",
                        zIndex: "12px",
                        overflow: "visible",
                        background: "#f55e42",
                        padding: "2px 8px",
                        fontSize: "14px",
                        borderTopRightRadius: "4px",
                        borderTopLeftRadius: "4px",
                        borderBottomLeftRadius: "4px",
                        "&::after": {
                          content: '""',
                          position: "absolute",
                          background: "#f55e42",
                          bottom: "-2px",
                          right: "0px",
                          padding: "3px 1px",
                          borderBottomRightRadius: "4px",
                        },
                      }}>
                      <Text fontSize="14px">Latest</Text>
                    </Box>
                  )}
                  <Box display="flex" width="100%">
                    <PersonIcon />
                    <Box
                      px="12px"
                      display="flex"
                      flexDirection="column"
                      flexWrap="wrap">
                      <Box display="flex" aligItems="center" mb="8px">
                        <Text fontSize="16px" semiBold>
                          {`${item.firstName || ""} `}
                          {`${item.lastName || ""}`}
                        </Text>
                        <Box
                          ml="4px"
                          px="4px"
                          py="2px"
                          sx={{
                            backgroundColor: "blue",
                            color: "white",
                            borderRadius: "4px",
                          }}>
                          <Text fontSize="12px">{item.role.toUpperCase()}</Text>
                        </Box>
                      </Box>
                      <Text mb="12px" fontSize="14px">
                        {item.remarks}
                      </Text>
                      <Text fontSize="12px" color="#A1A1A1">
                        {/* 01-Dec-2022 @ 10am */}
                        {item.createdDate  ? dayjs(item.createdDate).format("lll")
                          : ""}
                      </Text>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          ):

          <Box sx={{ margin: "12px 16px", position: "relative" }}>
          <Card>
            <CardContent
              display="flex"
              flexWrap="wrap"
              width="100%"
              sx={{
                padding: "16px",
              }}>
              {index === 0 && (
                <Box
                  sx={{
                    position: "absolute",
                    color: "white",
                    top: "4px",
                    right: "-2px",
                    zIndex: "12px",
                    overflow: "visible",
                    background: "#f55e42",
                    padding: "2px 8px",
                    fontSize: "14px",
                    borderTopRightRadius: "4px",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      background: "#f55e42",
                      bottom: "-2px",
                      right: "0px",
                      padding: "3px 1px",
                      borderBottomRightRadius: "4px",
                    },
                  }}>
                  <Text fontSize="14px">Latest</Text>
                </Box>
              )}
              <Box display="flex" width="100%">
                <PersonIcon />
                <Box
                  px="12px"
                  display="flex"
                  flexDirection="column"
                  flexWrap="wrap">
                  <Box display="flex" aligItems="center" mb="8px">
                    <Text fontSize="16px" semiBold>
                      {`${item.firstName || ""} `}
                      {`${item.lastName || ""}`}
                    </Text>
                    <Box
                      ml="4px"
                      px="4px"
                      py="2px"
                      sx={{
                        backgroundColor: "blue",
                        color: "white",
                        borderRadius: "4px",
                      }}>
                      <Text fontSize="12px">{item.role.toUpperCase()}</Text>
                    </Box>
                  </Box>
                  <Text mb="12px" fontSize="14px">
                    {item.remarks}
                  </Text>
                  <Text fontSize="12px" color="#A1A1A1">
                    {/* 01-Dec-2022 @ 10am */}
                    {item.createdDate  ? dayjs(item.createdDate).format("lll")
                      : ""}
                  </Text>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
          ;
        })}
      </Box>
    </Modal>
  );
};

export default CustomerFilter;
