import { Box } from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useMutation, useQuery } from "@tanstack/react-query";
import Immutable, { update } from "immutable";
import { useEffect } from "react";
import DoneIcon from "@mui/icons-material/Done";
import { v4 as uuidv4 } from "uuid";

import { getAutomationDashboardData } from "../apis/dashboard";
import DataTable from "../components/Molecules/DataTable";
import PageHeader from "../components/Molecules/PageHeader";
import AddUploadMaster from "../components/Organisms/AddUploadMaster";
import {
  deactivated,
  activate,
  selectAll,
  uploadUser,
  addNewUser,
} from "../assets/icons";
import { getClients } from "../apis/manageClients";
import DeletePopUp from "../components/Molecules/PopupModal";
import { useGlobalContext } from "../context/GlobalContext";
import { getColumns } from "../utils/tableHeaders/automationDashboard";
import { importUploadMaster, getImportTemplate } from "../apis/bulkOperations";
import Backdrop from "../components/Molecules/Backdrop";
import UploadFile from "../components/Organisms/UploadFile";
import PlainDatePicker from "../components/Form-Components/PlainDatePicker";
import Text from "../components/Atoms/Text";
import { formatDate } from "../components/Organisms/new-customer/formUtils";
import dayjs from "dayjs";

const pageTitle = "Automation Dashboard";

const AutomationDashboard = () => {
  const [addUserPanel, setAddUserPanel] = React.useState(false);
  const [openDeletePopup, toggleDeletePopup] = React.useState(false);
  const {
    state: { currentUser },
  } = useGlobalContext();
  const [selectedMaster, setSelectedDoc] = React.useState(null);
  const [filter, updateFilter] = React.useState({ condition: "" });
  const [search, updateSearch] = React.useState(null);
  const [clients, setClients] = React.useState([]);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [allMasterData, setAllMasterData] = React.useState([]);
  const [openUploadFile, toggleUploadFile] = React.useState();
  const [showCustomRange, toggleCustomRange] = React.useState(false);
  const [currentClient, setCurrentClient] = React.useState(null);
  const [filterDate, setFilterDate] = React.useState({
    start: null,
    end: null,
  });

  let currentRole =
    JSON?.parse(window.localStorage.getItem("roles")) ||
    currentUser.role.role_name;

  const { data, refetch } = useQuery(
    ["masters", { filter, search }],
    getAutomationDashboardData,
    {
      manual: true,
      select: (response) => response.get("data", []),
      retry: false,
      enabled: false,
    }
  );

  const { data: allClients, refetch: fetchClients } = useQuery(
    ["clients", { filter: {}, search: null }],
    getClients,
    {
      manual: true,
      enabled: false,
      select: (response) => response.getIn(["data", "items"]).toJS(),
      onSuccess: (response) => {
        let options = [];
        // if (response.length > 0) {
        // 	options.push({
        // 		label: "All Clients",
        // 		id: -1,
        // 		onClick: () => onFilter({ clientId: null }),
        // 	});
        // }
        response.forEach((item) => {
          if (item.job === "AUTOMATION")
            options.push({
              label: item.clientName,
              id: item.id,
              onClick: () => setCurrentClient(item.id),
            });
        });
        setClients(options);
        setCurrentClient(response && response[0]?.id);
      },
    }
  );

  useEffect(() => {
    if (currentClient) {
      toggleCustomRange(false);
      updateFilter({
        client_id: currentClient,
        condition: filter.condition,
        applicationType: filter.applicationType,
      });
    }
  }, [currentClient]);

  useEffect(() => {
    if (currentRole && currentRole === "SuperAdmin") {
      fetchClients();
    }
  }, []);

  // useEffect(() => {
  // 	updateFilter({ ...filter, condition: "today" });
  // }, []);

  const onToggleActive = (e, id) => {};

  useEffect(() => {
    refetch();
  }, [filter]);

  const onFilter = (status) => {
    toggleCustomRange(false);
    updateFilter({ ...filter, ...status });
  };

  let searchTimeout;

  const searchUsers = (query) => {
    clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      updateSearch(query);
    }, 500);
  };

  const onSearch = (e) => {
    const val = e.target.value;
    searchUsers(val);
  };

  const onCustomRange = () => {
    console.log(filterDate);
    updateFilter({
      ...filter,
      condition: {
        fromDate: formatDate(dayjs(filterDate.start)?.format("MM-DD-YYYY")),
        endDate: formatDate(dayjs(filterDate.end)?.format("MM-DD-YYYY")),
      },
    });
    refetch();
  };

  const customDateRange = () => {
    return (
      <Box alignItems="baseline" display="flex" sx={{ width: "max-content" }}>
        <PlainDatePicker
          onChange={(val) => setFilterDate({ start: val, end: "" })}
        />
        <Text mr="4px" ml="4px">
          to
        </Text>
        <PlainDatePicker
          value={filterDate.end}
          onChange={(val) => setFilterDate({ ...filterDate, end: val })}
          maxDate={filterDate && filterDate?.start?.add("30", "day")}
          disabled={!filterDate.start}
          minDate={filterDate && filterDate?.start?.add("1", "day")}
        />
        <DoneIcon
          sx={{ color: "green", marginLeft: "8px", marginRight: "6px" }}
          onClick={onCustomRange}
        />
      </Box>
    );
  };

  const leftSideActions = [
    ...[
      currentRole === "SuperAdmin" &&
        clients.length > 0 && {
          options: clients,
          isDropdown: true,
          explicit: true,
          title: "Clients",
          defaultText: clients && clients[0],
        },
    ],
  ];

  console.log(data);

  const totalData =
    (data && data.get("result") && data.get("result").toJS()) || {};

  return (
    <React.Fragment>
      <PageHeader
        toggleDrawer={null}
        leftSideActions={leftSideActions}
        // rightSideActions={rightSideActions}
        // leftSideActions={[]}
        pageTitle={pageTitle}
        isSearch={false}
        searchOnChange={onSearch}
        totalCount={data?.get("totalCount", 0)}
      />
      <Box
        sx={{
          padding: "15px",
          height: "90%",
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          overflowY: "scroll",
        }}>
        {Object.keys(totalData).map((x) => {
          console.log(
            totalData,
            totalData[x],
            totalData[x] && totalData[x].length > 0
          );
          return totalData[x] && totalData[x].length > 0 ? (
            <Box m="8px" height="100%">
              <Text fontSize="16px" semiBold ml="8px" mt="8px" mb="8px">
                {x?.replaceAll("_", " ").charAt(0).toUpperCase() +
                  x?.replaceAll("_", " ").slice(1)}
              </Text>
              <Box
                m="8px"
                p="4px"
                sx={{
                  border: "0.2px solid lightgray",
                  borderRadius: "10px",
                  height: "90%",
                }}>
                <DataTable
                  loading={false}
                  getRowId={(row) => {
                    console.log(
                      row,
                      Object.keys(row)[0],
                      getColumns(totalData[x][0])
                    );
                    return Object.keys(row)[0] + uuidv4() + Object.keys(row)[1];
                  }}
                  pageSize={10}
                  totalCount={totalData[x].length}
                  columns={getColumns(totalData[x][0])}
                  rows={totalData[x]}
                />
              </Box>
            </Box>
          ) : null;
        })}
      </Box>

      {/* <Backdrop loading={uploadingDocs} /> */}
    </React.Fragment>
  );
};

export default AutomationDashboard;
