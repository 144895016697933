import axiosInstance from "./axios";
import Immutable from "immutable";

export const createOrEditDistrict = async (district) => {
	let response = null;
	try {
		if (district?.id)
			response = await axiosInstance.post(`/districts/delete`, district);
		else if (district.district.id)
			response = await axiosInstance.post("/districts/update", district);
		else response = await axiosInstance.post("/districts", district);
		if(!response?.data?.isError && (district?.district?.status === 1 || district?.district?.status === 0)){
			response.data["message"] = response.data["message"]?.replace("updated",district?.district?.status ? "enabled" : "disabled");
		}
		return response.data;
	} catch (e) { }
};

export const getDistricts = async ({ queryKey }) => {
	const [_key, { filter, search }] = queryKey;
	const fils = { ...filter };
	const pageSize = filter.perPageSize || 100;
	const stateCodeFilter = filter.stateCodeFilter;

	try {
		let baseUrl = `/districts/?perPage=${pageSize}&offset=${filter?.page ? filter.page : 0}`;
		if (stateCodeFilter) {
			baseUrl = baseUrl + `&stateCodeFilter=${stateCodeFilter}`
		}
		delete fils.page;
		delete fils.perPageSize;
		delete fils.condition;
		let filtered = baseUrl;
		if (filter?.statusFilter >= 0 && filter?.statusFilter != null && Object.keys(filter).length > 0) {
			const keys = Object.keys(fils);
			keys.forEach((item) => {
				if (!["", null, undefined].includes(filter[item]))
					filtered = `${filtered}&${item}=${filter[item]}`;
			});
		}
		if (![null, "", undefined].includes(search))
			filtered = `${filtered}&search=${search}`;

		const response = await axiosInstance.get(filtered);
		return Immutable.fromJS(response.data);
	} catch (e) { }
};
export const getNewDistricts = async ({ queryKey }) => {
	const [_key, { filter }] = queryKey;

	try {
		const response = await axiosInstance.post(`/districts/fetchDataDistricts`, filter);
		return response?.data;
	} catch (e) { }
};

export const getNewPincode = async ({ queryKey }) => {
	const [key, { filter }] = queryKey;
	try {
		const response = await axiosInstance.post(`/districts/fetchDataDistricts`, filter);
		return response?.data;
	} catch (e) { }
}

export const getDistrict = async (id) => {
	const response = await axiosInstance.get(`/districts/${id.queryKey[1]}`);
	return response.data;
};

export const bulkDownload = async (bulk) => {
	try {
		const response = await axiosInstance.post(`/customers/bulkDownload`, bulk);
		return response.data;
	} catch (e) { }
};

export const bulkDownloadOther = async (bulk) => {
	const type = bulk.type;
	delete bulk.type;
	try {
		const response = await axiosInstance.post(`/customers/multiple_update_status${type ? `?type=${type}` : ""}`, bulk);
		return response.data;
	} catch (e) { }
};

export const bulkSearchApi = async (data) => {
	try {
		const response = await axiosInstance.post(`/customers/bulkSearch`, data);
		return response.data;
	} catch (e) { }
};

export const searchUploadFile = async (data) => {
	const headers = {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	};
	const response = await axiosInstance.post(
		`customers/bulkSearchResponse`,
		data,
		headers
	);

	return response.data;
};
export const downloadUploadFile = async (data) => {
	const headers = {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	};
	const response = await axiosInstance.post(
		`customers/bulkDownloadResponse`,
		data,
		headers
	);

	return response.data;
};

