import { LoadingButton } from "@mui/lab";
import { Box, Button } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import Text from "../../Atoms/Text";

import Paper from "@mui/material/Paper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  deleteFiles,
  updateCustomer,
  updateStatus,
  uploadDocs,
} from "../../../apis/newCustomer";
import { useGlobalContext } from "../../../context/GlobalContext";
import AlertModal from "../../Molecules/AlertModal";
import Modal from "../../Molecules/Modal";
import NewCustomerForm from "../new-customer/NewCustomerForm";
import MinorDetails from "./MinorDetails";
import PreviewDetails from "./PreviewDetails";
import UploadDocument from "./UploadDocument";
import {
  addressDocs,
  applicantDeclaration,
  constructStageFlags,
  currentAddress,
  currentProofOfAddress,
  defaultDocs,
  fieldValues,
  identityDocs,
  kycVerification,
  minorFlow,
  officeUseOnly,
  permanantAddress,
  personalDetails,
  proofOfAddress,
} from "./formElements";
import {
  arrCodes,
  arrCodesCaptal,
  constructRequestObject,
  formDefaultValues,
} from "./formUtils";
import { getImageName } from "./utils";
import { addressByIdentityMandatoryArray } from "../../../utils";
import { Watch } from "@mui/icons-material";
import MainKYCVerification from "./MainKYCVerification";
export const getUniqOptions = (options, key) => {
  // return _.uniqBy(options, (x) => (key ? x[key] : x?.name));
  return options
};

const schema = (validations, activeStep) => {
  if ([5].includes(activeStep)) return;
  let currentValidations = {};
  console.log("active step index 4", activeStep);
  if (activeStep === 4) {
    console.log("active step index 4");
    currentValidations["identityDocs"] = yup.array(
      yup.object({
        number: yup.string().matches(/^[0-9]*$/, "Enter valid phone number"),
      })
    );
  } else {
    validations
      .filter((item) => item)
      .forEach((item) => {
        if (item.type === "string")
          currentValidations[item.key] = yup
            .string()
            .max(255)
            .required("Required field");
        if (item?.type === "custom") {
          currentValidations[item.key] = yup
            .string()
            .matches(item?.test, item?.message);
        }
        if (item.type === "date") {
          console.log("coming date");
          currentValidations[item.key] = yup
            .string()
            .test("date_not_null", "Required", (value) => {
              return value && value !== "";
            });
        }

        if (item.type === "bool") currentValidations[item.key] = yup.bool();
      });
  }
  return yup.object().shape(currentValidations).required();
};

const currentValidations = (array, activeStep, disableElements) => {
  if (activeStep === 5) return [];
  return (
    array &&
    array.map((item) =>
      item.mandatory && !disableElements?.includes(item.key)
        ? {
          key: item.key,
          type:
            item?.validation !== undefined
              ? "custom"
              : !["checkbox"].includes(item.type)
                ? item.type === "date"
                  ? "date"
                  : "string"
                : "bool",
          test: item?.validation?.test,
        }
        : undefined
    )
  );
};

const constructObject = (values, getValues, defaultValues) => {
  const reqValues = constructRequestObject(values);
  console.log({ values: getValues(), defaultValues });
  if (defaultValues["applicationType"] === "03") {
    const stageFlags = constructStageFlags(getValues(), defaultValues);
    reqValues["updateFlags"] = stageFlags;
  }
  console.log("new customer account", reqValues);
  return reqValues;
};

const addressDocNeeded = (dropdownOptions, values) =>
  values("identityDocs").every(
    (i) =>
      dropdownOptions["identityProof"].find(
        (p) => p?.docCode === i?.documentType
      )?.address === "N"
  );

const getImageId = (dropdownOptions, id) => {
  const files = [
    ...defaultDocs,
    ...dropdownOptions["identityProof"],
    ...dropdownOptions["addressProof"],
  ];
  let proof = files.find((img) => parseInt(img.documentCode) === id);
  return proof?.documentType;
};

const getDocCode = (dropdownOptions, id) => {
  const files = [
    ...defaultDocs,
    ...dropdownOptions["identityProof"],
    ...dropdownOptions["addressProof"],
  ];
  let proof = files.find(
    (img) => parseInt(img.documentCode) === id || img.docCode === id
  );
  console.log("docs fixed", proof);
  return proof?.documentType;
};

export const getInitialDefaultDocs = (files = [], options, docs, fromMinor) => {

  const myDocNames = files?.map((item) => item.documentCode);
  let arr2 = fromMinor ? ["photograph", ...arrCodes] : ["signature", "photograph", ...arrCodes];

  const filteredDocs = options
    .filter((item) => arr2?.includes(item?.documentName?.toLowerCase()))
    ?.filter((item) => myDocNames?.includes(item?.documentCode));
  filteredDocs.forEach((ele) => {
    ele.docCode = parseInt(ele.documentCode);
  });
  docs.forEach((ele) => {
    ele.docCode = parseInt(ele.docCode);
  });
  let selectedFiles =
    files && files.filter((file) => file.docCateg === "common");

  selectedFiles?.forEach((ele) => {
    ele.docCode = parseInt(ele.documentCode);
  })

  let finalProofs = getUniqOptions(
    [...filteredDocs, ...selectedFiles, ...docs],
    "documentCode"
  );

  let filteredEles = finalProofs
    ?.map((item) => {
      let file =
        files &&
        files.find((i) => parseInt(i.documentCode) === parseInt(item.documentCode));
      const docname = getImageName(
        { documentType: item.documentCode},
        { identityProof: options }
      );

      let arr = fromMinor ? ["Photograph"] : ["Photograph", "Signature"];
      return arr.includes(docname) ||
        selectedFiles?.find((ele) => ele.docCode == parseInt(item.documentCode))
        ? {
          ...item,
          ...(file && { file: file ? file : item }),
          id: file?.id || "null",
          docCode: parseInt(item.documentCode),
          name: docname,
          label: docname,
          key: "commonDocs",
          mandatory: docname === "Photograph" || false,
          documentType: parseInt(item.documentCode),
          number: file?.number,
          remarks: file?.remarks,
          docTypeNeeded: !["Photograph", "Signature"].includes(docname),
          numberNeeded: !["Photograph", "Signature"].includes(docname),
          canBeDeleted: false,
        }
        : false;
    })
    ?.filter((ele) => ele !== false);
  // return filteredEles;
  return _.uniqBy(filteredEles, "documentCode");
};

export const getInitialIdentityDocs = (
  files = [],
  options,
  docs,
  subDocs,
  catagory
) => {
  const optionIds = options
    ?.filter(
      (item) =>
        ![
          "signature",
          "photograph",
          "pan",
          "Documents issued by Government departments of foreign jurisdictions and letter issued by Foreign Embassy or Mission in India."?.toLowerCase(),
          "Pension or family pension payment orders (PPOs) issued to retired employees by Government Departments or Public Sector Undertakings, if they contain the address."?.toLowerCase(),
          "Bank account or Post Office savings bank account statement.or Municipal Tax receipt"?.toLowerCase(),
          "Bank account or Post Office savings bank account statement."?.toLowerCase(),
          "Utility bill which is not more than two months old of any service provider (electricity, telephone, post-paid mobile phone, piped gas, water bill)."?.toLowerCase(),
          "Letter of allotment of accommodation from employer issued by State or Central Government departments, statutory or regulatory bodies, public sector undertakings, scheduled commercial banks, financial institutions and listed companies"?.toLowerCase(),
          "Simplified Measures Account  - Letter issued by a gazetted officer, with a duly attested photograph of the person"?.toLowerCase(),
          "Simplified Measures Account  - Identity card with applicant’s photograph issued by Central/ State Government Departments, Statutory/ Regulatory Authorities, Public Sector Undertakings, Scheduled Commercial Banks, and Public Financial Institutions"?.toLowerCase(),
          "National Population Register Letter"?.toLowerCase(),
          "National Population Registry Letter"?.toLowerCase(),
        ].includes(item.name?.toLowerCase())
    )
    .map((f) => parseInt(f.documentCode));
  let selectedFiles =
    files &&
    files.filter(
      (file) => (optionIds.includes(parseInt(file.docCode)) || optionIds.includes(parseInt(file.documentCode))) && file?.docCateg === catagory
    );

  if (selectedFiles && selectedFiles?.length > 0) {
    selectedFiles = selectedFiles?.map((item) => {
      return {
        ...docs[0],
        id: item?.id || "null",
        file: item,
        documentType: parseInt(item.documentCode),
        remarks: item.remarks,
        number: item?.number,
        docCode: parseInt(item.docCode),
        mandatory: catagory !== "address",
        // canBeDeleted: true,
      };
    });

    return selectedFiles;
  }

  return docs.map((item) => {
    return {
      ...item,
      id: item?.id || "null",
    };
  });
};

export default function NewCustomerAccount({
  appType,
  open,
  closePanel,
  toggleDrawer,
  customerInfo,
  dropdownOptions,
  files,
  fetchCustomerInfo,
  currentStep,
  getMappedLabel,
  openUpdateForm,
}) {
  const {
    state: { openPasswordChangeModal, currentUser },
    dispatch,
  } = useGlobalContext();
  const filesImageData = files;
  const [activeStep, setActiveStep] = React.useState(currentStep || 0);
  const defaultValues = formDefaultValues(customerInfo, files);
  const [showMinor, setShowMinorDetails] = React.useState(false);
  const [disableElements, setDisableElements] = React.useState([]);
  const [addedDocs, setAddedDocs] = React.useState({
    uploadCommon: [],
    identityDocs: [],
    addressDocs: [],
    files: [],
    filesData: [],
  });
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [isCountry, setIsCountry] = React.useState("");
  const [isCurrentCountry, setCurrentCountry] = React.useState("");
  const comDocs = getInitialDefaultDocs(
    files?.filter((ele) => !ele?.related_person_index),
    getUniqOptions(
      [...dropdownOptions["addressProof"], ...dropdownOptions["identityProof"]],
      "documentCode"
    ),
    defaultDocs
  );

  const addrDocs = getInitialIdentityDocs(
    files?.filter((ele) => !ele?.related_person_index),
    dropdownOptions["addressProof"]?.filter(item => item?.addressProofCode),
    addressDocs,
    addedDocs.addressDocs,
    "address"
  );

  const indtityDocs = getInitialIdentityDocs(
    files?.filter((ele) => !ele?.related_person_index),
    dropdownOptions["identityProof"]?.filter(item => item?.identityCode),
    identityDocs,
    addedDocs.identityDocs,
    "identity"
  );
  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: { errors, isDirty, dirtyFields, isValid, ...rest },
    watch,
    getValues,
  } = useForm({
    mode: "all",
    defaultValues: customerInfo
      ? {
        ...defaultValues,
      }
      : null,
  });
  const steps = [
    {
      label: "For office use only",
      formElements: officeUseOnly,
      ...(activeStep === 5 && watch("accountType") === "05"
        ? {
          displayName: "For office use only",
          subDivs: {
            label: "Related Person Details",
            formElements: minorFlow,
          },
        }
        : {}),
    },
    {
      label: "Personal Details",
      formElements: personalDetails,
    },
    {
      label: "Proof of Address",
      formElements: proofOfAddress,
      displayName: "Permanent Address",
      subDivs: {
        label: "Current Address",
        formElements: currentProofOfAddress,
      },
    },
    // {
    // 	label: "FATCA/CRS Details",
    // 	formElements: fatca,
    // },
    {
      label: "KYC Verification",
      formElements: kycVerification,
      subDivs: {
        label: "Applicant Declaration",
        formElements: applicantDeclaration,
      },
    },
    {
      label: "Upload",
      component: <UploadDocument />,
    },
    {
      label: "Preview",
      component: <PreviewDetails />,
    },
  ];
  console.log("new customer account files", {files: files})
  const [hideElements, setHideElements] = React.useState([]);
  const [openFatherMotherModal, toggleFatherMotherModal] =
    React.useState(false);
  const sameAsPermanent = watch("sameAsPermanant");
  const relatedSameAsPermanent = watch("relatedPermSameAsPermanant");
  const watchDistrict = watch("district");
  const watchCurrentDistrict = watch("currentDistrict");
  const watchState = watch("state");
  const watchCurrentState = watch("currentState");

  const applicationType = watch("applicationType", appType);
  const watchCkycUser = watch("employeeName");

  // officeUseOnly values
  const constitutionType = watch("constitutionType");
  const customerNumber = watch("customerNumber");
  const customerBranch = watch("customerBranch");
  const kycNumber = watch("kycNumber");
  const accountType = watch("accountType");
  const applicationTypes = watch("applicationType");

  const title = watch("title");
  const personalFirstName = watch("personalFirstName");
  const personalLastName = watch("personalLastName");
  const dependentType = watch("dependentType");
  const personalMobile = watch("personalMobile");
  const personaPan = watch("personaPan");
  const pesonalDob = watch("pesonalDob");
  const personalGender = watch("personalGender");
  const pesonalForm16 = watch("pesonalForm16");
  // proofOfAddress values
  const addressProof = watch("addressProof");
  const addressProofIdNumber = watch("addressProofIdNumber");
  const line1 = watch("line1");
  const state = watch("state");
  const district = watch("district");
  const city = watch("city");
  const pincode = watch("pincode");
  // current address
  const currentAddressProof = watch("currentAddressProof");
  const currentLine1 = watch("currentLine1");
  const currentState = watch("currentState");
  const currentDistrict = watch("currentDistrict");
  const currentCity = watch("currentCity");
  const currentPincode = watch("currentPincode");
  const currentAddressProofIdNumber = watch("currentAddressProofIdNumber");

  // kycVerification values
  const documentRecieved = watch("documentRecieved");
  const verificationDate = watch("kycVerifiDate");
  const employeeName = watch("employeeName");
  const employeeCode = watch("employeeCode");
  const employeeDesignation = watch("employeeDesignation");
  const employeeBranch = watch("employeeBranch");
  const instCode = watch("instCode");
  const instName = watch("instName");
  const dateOfDeclaration = watch("dateOfDeclaration");
  const placeOfDeclaration = watch("placeOfDeclaration");

  //
  const identityDoc = watch("identityDocs");
  const commonDoc = watch("commonDocs");
  const photograph = commonDoc?.filter(
    (items) => items?.name === "Photograph" && !items?.file
  );
  const demoValues = commonDoc?.filter(
    (items, index) =>
      items?.mandatory &&
      (!items?.documentType || !items?.number || !items?.file) &&
      items?.name !== "Photograph"
  );

  const addressDoc = watch("addressDocs");
  const county = watch("country");
  const currentCountry = watch("currentCountry");
  const currentProofOfAddresIfOthers = watch("currentProofOfAddresIfOthers");
  const proofOfAddresIfOthers = watch("proofOfAddresIfOthers");
  // let IDValues = []
  // commonDoc?.map((items, index)=>(
  //   IDValues.push(items?.docCode ? items?.docCode : items?.documentType)
  // ))
  // identityDoc?.map((items, index)=> (
  //   IDValues.push(items?.documentType)
  // ))
  // addressDoc?.map((items, index)=>(
  //   IDValues.push(items?.documentType)
  // ))

  // minor flow button button functionality.

  const minorFlowData = watch("minorDetails");
  const minorMandatoryFields = [
    "relationshipTitle",
    "addDelFlag",
    "title",
    "relatedPersonFirstName",
    "fatherSpousePrefix",
    "fFirstName",
    "minorGender",
    "minorDob",
    "relatedAddressProof",
    "relatedAddressProofIdNumber",
    "relatedPermAddressline1",
    "relatedPermState",
    "relatedPermDistrict",
    "relatdPermvillage",
    "relatedPermPincode",
    "AddressProof",
    "addressNumber",
    "relatedAddressline1",
    "relatedState",
    "relatedDistrict",
    "relatedVillage",
    "relatedPincode",
    "typeOfDocSubmitted",
    "kycVerifiDate",
    "kycVerificationName",
    "kycVerifiEmpCode",
    "kycVerificationDesignation",
    "kycVerifiBranch",
    "orgName",
    "orgCode",
    "relatedDateOfDeclarion",
  ];
  const minorEmptyFields = [];
  for (const obj of minorFlowData) {
    for (const key of minorMandatoryFields) {
      if (obj[key] === "" || obj[key] === undefined) {
        minorEmptyFields.push(key);
      }
    }
  }

  useEffect(() => {
    if (comDocs && indtityDocs && addrDocs) {
      setValue("commonDocs", comDocs);
      setValue("identityDocs", indtityDocs);
      setValue("addressDocs", addrDocs);
    }
  }, []);
  useEffect(() => {
    setIsCountry(county);
    setCurrentCountry(currentCountry);
    fieldValues(county, currentCountry);
  }, [currentCountry, county]);
  const formProps = {
    control,
    register,
    setValue,
    getValues,
    watch,
    isDirty,
    errors,
    dirtyFields,
  };

  const { mutate: updateCkycStatus, isLoading: updatingStatus } = useMutation(
    (userData) => updateStatus(userData),
    {
      onSuccess: (response) => {
        if (response.isError) {
          dispatch({
            type: "SET_TOAST",
            payload: {
              open: true,
              text: response.message,
              severity: "error",
            },
          });
        } else {
          if (activeStep === 5) {
            dispatch({
              type: "SET_TOAST",
              payload: {
                open: true,
                text: "Status updated Successfully",
                severity: "success",
              },
            });
            closePanel();
          }
          if ([0, 1, 2, 3]?.includes(activeStep) && isSubmit) {
            setIsSubmit(false);
            handleNext();
          } else if (activeStep === 4 || activeStep === 5) {
            handleNext();
          }
        }
      },
    }
  );

  const { mutate: removeFiles, isLoading: deletingFiles } = useMutation(
    (userData) => deleteFiles(userData),
    {
      onSuccess: (response) => {
        if (response.isError) {
          // dispatch({
          // 	type: "SET_TOAST",
          // 	payload: {
          // 		open: true,
          // 		text: response.message,
          // 		severity: "error",
          // 	},
          // });
        } else {
          // handleNext();
          // setRecentlyUpdate(null);
          // if (activeStep === 5) {
          // 	dispatch({
          // 		type: "SET_TOAST",
          // 		payload: {
          // 			open: true,
          // 			text: "Status updated Successfully",
          // 			severity: "success",
          // 		},
          // 	});
          // 	closePanel();
          // }
        }
      },
    }
  );

  const {
    mutate: updateCkycUser,
    isLoading,
    isSuccess: stepUpdated,
  } = useMutation((userData) => updateCustomer(userData), {
    onSuccess: (response) => {
      if (response.isError) {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.message,
            severity: "error",
          },
        });
      } else {
        if (activeStep !== 5) {
          setIsSubmit(true);
          updateCkycStatus({ customerId: customerInfo.customerId, status: 3 });
        }
        fetchCustomerInfo();
        // dispatch({
        // 	type: "SET_TOAST",
        // 	payload: {
        // 		open: true,
        // 		text: response.message,
        // 		severity: "success",
        // 	},
        // });
        // updateFilter(null);
        // setAddUserPanel(false);
        // toggleDeletePopup(false);
        // refetch();
      }
    },
    onError: (error) => { },
  });

  const {
    mutate: uploadDocuments,
    isLoading: uploadingDocs,
    // isSuccess: stepUpdated,
  } = useMutation((userData) => uploadDocs(userData), {
    onSuccess: (response) => {
      if (response.isError) {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.message,
            severity: "error",
          },
        });
      } else {
        if (activeStep !== 5) {
          updateCkycStatus({ customerId: customerInfo.customerId, status: 3 });
        }
        fetchCustomerInfo();
      }
    },
    onError: (error) => { },
  });

  // useEffect(() => {
  //   if(Object.keys(errors).length > 0){
  // 		const keys = Object.keys(errors)
  // 		const values = Object.values(errors)

  // 	}
  // }, [errors])

  // console.log(errors, activeStep);
  // console.log(currentValidations(steps[activeStep].formElements));

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const postRequest = (e) => {
    e.preventDefault();
    if (activeStep === 5) {
      updateCkycStatus({ customerId: customerInfo.customerId, status: 4 });
    } else handleNext();
  };

  const getExistedFile = (id) => {
    return files.find((item) => parseInt(item.documentCode) === id);
  };

  const getIdsToRemove = (fileDetails) => {
    console.log("new customer account file", {
      fileDetails: fileDetails,
      files: files,
    });
    let formValues = fileDetails;
    let fileIds;
    if (activeStep === 0) {
      fileIds = files.map((x) => (x.docCateg === "minor" ? x?.id : undefined));
    } else {
      fileIds = files.map((x) => !["minor"].includes(x.docCateg) ? x?.id : undefined);
    }
    const idsToRemove = formValues.map((x) => x?.id);
    fileIds = fileIds
      .map((x) => (!idsToRemove.includes(x) ? x : undefined))
      .filter((x) => x && x.id !== "null");

    return fileIds;
  };

  const uploadFiles = async () => {
    let formData = new FormData();
    let requestDetails = [];
    let files = [];
    let signatureFileNeeded = false;
    let numberRequiredError = false;

    if (
      activeStep === 0 &&
      watch("accountType") === "05" &&
      watch("constitutionType") === "01"
    ) {
      const RelatedPerson = getValues("minorDetails");
      let formData = new FormData();

      const RelatedFileData = RelatedPerson?.map((item, index) => {
        const commonDocs = getValues(`minorDetails.${index}.commonDocs`)?.map(
          (ele) => ({ ...ele, docCateg: "common" })
        );
        const addressDocs = getValues(`minorDetails.${index}.addressDocs`)?.map(
          (ele) => ({ ...ele, docCateg: "address" })
        );
        const identityDocs = getValues(
          `minorDetails.${index}.identityDocs`
        )?.map((ele) => ({ ...ele, docCateg: "identity" }));
        return [...commonDocs, ...addressDocs, ...identityDocs]?.map(
          (fileItem, fileIndex) => ({
            // id: "null",
            id: fileItem?.fileId ? fileItem?.fileId : "null",
            documentType: fileItem?.documentType,
            number: fileItem?.number,
            fileName: fileItem?.file?.name || fileItem?.fileName,
            remarks: `${fileItem?.remarks}`,
            docCateg: fileItem.docCateg,
            docCode: fileItem?.documentType,
            relatedPersonIndex: `RP${index}`,
          })
        );
      }).flat();

      filesImageData?.forEach((item) => {
        if (!item?.related_person_index) {
          RelatedFileData.push({
            id: item.id,
            documentType: item.documentType,
            number: item.number,
            fileName: item?.fileName,
            remarks: item.remarks,
            docCateg: item?.docCateg,
            docCode: item?.documentType,
          });
        }
      })

      const RelatedFiles = RelatedPerson?.map((item, index) => {
        const commonDocs = getValues(`minorDetails.${index}.commonDocs`);
        const addressDocs = getValues(`minorDetails.${index}.addressDocs`);
        const identityDocs = getValues(`minorDetails.${index}.identityDocs`);
        return [...commonDocs, ...addressDocs, ...identityDocs]?.map(
          (fileItem, fileIndex) => fileItem?.file
        );
      }).flat();

      for (let i = 0; i < RelatedFiles.length; i++) {
        if (
          (RelatedFiles[i]?.length > 0 || RelatedFiles[i] instanceof File) &&
          typeof RelatedFiles[i] !== "string"
        ) {
          console.log("file already exists ", typeof RelatedFiles[i]);
          formData.append("files", RelatedFiles[i]);
        }
      }

      formData.append("filesData", JSON.stringify(RelatedFileData));
      formData.append("customerId", customerInfo.customerId);

      const idsToRemove = getIdsToRemove(RelatedFileData);
      if (idsToRemove?.length > 0) {
        removeFiles({ data: { fileIds: idsToRemove } });
        if (RelatedFileData?.length > 0) {
          uploadDocuments(formData);
        }
      } else {
        uploadDocuments(formData);
      }
    } else {
      const commonDocs = getValues("commonDocs");
      const addressDocs = getValues("addressDocs");
      const identityDocs = getValues("identityDocs");
      const addressFileNeeded = addressDocNeeded(dropdownOptions, getValues);
      commonDocs.forEach((item) => {
        if (
          !["Signature", "Photograph"].includes(item.label) &&
          (item.documentType === "" ||
            (item.mandatory && item.number === "")) &&
          item?.file
        ) {
          numberRequiredError = true;
        } else if (
          item?.file ||
          (!["Signature", "Photograph", ...arrCodesCaptal].includes(
            item.label
          ) &&
            item.documentType !== "")
        ) {
          const file = getExistedFile(item.documentType);
          const dCode = getDocCode(dropdownOptions, item.documentType);
          requestDetails.push({
            id: file?.id || item.id,
            documentType: getImageId(dropdownOptions, dCode),
            number: item.number,
            fileName:
              item?.file?.name || item?.file?.fileName || file?.fileName,
            remarks: item.remarks,
            docCateg: "common",
            docCode: dCode?.toString()?.padStart(2, "0") || item?.documentType?.toString()?.padStart(2, "0"),
          });
          if (item?.file) files.push(item?.file);
        } else {
          if (item.label === "Photograph") {
            signatureFileNeeded = true;
          }
        }
      });
      identityDocs.forEach((item) => {
        if (
          (item.mandatory && item.number === "") ||
          item?.documentType === ""
        ) {
          numberRequiredError = true;
          return;
        } else {
          const file = getExistedFile(item.documentType);
          const dCode = getDocCode(dropdownOptions, item.documentType);
          requestDetails.push({
            id: file?.id || item.id,
            documentType: getImageId(dropdownOptions, dCode) || item.documentType,
            number: item.number,
            fileName:
              item?.file?.name || item?.file?.fileName || file?.fileName,
            remarks: item.remarks,
            docCateg: "identity",
            docCode: dCode?.toString()?.padStart(2, "0") || item?.documentType?.toString()?.padStart(2, "0"),
          });
          if (item?.file) files.push(item?.file);
        }
      });
      addressDocs.forEach((item) => {
        if (item.documentType !== "" || item.file || addressFileNeeded) {
          if (
            (item.mandatory && item.number === "") ||
            item?.documentType === ""
          ) {
            numberRequiredError = true;
            return;
          } else {
            const file = getExistedFile(item.documentType);
            const dCode = getDocCode(dropdownOptions, item.documentType);
            console.log(dCode, "---");
            requestDetails.push({
              id: file?.id || item.id,
              documentType: getImageId(dropdownOptions, dCode),
              number: item.number,
              fileName:
                item?.file?.name || item?.file?.fileName || file?.fileName,
              remarks: item.remarks,
              docCateg: "address",
              docCode: dCode?.toString()?.padStart(2, "0") || item?.documentType?.toString()?.padStart(2, "0"),
            });
            if (item?.file) files.push(item?.file);
          }
        }

        // else if (item.documentType === "") files.push(null);
        // else if (!addressDocNeeded && !item.file)
        // 	files.push(item?.fileUrl || "");
      });
      filesImageData?.forEach((item) => {
        if (item?.related_person_index) {
          requestDetails.push({
            id: item.id,
            documentType: item.documentType,
            number: item.number,
            fileName: item?.fileName,
            remarks: item.remarks,
            docCateg: item?.docCateg,
            docCode: item?.documentType?.toString()?.padStart(2, "0"),
            relatedPersonIndex: item?.related_person_index
          });
        }
      })

      if (numberRequiredError || signatureFileNeeded) {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: signatureFileNeeded
              ? "Uploading Photograph is mandatory, Please upload"
              : " Please fill the required fields",
            severity: "error",
          },
        });
        return;
      }

      let newFiles = [];
      // let unChangedFiles = []
      files.forEach((item, i) => {
        if (!item?.fileUrl) {
          formData.append(`files`, item);
          newFiles.push(item);
        }
      });
      const fileDetails = requestDetails
        .map((item) => ({
          ...item,
          id: item.id && item.id !== "null" ? item.id : "null",
        }))
        .filter((i) => i);
      formData.append("filesData", JSON.stringify(fileDetails));
      formData.append("customerId", customerInfo.customerId);

      const atleastOneIdentityProof = identityDocs.some(
        (item) => item?.documentType !== ""
      );
      // any proof number or selection type is changed
      const anyFieldIsChanged =
        dirtyFields &&
        Object.keys(dirtyFields).some((f) =>
          ["commonDocs", "addressDocs", "identityDocs"].includes(f)
        );

      // sending update flags
      if (getValues("applicationType") === "03") {
        const stageFlags = [
          {
            Identity: anyFieldIsChanged ? "01" : "02",
            ImageDetails: newFiles?.length > 0 ? "01" : "02",
          },
        ];
        formData.append("updateFlags", JSON.stringify(stageFlags));
      }

      if (
        !atleastOneIdentityProof ||
        (addressFileNeeded && !atleastOneIdentityProof)
      ) {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: "Please add the manadatory files",
            severity: "error",
          },
        });
      }

      else if (atleastOneIdentityProof) {
        const idsToRemove = getIdsToRemove(requestDetails);
        console.log(
          "ids...",
          idsToRemove,
          anyFieldIsChanged ||
          newFiles.length > 0 ||
          (newFiles.length > 0 && newFiles.length < files.length)
        );
        if (idsToRemove?.length > 0) {
          removeFiles({ data: { fileIds: idsToRemove } });
        }
        if (
          anyFieldIsChanged ||
          newFiles.length > 0 ||
          (newFiles.length > 0 && newFiles.length < files.length)
        ) {
          uploadDocuments(formData);
        } else {
          handleNext();
        }
      } else {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: "Please add the manadatory files",
            severity: "error",
          },
        });
      }
    }
  };

  const onSubmit = (data) => {
    if (activeStep === 0 && watch("accountType") === "05") {
      uploadFiles();
    }
    if (activeStep === 1) {
      // currently making the firstname, last name only as mandatory
      const fatherHasFilledValues = [

        data["father"],
        // data["fatherLastName"],
      ]?.every((x) => x && x !== "");

      const motherHasFilledValues = [
        data["motherFirstName"],
        //  data["motherLastName"],

      ]?.every((x) => x && x !== "");
      if (!fatherHasFilledValues && !motherHasFilledValues) {
        toggleFatherMotherModal(true);
        return;
      }

    }
    const requestObject = constructObject(
      data,
      getValues,
      formDefaultValues(customerInfo)
    );
    const clearTime = setTimeout(() => {
      updateCkycUser(requestObject);
      clearTimeout(clearTime);
    }, 10)
  };

  useEffect(() => {
    if (watchState) {
      console.log(watchState);
      dispatch({
        type: "SET_DROPDOWN_FILTER",
        payload: {
          state: watchState,
        },
      });
    }
  }, [watchState]);

  useEffect(() => {
    if (watchDistrict) {
      dispatch({
        type: "SET_DROPDOWN_FILTER",
        payload: {
          selectedDistrict: watchDistrict,
        },
      });
    }
  }, [watchDistrict]);

  useEffect(() => {
    if (watchCurrentDistrict) {
      dispatch({
        type: "SET_DROPDOWN_FILTER",
        payload: {
          currentSelectedDistrict: watchCurrentDistrict,
        },
      });
    }
  }, [watchCurrentDistrict]);

  useEffect(() => {
    if (watchCurrentState) {
      dispatch({
        type: "SET_DROPDOWN_FILTER",
        payload: {
          currentState: watchCurrentState,
        },
      });
    }
  }, [watchCurrentState]);

  useEffect(() => {
    if (relatedSameAsPermanent) {
      currentAddress.forEach((item, index) => {
        if (item.key && item.key !== "relatedPermSameAsPermanant") {
          const permItem = permanantAddress.find((i) => i.label === item.label);
          if (permItem) setValue(item.key, getValues(permItem.key));
        }
      });
    } else {
      currentAddress.forEach((item) => {
        if (item.key && item.key !== "relatedPermSameAsPermanant") {
          setValue(item.key, defaultValues[item.key]);
        }
      });
    }
  }, [relatedSameAsPermanent]);

  useEffect(() => {
    if (sameAsPermanent) {
      currentProofOfAddress.forEach((item, index) => {
        if (item.key && item.key !== "sameAsPermanant") {
          const permItem = proofOfAddress.find((i) => i.label === item.label);

          if (permItem) {
            setValue(item.key, getValues(permItem.key));
            // setValue("currentAddressProof", "01");
            console.log(item.key, getValues(permItem.key));
          }
        }
      });
    } else {
      currentProofOfAddress.forEach((item) => {
        if (item.key && item.key !== "sameAsPermanant") {
          setValue(item.key, "");
        }
      });
    }
  }, [sameAsPermanent]);

  useEffect(() => {
    if (customerInfo) {
      setValue("applicationType", customerInfo.applicationType);
      setValue("accountType", customerInfo.ACC_TYPE);
      if (customerInfo.applicationType === "01") {
        setHideElements([...hideElements, "kycNumber"]);
      }
    }
  }, [customerInfo]);

  useEffect(() => {
    if (openUpdateForm) {
      if (
        customerInfo &&
        ["On Hold", "CKYC Found"].includes(customerInfo?.status)
      )
        setActiveStep(0);
      else setActiveStep(5);
      setValue("applicationType", appType);
    }
  }, []);

  useEffect(() => {
    const options = dropdownOptions["accountType"];
    const type = options?.find((item) => item?.code === accountType);
    if (type && type?.name?.toLowerCase() === "minor") {
      setShowMinorDetails(true);
    } else {
      setShowMinorDetails(false);
    }
  }, [accountType]);
  useEffect(() => {
    if (watchCkycUser) {
      if (dropdownOptions) {
        const users = dropdownOptions["employeeNameNew"];
        const ckycUsers = dropdownOptions["ckycUsers"];
        const usersOptions = dropdownOptions["employeeName"];
        if (isNaN(watchCkycUser)) {
          const user = users.find(
            (user) => user?.dmsUserName === watchCkycUser
          );
          if (user) {
            const ckycUser = ckycUsers.find(
              (item) => item?.dmsUserId === user?.dmsUserId
            );
            const designationOptions = usersOptions?.find(
              (item) => item?.id === user?.dmsUserId
            );
            if (ckycUser) {
              setValue("instCode", ckycUser.fiCode);
              setValue("instName", ckycUser.instName);
            }
            setValue(
              "employeeDesignation",
              designationOptions?.designation?.designationName
            );
            setValue("employeeBranch", designationOptions?.branch?.branchName);
            setValue("employeeCode", designationOptions?.empCode);
          }
        } else {
          const user = users.find((user) => user?.dmsUserId === watchCkycUser);
          if (user) {
            const ckycUser = ckycUsers.find(
              (item) => item?.dmsUserId === user?.dmsUserId
            );
            const designationOptions = usersOptions?.find(
              (item) => item?.id === user?.dmsUserId
            );

            if (ckycUser) {
              setValue("instCode", ckycUser.fiCode);
              setValue("instName", ckycUser.instName);
            }
            setValue(
              "employeeDesignation",
              designationOptions?.designation?.designationName
            );
            setValue("employeeBranch", designationOptions?.branch?.branchName);
            setValue("employeeCode", designationOptions?.empCode);

            // setValue("instName");
          }
        }
      }
    }
  }, [watchCkycUser]);

  useEffect(() => {
    // if (watchAddressProof) {
    if (applicationType === "01") {
      setHideElements([...hideElements, "kycNumber"]);
      if (!["", null].includes(getValues("accountType"))) {
        setDisableElements(["accountType"])
      };
    } else {
      const elements = hideElements.filter((item) => item !== "kycNumber");
      const keys = [];
      if (!["", null].includes(getValues("constitutionType")))
        keys.push("constitutionType");
      if (!["", null].includes(getValues("kycNumber"))) keys.push("kycNumber");
      if (!["", null].includes(getValues("accountType"))) keys.push("accountType");
      if (!["", null].includes(getValues("documentRecieved")))
        keys.push("documentRecieved");
      if (!["", null].includes(getValues("country"))) {
        if (
          dropdownOptions["relatedPermCountry"]?.filter(
            (items) => items?.code === getValues("country")
          )?.length > 0
        ) {
          keys.push("country");
        }
      }
      setDisableElements(keys);
    }

  }, [applicationType]);

  useEffect(() => {
    setValue("applicationType", appType);
  }, [appType]);
  // const renderMinorDetails = () => {
  // 	return (
  // 		activeStep === 0 &&
  // 		getValues("accountType") === "02" && (
  // 			<MinorDetails formProps={formProps} dropdownOptions={dropdownOptions} />
  // 		)
  // 	);
  // };

  useEffect(() => {
    if (title === "Mr") {
      setValue("personalGender", "M");
    } else if (title === "Mis" || title === "Mrs" || title === "Miss") {
      setValue("personalGender", "F");
    } else {
      setValue("personalGender", null);
    }
  }, [title]);

  const disableNextButton =
    customerInfo?.job_type === "AUTOMATION" && customerInfo?.count < 2;

  const [requiredFields, setRequiredFields] = React.useState([]);

  useEffect(() => {
    const requiredFields = getRequiredFields(schema);
    setRequiredFields(requiredFields);
  }, []);

  const getRequiredFields = (schema) => {
    const requiredFields = [];
    const fields = schema?.fields;

    if (!fields) {
      console.error("Invalid schema object.");
      return requiredFields;
    }

    Object.keys(fields).forEach((fieldName) => {
      const field = fields[fieldName];
      if (field.tests.some((test) => test.OPTIONS.name === "required")) {
        requiredFields.push(fieldName);
      }
    });

    return requiredFields;
  };
  return (
    <Modal
      title={"Customer Details"}
      fullWidth
      open={open}
      toggleDrawer={toggleDrawer}
    >
      <Box display="flex" px="32px" py="32px">
        <Box display={"flex"} minWidth="20%">
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            sx={{
              "& .MuiStepConnector-root": {
                flex: 0,
                "& .MuiStepConnector-line": {
                  minHeight: "8px",
                },
              },
            }}
          >
            {steps.map((step, index) => (
              <Step
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (activeStep === steps.length - 1) {
                    setActiveStep(index);
                  } else {
                    if (isValid && !disableNextButton) {
                      setActiveStep(index);
                    }
                  }
                }}
                key={step.label}
              >
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box width="75%" flexGrow={1} display={"flex"} flexDirection="column">
          <Text fontSize="16px" fontWeight="500" mt="16px" mb="16px">
            {steps[activeStep]?.label}
          </Text>
          <Box>
            <form
              onSubmit={(e) => {
                e.preventDefault();

                if ([5].includes(activeStep)) {
                  postRequest(e);
                } else if (activeStep === 4) {
                  uploadFiles();
                } else {
                  handleSubmit(onSubmit)();
                }
              }}
            >
              {![4, 5].includes(activeStep) && (
                <React.Fragment>
                  {steps[activeStep].displayName && (
                    <Text fontSize="16px" fontWeight="500" mt={"16px"} mb="2px">
                      {steps[activeStep].displayName}
                    </Text>
                  )}
                  <NewCustomerForm
                    formElements={steps[activeStep].formElements}
                    formProps={formProps}
                    dropdownOptions={dropdownOptions}
                    hideElements={hideElements}
                    disableElements={disableElements}
                  />
                  {/* {renderMinorDetails()} */}
                  {activeStep === 0 && showMinor && (
                    <MinorDetails
                      formProps={formProps}
                      dropdownOptions={dropdownOptions}
                      disableElements={disableElements}
                      files={files?.filter((ele) => ele.related_person_index)}
                    />
                  )}
                  {steps[activeStep].subDivs && (
                    <Box>
                      <Text
                        fontSize="16px"
                        fontWeight="500"
                        mt="32px"
                        mb="16px"
                      >
                        {steps[activeStep].subDivs.label}
                      </Text>
                      <NewCustomerForm
                        formElements={steps[activeStep].subDivs.formElements}
                        formProps={formProps}
                        dropdownOptions={dropdownOptions}
                        disableElements={disableElements}
                      />
                    </Box>
                  )}
                </React.Fragment>
              )}
              {activeStep === 4 && (
                <Box>
                  <UploadDocument
                    addedDocs={addedDocs}
                    setAddedDocs={setAddedDocs}
                    formProps={formProps}
                    dropdownOptions={dropdownOptions}
                    files={files?.filter((ele) => !ele?.related_person_index) || []}
                    getUniqOptions={getUniqOptions}
                  />
                  {/* <MainKYCVerification
                    formProps={formProps}
                    dropdownOptions={dropdownOptions}
                     /> */}
                </Box>
              )}
              {activeStep === 5 && (
                <Box>
                  <PreviewDetails
                    steps={steps}
                    values={getValues()}
                    getMappedLabel={getMappedLabel}
                    dropdownOptions={dropdownOptions}
                    files={files?.filter((ele) => !ele.related_person_index) || []}
                    minorFiles={files?.filter((ele) => ele.related_person_index) || []}
                  />
                </Box>
              )}
              <Box
                mt="24px"
                width="100%"
                display="flex"
                justifyContent={"flex-end"}
                // mr="16px"
                sx={{ mb: 2 }}
              >
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    variant="outlined"
                    size="small"
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <LoadingButton
                    loading={isLoading || uploadingDocs || updatingStatus}
                    type={"submit"}
                    size="small"
                    variant="contained"
                    disabled={activeStep === steps.length - 1 ? (disableNextButton) : (disableNextButton || !isValid)}
                    // disabled={disableNextButton}
                    color="primary"
                    disableElevation
                    style={{ textTransform: "none" }}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Save & Next"}
                  </LoadingButton>
                </div>
              </Box>
            </form>
          </Box>
          {/* <Typography>{steps[activeStep].description}</Typography> */}
        </Box>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Paper>
        )}
      </Box>
      {openFatherMotherModal && (
        <AlertModal
          onOk={() => toggleFatherMotherModal(false)}
          title={"Alert"}
          closePopup={() => toggleFatherMotherModal(false)}
          openAlertModal={openFatherMotherModal}
          okTitle={"OK"}
          subText={
            "Any of Father's , Spouse's or Mother's First name  are mandatory"
          }
        />
      )}
    </Modal>
  );
}
