import * as React from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { useMutation, useQuery, useQueries } from "@tanstack/react-query";
import { redirect } from "react-router-dom";

import { logout } from "../../apis/auth";

import Text from "../Atoms/Text";
import ChangePassword from "./ChangePassword";
import { useGlobalContext } from "../../context/GlobalContext";
import { isLoggedIn } from "../../utils";

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const {
    state: { openPasswordChangeModal, currentUser },
    dispatch,
  } = useGlobalContext();

  const { refetch: userLogout } = useQuery(["logout"], logout, {
    manual: true,
    select: (response) => response.data,
    retry: false,
    enabled: false,
    onSuccess: () => {
      window.localStorage.clear();
      dispatch({
        type: "TOGGLE_IS_LOGGEDIN",
      });
      navigate("/login");
      // window.location.pathname = "/login";
      window.location.reload();
    },
  });

  const firstChar =
    currentUser && currentUser?.first_name?.charAt(0)?.toUpperCase();
  const lastChar =
    currentUser && currentUser?.last_name?.charAt(0)?.toUpperCase();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = async () => {
    await userLogout();
  };

  const openChangePasswordModal = () => {
    dispatch({
      type: "TOGGLE_PASSWORD_MODAL",
      payload: {
        openPasswordChangeModal: true,
      },
    });
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          textAlign: "center",
        }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="medium"
            // sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}>
            <Avatar sx={{ width: 40, height: 40 }}>
              <Text letterSpacing="-1px">{`${firstChar || ""} ${
                lastChar || ""
              }`}</Text>
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
            "& .MuiDivider-root": {
              marginBottom: 0,
              marginTop: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        {/* <MenuItem>
					<ListItemIcon>
						<Settings fontSize="small" />
					</ListItemIcon>
					<Text fontSize="14px"> Settings</Text>
				</MenuItem> */}
        {/* <Divider /> */}
        <MenuItem onClick={openChangePasswordModal}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          <Text fontSize="14px"> Change Password</Text>
        </MenuItem>
        <Divider />

        <MenuItem onClick={logOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <Text fontSize="14px"> Logout</Text>
        </MenuItem>
      </Menu>
      {openPasswordChangeModal && <ChangePassword />}
    </React.Fragment>
  );
}
