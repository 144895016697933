import React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Switch } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

// import { DeleteOutlineIcon, EditIcon } from "@mui/base";
import RestoreIcon from "@mui/icons-material/Restore";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import Text from "../../components/Atoms/Text";

const statusColor = {
  "Pending Mapping": "#8D8D8D",
  "In Progress": "#5B58FF",
  "On Hold": "#EE8E53",
  "CKYC Rejected": "#EA0000",
  "CKYC Found": "green",
  Audited: "green",
};

export const RenderCKYCHoldStatus = ({
  value,
  error,
  onRemarksClick,
  showHistory,
  noHistoryIcon
}) => {

  const displayHistoryIcon = (status) => {
    return (status !== "Pending Mapping" || error) && !noHistoryIcon  ? (
      <Box ml="12px" mt="4px">
        <Tooltip title="Show Hisotry">
          <RestoreIcon
            sx={{ cursor: "pointer", fontSize: "20px", opacity: 0.6 }}
            onClick={showHistory}
          />
        </Tooltip>
      </Box>
    ) : (
      ""
    );
  };

  const getHistoryIcon = (status) => {
    if (status === "On Hold")
      return (
        <Box ml="12px" mt="4px" display={"flex"} alignItems="center">
          <Tooltip title="Show Remarks">
            <WarningIcon
              sx={{ color: "#EE8E53", cursor: "pointer", fontSize: "20px" }}
              onClick={onRemarksClick}
            />
          </Tooltip>
          {displayHistoryIcon(status)}
        </Box>
      );
    else if (status === "Audited")
      return (
        <Box ml="12px" mt="4px" display={"flex"} alignItems="center">
          <Tooltip title="Show Remarks">
            <CheckCircleIcon
              sx={{ color: "green", cursor: "pointer", fontSize: "20px" }}
              onClick={onRemarksClick}
            />
          </Tooltip>

          {displayHistoryIcon(status)}
        </Box>
      );
    else {
      return displayHistoryIcon(status);
    }
  };

  return value ? (
    <Box
      display="flex"
      justifyContent={"center"}
      alignItems="center"
      flexWrap="wrap">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          color: statusColor[value] || "#FF4D4D",
          fontSize: "14px",
          marginLeft: "8px",
        }}
        title={value}
        >
        <div
          style={{
            marginRight: "6px",
            height: "6px",
            width: "6px",
            borderRadius: "6px",
            backgroundColor: statusColor[value] || "#FF4D4D",
          }}
        />
        {value}
      </div>
      {getHistoryIcon(value)}
    </Box>
  ) : (
    ""
  );
};
