
const officeUseOnly = [
  {
    key: "applicationType",
    label: "Application type",
    placeholder: "Enter text",
    errorText: "required",
    type: "radioGroup",
    mandatory: false,
    disabled: true,
    options: [
      { label: "New", value: "01" },
      { label: "Update", value: "03" },
    ],
    width: "90%",
  },
  {
    key: "constitutionType",
    label: "Constitution Type",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: true,
    // options: [{ id: 1, type: "Individual" }],
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
  },
  // {
  // 	key: "constitutionTypeOthers",
  // 	label: "Constitution Type Others",
  // 	placeholder: "Enter text",
  // 	errorText: "required",
  // 	type: "text",
  // 	mandatory: false,
  // },
  // {
  // 	key: "constitutionTypeOthers",
  // 	label: "Constitution Type Others",
  // 	placeholder: "Enter text",
  // 	errorText: "required",
  // 	type: "text",
  // 	mandatory: false,
  // },
  {
    key: "customerNumber",
    label: "Customer Number",
    placeholder: "Enter text",
    errorText: "Please enter valid number",
    type: "text",
    mandatory: true,
    //pattern: /^[0-9]+$/,
    //isRegex: "numeric"
  },
  {
    key: "customerBranch",
    label: "Customer Branch",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: true,
    options: [{ id: 1, username: "Test" }],
    valueRenderer: (option) => option.brCode,
    displayName: (option) => `${option.brName}`,
  },
  {
    key: "kycNumber",
    label: "CKYC Number",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    pattern: /^[0-9]+$/

  },
  {
    key: "accountType",
    label: "Account Type",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: true,
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
  },
];

const personalDetails = [
  {
    key: "title",
    label: "Title",
    placeholder: "Select Title",
    errorText: "required",
    type: "dropdown",
    mandatory: true,
    options: ["Mr", "Mrs", "Minor", "Miss", "Dr"],
    valueRenderer: (option) => option,
    displayName: (option) => option,
    // displayName: (option) =>
    //   option.charAt(0).toUpperCase() + option.slice(1).toLowerCase(),
    watchKey: "title",
    width: "10%",
  },
  {
    key: "personalFirstName",
    label: "First Name",
    placeholder: "Enter text",
    //    errorText: "required",
    errorText:
      "Only Alphabets with spaces are allowed. Min 3 and Max 50 characters",
    type: "text",
    mandatory: true,
    width: "25%",
    pattern: /^[A-Za-z ]{3,50}$/,
    isRegex: "alphaspace"
  },
  {
    key: "personalMiddleName",
    label: "Middle Name",
    placeholder: "Enter text",
    errorText:
      "Only Alphabets with spaces are allowed. Min 3 and Max 50 characters",
    type: "text",
    mandatory: false,
    width: "25%",
    pattern: /^[A-Za-z ]{3,50}$/,
    isRegex: "alphaspace"
  },
  {
    key: "personalLastName",
    label: "Last Name",
    placeholder: "Enter text",
    errorText:
      "Only Alphabets with spaces are allowed. Min 1 and Max 50 characters",
    type: "text",
    mandatory: false,
    width: "25%",
    pattern: /^[A-Za-z ]{1,50}$/,
    isRegex: "alphaspace"
  },
  {
    key: "dependentType",
    label: "",
    placeholder: "Enter text",
    errorText: "required",
    type: "radioGroup",
    mandatory: true,
    options: [
      { label: "Father", value: "01" },
      { label: "Spouse", value: "02" },
    ],
    width: "90%",
    infoText:
      "Any of Father's , Spouse's or Mother's First name are mandatory",
  },

  {
    key: "fatherInitial",
    label: "Father / Spouse",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: false,
    options: ["Mr", "Mrs", "Minor", "Miss", "Dr"],
    valueRenderer: (option) => option,
    displayName: (option) => option,
    width: "10%",
    watchKey: "dependentType",
    renderLabel: (watchKey) => (watchKey === "01" ? "Father" : "Spouse"),
  },
  {
    key: "father",
    label: "Father Name",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "25%",
    watchKey: "dependentType",
    renderLabel: (watchKey) =>
      watchKey === "01" ? "Father Name" : "Spouse Name",
    pattern: /^[a-zA-Z\s]*$/,
    isRegex: "alphaspace"
    // pattern:  /^[^0-9]*$/;
  },
  {
    key: "fatherMiddleName",
    label: "Middle Name",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "25%",
    pattern: /^[a-zA-Z\s]*$/,
    regex: /^[a-zA-Z\s]*$/,
    isRegex: "alphaspace"
  },
  {
    key: "fatherLastName",
    label: "Last Name",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "25%",
    pattern: /^[a-zA-Z\s]*$/,
    regex: /^[a-zA-Z\s]*$/,
    isRegex: "alphaspace"
  },
  {
    key: "motherInitial",
    label: "Mother",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: false,
    options: ["Mr", "Mrs", "Minor", "Miss", "Dr"],
    valueRenderer: (option) => option,
    displayName: (option) => option,
    width: "10%",
  },
  {
    key: "motherFirstName",
    label: "Mother Name",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "25%",
    pattern: /^[a-zA-Z\s]*$/,
    regex: /^[a-zA-Z\s]*$/,
    isRegex: "alphaspace"
  },
  {
    key: "motherMiddleName",
    label: "Middle Name",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "25%",
    pattern: /^[a-zA-Z\s]*$/,
    regex: /^[a-zA-Z\s]*$/,
    isRegex: "alphaspace"
  },
  {
    key: "motherLastName",
    label: "Last Name",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "25%",
    pattern: /^[a-zA-Z\s]*$/,
    regex: /^[a-zA-Z\s]*$/,
    isRegex: "alphaspace"
  },
  {
    key: "officialTel",
    label: "Tel(Official)",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "10%",
    number: true,
  },
  {
    key: "officialPhoneNumber",
    label: "Phone Number",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "25%",
    //number: true,
    isRegex: "numeric",

  },
  {
    key: "personalMobile",
    label: "Mobile Number",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    pattern: /^\d{10}$/,
    width: "25%",
    //number: true,
    isRegex: "numeric",
  },
  {
    key: "personalEmail",
    label: "Email",
    placeholder: "Enter email",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "25%",
    // pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
  },
  {
    key: "residentialTel",
    label: "Tel(Residential)",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "10%",
    number: true,
  },
  {
    key: "residentailPhoneNumber",
    label: "Phone Number",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "25%",
    // number: true,
    isRegex: "numeric",
  },
  {
    key: "pesonalForm16",
    label: "FORM-60 finished",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: true,
    options: ["Yes", "No"],
    valueRenderer: (option) => option,
    displayName: (option) => `${option}`,
    width: "25%",
  },
  {
    key: "personaPan",
    label: "PAN",
    placeholder: "Enter text",
    errorText: "Valid Pan number required",
    type: "text",
    mandatory: true,
    width: "25%",
    pattern: /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/,
    validation: {
      test: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
      regex: true,
      message: "Valid Pan Number Required",
    },
    isRegex: "personaPan"
  },
  {
    key: "-1",
    type: "emptyDiv",
    width: "10%",
  },
  {
    key: "pesonalAadhar",
    label: "Aadhaar No",
    placeholder: "",
    errorText: "Valid Aadhaar number required",
    type: "text",
    mandatory: false,
    width: "25%",
    isAadhar : true
    // pattern: /^\d{12}(\d{2})?$/,
    // validation: {
    //   test: /^\d{12}(\d{2})?$/,
    //   regex: true,
    //   message: "Valid Aadhar Number Required",
    // },
  },
  {
    key: "pesonalDob",
    label: "Date of Birth",
    placeholder: "Enter text",
    errorText: "required",
    type: "date",
    mandatory: true,
    width: "25%",
  },
  {
    key: "personalGender",
    label: "Gender",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: true,
    options: [
      { id: "M", name: "Male" },
      { id: "F", name: "Female" },
      { id: "T", name: "Transgender" },
    ],
    valueRenderer: (option) => option.id,
    displayName: (option) => `${option.name}`,
    width: "25%",
  },
  {
    key: "-1",
    type: "emptyDiv",
    width: "10%",
  },
  {
    key: "personalcitizenship",
    label: "Citizenship",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: false,
    options: [{ id: 1, username: "Test" }],
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
    width: "25%",
  },
  {
    key: "residentialStatus",
    label: "Residential Status",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: false,
    options: [{ id: 1, username: "Test" }],
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
    width: "25%",
  },
  {
    key: "occupatationType",
    label: "Occupation Type",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: false,
    options: [{ id: 1, username: "Test" }],
    valueRenderer: (option) => option.id,
    displayName: (option) => `${option.name}`,
    width: "25%",
  },
];

const proofOfAddress = [
  {
    key: "addressProof",
    label: "Address Proof",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: true,
    options: [{ id: 1, username: "Test" }],
    valueRenderer: (option) => option.addressProofCode,
    displayName: (option) => `${option.documentName}`,
    width: "45%",
    filterKey: "addressProofCode"
  },
  {
    key: "addressProofIdNumber",
    label: "Address Proof ID Number",
    placeholder: "Enter text",
    errorText: "Enter valid number",
    type: "text",
    mandatory: true,
    width: "45%",
    isRegex: "addressProofIdNumber"

  },
  {
    key: "line1",
    label: "Address Line1",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "90%",
    // pattern: /^[A-Za-z\s]{55}$/,
    pattern: /^.{1,55}$/
    ,
  },
  {
    key: "line2",
    label: "Address Line 2",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "90%",
  },
  {
    key: "line3",
    label: "Address Line 3",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "90%",
  },
  {
    key: "state",
    label: "State",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: true,
    options: [{ id: 1, username: "Test" }],
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
    width: "45%",
  },
  {
    key: "district",
    label: "District",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: true,
    valueRenderer: (option) => option?.toLowerCase(),
    displayName: (option) => option,
    width: "45%",
  },
  {
    key: "city",
    label: "City",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "45%",
  },
  {
    key: "pincode",
    label: "PinCode",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: true,
    width: "45%",
    valueRenderer: (option) => option,
    displayName: (option) => option,
  },
  {
    key: "country",
    label: "Country",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: false,
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
    width: "45%",
  },
  {
    key: "proofOfAddresIfOthers",
    label: "Proof Of Address If Others",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "45%",
  },
];

let currentProofOfAddress = [
  {
    key: "sameAsPermanant",
    label: "Same as Permanent Address ",
    placeholder: "Enter text",
    errorText: "required",
    type: "checkbox",
    mandatory: true,
    width: "100%",
  },
  {
    key: "currentAddressProof",
    label: "Address Proof",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: true,
    valueRenderer: (option) => option.addressProofCode,
    displayName: (option) => `${option.documentName}`,
    width: "45%",
  },
  {
    key: "currentAddressProofIdNumber",
    label: "Address Proof ID Number",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "45%",
    isRegex: "currentAddressProofIdNumber"
  },
  {
    key: "currentLine1",
    label: "Address Line1",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "90%",
    pattern: /^.{1,55}$/
  },
  {
    key: "currentLine2",
    label: "Address Line 2",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "90%",
  },
  {
    key: "currentLine3",
    label: "Address Line 3",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "90%",
  },
  {
    key: "currentState",
    label: "State",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: true,
    options: [{ id: 1, username: "Test" }],
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
    width: "45%",
  },
  {
    key: "currentDistrict",
    label: "District",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: true,
    valueRenderer: (option) => option?.toLowerCase(),
    displayName: (option) => option,
    width: "45%",
  },
  {
    key: "currentCity",
    label: "City",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "45%",
  },
  {
    key: "currentPincode",
    label: "PinCode",
    placeholder: "Enter text",
    errorText: "required",
    valueRenderer: (option) => option,
    displayName: (option) => option,
    type: "dropdown",
    mandatory: true,
    width: "45%",
  },
  {
    key: "currentCountry",
    label: "Country",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: false,
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
    width: "45%",
  },
  {
    key: "currentProofOfAddresIfOthers",
    label: "Proof Of Address If Others",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "45%",
  },
];

const kycVerification = [
  {
    key: "documentRecieved",
    label: "Document Received",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: true,
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
    width: "30%",
  },
  {
    key: "kycVerifiDate",
    label: "Verification Date",
    placeholder: "Enter text",
    errorText: "required",
    type: "date",
    mandatory: true,
    width: "30%",
  },
  {
    key: "employeeName",
    label: "Employee Name",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    valueRenderer: (option) => option.dmsUserName,
    valueIdRenderer: (option) => option.dmsUserId,
    displayName: (option) => option?.dmsUserName,
    mandatory: true,
    width: "30%",
  },
  {
    key: "employeeCode",
    label: "Employee Code",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
    pattern: /^[a-zA-Z0-9\s]*$/,
    isRegex: "alphanumericspace"
  },
  {
    key: "employeeDesignation",
    label: "Employee Designation",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: true,
    options: [{ id: 1, username: "Test" }],
    valueRenderer: (option) => option.name,
    valueIdRenderer: (option) => option.id,
    displayName: (option) => `${option.name}`,
    width: "30%",
  },
  {
    key: "employeeBranch",
    label: "Employee Branch",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
    isRegex: "alphanumericspace"
  },
  {
    key: "instCode",
    label: "Institution Code",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
    isRegex: "alphanumericspace",
    pattern: /^[a-zA-Z0-9\s]*$/,
  },
  {
    key: "instName",
    label: "Institution Name",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
    isRegex: "alphanumericspace",
    pattern: /^[a-zA-Z0-9\s]*$/,
  },
  {
    type: "emptyDiv",
    width: "30%",
    key: "-1",
  },
  {
    key: "remarks",
    label: "Remarks",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "60%",
  },
];

const applicantDeclaration = [
  {
    key: "dateOfDeclaration",
    label: "Date of Declaration",
    placeholder: "Enter text",
    errorText: "required",
    type: "date",
    mandatory: true,
    width: "30%",
  },
  {
    key: "placeOfDeclaration",
    label: "Place of Declaration",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
    width: "30%",
    pattern: /^[a-zA-Z]*$/,
    regex: /^[a-zA-Z]*$/,
  },
];

const currentAddress = [
  {
    key: "relatedPermSameAsPermanant",
    label: "Same as Permanent Address ",
    placeholder: "Enter text",
    errorText: "required",
    type: "checkbox",
    mandatory: false,
    width: "100%",
  },
  // {
  //   key: "relatedAddressProof",
  //   label: "Address Proof",
  //   placeholder: "Enter text",
  //   errorText: "required",
  //   type: "dropdown",
  //   mandatory: true,
  //   options: [{ id: 1, username: "Test" }],
  //   valueRenderer: (option) => option.code,
  //   displayName: (option) => `${option.name}`,
  //   width: "30%",
  // },
  {
    key: "AddressProof",
    label: "Proof of Address",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: true,
    options: [{ id: 1, username: "Test" }],
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
    width: "30%",
  },
  {
    key: "addressNumber",
    label: "Proof of Address Number ",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
  },
  // {
  // 	key: "otherProof",
  // 	label: "Proof of Address if Others",
  // 	placeholder: "Enter text",
  // 	errorText: "required",
  // 	type: "text",
  // 	mandatory: true,
  // 	width: "30%",
  // },
  {
    key: "relatedAddressline1",
    label: "Address Line 1",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "90%",
  },
  {
    key: "relatedAddressline2",
    label: "Address Line 2",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "90%",
  },
  {
    key: "relatedCountry",
    label: "Country",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: false,
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
    width: "30%",
  },
  {
    key: "relatedState",
    label: "State",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: true,
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
    width: "30%",
  },
  {
    key: "relatedDistrict",
    label: "District",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    valueRenderer: (option) => option.districtName,
    displayName: (option) => `${option.districtName}`,
    mandatory: true,
    width: "30%",
  },
  {
    key: "relatedVillage",
    label: "City/Town/Village ",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
  },
  {
    key: "relatedPincode",
    label: "PinCode",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
  },
];

const permanantAddress = [
  {
    key: "relatedAddressProof",
    label: "Address Proof",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: true,
    options: [{ id: 1, username: "Test" }],
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
    width: "30%",
  },
  {
    key: "relatedAddressProofIdNumber",
    label: "Address Proof ID Number",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
  },
  {
    key: "relatedProofOfAddresIfOthers",
    label: "Proof Of Address If Others",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "30%",
  },
  {
    key: "relatedPermAddressline1",
    label: "Address Line 1",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "90%",
  },
  {
    key: "relatedPermAddressline2",
    label: "Address Line 2",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "90%",
  },
  {
    key: "relatedPermCountry",
    label: "Country",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: false,
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
    width: "30%",
  },
  {
    key: "relatedPermState",
    label: "State",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: true,
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
    width: "30%",
  },
  {
    key: "relatedPermDistrict",
    label: "District",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    valueRenderer: (option) => option.districtName,
    displayName: (option) => `${option.districtName}`,
    mandatory: true,
    width: "30%",
  },
  {
    key: "relatdPermvillage",
    label: "City/Town/Village ",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
  },
  {
    key: "relatedPermPincode",
    label: "PinCode",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
  },
];

const proofOfIdentityPermanantAddress = [
  {
    key: "relatedUID",
    label: "UID (Aadhaar)",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "30%",
  },
  {
    key: "relatedVoterCard",
    label: "Voter ID Card",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "30%",
  },
  {
    key: "NregaJobCard",
    label: "NREGA Job Card",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "30%",
  },
  {
    key: "nationalPopRegister",
    label: "National Population RegisterNo",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "30%",
  },
  {
    key: "passportNo",
    label: "Passport No",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "30%",
  },
  {
    key: "passportExpCode",
    label: "Passport Expiry Date",
    placeholder: "Enter text",
    errorText: "required",
    type: "date",
    mandatory: false,
    width: "30%",
  },
  {
    key: "drivingLicenseNo",
    label: "Driving Licence No",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "30%",
  },
  {
    key: "drivingExpCode",
    label: "Driving Licence Expiry Date",
    placeholder: "Enter text",
    errorText: "required",
    type: "date",
    mandatory: false,
    width: "30%",
  },
  {
    key: "otherId",
    label: "Any other Id Proof Name",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "30%",
  },

  {
    key: "otherProofNumber",
    label: "Any other Id Proof Number",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "30%",
  },
  {
    key: "smaDocCode",
    label: "SMA Document Type Code",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "30%",
  },
  {
    key: "smaIdentNumber",
    label: "SMA Identification Number",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "30%",
  },
  ...permanantAddress,
];

const kycVerificationMinor = [
  {
    key: "typeOfDocSubmitted",
    label: "Type of Document Submitted ",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
  },
  {
    key: "kycVerificationName",
    label: "KYC Verification Name ",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
  },
  {
    key: "kycVerificationDesignation",
    label: "KYC Verification Designation",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
  },
  {
    key: "kycVerifiBranch",
    label: "KYC Verification Branch",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
  },
  {
    key: "kycVerifiEmpCode",
    label: "KYC Verification EMP code ",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
  },
  {
    key: "orgName",
    label: "Organistion Name ",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
  },
  {
    key: "orgCode",
    label: "Organisation Code",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
  },
  // {
  // 	key: "orgCode",
  // 	label: "Organisation Code",
  // 	placeholder: "Enter text",
  // 	errorText: "required",
  // 	type: "text",
  // 	mandatory: true,
  // 	width: "30%",
  // },
  {
    key: "kycVerifiDate",
    label: "KYC Verification Date ",
    placeholder: "Enter text",
    errorText: "required",
    type: "date",
    mandatory: true,
    width: "30%",
  },
  {
    key: "relatedDateOfDeclarion",
    label: "Date of Declaration",
    placeholder: "Enter text",
    errorText: "required",
    type: "date",
    mandatory: true,
    width: "30%",
  },
  {
    key: "relatedPlaceOfDeclarion",
    label: "Place of Declaration",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
    pattern: /^[a-zA-Z]*$/,
    regex: /^[a-zA-Z]*$/,
  },
];

const fatca = [
  {
    key: "political",
    label: "Are you a politically exposed person (PEP)?",
    placeholder: "Enter text",
    errorText: "required",
    type: "radioGroup",
    mandatory: true,
    options: [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
    width: "90%",
  },
  {
    key: "relatedPolitical",
    label: "Are you related to a politically exposed person (RPEP)?",
    placeholder: "Enter text",
    errorText: "required",
    type: "radioGroup",
    mandatory: true,
    options: [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
    width: "90%",
  },
  {
    key: "outsideResidense",
    label: "Are you a residence outside India?",
    placeholder: "Enter text",
    errorText: "required",
    type: "radioGroup",
    mandatory: true,
    options: [
      { label: "Yes", value: "true" },
      { label: "No", value: "false" },
    ],
    width: "90%",
  },
  {
    key: "copyOfResidense",
    label: "Country of Residence",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: true,
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
    width: "30%",
  },
  {
    key: "taxIdeNumber",
    label: "Tax Identification Number",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
  },
  {
    key: "placeOfbirth",
    label: "Place / City of Birth",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
  },
  {
    key: "countryOfbirth",
    label: "Country of Birth",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
  },
];

const addressDocs = [
  {
    label: "Address Proof",
    key: "addressDocs",
    mandatory: false,
    documentType: "",
    number: "",
    remarks: "",
    docTypeNeeded: true,
    numberNeeded: true,
    canBeDeleted: false,
  },
];

const identityDocs = [
  {
    label: "Identity Proof",
    key: "identityDocs",
    mandatory: false,
    documentType: "",
    number: "",
    remarks: "",
    docTypeNeeded: true,
    numberNeeded: true,
    canBeDeleted: false,
  },
];
const defaultDocsMinor = [{
  id: 68,
  label: "Photograph",
  name: "Photograph",
  key: "commonDocs",
  mandatory: true,
  documentType: 2,
  number: "",
  remarks: "",
  docTypeNeeded: false,
  numberNeeded: false,
  documentCode: "02",
  docCode: "02",
  canBeDeleted: false,
}]
const defaultDocs = [
  {
    id: 69,
    label: "Signature",
    name: "Signature",
    key: "commonDocs",
    mandatory: false,
    documentType: 9,
    number: "",
    remarks: "",
    docTypeNeeded: false,
    documentCode: "09",
    docCode: "09",
    numberNeeded: false,
    canBeDeleted: false,
  },
  {
    id: 68,
    label: "Photograph",
    name: "Photograph",
    key: "commonDocs",
    mandatory: true,
    documentType: 2,
    number: "",
    remarks: "",
    docTypeNeeded: false,
    numberNeeded: false,
    documentCode: "02",
    docCode: "02",
    canBeDeleted: false,
  },
  {
    // id: 245,
    name: "PAN",
    documentType: 3,
    documentCode: "03",
    docCode: "03",
    key: "commonDocs",

  },
  {
    name: "National Population Registry Letter",
    documentType: 35,
    documentCode: "35",
    docCode: "35",
    key: "commonDocs",
  },

  {
    name: "Pension or family pension payment orders (PPOs) issued to retired employees by Government Departments or Public Sector Undertakings, if they contain the address.",
    documentType: 15,
    documentCode: "15",
    docCode: "15",
    key: "commonDocs",
  },
  {

    name: "Documents issued by Government departments of foreign jurisdictions and letter issued by Foreign Embassy or Mission in India.",
    documentType: 17,
    documentCode: "17",
    docCode: "17",
    key: "commonDocs",
  },
  {
    name: "Bank account or Post Office savings bank account statement.",
    documentType: 14,
    documentCode: "14",
    docCode: "14",
    key: "commonDocs",
  },
  {
    name: "Utility bill which is not more than two months old of any service provider (electricity, telephone, post-paid mobile phone, piped gas, water bill).",
    documentType: 12,
    documentCode: "12",
    docCode: "12",
    key: "commonDocs",
  },
  {
    name: "Letter of allotment of accommodation from employer issued by State or Central Government departments, statutory or regulatory bodies, public sector undertakings, scheduled commercial banks, financial institutions and listed companies",
    documentType: 16,
    documentCode: "16",
    docCode: "16",
    key: "commonDocs",
  },
  {
    name: "Simplified Measures Account  - Letter issued by a gazetted officer, with a duly attested photograph of the person",
    documentType: 11,
    documentCode: "11",
    docCode: "11",
    key: "commonDocs",
  },
  {
    name: "Simplified Measures Account  - Identity card with applicant’s photograph issued by Central/ State Government Departments, Statutory/ Regulatory Authorities",
    documentType: 10,
    documentCode: "10",
    docCode: "10",
    key: "commonDocs",
  },
];

const minorFlow = [
  {
    label: "Related Person Details",
    formElements: [
      {
        key: "displayName",
        displayName: "Related Person Details",
      },

      {
        key: "relationshipTitle",
        label: "Relationship Type",
        type: "dropdown",
        mandatory: true,
        options: [{ id: 1, name: "Test" }],
        valueRenderer: (option) => option.id,
        displayName: (option) => `${option.name}`,
        width: "30%",
      },
      {
        key: "addDelFlag",
        label: "Addition/Deletion Flag",
        type: "dropdown",
        mandatory: true,
        options: [{ id: 1, name: "Test" }],
        valueRenderer: (option) => option.code,
        displayName: (option) => `${option.name}`,
        width: "30%",
      },
      {
        key: "ckycNo",
        label: "KYC Number",
        type: "text",
        width: "30%",
      },

      {
        key: "relatedPersonFirstName",
        label: "Related Person First Name",
        type: "text",
        width: "30%",
      },
      {
        key: "relatedPersonFirstName",
        label: "Related Person First Name",
        type: "text",
        width: "30%",
      },
      {
        key: "relatedPersonMiddleName",
        label: "Related Person Middle Name",
        type: "text",
        width: "30%",
      },
      {
        key: "relatedPersonLastName",
        label: "Related Person Last  Name",
        type: "text",
        width: "30%",
      },
      {
        key: "fFirstName",
        label: "Father|Spouse First Name",
        type: "text",
        width: "30%",
      },
      {
        key: "fMiddleName",
        label: "Father|Spouse  Middle Name",
        type: "text",
        width: "30%",
      },
      {
        key: "fLastName",
        label: "Father|Spouse Last Name",
        type: "text",
        width: "30%",
      },
      {
        key: "mfirstName",
        label: "Mother's First Name",
        type: "text",
        width: "30%",
      },
      {
        key: "mMiddleName",
        label: "Mother's Middle Name",
        type: "text",
        width: "30%",
      },
      {
        key: "mLastName",
        label: "Mother's Last  Name",
        type: "text",
        width: "30%",
      },
      {
        key: "minorGender",
        label: "Gender",
        type: "dropdown",
        mandatory: true,
        options: [{ id: 1, name: "Test" }],
        valueRenderer: (option) => option.id,
        displayName: (option) => `${option.name}`,
        width: "30%",
      },
      {
        key: "minorDob",
        label: "Date of Birth",
        type: "date",
        width: "30%",
      },
      {
        key: "minorPan",
        label: "Pan",
        type: "text",
        width: "30%",
      },
      {
        key: "minorForm16Finished",
        label: "Form-60 Finished",
        type: "dropdown",
        mandatory: true,
        options: [{ id: 1, name: "Test" }],
        valueRenderer: (option) => option.id,
        displayName: (option) => `${option.name}`,
        width: "30%",
      },
      {
        key: "nationality",
        label: "Nationality",
        type: "text",
        width: "30%",
      }]
  },
  // ========================Proof of Identity and Permanent Address

  {
    label: "Proof of Identity and Permanent Address",
    formElements: [{
      key: "displayName",
      displayName: "Proof of Identity and Permanent Address",
    },
    {
      key: "relatedUID",
      label: "UID (Aadhaar)",
      placeholder: "Enter text",
      errorText: "required",
      type: "text",
      mandatory: false,
      width: "30%",
    },
    {
      key: "relatedVoterCard",
      label: "Voter ID Card",
      placeholder: "Enter text",
      errorText: "required",
      type: "text",
      mandatory: false,
      width: "30%",
    },
    {
      key: "NregaJobCard",
      label: "NREGA Job Card",
      placeholder: "Enter text",
      errorText: "required",
      type: "text",
      mandatory: false,
      width: "30%",
    },
    {
      key: "nationalPopRegister",
      label: "National Population RegisterNo",
      placeholder: "Enter text",
      errorText: "required",
      type: "text",
      mandatory: false,
      width: "30%",
    },
    {
      key: "passportNo",
      label: "Passport No",
      placeholder: "Enter text",
      errorText: "required",
      type: "text",
      mandatory: true,
      width: "30%",
    },
    {
      key: "drivingLicenseNo",
      label: "Driving Licence No",
      placeholder: "Enter text",
      errorText: "required",
      type: "text",
      mandatory: false,
      width: "30%",
    },
    {
      key: "ekycAuthentication",
      label: "E-KYC Authentication",
      type: "text",
    },

    {
      key: "relatedAddressProof",
      label: "Address Proof",
      placeholder: "Enter text",
      errorText: "required",
      type: "dropdown",
      mandatory: true,
      options: [{ id: 1, username: "Test" }],
      valueRenderer: (option) => option.code,
      displayName: (option) => `${option.name}`,
      width: "30%",
    },
    {
      key: "relatedAddressProofIdNumber",
      label: "Address Proof ID Number",
      placeholder: "Enter text",
      errorText: "required",
      type: "text",
      mandatory: false,
      width: "30%",
    },
    {
      key: "relatedProofOfAddresIfOthers",
      label: "Proof Of Address If Others",
      placeholder: "Enter text",
      errorText: "required",
      type: "text",
      mandatory: false,
      width: "30%",
    },
    {
      key: "relatedPermAddressline1",
      label: "Address Line 1",
      placeholder: "Enter text",
      errorText: "required",
      type: "text",
      mandatory: true,
      width: "90%",
    },
    {
      key: "relatedPermAddressline2",
      label: "Address Line 2",
      placeholder: "Enter text",
      errorText: "required",
      type: "text",
      mandatory: false,
      width: "90%",
    },
    {
      key: "relatedPermAddressline3",
      label: "Address Line 3",
      placeholder: "Enter text",
      errorText: "required",
      type: "text",
      mandatory: false,
      width: "90%",
    },
    {
      key: "relatedPermCountry",
      label: "Country",
      placeholder: "Enter text",
      errorText: "required",
      type: "dropdown",
      mandatory: false,
      valueRenderer: (option) => option.code,
      displayName: (option) => `${option.name}`,
      width: "30%",
    },
    {
      key: "relatedPermState",
      label: "State",
      placeholder: "Enter text",
      errorText: "required",
      type: "dropdown",
      mandatory: true,
      valueRenderer: (option) => option.code,
      displayName: (option) => `${option.name}`,
      width: "30%",
    },
    {
      key: "relatdPermvillage",
      label: "City/Town/Village ",
      placeholder: "Enter text",
      errorText: "required",
      type: "text",
      mandatory: true,
      width: "30%",
    },
    {
      key: "relatedPermPincode",
      label: "PinCode",
      placeholder: "Enter text",
      errorText: "required",
      type: "text",
      mandatory: true,
      width: "30%",
    }]
  },

  // =========================Current Address Details
  {
    label: "Current Address Details",
    formElements: [{
      key: "displayName",
      displayName: "Current Address Details",
    },
    {
      key: "AddressProof",
      label: "Proof of Address",
      placeholder: "Enter text",
      errorText: "required",
      type: "dropdown",
      mandatory: true,
      options: [{ id: 1, username: "Test" }],
      valueRenderer: (option) => option.code,
      displayName: (option) => `${option.name}`,
      width: "30%",
    },
    {
      key: "addressNumber",
      label: "Proof of Address Number ",
      placeholder: "Enter text",
      errorText: "required",
      type: "text",
      mandatory: true,
      width: "30%",
    },
    {
      key: "relatedAddressline1",
      label: "Address Line 1",
      placeholder: "Enter text",
      errorText: "required",
      type: "text",
      mandatory: true,
      width: "90%",
    },
    {
      key: "relatedAddressline2",
      label: "Address Line 2",
      placeholder: "Enter text",
      errorText: "required",
      type: "text",
      mandatory: false,
      width: "90%",
    },
    {
      key: "relatedAddressline3",
      label: "Address Line 3",
      placeholder: "Enter text",
      errorText: "required",
      type: "text",
      mandatory: false,
      width: "90%",
    },
    {
      key: "relatedCountry",
      label: "Country",
      placeholder: "Enter text",
      errorText: "required",
      type: "dropdown",
      mandatory: false,
      options: [{ id: 1, username: "Test" }],
      valueRenderer: (option) => option.code,
      displayName: (option) => `${option.name}`,
      width: "30%",
    },
    {
      key: "relatedState",
      label: "State",
      placeholder: "Enter text",
      errorText: "required",
      type: "dropdown",
      mandatory: true,
      options: [{ id: 1, username: "Test" }],
      valueRenderer: (option) => option.code,
      displayName: (option) => `${option.name}`,
      width: "30%",
    },
    {
      key: "relatedVillage",
      label: "City/Town/Village ",
      placeholder: "Enter text",
      errorText: "required",
      type: "text",
      mandatory: true,
      width: "30%",
    },
    {
      key: "relatedPincode",
      label: "PinCode",
      placeholder: "Enter text",
      errorText: "required",
      type: "text",
      mandatory: true,
      width: "30%",
    }]
  },
  // =========================KYC Verification
  {
    label: "KYC Verification",
    formElements: [{
      key: "displayName",
      displayName: "KYC Verification",
    },
    {
      key: "typeOfDocSubmitted",
      label: "Document Received",
      width: "30%",
      type: "dropdown",
    },
    {
      key: "kycVerifiDate",
      label: "Verification Date",
      width: "30%",
      type: "date",
    },
    {
      key: "kycVerificationName",
      label: "Employee Name",
      width: "30%",
      type: "text",
    },
    {
      key: "kycVerifiEmpCode",
      label: "Employee Code",
      width: "30%",
      type: "text",
    },
    {
      key: "kycVerificationDesignation",
      label: "Employee Designation",
      width: "30%",
      type: "text",
    },
    {
      key: "orgName",
      label: "Organistion Name ",
      width: "30%",
      type: "text",
    },
    {
      key: "kycVerifiBranch",
      label: "Employee Branch",
      width: "30%",
      type: "text",

    },
    {
      key: "orgName",
      label: "Institution Name",
      width: "30%",
      type: "text",
    },
    {
      key: "orgCode",
      label: "Institution Code",
      width: "30%",
      type: "text",
    },
    {
      key: "relatedDateOfDeclarion",
      label: "Date of Declaration",
      type: "date",
      mandatory: true,
      width: "30%",
    },
    {
      key: "relatedPlaceOfDeclarion",
      label: "Place of Declaration",
      type: "text",
      mandatory: true,
      width: "30%",
    },
    ]
  },
  // upload section 
  {
    label: "Upload",
    formElements: [{
      key: "minorUploadSection",
      displayName: "upload",
    }]
  }
]
const minorFlowPrev = [
    {
      key: "displayName",
      displayName: "Related Person Details",
    },
  {
    key: "customerNo",
    label: "Customer No",
    type: "text",
    width: "30%",
  },
  {
    key: "relationshipTitle",
    label: "Relationship Type",
    type: "dropdown",
    mandatory: true,
    options: [{ id: 1, name: "Test" }],
    valueRenderer: (option) => option.id,
    displayName: (option) => `${option.name}`,
    width: "30%",
  },
  {
    key: "addDelFlag",
    label: "Addition/Deletion Flag",
    type: "dropdown",
    mandatory: true,
    options: [{ id: 1, name: "Test" }],
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
    width: "30%",
  },
  {
    key: "ckycNo",
    label: "KYC Number",
    type: "text",
    width: "30%",
  },
  // {
  //   key: "minorPrefix",
  //   label: "Title",
  //   type: "dropdown",
  //   mandatory: true,
  //   options: [{ id: 1, name: "Test" }],
  //   valueRenderer: (option) => option,
  //   displayName: (option) => `${option}`,
  //   width: "30%",
  // },
  {
    key: "relatedPersonFirstName",
    label: "Related Person First Name",
    type: "text",
    width: "30%",
  },
  {
    key: "relatedPersonMiddleName",
    label: "Related Person Middle Name",
    type: "text",
    width: "30%",
  },
  {
    key: "relatedPersonLastName",
    label: "Related Person Last  Name",
    type: "text",
    width: "30%",
  },
  {
    key: "maidenFirstName",
    label: "Related Person Maiden First Name",
    type: "text",
    width: "30%",
  },
  {
    key: "maidenMiddleName",
    label: "Related Person Maiden Middle Name",
    type: "text",
    width: "30%",
  },
  {
    key: "maidenLastName",
    label: "Related Person Maiden Last Name",
    type: "text",
    width: "30%",
  },
  {
    key: "fFirstName",
    label: "Father|Spouse First Name",
    type: "text",
    width: "30%",
  },
  {
    key: "fMiddleName",
    label: "Father|Spouse  Middle Name",
    type: "text",
    width: "30%",
  },
  {
    key: "fLastName",
    label: "Father|Spouse Last Name",
    type: "text",
    width: "30%",
  },
  {
    key: "mfirstName",
    label: "Mother's First Name",
    type: "text",
    width: "30%",
  },
  {
    key: "mMiddleName",
    label: "Mother's Middle Name",
    type: "text",
    width: "30%",
  },
  {
    key: "mLastName",
    label: "Mother's Last  Name",
    type: "text",
    width: "30%",
  },
  {
    key: "minorGender",
    label: "Gender",
    type: "dropdown",
    mandatory: true,
    options: [{ id: 1, name: "Test" }],
    valueRenderer: (option) => option.id,
    displayName: (option) => `${option.name}`,
    width: "30%",
  },
  {
    key: "minorDob",
    label: "Date of Birth",
    type: "date",
    width: "30%",
  },
  {
    key: "minorPan",
    label: "Pan",
    type: "text",
    width: "30%",
  },
  {
    key: "minorForm16Finished",
    label: "Form-60 Finished",
    type: "dropdown",
    mandatory: true,
    options: [{ id: 1, name: "Test" }],
    valueRenderer: (option) => option.id,
    displayName: (option) => `${option.name}`,
    width: "30%",
  },
  {
    key: "nationality",
    label: "Nationality",
    type: "dropdown",
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
    width: "30%",
  },
  // ========================Proof of Identity and Permanent Address
{
    key: "displayName",
    displayName : "Proof of Identity and Permanent Address",
  },
  {
    key: "relatedUID",
    label: "UID (Aadhaar)",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "30%",
  },
  {
    key: "relatedVoterCard",
    label: "Voter ID Card",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "30%",
  },
  {
    key: "NregaJobCard",
    label: "NREGA Job Card",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "30%",
  },
  {
    key: "nationalPopRegister",
    label: "National Population RegisterNo",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "30%",
  },
  {
    key: "passportNo",
    label: "Passport No",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
  },
  {
    key: "drivingLicenseNo",
    label: "Driving Licence No",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "30%",
  },
  {
    key: "ekycAuthentication",
    label: "E-KYC Authentication",
    type: "text",
  },
  {
    key: "otherId",
    label: "Any other Id Proof Name",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "30%",
  },
  {
    key: "relatedAddressProof",
    label: "Address Proof",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: true,
    options: [{ id: 1, username: "Test" }],
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
    width: "30%",
  },
  {
    key: "relatedAddressProofIdNumber",
    label: "Address Proof ID Number",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "30%",
  },
  {
    key: "relatedProofOfAddresIfOthers",
    label: "Proof Of Address If Others",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "30%",
  },
  {
    key: "relatedPermAddressline1",
    label: "Address Line 1",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "90%",
  },
  {
    key: "relatedPermAddressline2",
    label: "Address Line 2",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "90%",
  },
  {
    key: "relatedPermAddressline3",
    label: "Address Line 3",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "90%",
  },
  {
    key: "relatedPermCountry",
    label: "Country",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: false,
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
    width: "30%",
  },
  {
    key: "relatedPermState",
    label: "State",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: true,
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
    width: "30%",
  },
  {
    key: "relatdPermvillage",
    label: "City/Town/Village ",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
  },
  {
    key: "relatedPermPincode",
    label: "PinCode",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
  },

  // =========================Current Address Details
{
    key: "displayName",
    displayName : "Current Address Details",
  },
  {
    key: "AddressProof",
    label: "Proof of Address",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: true,
    options: [{ id: 1, username: "Test" }],
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
    width: "30%",
  },
  {
    key: "addressNumber",
    label: "Proof of Address Number ",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
  },
  {
    key: "relatedAddressline1",
    label: "Address Line 1",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "90%",
  },
  {
    key: "relatedAddressline2",
    label: "Address Line 2",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "90%",
  },
  {
    key: "relatedAddressline3",
    label: "Address Line 3",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: false,
    width: "90%",
  },
  {
    key: "relatedCountry",
    label: "Country",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: false,
    options: [{ id: 1, username: "Test" }],
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
    width: "30%",
  },
  {
    key: "relatedState",
    label: "State",
    placeholder: "Enter text",
    errorText: "required",
    type: "dropdown",
    mandatory: true,
    options: [{ id: 1, username: "Test" }],
    valueRenderer: (option) => option.code,
    displayName: (option) => `${option.name}`,
    width: "30%",
  },
  {
    key: "relatedVillage",
    label: "City/Town/Village ",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
  },
  {
    key: "relatedPincode",
    label: "PinCode",
    placeholder: "Enter text",
    errorText: "required",
    type: "text",
    mandatory: true,
    width: "30%",
  },
  // =========================KYC Verification
  {
    key: "displayName",
    displayName: "KYC Verification",
  },
  {
    key: "typeOfDocSubmitted",
    label: "Document Received",
    width: "30%",
    type: "dropdown",
  },
  {
    key: "kycVerifiDate",
    label: "Verification Date",
    width: "30%",
    type: "date",
  },
  {
    key: "kycVerificationName",
    label: "Employee Name",
    width: "30%",
    type: "text",
  },
  {
    key: "kycVerifiEmpCode",
    label: "Employee Code",
    width: "30%",
    type: "text",
  },
  {
    key: "kycVerificationDesignation",
    label: "Employee Designation",
    width: "30%",
    type: "text",
  },
  {
    key: "orgName",
    label: "Organistion Name ",
    width: "30%",
    type: "text",
  },
  {
    key: "kycVerifiBranch",
    label: "Employee Branch",
    width: "30%",
    type: "text",
    
  },
  {
    key: "orgName",
    label: "Institution Name",
    width: "30%",
    type: "text",
  },
  {
    key: "orgCode",
    label: "Institution Code",
    width: "30%",
    type: "text",
  },
  {
    key: "relatedDateOfDeclarion",
    label: "Date of Declaration",
    type: "date",
    mandatory: true,
    width: "30%",
  },
  {
    key: "relatedPlaceOfDeclarion",
    label: "Place of Declaration",
    type: "text",
    mandatory: true,
    width: "30%",
  },
]

const applicant = [
  "title",
  "personalFirstName",
  "personalMiddleName",
  "personalLastName",
];

const contact = [
  "officialTel",
  "officialPhoneNumber",
  "personalMobile",
  "personalEmail",
  "residentialTel",
  "residentailPhoneNumber",
];

const personal = personalDetails
  .map((x) => x.key)
  .filter((y) => y !== "-1")
  .filter((key) => !contact.includes(key))
  .filter((key) => !applicant.includes(key));

const kycVerfication = kycVerification.map((x) => x.key);
const address = [
  ...proofOfAddress.map((x) => x.key),
  ...currentProofOfAddress.map((x) => x.key),
];

let related = [
  "customerNo",
  "relationshipTitle",
  "addDelFlag",
  "ckycNo",
  "minorPrefix",
  "relatedPersonFirstName",
  "relatedPersonMiddleName",
  "relatedPersonLastName",
  "relatedFatherSpouseFlag",
  "fFirstName",
  "fMiddleName",
  "fLastName",
  "mfirstName",
  "mMiddleName",
  "mLastName",
  "minorGender",
  "minorDob",
  "minorPan",
  "minorForm16Finished",
  "nationality",
];

related = [
  ...related,
  ...proofOfIdentityPermanantAddress
    .map((x) => x.key)
    .filter((y) => y !== "-1"),
  ...currentAddress.map((x) => x.key).filter((y) => y !== "-1"),
  ...kycVerificationMinor.map((x) => x.key).filter((y) => y !== "-1"),
];

const getFlag = (keys, values, defaultValues) => {
  let result = false;
  for (const key of keys) {
    const ckycVal = values[key] && key ? values?.[key] : "";
    const custVal = defaultValues[key] && key ? defaultValues?.[key] : "";
    if (ckycVal?.toString()?.toLowerCase() !== custVal?.toString()?.toLowerCase()) {
      result = true;
      break;
    }
  }
  return result ? "01" : "02";
};

const constructStageFlags = (values, defaultValues) => {
  return [
    {
      applicant: getFlag(applicant, values, defaultValues),
      personal: getFlag(personal, values, defaultValues),
      address: getFlag(address, values, defaultValues),
      contact: getFlag(contact, values, defaultValues),
      remarks: getFlag(["remarks"], values, defaultValues),
      kycVerfication: getFlag(kycVerfication, values, defaultValues),
      // Identity: getFlag(applicant, values),
      relatedPerson: getFlag(related, values, defaultValues),
      // ImageDetails: getFlag(applicant, values),
    },
  ];
};

const fieldValues = (Country, curCountry) => {
  if (Country !== "IN") {
    proofOfAddress?.map(items => {
      if (items.key === "proofOfAddresIfOthers") {
        items.mandatory = true;
      }
    })
  } else {
    proofOfAddress?.map(items => {
      if (items.key === "proofOfAddresIfOthers") {
        items.mandatory = false;
      }
    })
  }
  if (curCountry !== "IN") {
    currentProofOfAddress?.map(items => {
      if (items.key === "currentProofOfAddresIfOthers") {
        items.mandatory = true;
      }
    })
  } else {
    currentProofOfAddress?.map(items => {
      if (items.key === "currentProofOfAddresIfOthers") {
        items.mandatory = false;
      }
    })
  }
}

export const allElements = [
  ...officeUseOnly,
  ...personalDetails,
  ...proofOfAddress,
  ...kycVerification,
  ...applicantDeclaration,
  ...permanantAddress,
  ...currentAddress,
  ...kycVerificationMinor,
  ...currentProofOfAddress,
  ...fatca,
  ...minorFlowPrev,
];

export {
  officeUseOnly,
  personalDetails,
  proofOfAddress,
  kycVerification,
  applicantDeclaration,
  currentAddress,
  proofOfIdentityPermanantAddress,
  permanantAddress,
  kycVerificationMinor,
  currentProofOfAddress,
  fatca,
  defaultDocs,
  defaultDocsMinor,
  addressDocs,
  identityDocs,
  constructStageFlags,
  fieldValues,
  minorFlow
};
