import axiosInstance from "./axios";
import TokenService from "../utils/TokenService";

export const loginUserFn = async (user) => {
	const response = await axiosInstance.post("/users/login", user);
	// Need to store token into localStorage and validate

	if (response.data)
		TokenService.updateLocalAccessToken(response.data.user.token);

	return response.data;
};

export const verifyToken = async (user) => {
	const response = await axiosInstance.post("/users/login", user);
	return response;
};

export const changePassword = async (user) => {
	const response = await axiosInstance.put(`/users/${user.id}/reset`, user);
	return response;
};

export const setPassword = async (user) => {
	const response = await axiosInstance.post("/users/password/set", user);
	return response;
};

export const recoverPassword = async (user) => {
	const response = await axiosInstance.post("/users/password/recovery", user);
	return response;
};

export const logout = async (user) => {
	try {
		const response = await axiosInstance.get("/users/session/logout");
		return response;
	} catch (e) {}
};
