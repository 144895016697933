import React from "react";
import { Box } from "@mui/material";
import UploadImageSection from "./UploadImageSection";

const PreviewUploadSection = ({ files, dropdownOptions }) => {
  return (
    <React.Fragment>
      <Box display="flex" justifyContent={"space-between"}>
        <Box mt="8px" width="100%">
          <Box
            display="flex"
            flexWrap="wrap"
            style={{
              border: "1px solid #D6D5FC",
              borderRadius: "8px",
              background: "#F2F2F2",
            }}
          >
            {files.length > 0 ? (
              files.map((item) => {
                return (
                  <Box>
                    <UploadImageSection
                      item={item}
                      dropdownOptions={dropdownOptions}
                    />
                  </Box>
                );
              })
            ) : (
              <Box style={{ background: "white" }} p="16px">
                No Files to display
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default PreviewUploadSection;
