import React from "react";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import { Box } from "@mui/material";

const CAPTCH_FONT_COLOR = "#FF8744";
const CAPTCH_BACKGROUND_COLOR = "white";

const Captcha = () => {
  React.useEffect(() => {
    loadCaptchaEnginge(6, CAPTCH_BACKGROUND_COLOR, CAPTCH_FONT_COLOR);
  }, []);

  return (
    <Box
      display="flex"
      style={{
        border: "1px solid #D1D1FF",
        marginTop: "5px",
        paddingTop: "7px",
        borderRadius: "4px",
        backgroundColor: "white",
      }}>
      <LoadCanvasTemplate />
    </Box>
  );
};

export default Captcha;
