import React from 'react'
import { useFieldArray } from 'react-hook-form';
import { Box, Button } from "@mui/material"
import SelectInput from "../../Form-Components/SelectInput";
import TextInput from "../../Form-Components/TextInput";
import CheckBox from "../../Form-Components/CheckBox";
const MinorCurrentAddress = (props) => {
    const { index, formProps, files, dropdownOptions, handleFieldValue } = props
    const { control, register, setValue, getValues, watch } = formProps;
    // const {fields: MinorPermanentAddress, append: appendMinorPermanentAddress, remove: removeMinorPermanentAddress} = useFieldArray({
    //     control,
    //     name: `minorDetails.${index}.relatedProofOfIdentity`
    // })
    return (
        <Box display="flex" flexWrap="wrap">
            <CheckBox
                width={"90%"}
                label={"Same as Permanent Address "}
                fieldkey={`minorDetails[${index}].relatedPermSameAsPermanant`}
                errorText={"Same as Permanent Address  is Required"}
                placeholder={"Enter text"}
                control={control}
                register={register}
                setValue={setValue}
                mandatory={false}
                onChangeValue={() => handleFieldValue(index, "sameAddress")}
            />
            <SelectInput
                label={"Proof of Address"}
                fieldkey={`minorDetails[${index}].AddressProof`}
                errorText="Proof of Address is Required"
                placeholder="State"
                control={control}
                register={register}
                mandatory={true}
                setValue={setValue}
                options={dropdownOptions && dropdownOptions["AddressProof"]}
                valueRenderer={(option) => option?.code}
                displayName={(option) => option?.name}
                width="30%"
                watch={watch}
            />
            <TextInput
                label={"Proof of Address Number"}
                fieldkey={`minorDetails[${index}].addressNumber`}
                errorText="Proof of Address Number is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={true}
                width="30%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.addressNumber}
            />
            <TextInput
                label={"Address Line 1"}
                fieldkey={`minorDetails[${index}].relatedAddressline1`}
                errorText="Address Line 1 is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={true}
                width="92.5%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.relatedAddressline1}
            />
            <TextInput
                label={"Address Line 2"}
                fieldkey={`minorDetails[${index}].relatedAddressline2`}
                errorText="Address Line 2 is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={false}
                width="92.5%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.relatedAddressline2}
            />
            <TextInput
                label={"Address Line 3"}
                fieldkey={`minorDetails[${index}].relatedAddressline3`}
                errorText="Address Line 3 is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={false}
                width="92.5%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.relatedAddressline3}
            />
            <SelectInput
                label={"Country"}
                fieldkey={`minorDetails[${index}].relatedCountry`}
                errorText="Country is Required"
                placeholder="State"
                control={control}
                register={register}
                mandatory={false}
                setValue={setValue}
                options={dropdownOptions && dropdownOptions["relatedCountry"]}
                valueRenderer={(option) => option?.code}
                displayName={(option) => option?.name}
                width="30%"
                watch={watch}
                disabled={true}
            // defaultValue={"IN"}
            />
            <SelectInput
                label={"State"}
                fieldkey={`minorDetails[${index}].relatedState`}
                errorText="State is Required"
                placeholder="State"
                control={control}
                register={register}
                mandatory={true}
                setValue={setValue}
                options={dropdownOptions && dropdownOptions["relatedState"]}
                valueRenderer={(option) => option?.code}
                displayName={(option) => option?.name}
                width="30%"
                watch={watch}
            />
            {/* <SelectInput
                label={"District"}
                fieldkey={`minorDetails[${index}].relatedDistrict`}
                errorText="District is Required"
                placeholder="State"
                control={control}
                register={register}
                mandatory={true}
                setValue={setValue}
                options={dropdownOptions && dropdownOptions["district"]}
                valueRenderer={(option) => option}
                displayName={(option) => option}
                width="30%"
                watch={watch}
            /> */}
            <TextInput
                label={"District"}
                fieldkey={`minorDetails[${index}].relatedDistrict`}
                errorText="District is Required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={false}
                width="30%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.relatedDistrict}
            />
            <TextInput
                label={"City/Town/Village"}
                fieldkey={`minorDetails[${index}].relatedVillage`}
                errorText="City/Town/Village is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={true}
                width="30%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.relatedVillage}
            />
            <TextInput
                label={"PinCode"}
                fieldkey={`minorDetails[${index}].relatedPincode`}
                errorText="PinCode is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={true}
                width="30%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.relatedPincode}
            />
            {/* <SelectInput
                label={"PinCode"}
                fieldkey={`minorDetails[${index}].relatedPincode`}
                errorText="PinCode is Required"
                placeholder="State"
                control={control}
                register={register}
                mandatory={true}
                setValue={setValue}
                options={dropdownOptions && dropdownOptions["pincode"]}
                valueRenderer={(option) => option}
                displayName={(option) => option}
                width="30%"
                watch={watch}
            /> */}
        </Box>
    )
}

export default MinorCurrentAddress;