import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";
import Modal from "@mui/material/Modal";
import { Card } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Text from "../Atoms/Text";
import deletePopup from "../../assets/icons/delete-popup.svg";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  // bgcolor: "background.paper",
  // border: "2px solid #000",
  // boxShadow: 24,
  p: 2,
};

export default function DeletePopUp(props) {
  const {
    closePopup,
    onDelete,
    openAlertModal,
    isLoading,
    title,
    okTitle,
    subText,
    onOk,
    cancelText,
  } = props;
  const [open, setOpen] = React.useState(openAlertModal);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onClose = () => {
    closePopup();
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Card sx={style}>
        <Box display="flex" justifyContent="space-between">
          <Text fontSize="16px" fontWeight="600">
            {title}
          </Text>
          <HighlightOffIcon sx={{ cursor: "pointer" }} onClick={onClose} />
        </Box>
        <Box m="16px" display="flex">
          <HelpOutlineOutlinedIcon
            sx={{ fontSize: "14px", marginTop: "2px" }}
          />
          <Text fontSize="14px" ml="4px">
            {subText}
          </Text>
        </Box>
        <Box mt={"16px"} display="flex" justifyContent={"flex-end"}>
          {cancelText && (
            <Button
              onClick={closePopup}
              size="small"
              variant="outlined"
              sx={{ marginRight: "8px" }}>
              Cancel
            </Button>
          )}

          {onOk && (
            <LoadingButton
              loading={false}
              type={"submit"}
              size="small"
              variant="contained"
              color="primary"
              disableElevation
              onClick={onOk}
              style={{ textTransform: "none" }}>
              <Text>{okTitle}</Text>
            </LoadingButton>
          )}
        </Box>
      </Card>
    </Modal>
  );
}
