import dayjs from "dayjs";

import moment from "moment";
import {
  applicantDeclaration,
  currentProofOfAddress,
  kycVerification,
  officeUseOnly,
  personalDetails,
  proofOfAddress
} from "./formElements";

export const formatDate = (value, format = "MM-DD-YYYY") => {
  if (dayjs.isDayjs(value)) return value;
  if (!value || value === "") return dayjs().format(format);

  const split = value && value?.split("-");
  if (split && split.length > 1) {
    return `${value.split("-")[1]}-${value.split("-")[0]}-${value.split("-")[2]
      }`;
  } else {
    const date = new Date(value);
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    const yyyy = date.getFullYear();
    dd = dd > 9 ? dd : `0${dd}`;
    mm = mm > 9 ? mm : `0${mm}`;
    if (date != "Invalid Date") {
      return format === "MM-DD-YYYY"
        ? `${mm}-${dd}-${yyyy}`
        : `${dd}-${mm}-${yyyy}`;
    }
  }
};

export const arrCodes = [
  "pan",
  "Documents issued by Government departments of foreign jurisdictions and letter issued by Foreign Embassy or Mission in India.".toLowerCase(),
  "Pension or family pension payment orders (PPOs) issued to retired employees by Government Departments or Public Sector Undertakings, if they contain the address.".toLowerCase(),
  "Bank account or Post Office savings bank account statement.or Municipal Tax receipt".toLowerCase(),
  "Bank account or Post Office savings bank account statement.".toLowerCase(),
  "Utility bill which is not more than two months old of any service provider (electricity, telephone, post-paid mobile phone, piped gas, water bill).".toLowerCase(),
  "Letter of allotment of accommodation from employer issued by State or Central Government departments, statutory or regulatory bodies, public sector undertakings, scheduled commercial banks, financial institutions and listed companies".toLowerCase(),
  "Simplified Measures Account  - Letter issued by a gazetted officer, with a duly attested photograph of the person".toLowerCase(),
  "Simplified Measures Account  - Identity card with applicant’s photograph issued by Central/ State Government Departments, Statutory/ Regulatory Authorities, Public Sector Undertakings, Scheduled Commercial Banks, and Public Financial Institutions".toLowerCase(),
  "National Population Register Letter".toLowerCase(),
]


export const arrCodesCaptal = [
  "PAN",
  "Documents issued by Government departments of foreign jurisdictions and letter issued by Foreign Embassy or Mission in India.",
  "Pension or family pension payment orders (PPOs) issued to retired employees by Government Departments or Public Sector Undertakings, if they contain the address.",
  "Bank account or Post Office savings bank account statement.or Municipal Tax receipt",
  "Bank account or Post Office savings bank account statement.",
  "Utility bill which is not more than two months old of any service provider (electricity, telephone, post-paid mobile phone, piped gas, water bill).",
  "Letter of allotment of accommodation from employer issued by State or Central Government departments, statutory or regulatory bodies, public sector undertakings, scheduled commercial banks, financial institutions and listed companies",
  "Simplified Measures Account  - Letter issued by a gazetted officer, with a duly attested photograph of the person",
  "Simplified Measures Account  - Identity card with applicant’s photograph issued by Central/ State Government Departments, Statutory/ Regulatory Authorities, Public Sector Undertakings, Scheduled Commercial Banks, and Public Financial Institutions",
  "National Population Register Letter",
  "National Population Registry Letter",

]

export const constructRequestObject = (values, customerId) => {
  const MinorDetails = values["minorDetails"]?.map((item, index) => {
    return (
      {
        relatedPersonDetails: {
          SEQUENCE_NO: "",
          REL_TYPE: item?.relationshipTitle,
          ADD_DEL_FLAG: item?.addDelFlag,
          CKYC_NO: item?.ckycNo,
          PREFIX: item?.title,
          FNAME: item?.relatedPersonFirstName,
          MNAME: item?.relatedPersonMiddleName,
          LNAME: item?.relatedPersonLastName,
          MAIDEN_PREFIX: item?.maidenTitle,
          MAIDEN_FNAME: item?.maidenFirstName,
          MAIDEN_MNAME: item?.maidenMiddleName,
          MAIDEN_LNAME: item?.maidenLastName,
          FATHERSPOUSE_FLAG: item?.relatedFatherSpouseFlag,
          FATHER_PREFIX: item?.fatherSpousePrefix,
          FATHER_FNAME: item.fFirstName,
          FATHER_MNAME: item.fMiddleName,
          FATHER_LNAME: item.fLastName,
          MOTHER_PREFIX: item?.motherPrefix,
          MOTHER_FNAME: item.mfirstName,
          MOTHER_MNAME: item.mMiddleName,
          MOTHER_LNAME: item.mLastName,
          DOB: item.minorDob,
          GENDER: item?.minorGender,
          PAN: item?.minorPan,
          FORM_SIXTY: item.minorForm16Finished,
          NATIONALITY: item?.nationality,
          IMAGE_NAME_PHOTO: `${item?.commonDocs?.filter(items => items?.documentCode === "02" ? items : "")?.[0]?.file?.fileName ||item?.commonDocs?.filter(items => items?.documentCode === "02" ? items : "")?.[0]?.file?.name}`,
          POA_POI_IMG_NAME: item?.identityDocs?.[0]?.file?.fileName || item?.identityDocs?.[0]?.file?.name,
          CURRENT_POI_POA_IMG_NAME: item?.addressDocs?.[0]?.file?.fileName ? item?.addressDocs?.[0]?.file?.fileName : item?.addressDocs?.[0]?.file?.name ? item?.addressDocs?.[0]?.file?.name : "",
        },
        relatedPersonAddressIdenity: {
          ADD_LINE1: item?.relatedPermAddressline1,
          ADD_LINE2: item?.relatedPermAddressline2,
          ADD_LINE3: item?.relatedPermAddressline3,
          ADD_CITY: item?.relatdPermvillage,
          ADD_DIST: item?.relatedPermDistrict,
          ADD_STATE: item?.relatedPermState,
          ADD_COUNTRY: item?.relatedPermCountry,
          ADD_PIN: item?.relatedPermPincode,
          PERM_POI_TYPE: item?.relatedAddressProof,
          PERM_POI_TYPE_NUMBER: item?.relatedAddressProofIdNumber,
          SAME_AS_PERM_FLAG: item?.relatedPermSameAsPermanant,
          CORRES_ADD_LINE1: item?.relatedAddressline1,
          CORRES_ADD_LINE2: item?.relatedAddressline2,
          CORRES_ADD_LINE3: item?.relatedAddressline3,
          CORRES_ADD_CITY: item?.relatedVillage,
          CORRES_ADD_DIST: item?.relatedDistrict,
          CORRES_ADD_STATE: item?.relatedState,
          CORRES_ADD_COUNTRY: item?.relatedCountry,
          CORRES_ADD_PIN: item?.relatedPincode,
          CORRES_POI_TYPE: item?.AddressProof,
          CORRES_POI_TYPE_NUMBER: item?.addressNumber,
          DRIVING_LICENCE: item.drivingLicenseNo,
          DRIVING_LICENCE_CODE: item.drivingExpCode,
          PASSPORT: item.passportNo,
          PASSPORT_CODE: item.passportExpCode,
          PROOF_OF_POSSESSION_OF_AADHAAR: item?.relatedaadharNumber,
          NREGA: item?.NregaJobCard,
          NATIONAL_POPULATION_REG_LETTER: item?.nationalPopRegister,
          VOTERID: item?.relatedVoterCard,
          OFFLINE_VERIFICATION_AADHAAR: item?.relatedUID,
          E_KYC_AUTHENTICATION: item?.ekycAuthentication,
          OTHER_ID: item?.otherId,
          OTHER_ID_NO: item?.otherProofNumber,
          SMA_CODE: item?.smaDocCode,
          SMA_CODE_NO: item?.smaIdentNumber,
        },
        declaration: {
          DEC_DATE: dayjs(
            new Date(item?.relatedDateOfDeclarion)
          )?.format("DD-MM-YYYY"),
          DEC_PLACE: item?.relatedPlaceOfDeclarion,
          KYC_DATE: item?.kycVerifiDate,
          DOC_SUB: item?.typeOfDocSubmitted,
          KYC_NAME: item?.kycVerificationName,
          KYC_DESIGNATION: item?.kycVerificationDesignation,
          KYC_BRANCH: item.kycVerifiBranch,
          KYC_EMPCODE: item.kycVerifiEmpCode,
          ORG_NAME: item?.orgName,
          ORG_CODE: item?.orgCode,
        },
      }

    )
})

  return {
    customerId: customerId || values["customerId"],
    office: [
      {
        CONSTI_TYPE:
          values["constitutionType"] && values["constitutionType"] !== ""
            ? values["constitutionType"]
            : "01",
        CONSTI_TYPE_OTHERS: values["constitutionTypeOthers"],
        CKYC_NO: values["kycNumber"],
        applicationType: values["applicationType"],
        custNo: values["customerNumber"],
        // mobileNumber: values["MOBILE_NUM"],
        custBranch: values["customerBranch"],
        ACC_TYPE: values["accountType"],
        // acntTypeDetails: [
        //   {
        //     relatedPersonDetails: {
        //       SEQUENCE_NO: values["customerNo"],
        //       REL_TYPE: values["relationshipTitle"],
        //       ADD_DEL_FLAG: values["addDelFlag"],
        //       CKYC_NO: values["ckycNo"],
        //       PREFIX: values["minorPrefix"],
        //       FNAME: values["relatedPersonFirstName"],
        //       MNAME: values["relatedPersonMiddleName"],
        //       LNAME: values["relatedPersonLastName"],
        //       MAIDEN_PREFIX: "",
        //       MAIDEN_FNAME: "",
        //       MAIDEN_MNAME: "",
        //       MAIDEN_LNAME: "",
        //       FATHERSPOUSE_FLAG: values["forMother"],
        //       FATHER_PREFIX: "",
        //       FATHER_FNAME: values["fFirstName"],
        //       FATHER_MNAME: values["fMiddleName"],
        //       FATHER_LNAME: values["fLastName"],
        //       MOTHER_PREFIX: "",
        //       MOTHER_FNAME: values["mfirstName"],
        //       MOTHER_MNAME: values["mMiddleName"],
        //       MOTHER_LNAME: values["mLastName"],
        //       DOB: values["minorDob"],
        //       GENDER: values["minorGender"],
        //       PAN: values["minorPan"],
        //       FORM_SIXTY: values["minorForm16Finished"],
        //       NATIONALITY : values["nationality"],
        //       SPOUSE_VALUE: values["relatedFatherSpouseFlag"],
        //     },
        //     relatedPersonAddressIdenity: {

        //       ADD_LINE1: values["relatedAddressline1"],
        //       ADD_LINE2: values["relatedAddressline2"],
        //       ADD_LINE3: "",
        //       ADD_CITY: values["relatedVillage"],
        //       ADD_DIST: values["relatedDistrict"],
        //       ADD_STATE: values["relatedState"],
        //       ADD_COUNTRY: values["relatedCountry"],
        //       ADD_PIN: values["relatedPincode"],
        //       PERM_POI_TYPE: values["proofOfAddress"],
        //       SAME_AS_PERM_FLAG: values["relatedPermSameAsPermanant"],
        //       CORRES_ADD_LINE1: values["relatedPermAddressline1"],
        //       CORRES_ADD_LINE2: values["relatedPermAddressline2"],
        //       CORRES_ADD_LINE3: "",
        //       CORRES_ADD_CITY: values["relatdPermvillage"],
        //       CORRES_ADD_DIST: values["relatedPermDistrict"],
        //       CORRES_ADD_STATE: values["relatedPermState"],
        //       CORRES_ADD_COUNTRY: values["relatedPermCountry"],
        //       CORRES_ADD_PIN: values["relatedPermPincode"],
        //       PERM_POA: values["relatedAddressProof"],
        //       PERM_POA_VALUE: values["relatedAddressProofIdNumber"],
        //       CORRES_POA: values["AddressProof"],
        //       CORRES_POA_VALUE: values["addressNumber"],
        //       OTHER_ID : values["otherId"],
        //       OTH_PROOF_NO: values["otherProofNumber"],
        //       SMA_DOC_CODE: values["smaDocCode"],
        //       SMA_IDENT_NO: values["smaIdentNumber"],
        //       ADD_OF_OTHER_ID: values["relatedProofOfAddresIfOthers"],
        //       // "RESI_STD_CODE": "",
        //       // "RESI_TEL_NUM": "",
        //       // "OFF_STD_CODE": "",
        //       // "OFF_TEL_NUM": "",
        //       // "MOB_CODE": "",
        //       // "MOB_NUM": "",
        //       // "EMAIL": "",
        //       // "REMARKS": "",
        //       PROOF_OF_POSSESSION_OF_AADHAAR: "",
        //       VOTERID: values["relatedVoterCard"],
        //       NREGA: values["NregaJobCard"],
        //       PASSPORT: values["passportNo"],
        //       DRIVING_LICENCE: values["drivingLicenseNo"],
        //       NATIONAL_POPULATION_REG_LETTER: values["nationalPopRegister"],
        //       OFFLINE_VERIFICATION_AADHAAR: values["relatedUID"],
        //       E_KYC_AUTHENTICATION: "",
        //     },
        //     declaration: {
        //       DEC_DATE: values["relatedDateOfDeclarion"] ?? moment().format("DD-MM-YYYY"),
        //       DEC_PLACE: values["relatedPlaceOfDeclarion"],
        //       KYC_DATE: values["kycVerifiDate"] ?? moment().format("DD-MM-YYYY"),
        //       DOC_SUB: values["typeOfDocSubmitted"],
        //       KYC_NAME: values["kycVerificationName"],
        //       KYC_DESIGNATION: values["kycVerificationDesignation"],
        //       KYC_BRANCH: values["kycVerifiBranch"],
        //       KYC_EMPCODE: values["kycVerifiEmpCode"],
        //       ORG_NAME: values["orgName"],
        //       ORG_CODE: values["orgCode"],
        //     },
        //   },
        // ],
        acntTypeDetails: MinorDetails
      },
    ],
    personal: [
      {
        // id: values[''],
        PREFIX: values["title"],
        FNAME: values["personalFirstName"],
        MNAME: values["personalMiddleName"],
        LNAME: values["personalLastName"],
        MOB_CODE: values["officialTel"],
        MOB_NUM: values["personalMobile"],
        EMAIL: values["personalEmail"],
        PROOF_OF_POSSESSION_OF_AADHAAR: "999",
        PAN: values["personaPan"],
        aadharNum: values["pesonalAadhar"],
        dependents: {
          FATHERSPOUSE_FLAG: values["dependentType"],
          RESI_STD_CODE: values["residentialTel"],
          RESI_TEL_NUM: values["residentailPhoneNumber"],
          OFF_STD_CODE: values["officialTel"],
          OFF_TEL_NUM: values["officialPhoneNumber"],
          // MOB_NUM: values["personalMobile"],
          FORM_SIXTY: values["pesonalForm16"],
          DOB: values["pesonalDob"],
          GENDER: values["personalGender"],
          citezenship: values["personalcitizenship"],
          resdentStatus: values["residentialStatus"],
          occutpationType: values["occupatationType"],
          details: [
            {
              // FATHER_PREFIX: values["fatherInitial"],
              // FATHER_FNAME: values["father"],
              // FATHER_MNAME: values["fatherMiddleName"],
              // FATHER_LNAME: values["fatherLastName"],
              // "FATHER_FULLNAME": "Test"
              "FATHER/SPOUSE_PREFIX": values["fatherInitial"],
              "FATHER/SPOUSE_FNAME": values["father"],
              "FATHER/SPOUSE_MNAME": values["fatherMiddleName"],
              "FATHER/SPOUSE_LNAME": values["fatherLastName"],
              // "FATHER/SPOUSE_FULLNAME": "MR THIRUPATHI RAO REDDY"
            },
            {
              MOTHER_PREFIX: values["motherInitial"],
              MOTHER_FNAME: values["motherFirstName"],
              MOTHER_MNAME: values["motherMiddleName"],
              MOTHER_LNAME: values["motherLastName"],
              // "MOTHER_FULLNAME": "Test"
            },
            // {
            //   "MAIDEN_PREFIX": "MR",
            //   "MAIDEN_FNAME": "",
            //   "MAIDEN_MNAME": "Test",
            //   "MAIDEN_LNAME": "Test"
            // }
          ],
        },
        address: {
          "current/permenantAddress": {
            PERM_LINE1: values["line1"],
            PERM_LINE2: values["line2"],
            PERM_LINE3: values["line3"],
            PERM_CITY: values["city"],
            PERM_DIST: values["district"],
            PERM_STATE: values["state"],
            PERM_COUNTRY: values["country"],
            PERM_PIN: values["pincode"],
            PERM_POA: values["addressProof"],
            PERM_POA_VALUE: values["addressProofIdNumber"],
            PERM_CORRES_SMEFLAG: values["sameAsPermanant"] ? 1 : 0,
          },
          "correspondence/localAddress": {
            CORRES_LINE1: values["currentLine1"],
            CORRES_LINE2: values["currentLine2"],
            CORRES_LINE3: values["currentLine3"],
            CORRES_CITY: values["currentCity"],
            CORRES_DIST: values["currentDistrict"],
            CORRES_STATE: values["currentState"],
            CORRES_COUNTRY: values["currentCountry"],
            CORRES_PIN: values["currentPincode"],
            CORRES_POA: values["currentAddressProof"],
            CORRES_POA_VALUE: values["currentAddressProofIdNumber"],
          },
        },
        identityDetails: [
          {
            NUM_IDENTITY: "",
            NUM_RELATED: "",
            NUM_IMAGES: "",
            SEQUENCE_NO: "",
            IDENT_TYPE: "",
            IDENT_NUM: "",
            IDVER_STATUS: "",
          },
          {
            NUM_IDENTITY: "",
            NUM_RELATED: "",
            NUM_IMAGES: "",
            SEQUENCE_NO: "",
            IDENT_TYPE: "",
            IDENT_NUM: "",
            IDVER_STATUS: "",
          },
        ],
      },
    ],
    kycVerification: [
      {
        DOC_SUB: values["documentRecieved"],
        KYC_DATE: values["kycVerifiDate"],
        KYC_NAME: values["employeeName"],
        KYC_EMPCODE: values["employeeCode"],
        KYC_DESIGNATION: values["employeeDesignation"],
        KYC_BRANCH: values["employeeBranch"],
        ORG_CODE: values["instCode"],
        ORG_NAME: values["instName"],
        REMARKS: values["remarks"],
        DEC_DATE: values["dateOfDeclaration"],
        DEC_PLACE: values["placeOfDeclaration"],
      },
    ],
  };
};

const getDependentValue = (key, values, index) => {
  return values && values.details && values.details[index]
    ? values.details[index][key]
    : "";
};

export const formDefaultValues = (values, files = []) => {
  const options = ["Mr", "Mrs", "Minor", "Miss", "Dr"]
  if (!values) return {};
  const { dependents } = values || {};
  const currentA =
    (values?.address && values?.address["correspondence/localAddress"]) || {};
  const currentP =
    (values?.address && values?.address["current/permenantAddress"]) || {};
  const related = values?.acntTypeDetails ? values?.acntTypeDetails[0] : {};
  let { relatedPersonDetails, relatedPersonAddressIdenity, declaration } =
    related || {};
  relatedPersonDetails = relatedPersonDetails || {};
  relatedPersonAddressIdenity = relatedPersonAddressIdenity || {};
  declaration = declaration || {};


  const MinorDetails = values["acntTypeDetails"]?.map((item, index) => (
    {
      relationshipTitle: item?.relatedPersonDetails?.REL_TYPE,
      addDelFlag: item?.relatedPersonDetails?.ADD_DEL_FLAG,
      ckycNo: item?.relatedPersonDetails?.CKYC_NO,
      title: item?.relatedPersonDetails?.PREFIX,
      relatedPersonFirstName: item?.relatedPersonDetails?.FNAME,
      relatedPersonMiddleName: item?.relatedPersonDetails?.MNAME,
      relatedPersonLastName: item?.relatedPersonDetails?.LNAME,
      maidenTitle: item?.relatedPersonDetails?.MAIDEN_PREFIX,
      maidenFirstName: item?.relatedPersonDetails?.MAIDEN_FNAME,
      maidenMiddleName: item?.relatedPersonDetails?.MAIDEN_MNAME,
      maidenLastName: item?.relatedPersonDetails?.MAIDEN_LNAME,
      relatedFatherSpouseFlag: item?.relatedPersonDetails?.FATHERSPOUSE_FLAG,
      fatherSpousePrefix: item?.relatedPersonDetails?.FATHER_PREFIX,
      fFirstName: item?.relatedPersonDetails.FATHER_FNAME,
      fMiddleName: item?.relatedPersonDetails.FATHER_MNAME,
      fLastName: item?.relatedPersonDetails.FATHER_LNAME,
      motherPrefix: item?.relatedPersonDetails?.MOTHER_PREFIX,
      mfirstName: item?.relatedPersonDetails.MOTHER_FNAME,
      mMiddleName: item?.relatedPersonDetails.MOTHER_MNAME,
      mLastName: item?.relatedPersonDetails.MOTHER_LNAME,
      minorDob: item?.relatedPersonDetails.DOB,
      minorGender: item?.relatedPersonDetails?.GENDER,
      minorPan: item?.relatedPersonDetails?.PAN,
      minorForm16Finished: item?.relatedPersonDetails.FORM_SIXTY,
      nationality: item?.relatedPersonDetails?.NATIONALITY,

      relatedPermAddressline1: item?.relatedPersonAddressIdenity?.ADD_LINE1,
      relatedPermAddressline2: item?.relatedPersonAddressIdenity?.ADD_LINE2,
      relatedPermAddressline3: item?.relatedPersonAddressIdenity?.ADD_LINE3,
      relatdPermvillage: item?.relatedPersonAddressIdenity?.ADD_CITY,
      relatedPermDistrict: item?.relatedPersonAddressIdenity?.ADD_DIST,
      relatedPermState: item?.relatedPersonAddressIdenity?.ADD_STATE,
      relatedPermCountry: item?.relatedPersonAddressIdenity?.ADD_COUNTRY,
      relatedPermPincode: item?.relatedPersonAddressIdenity?.ADD_PIN,
      relatedAddressProof: item?.relatedPersonAddressIdenity?.PERM_POI_TYPE,
      relatedAddressProofIdNumber: item?.relatedPersonAddressIdenity?.PERM_POI_TYPE_NUMBER,
      relatedPermSameAsPermanant: item?.relatedPersonAddressIdenity?.SAME_AS_PERM_FLAG,
      relatedAddressline1: item?.relatedPersonAddressIdenity?.CORRES_ADD_LINE1,
      relatedAddressline2: item?.relatedPersonAddressIdenity?.CORRES_ADD_LINE2,
      relatedAddressline3: item?.relatedPersonAddressIdenity?.CORRES_ADD_LINE3,
      relatedVillage: item?.relatedPersonAddressIdenity?.CORRES_ADD_CITY,
      relatedDistrict: item?.relatedPersonAddressIdenity?.CORRES_ADD_DIST,
      relatedState: item?.relatedPersonAddressIdenity?.CORRES_ADD_STATE,
      relatedCountry: item?.relatedPersonAddressIdenity?.CORRES_ADD_COUNTRY,
      relatedPincode: item?.relatedPersonAddressIdenity?.CORRES_ADD_PIN,
      AddressProof: item?.relatedPersonAddressIdenity?.CORRES_POI_TYPE,
      addressNumber: item?.relatedPersonAddressIdenity?.CORRES_POI_TYPE_NUMBER,
      drivingLicenseNo: item?.relatedPersonAddressIdenity.DRIVING_LICENCE,
      drivingExpCode: item?.relatedPersonAddressIdenity.DRIVING_LICENCE_CODE,
      passportNo: item?.relatedPersonAddressIdenity.PASSPORT,
      passportExpCode: item?.relatedPersonAddressIdenity.PASSPORT_CODE,
      relatedaadharNumber: item?.relatedPersonAddressIdenity?.PROOF_OF_POSSESSION_OF_AADHAAR,
      NregaJobCard: item?.relatedPersonAddressIdenity?.NREGA,
      nationalPopRegister: item?.relatedPersonAddressIdenity?.NATIONAL_POPULATION_REG_LETTER,
      relatedVoterCard: item?.relatedPersonAddressIdenity?.VOTERID,
      relatedUID: item?.relatedPersonAddressIdenity?.OFFLINE_VERIFICATION_AADHAAR,
      ekycAuthentication: item?.relatedPersonAddressIdenity?.E_KYC_AUTHENTICATION,
      otherId: item?.relatedPersonAddressIdenity?.OTHER_ID,
      otherProofNumber: item?.relatedPersonAddressIdenity?.OTHER_ID_NO,
      smaDocCode: item?.relatedPersonAddressIdenity?.SMA_CODE,
      smaIdentNumber: item?.relatedPersonAddressIdenity?.SMA_CODE_NO,
      relatedDateOfDeclarion: item?.declaration?.DEC_DATE,
      relatedPlaceOfDeclarion: item?.declaration?.DEC_PLACE,
      kycVerifiDate: item?.declaration?.KYC_DATE,
      typeOfDocSubmitted: item?.declaration?.DOC_SUB,
      kycVerificationName: item?.declaration?.KYC_NAME,
      kycVerificationDesignation: item?.declaration?.KYC_DESIGNATION,
      kycVerifiBranch: item?.declaration.KYC_BRANCH,
      kycVerifiEmpCode: item?.declaration.KYC_EMPCODE,
      orgName: item?.declaration?.ORG_NAME,
      orgCode: item?.declaration?.ORG_CODE,
    }
  ))

  return {
    applicationType: values["applicationType"],
    docketNo: values["docketNo"],
    constitutionType: values["CONSTI_TYPE"] || "01",
    constitutionTypeOthers: values["CONSTI_TYPE_OTHERS"],
    customerNumber: values["customerNumber"],
    customerId: values["customerId"],
    customerBranch: values["customerBranch"],
    kycNumber: values["CKYC_NO"],
    accountType: values["ACC_TYPE"],
    title: options?.includes(values["PREFIX"]?.charAt(0)?.toUpperCase() + values["PREFIX"]?.slice(1)?.toLowerCase()) ? values["PREFIX"]?.charAt(0)?.toUpperCase() + values["PREFIX"]?.slice(1)?.toLowerCase() : null,
    personalFirstName: values["FNAME"],
    personalMiddleName: values["MNAME"],
    personalLastName: values["LNAME"],
    dependentType: (dependents && dependents["FATHERSPOUSE_FLAG"]) || "01",
    fatherInitial: options?.includes(getDependentValue("FATHER/SPOUSE_PREFIX", dependents, 0)?.charAt(0)?.toUpperCase() + getDependentValue("FATHER/SPOUSE_PREFIX", dependents, 0)?.slice(1)?.toLowerCase()) ? getDependentValue("FATHER/SPOUSE_PREFIX", dependents, 0)?.charAt(0)?.toUpperCase() + getDependentValue("FATHER/SPOUSE_PREFIX", dependents, 0)?.slice(1)?.toLowerCase() : null ||
      options?.includes(getDependentValue("FATHER_PREFIX", dependents, 0)?.charAt(0)?.toUpperCase() + getDependentValue("FATHER_PREFIX", dependents, 0)?.slice(1)?.toLowerCase()) ? getDependentValue("FATHER_PREFIX", dependents, 0)?.charAt(0)?.toUpperCase() + getDependentValue("FATHER_PREFIX", dependents, 0)?.slice(1)?.toLowerCase() : null,
    father: (getDependentValue("FATHER/SPOUSE_FNAME", dependents, 0) ? getDependentValue("FATHER/SPOUSE_FNAME", dependents, 0) : "") ?? (getDependentValue("FATHER_FNAME", dependents, 0) ? getDependentValue("FATHER_FNAME", dependents, 0) : ""),
    fatherMiddleName: (getDependentValue("FATHER/SPOUSE_MNAME", dependents, 0) ? getDependentValue("FATHER/SPOUSE_MNAME", dependents, 0) : "") ?? (getDependentValue("FATHER_MNAME", dependents, 0) ? getDependentValue("FATHER_MNAME", dependents, 0) : ""),
    fatherLastName: (getDependentValue("FATHER/SPOUSE_LNAME", dependents, 0) ? getDependentValue("FATHER/SPOUSE_LNAME", dependents, 0) : "") ?? (getDependentValue("FATHER_LNAME", dependents, 0) ? getDependentValue("FATHER_LNAME", dependents, 0) : ""),
    motherInitial: options?.includes(getDependentValue("MOTHER_PREFIX", dependents, 1)?.charAt(0)?.toUpperCase() + getDependentValue("MOTHER_PREFIX", dependents, 1)?.slice(1)?.toLowerCase()) ? getDependentValue("MOTHER_PREFIX", dependents, 1)?.charAt(0)?.toUpperCase() + getDependentValue("MOTHER_PREFIX", dependents, 1)?.slice(1)?.toLowerCase() : null,
    // motherInitial: getDependentValue("MOTHER_PREFIX", dependents, 1),
    motherFirstName: getDependentValue("MOTHER_FNAME", dependents, 1),
    motherMiddleName: getDependentValue("MOTHER_MNAME", dependents, 1),
    motherLastName: getDependentValue("MOTHER_LNAME", dependents, 1),
    officialTel: dependents && dependents["OFF_STD_CODE"],
    officialPhoneNumber: dependents && dependents["OFF_TEL_NUM"],
    residentialTel: dependents && dependents["RESI_STD_CODE"],
    residentailPhoneNumber: dependents && dependents["RESI_TEL_NUM"],
    personalMobile: values["MOB_NUM"],
    personalEmail: values["EMAIL"],
    personaPan: values["pan"],
    pesonalForm16: dependents && dependents["FORM_SIXTY"],
    pesonalAadhar: values["aadharNum"] ?? "",
    pesonalDob:
      dependents && dependents["DOB"] && dependents["DOB"] !== ""
        ? dependents["DOB"]
        : null,
    personalGender: dependents && dependents["GENDER"],
    personalcitizenship: dependents && dependents["citezenship"],
    residentialStatus: dependents && dependents["resdentStatus"],
    occupatationType: dependents && dependents["occutpationType"],
    currentAddressProof: currentP["PERM_CORRES_SMEFLAG"] === "Y" ? currentP["PERM_POA"] : currentA["CORRES_POA"] || "",
    currentAddressProofIdNumber: currentP["PERM_CORRES_SMEFLAG"] === "Y" ? currentP["PERM_POA_VALUE"] : currentA["CORRES_POA_VALUE"] || "",
    currentLine1: currentP["PERM_CORRES_SMEFLAG"] === "Y" ? currentP["PERM_LINE1"] : currentA["CORRES_LINE1"],
    currentLine2: currentP["PERM_CORRES_SMEFLAG"] === "Y" ? currentP["PERM_LINE2"] : currentA["CORRES_LINE2"],
    currentLine3: currentP["PERM_CORRES_SMEFLAG"] === "Y" ? currentP["PERM_LINE3"] : currentA["CORRES_LINE3"],
    currentState: currentP["PERM_CORRES_SMEFLAG"] === "Y" ? currentP["PERM_STATE"] : currentA["CORRES_STATE"] || "",
    currentDistrict: currentP["PERM_CORRES_SMEFLAG"] === "Y" ? currentP["PERM_DIST"] : currentA["CORRES_DIST"],
    currentCity: currentP["PERM_CORRES_SMEFLAG"] === "Y" ? currentP["PERM_CITY"] : currentA["CORRES_CITY"],
    currentPincode: currentP["PERM_CORRES_SMEFLAG"] === "Y" ? currentP["PERM_PIN"] : currentA["CORRES_PIN"],
    currentCountry: currentP["PERM_CORRES_SMEFLAG"] === "Y" ? currentP["PERM_COUNTRY"] : currentA["CORRES_COUNTRY"] || "",
    addressProof: currentP["PERM_POA"] || "",
    addressProofIdNumber: currentP["PERM_POA_VALUE"] || "",
    sameAsPermanant: currentP["PERM_CORRES_SMEFLAG"] || 0,
    line1: currentP["PERM_LINE1"],
    line2: currentP["PERM_LINE2"],
    line3: currentP["PERM_LINE3"],
    state: currentP["PERM_STATE"] || "",
    district: currentP["PERM_DIST"],
    city: currentP["PERM_CITY"],
    pincode: currentP["PERM_PIN"],
    country: currentP["PERM_COUNTRY"] || "",
    documentRecieved: values["DOC_SUB"],
    kycVerifiDate: values["KYC_DATE"] ?? declaration["KYC_DATE"] ?? "",
    employeeName: values["KYC_NAME"],
    employeeCode: values["KYC_EMPCODE"] ?? declaration["KYC_EMPCODE"] ?? "",
    employeeDesignation: values["KYC_DESIGNATION"] ?? "",
    employeeBranch: values["KYC_BRANCH"],
    instCode: values["ORG_CODE"],
    instName: values["ORG_NAME"],
    remarks: values["REMARKS"],
    dateOfDeclaration:
      values["DEC_DATE"] &&
        values["DEC_DATE"] != "" &&
        !values["DEC_DATE"]?.includes("*")
        ? values["DEC_DATE"]
        : moment().format("DD-MM-YYYY"),
    placeOfDeclaration: values["DEC_PLACE"],
    customerNo: relatedPersonDetails["SEQUENCE_NO"],
    relationshipTitle: relatedPersonDetails["REL_TYPE"],
    addDelFlag: relatedPersonDetails["ADD_DEL_FLAG"],
    ckycNo: relatedPersonDetails["CKYC_NO"],
    minorPrefix: relatedPersonDetails["PREFIX"],
    relatedPersonFirstName: relatedPersonDetails["FNAME"],
    relatedPersonMiddleName: relatedPersonDetails["MNAME"],
    relatedPersonLastName: relatedPersonDetails["LNAME"],
    forMother: relatedPersonDetails["FATHERSPOUSE_FLAG"],
    fFirstName: relatedPersonDetails["FATHER_FNAME"],
    fLastName: relatedPersonDetails["FATHER_LNAME"],
    fMiddleName: relatedPersonDetails["FATHER_MNAME"],
    mfirstName: relatedPersonDetails["MOTHER_FNAME"],
    mLastName: relatedPersonDetails["MOTHER_LNAME"],
    mMiddleName: relatedPersonDetails["MOTHER_MNAME"],
    minorGender: relatedPersonDetails["GENDER"],
    minorDob: relatedPersonDetails["DOB"],
    minorPan: relatedPersonDetails["PAN"],
    minorForm16Finished: relatedPersonDetails["FORM_SIXTY"],
    nationality: relatedPersonDetails["NATIONALITY"],
    relatedFatherSpouseFlag: relatedPersonDetails["SPOUSE_VALUE"],
    relatedUID: relatedPersonAddressIdenity["OFFLINE_VERIFICATION_AADHAAR"],
    relatedVoterCard: relatedPersonAddressIdenity["VOTERID"],
    NregaJobCard: relatedPersonAddressIdenity["NREGA"],
    nationalPopRegister:
      relatedPersonAddressIdenity["NATIONAL_POPULATION_REG_LETTER"],
    passportNo: relatedPersonAddressIdenity["PASSPORT"],
    // passportExpCode: relatedPersonAddressIdenity["VOTERID"] || '' ,
    drivingLicenseNo: relatedPersonAddressIdenity["DRIVING_LICENCE"],
    // drivingExpCode: relatedPersonAddressIdenity["VOTERID"] || '' ,
    proofOfAddress: relatedPersonAddressIdenity["PERM_POI_TYPE"],
    relatedAddressline1: relatedPersonAddressIdenity["CORRES_ADD_LINE1"],
    relatedAddressline2: relatedPersonAddressIdenity["CORRES_ADD_LINE2"],
    relatedAddressline3: relatedPersonAddressIdenity["CORRES_ADD_LINE3"],
    relatedCountry: relatedPersonAddressIdenity["CORRES_ADD_COUNTRY"],
    relatedState: relatedPersonAddressIdenity["CORRES_ADD_STATE"],
    relatedDistrict: relatedPersonAddressIdenity["CORRES_ADD_DIST"],
    relatedPincode: relatedPersonAddressIdenity["CORRES_ADD_PIN"],
    relatedVillage: relatedPersonAddressIdenity["CORRES_ADD_CITY"],
    relatedPermSameAsPermanant: relatedPersonAddressIdenity["SAME_AS_PERM_FLAG"],
    // proofOfAddress: relatedPersonAddressIdenity["PERM_POI_TYPE"] || '' ,
    relatedPermAddressline1: relatedPersonAddressIdenity["ADD_LINE1"],
    relatedPermAddressline2: relatedPersonAddressIdenity["ADD_LINE2"],
    relatedPermAddressline3: relatedPersonAddressIdenity["ADD_LINE3"],
    relatedPermCountry: relatedPersonAddressIdenity["ADD_COUNTRY"],
    relatedPermState: relatedPersonAddressIdenity["ADD_STATE"],
    relatedPermDistrict: relatedPersonAddressIdenity["ADD_DIST"],
    relatdPermvillage: relatedPersonAddressIdenity["ADD_CITY"],
    relatedPermPincode: relatedPersonAddressIdenity["ADD_PIN"],
    relatedAddressProof: relatedPersonAddressIdenity["PERM_POA"],
    relatedAddressProofIdNumber: relatedPersonAddressIdenity["PERM_POA_VALUE"],
    AddressProof: relatedPersonAddressIdenity["CORRES_POA"],
    addressNumber: relatedPersonAddressIdenity["CORRES_POA_VALUE"],
    otherId: relatedPersonAddressIdenity["OTHER_ID"],
    otherProofNumber: relatedPersonAddressIdenity["OTH_PROOF_NO"],
    smaDocCode: relatedPersonAddressIdenity["SMA_DOC_CODE"],
    smaIdentNumber: relatedPersonAddressIdenity["SMA_IDENT_NO"],
    relatedProofOfAddresIfOthers: relatedPersonAddressIdenity["ADD_OF_OTHER_ID"],
    typeOfDocSubmitted: declaration["DOC_SUB"],
    kycVerificationName: declaration["KYC_NAME"] ?? values["KYC_NAME"] ?? "",
    kycVerifiEmpCode: declaration["KYC_EMPCODE"] ?? values["KYC_EMPCODE"] ?? "",
    kycVerifiBranch: declaration["KYC_BRANCH"] ?? values["KYC_BRANCH"] ?? "",
    orgCode: declaration["ORG_CODE"],
    orgName: declaration["ORG_NAME"],
    relatedDateOfDeclarion:
      declaration["DEC_DATE"] && declaration["DEC_DATE"] !== ""
        ? declaration["DEC_DATE"]
        : null,
    relatedPlaceOfDeclarion: declaration["DEC_PLACE"],
    kycVerificationDesignation: declaration["KYC_DESIGNATION"],
    minorDetails: MinorDetails
  };
};

const getAadharNumber = (identityDetailsObject) => {
  let identityDetails = identityDetailsObject;
  identityDetails =
    identityDetails &&
      identityDetails[0] &&
      identityDetails[0]?.IDENTITY &&
      identityDetails[0].IDENTITY.length > 0
      ? identityDetails[0].IDENTITY
      : [];

  const ckycAadhar = identityDetails
    ? identityDetails.find((item) => item.IDENT_TYPE[0] === "E")?.IDENT_NUM[0]
    : "";
  return ckycAadhar;
};

const getCode = (code) => {
  const trimmedCode = code && code?.trim();
  return trimmedCode && trimmedCode.length > 0 ? `${code}-` : "";
};

export const customerDetailsObject = (data, flag, isCkycObject) => {
  console.log("dattaa===", flag, data)
  let personalDetails = null;
  let identityDetails = null;

  // let identityDetails;
  // let aadhar;
  if (!flag) {
    personalDetails = data?.PERSONAL_DETAILS?.[0] || null;
    identityDetails = data?.IDENTITY_DETAILS || null;

    // identityDetails = data?.IDENTITY_DETAILS[0]
    // if(identityDetails){
    //   aadhar =
    // }
  }
  return !data
    ? []
    : [
      [
        {
          label: "Constitution Type",
          value: personalDetails?.CONSTI_TYPE?.[0],
          key: "constitutionType",
        },
        {
          label: "Account Type",
          value: personalDetails?.ACC_TYPE?.[0],
          key: "accountType",
        },
        // {
        // 	label: "Customer Bank",
        // 	value: "",
        // 	key: "",
        // },
        // {
        // 	label: "Customer Branch",
        // 	value: "",
        // 	key: "customerBranch",
        // },
        // {
        // 	label: "Customer Number",
        // 	value: "",
        // 	key: "customerNumber",
        // },
        {
          label: "CKYC Number",
          value: personalDetails?.CKYC_NO?.[0],
          key: "kycNumber",
        },
        // {
        // 	label: "Account Number",
        // 	value: "",
        // 	key: null,
        // },
        // {
        // 	label: "IFSC Code",
        // 	value: "",
        // 	key: null,
        // },
      ],
      [
        {
          label: "First Name",
          value: personalDetails?.FNAME?.[0],
          key: "personalFirstName",
        },
        {
          label: "Middle Name",
          value: personalDetails?.MNAME?.[0],
          key: "personalMiddleName",
        },
        {
          label: "Last Name",
          value: personalDetails?.LNAME?.[0],
          key: "personalLastName",
        },
        {
          label: "Father Name",
          value: personalDetails?.FATHER_FNAME?.[0],
          key: "father",
        },
        {
          label: "Middle Name",
          value: personalDetails?.FATHER_MNAME?.[0],
          key: "fatherMiddleName",
        },
        {
          label: "Last Name",
          value: personalDetails?.FATHER_LNAME?.[0],
          key: "fatherLastName",
        },
        {
          label: "Mother Name",
          value: personalDetails?.MOTHER_FNAME?.[0],
          key: "motherFirstName",
        },
        {
          label: "Middle Name",
          value: personalDetails?.MOTHER_MNAME?.[0],
          key: "motherMiddleName",
        },
        {
          label: "Last Name",
          value: personalDetails?.MOTHER_LNAME?.[0],
          key: "motherLastName",
        },
        {
          label: "Tel.(off) Number",
          value: isCkycObject
            ? personalDetails?.OFF_TEL_NUM && personalDetails?.OFF_TEL_NUM?.[0]
            : `${getCode(
              personalDetails?.OFF_STD_CODE &&
              personalDetails?.OFF_STD_CODE?.[0]
            )}${personalDetails?.OFF_TEL_NUM?.[0]}`,
          key: "officialTel",
          renderValue: (value) => value,
        },
        {
          label: "Tel.(Res) Number",
          value: isCkycObject
            ? personalDetails?.RESI_TEL_NUM &&
            personalDetails?.RESI_TEL_NUM?.[0]
            : `${getCode(
              personalDetails?.RES_STD_CODE &&
              personalDetails?.RES_STD_CODE?.[0]
            )}${personalDetails?.RESI_TEL_NUM?.[0]}`,
          key: "residentialTel",
          renderValue: (value) => value,
        },
        {
          label: "Mobile",
          value: personalDetails?.MOB_NUM?.[0],
          key: "personalMobile",
        },
        ...(isCkycObject
          ? [
            {
              label: "Residential Number",
              value: personalDetails?.RESI_TEL_NUM?.[0],
              key: "residentailPhoneNumber",
            },
            {
              label: "Official Number",
              value: personalDetails?.OFF_TEL_NUM?.[0],
              key: "officialPhoneNumber",
            },
          ]
          : []),
        {
          label: "DOB",
          value: personalDetails?.DOB?.[0] ?? "",
          key: "pesonalDob",
        },
        {
          label: "GENDER",
          // value: isCkycObject
          // 	? personalDetails.GENDER?.[0]
          // 	: personalDetails?.GENDER?.[0]
          // 	? personalDetails?.GENDER?.[0] === "F"
          // 		? "Female"
          // 		: "Male"
          // 	: "",
          value: personalDetails?.GENDER?.[0],

          key: "personalGender",
        },
        {
          label: "Email",
          value: personalDetails?.EMAIL?.[0],
          key: "personalEmail",
        },
        {
          label: "Aadhaar No",
          value: getAadharNumber(identityDetails),
          key: "pesonalAadhar",
        },
        {
          label: "Pan",
          value: personalDetails?.PAN?.[0],
          key: "personaPan",
        },
        {
          label: "FORM 60 Furnished",
          value: "",
          key: "pesonalForm16",
        },
        // {
        // 	label: "Citizenship",
        // 	value: "",
        // 	key: "personalcitizenship",
        // },
        // {
        // 	label: "Residential Status",
        // 	value: "",
        // 	key: "residentialStatus",
        // },
        // {
        // 	label: "Occupation Type",
        // 	value: "",
        // 	key: "occupatationType",
        // },
      ],
      [
        {
          label: "Address Proof",
          value: personalDetails?.PERM_POA?.[0],
          key: "addressProof",
        },
        {
          label: "Address Proof ID Number",
          value: personalDetails?.PERM_POA?.[0],
          key: "addressProofIdNumber",
        },
        {
          label: "Address Line 1",
          value: `${personalDetails?.PERM_LINE1?.[0]}`,
          key: "line1",
          renderValue: (value) => value,
        },
        {
          label: "Address Line 2",
          value: `${personalDetails?.PERM_LINE2?.[0]}`,
          key: "line2",
          renderValue: (value) => value,
        },
        {
          label: "Address Line 3",
          value: `${personalDetails?.PERM_LINE3?.[0]}`,
          key: "line3",
          renderValue: (value) => value,
        },
        {
          label: "State",
          value: personalDetails?.PERM_STATE?.[0],
          key: "state",
        },
        {
          label: "District",
          value: personalDetails?.PERM_DIST?.[0],
          key: "district",
        },
        {
          label: "City",
          value: personalDetails?.PERM_CITY?.[0],
          key: "city",
        },
        {
          label: "Country",
          value: personalDetails?.PERM_COUNTRY?.[0],
          key: "country",
        },
        {
          label: "Pin Code",
          value: personalDetails?.PERM_PIN?.[0],
          key: "pincode",
        },
      ],
      [
        {
          label: "Address Proof",
          value: personalDetails?.CORRES_POA?.[0],
          key: "currentAddressProof",
        },
        {
          label: "Address Proof ID Number",
          value: personalDetails?.CORRES_POA?.[0],
          key: "addressProofIdNumber",
        },
        {
          label: "Address Line 1",
          value: `${personalDetails?.CORRES_LINE1?.[0]}`,
          key: "currentLine1",
          renderValue: (value) => value,
        },
        {
          label: "Address Line 2",
          value: `${personalDetails?.CORRES_LINE2?.[0]}`,
          key: "currentLine2",
          renderValue: (value) => value,
        },
        {
          label: "Address Line 3",
          value: `${personalDetails?.CORRES_LINE3?.[0]}`,
          key: "currentLine3",
          renderValue: (value) => value,
        },
        {
          label: "State",
          value: personalDetails?.CORRES_STATE?.[0],
          key: "currentState",
        },
        {
          label: "District",
          value: personalDetails?.CORRES_DIST?.[0],
          key: "currentDistrict",
        },
        {
          label: "City",
          value: personalDetails?.CORRES_CITY?.[0],
          key: "currentCity",
        },
        {
          label: "Country",
          value: personalDetails?.CORRES_COUNTRY?.[0],
          key: "currentCountry",
        },
        {
          label: "Pin Code",
          value: personalDetails?.CORRES_PIN?.[0],
          key: "currentPincode",
        },
      ],
      [
        {
          label: "Document Received",
          value: personalDetails?.DOC_SUB?.[0],
          key: "documentRecieved",
        },
        {
          label: "Verification Date",
          value: personalDetails?.KYC_DATE?.[0] || "",
          key: "kycVerifiDate",
        },
        {
          label: "Employee Name",
          value: personalDetails?.KYC_NAME?.[0],
          key: "employeeName",
        },
        {
          label: "Employee Code",
          value: personalDetails?.KYC_EMPCODE?.[0],
          key: "employeeCode",
        },
        {
          label: "Employee Designation",
          value: personalDetails?.KYC_DESIGNATION?.[0],
          key: "employeeDesignation",
        },
        {
          label: "Employee Branch",
          value: personalDetails?.KYC_BRANCH?.[0],
          key: "employeeBranch",
        },
        {
          label: "Institution Code",
          value: personalDetails?.ORG_CODE?.[0],
          key: "instCode",
        },
        {
          label: "Institution Name",
          value: personalDetails?.ORG_NAME?.[0],
          key: "instName",
        },
        {
          label: "Remarks",
          value: personalDetails?.REMARKS?.[0],
          key: "remarks",
        },
      ],
      [
        {
          label: "Date of Declaration",
          value: personalDetails?.DEC_DATE?.[0] || "",
          key: "dateOfDeclaration",
        },
        {
          label: "Place of Declaration",
          value: personalDetails?.DEC_PLACE?.[0],
          key: "placeOfDeclaration",
        },
      ],
    ];
};


export const steps = [
  {
    label: "For office use only",
    formElements: officeUseOnly,
  },
  {
    label: "Personal Details",
    formElements: personalDetails,
  },
  {
    label: "Proof of Address",
    formElements: proofOfAddress,
    displayName: "Permanent Address",
    subDivs: {
      label: "Current Address",
      formElements: currentProofOfAddress,
    },
  },
  // {
  // 	label: "FATCA/CRS Details",
  // 	formElements: fatca,
  // },
  {
    label: "KYC Verification",
    formElements: kycVerification,
    subDivs: {
      label: "Applicant Declaration",
      formElements: applicantDeclaration,
    },
  },
  {
    label: "Upload",
    component: "",
  },
  {
    label: "Preview",
    component: "",
  },
];
