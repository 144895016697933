// import { Box, Switch } from "@mui/material";
// import { DeleteOutlineIcon, EditIcon } from "@mui/base";
// import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
// import EditIcon from "@mui/icons-material/Edit";
import Text from "../../components/Atoms/Text";

const statusColor = {
  "Pending Mapping": "#8D8D8D",
  "In Progress": "#5B58FF",
  "On Hold": "#EE8E53",
  "CKYC Rejected": "#EA0000",
  "CKYC Found": "green",
  Audited: "green",
};

export const renderCKYCStatus = (value) => {
  return value ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        color: statusColor[value] || "#FF4D4D",
        fontSize: "14px",
      }}>
      <div
        style={{
          marginRight: "6px",
          height: "6px",
          width: "6px",
          borderRadius: "6px",
          backgroundColor: statusColor[value] || "#FF4D4D",
        }}
      />
      {value}
    </div>
  ) : (
    ""
  );
};

export const getColumns = ({ onDocketClick, onToggleActive }) => {
  return [
    {
      field: "docketNo",
      headerName: "Docket No",
      // flex: 1,
      flex: 0.8,
      renderCell: (params) => (
        <Text
          cursor="pointer"
          color="#0400E1"
          fontSize="14px"
          semiBold
          fontWeight="400"
          onClick={() => {
            console.warn(params);
            onDocketClick(params.row.customerId);
          }}>
          {params.value}
        </Text>
      ),
    },
    // {
    // 	field: "customerBranch",
    // 	headerName: "Branch Code",
    // 	flex: 0.9,
    // 	// renderHeader: (params) => HeaderWrapper(params),
    // },

    {
      field: "customerNumber",
      headerName: "Customer No",
      flex: 0.9,
      
      // renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.8,
      renderCell:(params) =>
      <span title={params.value}>{params.value?.length > 10 ? `${params.value?.substr(0,10)}...`:params.value}</span>
      // renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "dob",
      headerName: "Dob",
      flex: 0.8,
      // renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "pan",
      headerName: "Pan No",
      flex: 0.8,
      // renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "referenceNum",
      headerName: "Reference",
      flex: 0.8,
      // renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "CKYC_NO",
      headerName: "CKYC No",
      flex: 0.8,
      // renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "ckycRemarks",
      headerName: "CKYC Remarks",
      flex: 0.8,
      renderCell:(params) =>
      <span title={params.value}>{params.value?.length > 10 ? `${params.value?.substr(0,10)}...`:params.value}</span>
      // renderCell: (params) => renderCKYCStatus(params.row.status),
    },
    // {
    // 	field: "create_at",
    // 	headerName: "Last Updated",
    // 	flex: 0.8,
    // 	// renderCell: (params) => renderCKYCStatus(params.row.status),
    // },
    {
      field: "status",
      headerName: "Status",
      flex: 0.8,
      renderCell: (params) => 
     <span title={params.row.status}> {renderCKYCStatus(params.row.status)}</span>
    },
    // {
    // 	field: "active",
    // 	headerName: "Active",
    // 	flex: 0.6,
    // 	disableExport: true,
    // 	renderCell: (params) => {
    // 		return (
    // 			<Switch
    // 				checked={params.value}
    // 				onChange={(e) => onToggleActive(e, params.row.id)}
    // 				defaultChecked
    // 			/>
    // 		);
    // 	},
    // },
    // {
    // 	field: "actions",
    // 	headerName: "Actions",
    // 	sortable: false,
    // 	disableExport: true,
    // 	renderCell: (params) => {
    // 		return (
    // 			<Box display="flex" justifyContent={"space-between"}>
    // 				<EditIcon
    // 					onClick={() => onEditClick(params.row.id)}
    // 					sx={{ marginRight: "4px", cursor: "pointer", marginRight: "8px" }}
    // 				/>
    // 				<DeleteOutlineIcon
    // 					onClick={() => onDeleteClick(params.row.id)}
    // 					sx={{ color: "red", cursor: "pointer", marginLeft: "8px" }}
    // 				/>
    // 			</Box>
    // 		);
    // 	},
    // },
  ];
};
