import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { TextField, OutlinedInput, Button } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import InputAdornment from "@mui/material/InputAdornment";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useForm } from "react-hook-form";

import Text from "../Atoms/Text";
import { allElements } from "../Organisms/new-customer/formElements";
import FormElement from "./FormElement";
import { formatDate } from "../Organisms/new-customer/formUtils";

export default function MenuInput(props) {
  const {
    label,
    ckycValue,
    bankRecordsValue,
    mappedValue,
    labelKey,
    onUpdate,
    recentlyUpdate,
    element,
    dropdownOptions,
    isChecker,
    rawValue,
    rawCKYCValue,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const formattedValue = bankRecordsValue;
  const formProps = useForm({
    defaultValues: {
      [labelKey]: rawValue,
    },
  });
  const { getValues } = formProps;
  const open = Boolean(anchorEl);
  const currentRecentlyUpdatedKey =
    recentlyUpdate && recentlyUpdate.includes(labelKey);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={label}>
          <Box>
            <TextField
              id="email"
              defaultValue=""
              size="small"
              // placeholder="password"
              // color={currentRecentlyUpdatedKey ? "success" : "warning"}
              disabled
              sx={{
                height: "32px",
                color: "red !important",
                "& .MuiOutlinedInput-root": {
                  paddingRight: "8px",
                },
                "& .MuiOutlinedInput-input:focus": {
                  border: "0",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: `1px solid ${
                    currentRecentlyUpdatedKey ? "#1DCE24" : "#EE8E53"
                  } !important`,
                },
                "& .Mui-disabled": {
                  "-webkit-text-fill-color": "black !important",
                  color: "black !important",
                },
              }}
              value={mappedValue || bankRecordsValue}
              // style={{ border: "2px solid #EE8E53" }}
              autoFocus
              InputProps={{
                ...(!isChecker && {
                  endAdornment: (
                    <InputAdornment position="end">
                      {currentRecentlyUpdatedKey ? (
                        <CheckCircleOutlineIcon
                          sx={{
                            color: "#1DCE24",
                            fontSize: "16px",
                            cursor: "pointer",
                          }}
                        />
                      ) : (
                        <WarningAmberIcon
                          sx={{
                            color: "#EE8E53",
                            fontSize: "16px",
                            cursor: "pointer",
                          }}
                          onClick={handleClick}
                          // disabled={true}
                        />
                      )}
                    </InputAdornment>
                  ),
                }),
              }}
            />
          </Box>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            // "&:before": {
            // 	content: '""',
            // 	display: "block",
            // 	position: "absolute",
            // 	top: 0,
            // 	right: 14,
            // 	width: 10,
            // 	height: 10,
            // 	bgcolor: "background.paper",
            // 	transform: "translateY(-50%) rotate(45deg)",
            // 	zIndex: 0,
            // },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        <Box>
          <Box p={"16px"}>
            <Box display="flex" alignItems="center">
              <Text mr="8px" color="#6C6C6C" fontSize="14px" mb={"6px"}>
                {label}
              </Text>
              <Box
                pY="2px"
                px="6px"
                mb="8px"
                style={{ backgroundColor: "#E4EFE0" }}>
                <Text color="#008F28" fontSize="10px">
                  CERSAI
                </Text>
              </Box>
            </Box>
            <Box flexDirection="row" display="flex" alignItems="center">
              <TextField
                id="outlined-textarea"
                placeholder=""
                size="small"
                value={ckycValue}
                sx={{
                  "& .Mui-disabled": {
                    "-webkit-text-fill-color": "black !important",
                    color: "black !important",
                    background: "#EFEFFF",
                  },
                  "& .MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
                    "-webkit-text-fill-color": "black !important",
                  },
                }}
                disabled
              />
              <Text
                onClick={() => onUpdate(labelKey, rawCKYCValue, handleClose)}
                // ml={"-0.5rem"}
                ml={"12px"}
                fontWeight="500"
                color="#5B58FF"
                cursor={
                  // getValues("newValue") !== bankRecordsValue
                  "pointer"
                  // : "not-allowed"
                }>
                Sync this
              </Text>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box>
          <Box p={"16px"}>
            <Box display="flex" width="100%">
              <form>
                <Box flexDirection="row" display="flex" alignItems="center">
                  <FormElement
                    formProps={formProps}
                    element={{
                      ...element,
                      width: "100%",
                    }}
                    dropdownOptions={dropdownOptions}
                  />
                  <Text
                    onClick={() =>
                      onUpdate(labelKey, getValues(labelKey), handleClose)
                    }
                    mt={"2rem"}
                    // ml={"-0.5rem"}
                    fontWeight="500"
                    color="#5B58FF"
                    cursor={
                      // getValues("newValue") !== bankRecordsValue
                      "pointer"
                      // : "not-allowed"
                    }>
                    Update
                  </Text>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      </Menu>
    </React.Fragment>
  );
}
