import axiosInstance from "./axios";
import Immutable from "immutable";

export const uploadResponseFile = async (data) => {
	const headers = {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	};
	const response = await axiosInstance.post(
		`/customers/ckycResponse`,
		data,
		headers
	);
	console.log(response.data);
	return response.data;
};

export const downloadZip = async (data) => {
	const headers = {
		responseType: "stream",
	};
	const response = await axiosInstance.post(`/customers/downloadZip`, data, {
		responseType: data?.isSftp ? "application/json" : "arraybuffer",
	});
	// This the last resort logic to know that api is returning error or fileobject
	// Current backend response type is stream/buffer so it should send the same at all times
	// but it is sending the normal json when there is error
	// So as we sending the responsetype is arraybuffer , we can't read at directly
	if (data?.isSftp) {
		return response.data;
	} else {
		let jsonRespnse;
		try {
			jsonRespnse = new Response(response?.data);
			jsonRespnse = await jsonRespnse?.json();
		} catch (e) {}
		return jsonRespnse && jsonRespnse?.isError ? jsonRespnse : response;
	}
};

// api / static / statuses;

export const getAllStatuses = async () => {
	const response = await axiosInstance.get(`/static/statuses `);
	return response.data;
};

export const fetchImages = async ({ queryKey }) => {
	const [_key, { customerId }] = queryKey;

	const response = await axiosInstance.get(
		`/customers/files?customerId=${customerId} `
	);
	return response.data;
};
