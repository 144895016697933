import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Chip from "@mui/material/Chip";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import Text from "../Atoms/Text";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
};

export default function MismatchModal({
  closePanel,
  open,
  onContinue,
  misMatchedValues,
  recentlyUpdate,
}) {
  console.log(recentlyUpdate, misMatchedValues);
  const remaningMismatched =
    misMatchedValues.map((item) => {
      if (item.values) {
        return item.values
          .map((x) => (!recentlyUpdate.includes(x.key) ? x.label : undefined))
          .filter((i) => i);
      }
      return [];
    }) || [];

  const updateLater = () => {
    closePanel();
    onContinue();
  };

  if (remaningMismatched.length == 0) {
    updateLater();
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={closePanel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box display="flex" flexDirection="column" sx={style}>
          <Box display="flex" flexDirection={"column"}>
            <Text>Please find the mismatched field below: </Text>
            {remaningMismatched.flat()?.length ? (
              <Box mt="8px" display="flex" flexWrap={"wrap"}>
                {remaningMismatched.flat().map((item) => {
                  return (
                    <Box mr="12px" mt={"4px"}>
                      <Chip label={item} variant="outlined" />
                    </Box>
                  );
                })}
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                justifyContent={"space-between"}
              >
                {misMatchedValues.map((item, index) => {
                  return (
                    <Box display="flex" flexDirection={"row"} flexWrap="wrap">
                      <Box>
                        <Text
                          mb="12px"
                          mt="8px"
                          fontSize="16px"
                          fontWeight="500"
                          color="#EE8E53"
                        >
                          {item.label}
                        </Text>
                        {item.values.map((element) => {
                          return (
                            <Box display="flex" flexDirection={"row"}>
                              <WarningAmberIcon
                                sx={{
                                  color: "#EE8E53",
                                  fontSize: "16px",
                                }}
                              />
                              <Text ml="8px" mb={"8px"} fontSize="14px">
                                {`${element.label} is not matched`}
                              </Text>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
          <Box mt={"32px"} display="flex" justifyContent="flex-end">
            <Button onClick={closePanel} variant="outlined" mr={"8px"}>
              Close and Update Now
            </Button>
            <Button
              sx={{ marginLeft: "12px" }}
              color="primary"
              onClick={updateLater}
              variant="contained"
            >
              Continue and Update Later
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
