import React from "react";

import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

import { useEffect, useState } from "react";
import {
  createOrEditDistrict
} from "../apis/districtMaster";
import { getAllStatuses, getCustomers } from "../apis/newCustomer";
import DataTable from "../components/Molecules/DataTable";
import PageHeader from "../components/Molecules/PageHeader";
import DeletePopUp from "../components/Molecules/PopupModal";
import CustomerDetails from "../components/Organisms/CustomerDetails";
import CustomerJoruneyPanel from "../components/Organisms/CustomerJourneyPanel";
import CustomerFilter from "../components/Organisms/new-customer/CustomerFilter";
import { formatDate } from "../components/Organisms/new-customer/formUtils";
import { useGlobalContext } from "../context/GlobalContext";
import useDropdownOptions from "../hooks/useDropdownOptions";
import { getColumns } from "../utils/tableHeaders/newCustomers";

const NewCustomers = () => {
  const [addUserPanel, setAddUserPanel] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const [openFilter, toggleFilter] = React.useState(false);
  const [openDeletePopup, toggleDeletePopup] = React.useState(false);
  const {
    state: { currentUser },
    dispatch,
  } = useGlobalContext();
  const [selectedDistrict, setSelectedDoc] = React.useState(null);
  //const [filter, updateFilter] = React.useState({});
  const [filter, updateFilter] = React.useState({
    condition: "",
    page: 0,
    perPageSize: 5
  });
  const [totalCount, setTotalCount] = React.useState(0);
  const [search, updateSearch] = React.useState(null);
  const [docketOpen, setDocketOpen] = React.useState(false);
  const [customerId, setCustomerId] = React.useState(null);
  const { dropdownOptions } = useDropdownOptions();

  const [filters, setFiltes] = React.useState(null);
  const [openCustomerJourneyPanel, toggleCustomerJourneyPanel] =
    React.useState(false);
  // Pass this to customer details to avoid duplicate code
  let currentRole =
    JSON?.parse(window.localStorage.getItem("roles")) ||
    currentUser.role.role_name;
  currentRole = currentRole.toLowerCase();

  const pageTitle = "Update Customers";

  const {
    data,
    refetch,
    isSuccess: customersFetched,
  } = useQuery(
    ["customers", { filter, search, filters, module: "UC" }],
    getCustomers,
    {
      manual: true,
      select: (response) => response.get("data", []),
      retry: false,
    }
  );

  const { data: allStatuses, refetch: fetchAllStatuses } = useQuery(
    ["statuses"],
    getAllStatuses,
    {
      manual: true,
      select: (resp) => resp.data,
      retry: false,
      enabled: false,
    }
  );
  const { mutate: addOrUpdateDoc, isLoading } = useMutation(
    (userData) => createOrEditDistrict(userData),
    {
      onSuccess: (response) => {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.message,
            severity: response.isError ? "error" : "success",
          },
        });
        // updateFilter({});
        if (!response.isError) {
          setAddUserPanel(false);
          toggleDeletePopup(false);
          refetch();
        }
      },
      onError: (error) => {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: error.response.data.message,
            severity: "error",
          },
        });
      },
    }
  );

  const onSubmit = (data) => {
    let reqData = data;
    if (reqData["toDate"])
      reqData["toDate"] = !dayjs(
        reqData["toDate"],
        "MM-DD-YYYY",
        true
      ).isValid()
        ? reqData["toDate"]
        : formatDate(reqData["toDate"]);
    if (reqData["fromDate"])
      reqData["fromDate"] = !dayjs(
        reqData["fromDate"],
        "MM-DD-YYYY",
        true
      ).isValid()
        ? reqData["fromDate"]
        : formatDate(reqData["fromDate"]);
    setFiltes(reqData);
    refetch();
    if (customersFetched) {
      toggleFilter(false);
    }
  };

  const onDeleteUser = () => {
    const requestData = {
      id: selectedDistrict.get("id"),
    };
    addOrUpdateDoc(requestData);
    setSelectedDoc(null);
  };

  const toggleDrawer = () => {
    // setNewCustomerModal(!openNewCustomerModal);
  };

  const onDocketClick = (id) => {
    // fetch the ckyc user info
    setCustomerId(id);
    setDocketOpen(true);
  };

  const onToggleActive = (e, id) => {
    let checked = e.target.checked;
    let requestData = {
      id: id,
      status: checked ? 1 : 0,
    };
    requestData = { district: requestData };
    addOrUpdateDoc(requestData);
  };

  const onFilter = (status) => {
    updateFilter({ ...filter, ...status });
    refetch();
  };

  let searchTimeout;

  const searchUsers = (query) => {
    clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      updateSearch(query);
    }, 500);
  };

  const onSearch = (e) => {
    const val = e.target.value;
    searchUsers(val);
  };

  const openNewCKYCForm = () => {
    setDocketOpen(false);
  };

  const onCloseDocketForm = () => {
    setDocketOpen(false);
    refetch();
  };

  const onRemarksClick = (id) => {
    const allCustomers = data?.get("items")?.toJS() || [];
    setCustomerId(id);
    const customer = allCustomers.find((item) => item.customerId === id);
    toggleCustomerJourneyPanel(true);
  };

  React.useEffect(() => {
    (async () => {
      await fetchAllStatuses();
    })();
  }, []);

  // const rightSideActions = [
  // 	{
  // 		icon: EditIcon,
  // 		options: [
  // 			{ label: "Add New", Icon: addNewUser, onClick: toggleDrawer },
  // 			{ label: "Upload", Icon: uploadUser, EditIcon, onClick: null },
  // 		],
  // 		title: "Add New",
  // 		onClick: null,
  // 		isSplit: true,
  // 	},
  // ];

  const getAllStatusDropdownValues = () => {
    let options = [];
    if (allStatuses) {
      options.push({
        label: "All Status",
        icon: null,
        onClick: () => onFilter({ status: '' }),
      });
      allStatuses.forEach((item) => {

        const status = [5, 9, 13].includes(item.id);
        if (status) {
          const newOption = {
            label: item.status,
            icon: null,
            onClick: () => onFilter({ status: item.id }),
          };
          options.push(newOption);
        }
      });
    }
    return options;
  };

  const leftSideActions = [
    {
      defaultText: "Filter",
      isDropdown: false,
      isButton: true,
      icon: <EditIcon />,
      title: "Filter",
      onClick: () => toggleFilter(true),
    },
    ...(allStatuses
      ? [
        {
          options: getAllStatusDropdownValues(),
          defaultText: "All Status",
          icon: null,
          isDropdown: true,
          groupTitle: "Status",
        },
      ]
      : []),
  ];
  const rightSideActions = [
    {
      title: "Individual",
      onClick: null,
      isSplit: true,
    },
    // {
    //   title: "Legal Entities",
    //   onClick: null,
    //   isSplit: true,
    // },
  ];

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    if (activeTab === 0) {
      setActiveTab(1)
    }
    else {
      setActiveTab(0)
    }
  };

  const showHistory = (id) => {
    const allCustomers = data?.get("items")?.toJS() || [];
    setCustomerId(id);
    const customer = allCustomers.find((item) => item.customerId === id);
    toggleCustomerJourneyPanel(true);
  };

  // useEffect(()=>{
  //   if(activeTab === 0) {
  //     setTableData(data?.get("items")?.toJS()?.filter(item => item?.CONSTI_TYPE === "01"))
  //   }else{
  //     setTableData(data?.get("items")?.toJS()?.filter(item => item?.CONSTI_TYPE === "02"))
  //   }
  // },[activeTab, tableData, data?.get("items")?.toJS()])

  useEffect(() => {
    if (data?.get("totalCount") >= 0) {
      setTotalCount(data?.get("totalCount"));
    }
  }, [data?.get("totalCount")])
  useEffect(() => {
    if (data?.get("items")?.toJS()?.length >= 0) {
      setTableData(data?.get("items")?.toJS())
    }
  }, [data?.get("items")])
  return (
    <React.Fragment>
      <PageHeader
        toggleDrawer={toggleDrawer}
        leftSideActions={leftSideActions}
        rightSideActions={rightSideActions}
        pageTitle={pageTitle}
        isSearch={true}
        searchOnChange={onSearch}
        totalCount={totalCount}
        activeTab={activeTab}
        handleTabChange={handleTabChange}
      />
      <Box sx={{ padding: "15px", height: "100%" }}>
        <DataTable
          loading={false}
          getRowId={(row) => row.customerId}
          pageSize={filter?.perPageSize}
          totalCount={totalCount}
          columns={getColumns({
            onToggleActive,
            onDocketClick,
            onRemarksClick,
            showHistory,
            isUploadCustomers: true
          })}
          rows={tableData}
          onPageSize={(pageSize) => {
            updateFilter((fil) => ({
              ...fil,
              perPageSize: pageSize,
            }));
          }}
          onPage={(page) => {
            updateFilter((fil) => ({
              ...fil,
              page: page,
            }));
          }}
        />
        {openFilter && (
          <CustomerFilter
            open={openFilter}
            toggleDrawer={() => toggleFilter(false)}
            onSubmit={onSubmit}
            filters={filters}
            // selectedDistrict={selectedDistrict || Immutable.Map()}
            isLoading={isLoading}
            statuses={allStatuses}
            dropdownOptions={dropdownOptions}
          />
        )}
      </Box>
      {openDeletePopup && (
        <DeletePopUp
          title="Delete Document"
          closePopup={() => toggleDeletePopup(false)}
          openDeletePopup={openDeletePopup}
          onDelete={() => onDeleteUser()}
          isLoading={isLoading}
        />
      )}
      {docketOpen && customerId && (
        <CustomerDetails
          open={docketOpen}
          closePanel={onCloseDocketForm}
          openNewCKYCForm={openNewCKYCForm}
          customerId={customerId}
          dropdownOptions={dropdownOptions}
          openUpdateForm={true}
        />
      )}
      {openCustomerJourneyPanel && (
        <CustomerJoruneyPanel
          open={openCustomerJourneyPanel}
          toggleDrawer={() => toggleCustomerJourneyPanel(!openCustomerJourneyPanel)}
          customerId={customerId}
        />
      )}
    </React.Fragment>
  );
};

export default NewCustomers;
