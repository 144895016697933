import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3f81f4",
    },
    secondary: {
      main: "#4caf50",
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: "#4EB56A",
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        colorPrimary: {
          "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#4EB56A",
            color: "white",
          },
          "&.Mui-checked": {
            color: "#4EB56A !important",
          },
        },
        root: {
          "&.Mui-checked": {
            color: "#4EB56A !important",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: `1px solid #9A9A9A`,
            },
          },
          "&.Mui-disabled": {
            backgroundColor: "#F2F2F2",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        outlined: {
          border: "1px solid #BFBFBF",
          backgroundColor: "ffffff",
          color: "#494E59",
          "&:hover": {
            border: "1px solid #BFBFBF",
            // backgroundColor: "white",
            // color: "#5b58ff",
          },
        },
        // text: {
        // 	color: "red",
        // },
      },
    },
  },
});

export default theme;
