import { Box } from "@mui/material";
import React, { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useMutation, useQuery } from "@tanstack/react-query";
import Immutable from "immutable";
import { getCkycUsers, addOrEditCkycUser } from "../apis/ckycUsers";
import DataTable from "../components/Molecules/DataTable";
import PageHeader from "../components/Molecules/PageHeader";
import AddCkycUser from "../components/Organisms/AddCkycUser";
import {
  deactivated,
  activate,
  selectAll,
  uploadUser,
  addNewUser,
} from "../assets/icons";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import DeletePopUp from "../components/Molecules/PopupModal";
import { useGlobalContext } from "../context/GlobalContext";
import { getColumns } from "../utils/tableHeaders/ckycUsers";

const pageTitle = "CKYC Mapping";

const CKYCUser = () => {
  const [addUserPanel, setAddUserPanel] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const [openDeletePopup, toggleDeletePopup] = React.useState(false);
  const { dispatch } = useGlobalContext();
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [search, updateSearch] = React.useState(null);
  const [filter, updateFilter] = React.useState({ condition: "" ,
  page: 0,
  perPageSize:5
  });
  const [totalCount, setTotalCount] = React.useState(0);
  const {
    data,
    refetch,
    error: branchesError,
  } = useQuery(["allUsers", { filter, search }], getCkycUsers, {
    manual: true,
    select: (response) => response.get("data", []),
    retry: false,
  });

  const { mutate: addOrUpdateUser, isLoading } = useMutation(
    (userData) => addOrEditCkycUser(userData),
    {
      onSuccess: (response) => {
        if (response.isError) {
          dispatch({
            type: "SET_TOAST",
            payload: {
              open: true,
              text: response.message,
              severity: "error",
            },
          });
        } else {
          dispatch({
            type: "SET_TOAST",
            payload: {
              open: true,
              text: response.message,
              severity: "success",
            },
          });
          // updateFilter({});
          setAddUserPanel(false);
          toggleDeletePopup(false);
          refetch();
        }
      },
      onError: (error) => {},
    }
  );

  const onSubmit = (data) => {
    let requestData = data;
    if (selectedUser?.get("ckycUserId")) {
      requestData["ckycUserId"] = selectedUser.get("ckycUserId");
    }
    addOrUpdateUser(requestData);
  };

  const onDeleteUser = () => {
    const requestData = {
      ckycUserId: selectedUser.get("ckycUserId"),
      isDeleted: "1",
    };
    addOrUpdateUser(requestData);
    setSelectedUser(null);
  };

  const toggleDrawer = () => {
    if (addUserPanel) {
      setSelectedUser(null);
    }
    setAddUserPanel(!addUserPanel);
  };

  const onEditClick = async (id) => {
    const user = data
      .get("items")
      .find((item) => item.get("ckycUserId") === id);
    setSelectedUser(user);
    toggleDrawer();
  };

  const onDeleteClick = (id) => {
    const user = data
      .get("items")
      .find((item) => item.get("ckycUserId") === id);
    setSelectedUser(user);
    toggleDeletePopup(true);
  };

  const onToggleActive = async (e, id) => {
    let checked = e.target.checked;
    const requestData = {
      ckycUserId: id,
      status: checked ? 1 : 0,
    };
    addOrUpdateUser(requestData);
  };

  const onStatusFilter = (status) => {
    updateFilter({...filter, ...status});
    refetch();
  };

  let searchTimeout;

  const searchUsers = (query) => {
    clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      updateSearch(query);
    }, 500);
  };

  const onSearch = (e) => {
    const val = e.target.value;
    searchUsers(val);
  };

  const rightSideActions = [
    // {
    // 	icon: EditIcon,
    // 	options: [
    // 		{ label: "Add New", Icon: addNewUser, onClick: toggleDrawer },
    // 		{ label: "Upload", Icon: uploadUser, EditIcon, onClick: null },
    // 	],
    // 	title: "Add New",
    // 	onClick: null,
    // 	isSplit: true,
    // },
    {
      defaultText: "Add New",
      isDropdown: false,
      isButton: true,
      icon: <PersonAddAltIcon />,
      title: "Add New",
      onClick: toggleDrawer,
      color: "contained",
    },
  ];

  const leftSideActions = [
    {
      options: [
        {
          label: "All Status",
          Icon: selectAll,
          onClick: () => onStatusFilter({statusFilter:null}),
        },
        {
          label: "Active",
          Icon: activate,
          onClick: () => onStatusFilter({ statusFilter: 1 }),
        },
        {
          label: "InActive",
          Icon: deactivated,
          onClick: () => onStatusFilter({ statusFilter: 0 }),
        },
      ],
      defaultText: "Activate",
      isDropdown: true,
    },
  ];

  useEffect(() =>{
    if(data?.get("totalCount") >= 0){
      setTotalCount(data?.get("totalCount"));
    }
  },[data?.get("totalCount")])
  useEffect(() => {
    if (data?.get("items")?.toJS()?.length >= 0) {
      setTableData(data?.get("items")?.toJS())
    }
  }, [data?.get("items")])
  return (
    <React.Fragment>
      <PageHeader
        toggleDrawer={toggleDrawer}
        leftSideActions={[...leftSideActions, ...rightSideActions]}
        // rightSideActions={rightSideActions}
        pageTitle={pageTitle}
        isSearch={true}
        searchOnChange={onSearch}
        totalCount={totalCount}
      />
      <Box sx={{ padding: "15px", height: "100%" }}>
        <DataTable
          loading={false}
          getRowId={(row) => row.ckycUserId}
          pageSize={filter?.perPageSize}
          totalCount={totalCount}
          columns={getColumns({ onToggleActive, onDeleteClick, onEditClick })}
          rows={tableData}
          onPageSize={(pageSize) => {
            updateFilter((fil) => ({
              ...fil,
              perPageSize: pageSize,
            }));
          }}
          onPage={(page) => {
            updateFilter((fil) => ({
              ...fil,
              page: page,
            }));
          }}
        />
        {addUserPanel && (
          <AddCkycUser
            open={addUserPanel}
            toggleDrawer={toggleDrawer}
            onSubmit={onSubmit}
            selectedUser={selectedUser || Immutable.Map()}
            isLoading={isLoading}
          />
        )}
      </Box>
      {openDeletePopup && (
        <DeletePopUp
          title="Delete User"
          closePopup={() => toggleDeletePopup(false)}
          openDeletePopup={openDeletePopup}
          onDelete={() => onDeleteUser()}
          isLoading={isLoading}
        />
      )}
    </React.Fragment>
  );
};

export default CKYCUser;
