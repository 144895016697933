import { Box } from "@mui/material";
import React ,{useEffect} from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useMutation, useQuery } from "@tanstack/react-query";
import Immutable from "immutable";

import {
  getMasters,
  createOrEditUploadMaster,
  getPicklists,
} from "../apis/uploadMaster";
import DataTable from "../components/Molecules/DataTable";
import PageHeader from "../components/Molecules/PageHeader";
import AddUploadMaster from "../components/Organisms/AddUploadMaster";
import {
  deactivated,
  activate,
  selectAll,
  uploadUser,
  addNewUser,
} from "../assets/icons";
import DeletePopUp from "../components/Molecules/PopupModal";
import { useGlobalContext } from "../context/GlobalContext";
import { getColumns } from "../utils/tableHeaders/uploadMaster";
import { importUploadMaster, getImportTemplate } from "../apis/bulkOperations";
import Backdrop from "../components/Molecules/Backdrop";
import UploadFile from "../components/Organisms/UploadFile";

const pageTitle = "Upload Master";

const UploadMaster = () => {
  const [addUserPanel, setAddUserPanel] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const [openDeletePopup, toggleDeletePopup] = React.useState(false);
  const { dispatch } = useGlobalContext();
  const [selectedMaster, setSelectedDoc] = React.useState(null);
 // const [filter, updateFilter] = React.useState({});
 const [filter, updateFilter] = React.useState({ condition: "" ,
  page:0,
  perPageSize:5
  });
  const [totalCount, setTotalCount] = React.useState(0);
  const [search, updateSearch] = React.useState(null);
  const [mappedPicklists, setMappedPicklists] = React.useState([]);
  const [openUploadFile, toggleUploadFile] = React.useState();
  const [formReset, setFormReset] = React.useState(false);
  const { data, refetch } = useQuery(
    ["masters", { filter, search }],
    getMasters,
    {
      manual: true,
      select: (response) => response.get("data", []),
      retry: false,
    }
  );

  const { refetch: downloadTemplate, isLoading: downloadingTemplate } =
    useQuery(["template", { module: "uploadMaster" }], getImportTemplate, {
      manual: true,
      // select: (response) => response.get("data", []),
      retry: false,
      enabled: false,
      onSuccess: async (response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        if (url) {
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "uploadMaster_template");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      },
    });

  const { data: picklistTypes } = useQuery(["picklistTypes"], getPicklists, {
    select: (response) => response.data,
    manual: true,
    onSuccess: (response) => {
      let options = [];
      if (response.items.length > 0) {
        options.push({
          label: "All Master Types",
          id: -1,
          onClick: () => onFilter({ masterId: null }),
        });
      }
      response.items.forEach((item) => {
        options.push({
          label: item.masterName,
          id: item.id,
          onClick: () => onFilter({ masterId: item.id }),
        });
      });

      setMappedPicklists(options);
    },
  });

  const { mutate: addOrUpdateDoc, isLoading } = useMutation(
    (userData) => createOrEditUploadMaster(userData),
    {
      onSuccess: (response) => {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.message,
            severity: response.isError ? "error" : "success",
          },
        });
        if (!response.isError) {
          // updateFilter({});
          setAddUserPanel(false);
          toggleDeletePopup(false);
          refetch();
        }
      },
      onError: (error) => {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: error.response.data.message,
            severity: "error",
          },
        });
      },
    }
  );

  const {
    mutate: uploadFile,
    isLoading: uploadingDocs,
    // isSuccess: stepUpdated,
  } = useMutation((userData) => importUploadMaster(userData), {
    onSuccess: (response) => {
      // toggleSaveData(false);
      if (response.isError) {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.message,
            severity: "error",
          },
        });
      } else {
        setFormReset(true);
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.message,
            severity: response.isError ? "error" : "success",
          },
        });
        refetch();
      }
    },
    onError: (error) => {
      // toggleSaveData(false);
      dispatch({
        type: "SET_TOAST",
        payload: {
          open: true,
          text: "Something went wrong",
          severity:"error",
        },
      });
    },
  });

  const onSubmit = (data) => {
    let requestData = data;
    if (selectedMaster?.get("id")) {
      requestData["id"] = selectedMaster.get("id");
    }
    requestData = { uploadMaster: requestData };
    addOrUpdateDoc(requestData);
  };

  const onDeleteUser = () => {
    const requestData = {
      id: selectedMaster.get("id"),
    };
    addOrUpdateDoc(requestData);
    setSelectedDoc(null);
  };

  const toggleDrawer = () => {
    if (addUserPanel) {
      setSelectedDoc(null);
    }
    setAddUserPanel(!addUserPanel);
  };

  const onEditClick = async (id) => {
    const user = data.get("items").find((item) => item.get("id") === id);
    setSelectedDoc(user);
    toggleDrawer();
  };

  const onDeleteClick = (id) => {
    const user = data.get("items").find((item) => item.get("id") === id);
    setSelectedDoc(user);
    toggleDeletePopup(true);
  };

  const onToggleActive = (e, id) => {
    let checked = e.target.checked;
    let requestData = {
      id: id,
      status: checked ? 1 : 0,
    };
    requestData = { uploadMaster: requestData };
    addOrUpdateDoc(requestData);
  };

  const onFilter = (status) => {
    console.log("filterr_mster",filter)
    updateFilter({ ...filter, ...status });
    refetch();
  };

  let searchTimeout;

  const searchUsers = (query) => {
    clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      updateSearch(query);
    }, 500);
  };

  const onSearch = (e) => {
    const val = e.target.value;
    searchUsers(val);
  };

  const onFileUpload = function (file) {
    if (file) {
      let formData = new FormData();
      formData.append(`files`, file);

      uploadFile(formData);
    }
  };

  const rightSideActions = [
    {
      icon: EditIcon,
      options: [
        { label: "Add New", Icon: addNewUser, onClick: toggleDrawer },
        {
          label: "Upload",
          Icon: uploadUser,
          EditIcon,
          onClick: () => toggleUploadFile(!openUploadFile),
        },
      ],
      title: "Add New",
      onClick: toggleDrawer,
      isSplit: true,
    },
  ];

  const leftSideActions = [
    {
      options: [
        {
          label: "All Status",
          Icon: selectAll,
          onClick: () => onFilter({ statusFilter: null }),
        },
        {
          label: "Active",
          Icon: activate,
          onClick: () => onFilter({ statusFilter: 1 }),
        },
        {
          label: "InActive",
          Icon: deactivated,
          onClick: () => onFilter({ statusFilter: 0 }),
        },
      ],
      defaultText: "Activate",
      isDropdown: true,
    },
    ...[
      mappedPicklists.length > 0 && {
        options: mappedPicklists,
        isDropdown: true,
        explicit: true,
        title: "Master Type",
        defaultText: "All Master Types",
      },
    ],
  ];
  
 useEffect(() =>{
  if(data?.get("totalCount") >= 0){
    setTotalCount(data?.get("totalCount"));
  }
},[data?.get("totalCount")])
useEffect(() => {
  if (data?.get("items")?.toJS()?.length >= 0) {
    setTableData(data?.get("items")?.toJS())
  }
}, [data?.get("items")])

useEffect(()=> {
  refetch();
},[])

  return (
    <React.Fragment>
      <PageHeader
        toggleDrawer={toggleDrawer}
        leftSideActions={[...leftSideActions, ...rightSideActions]}
        // rightSideActions={rightSideActions}
        pageTitle={pageTitle}
        isSearch={true}
        searchOnChange={onSearch}
        totalCount={totalCount}
      />
      <Box sx={{ padding: "15px", height: "100%" }}>
        <DataTable
          loading={false}
          getRowId={(row) => row.id}
          pageSize={filter?.perPageSize}
          totalCount={totalCount}
          columns={getColumns({ onToggleActive, onDeleteClick, onEditClick })}
          rows={tableData}
          
  onPageSize={(pageSize) => {
    updateFilter((fil) => ({
      ...fil,
      perPageSize: pageSize,
    }));
  }}
  onPage={(page) => {
    updateFilter((fil) => ({
      ...fil,
      page: page,
    }));
  }}
        />
        {addUserPanel && (
          <AddUploadMaster
            open={addUserPanel}
            toggleDrawer={toggleDrawer}
            onSubmit={onSubmit}
            selectedMaster={selectedMaster || Immutable.Map()}
            isLoading={isLoading}
            picklistTypes={picklistTypes}
          />
        )}
      </Box>
      {openDeletePopup && (
        <DeletePopUp
          title="Delete Document"
          closePopup={() => toggleDeletePopup(false)}
          openDeletePopup={openDeletePopup}
          onDelete={() => onDeleteUser()}
          isLoading={isLoading}
        />
      )}
      <Backdrop loading={uploadingDocs} />
      <UploadFile
        open={openUploadFile}
        toggleDrawer={() => toggleUploadFile(!openUploadFile)}
        title={"Upload Files"}
        onSubmit={onFileUpload}
        uploadingDoc={uploadingDocs}
        downloadSample={downloadTemplate}
        supportFile={"uploadMaster"}
        formReset={formReset}
        setFormReset={setFormReset}
      />
    </React.Fragment>
  );
};

export default UploadMaster;
