import { LoadingButton } from "@mui/lab";
import React, { useRef } from "react";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Box } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

import { uploadResponseFile } from "../apis/ckycResponse";
import {
  createOrEditDistrict
} from "../apis/districtMaster";
import { downloadZip, getAllStatuses, getCustomers } from "../apis/newCustomer";
import Text from "../components/Atoms/Text";
import DataTable from "../components/Molecules/DataTable";
import PageHeader from "../components/Molecules/PageHeader";
import DeletePopUp from "../components/Molecules/PopupModal";
import CustomerDetails from "../components/Organisms/CustomerDetails";
import CustomerFilter from "../components/Organisms/new-customer/CustomerFilter";
import { formatDate } from "../components/Organisms/new-customer/formUtils";
import { useGlobalContext } from "../context/GlobalContext";
import useDropdownOptions from "../hooks/useDropdownOptions";
import { getColumns } from "../utils/tableHeaders/ckycResponseFile";

const CKYCSecondResponse = () => {
  const inputRef = useRef(null)
  const [openFilter, toggleFilter] = React.useState(false);
  const [openDeletePopup, toggleDeletePopup] = React.useState(false);
  const {
    state: { currentUser },
    dispatch,
  } = useGlobalContext();
  const [selectedDistrict, setSelectedDoc] = React.useState(null);
  const [filter, updateFilter] = React.useState({});
  const [search, updateSearch] = React.useState(null);
  const [docketOpen, setDocketOpen] = React.useState(false);
  const [customerId, setCustomerId] = React.useState(null);
  const { dropdownOptions } = useDropdownOptions();
  const [filters, setFiltes] = React.useState(null);
  const [selectedCustomerIds, setSelectedCustomersIds] = React.useState([]);
  const [isSFTPGenerating, setIsSFTPGenerating] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [responseList, setResponseList] = React.useState(null);
  const [saveData, toggleSaveData] = React.useState(false);
  const [isFileUpload, setIsFileUpload] = React.useState(false);
  const [isSave, setIsSave] = React.useState(false);

  // Pass this to customer details to avoid duplicate code
  let currentRole =
    JSON?.parse(window.localStorage.getItem("roles")) ||
    currentUser.role.role_name;
  currentRole = currentRole.toLowerCase();

  const pageTitle = "CKYC Second Response - CKYC Uploaded Files";

  const {
    data,
    refetch,
    isSuccess: customersFetched,
  } = useQuery(
    ["customers", { filter: { status: 7 }, search, filters }],
    getCustomers,
    {
      manual: true,
      select: (response) => response.get("data", []),
      retry: false,
      enabled: false,
    }
  );

  const { data: allStatuses, refetch: fetchAllStatuses } = useQuery(
    ["statuses"],
    getAllStatuses,
    {
      manual: true,
      select: (resp) => resp.data,
      retry: false,
      enabled: false,
    }
  );

  const { mutate: addOrUpdateDoc, isLoading } = useMutation(
    (userData) => createOrEditDistrict(userData),
    {
      onSuccess: (response) => {
        console.log(response);
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.message,
            severity: response.isError ? "error" : "success",
          },
        });
      },
      onError: (error) => {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: error.response.data.message,
            severity: "error",
          },
        });
      },
    }
  );

  const { mutate: generateCkyc, isLoading: downloadingZip } = useMutation(
    (userData) => downloadZip(userData),
    {
      onSuccess: async (response) => {
        setIsSFTPGenerating(false);
        if (response && response?.isError) {
          dispatch({
            type: "SET_TOAST",
            payload: {
              open: true,
              text: response.message,
              severity: "error",
            },
          });
        } else {
          if (response?.data?.length === 0) {
            dispatch({
              type: "SET_TOAST",
              payload: {
                open: true,
                text: response.message,
                severity: "success",
              },
            });
          } else {
            const url = window.URL.createObjectURL(
              new Blob([response?.data], { type: "application/zip" })
            );
            if (url) {
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", response?.headers["file-name"]);
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
            }
          }
        }
      },
      onError: (error) => {
        setIsSFTPGenerating(false);
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: error.response.data.message,
            severity: "error",
          },
        });
      },
    }
  );

  const {
    mutate: uploadFile,
    isLoading: uploadingDocs,
    // isSuccess: stepUpdated,
  } = useMutation((userData) => uploadResponseFile(userData), {
    onSuccess: (response) => {
      toggleSaveData(false);
      if (response.isError) {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.message,
            severity: "error",
          },
        });
      } else {
        console.log(response);
        if (isSave) {
          setIsFileUpload(false);
        } else {
          setIsFileUpload(true);
          setIsSave(false);
        }
        setResponseList(response);

        if (!Array.isArray(response.data)) {
          console.log("---");
          dispatch({
            type: "SET_TOAST",
            payload: {
              open: true,
              text: response.message,
              severity: response.isError ? "error" : "success",
            },
          });
          setResponseList([]);
          // setSelectedFile(null);
        } else {
          dispatch({
            type: "SET_TOAST",
            payload: {
              open: true,
              text: response.message,
              severity: response.isError ? "error" : "success",
            },
          });
        }
        // fetchCustomerInfo();
        // handleNext();
      }
    },
    onError: (error) => {
      toggleSaveData(false);
    },
  });

  const onSubmit = (data) => {
    let reqData = data;
    if (reqData["toDate"])
      reqData["toDate"] = !dayjs(
        reqData["toDate"],
        "MM-DD-YYYY",
        true
      ).isValid()
        ? reqData["toDate"]
        : formatDate(reqData["toDate"]);
    if (reqData["fromDate"])
      reqData["fromDate"] = !dayjs(
        reqData["fromDate"],
        "MM-DD-YYYY",
        true
      ).isValid()
        ? reqData["fromDate"]
        : formatDate(reqData["fromDate"]);
    setFiltes(reqData);
    refetch();
    if (customersFetched) {
      toggleFilter(false);
    }
  };

  const onDeleteUser = () => {
    const requestData = {
      id: selectedDistrict.get("id"),
    };
    addOrUpdateDoc(requestData);
    setSelectedDoc(null);
  };

  const toggleDrawer = () => {
    // setNewCustomerModal(!openNewCustomerModal);
  };

  const onDocketClick = (id) => {
    // fetch the ckyc user info
    setCustomerId(id);
    setDocketOpen(true);
  };

  const onToggleActive = (e, id) => {
    let checked = e.target.checked;
    let requestData = {
      id: id,
      status: checked ? 1 : 0,
    };
    requestData = { district: requestData };
    addOrUpdateDoc(requestData);
  };

  const onFilter = (status) => {
    updateFilter(status);
    refetch();
  };

  let searchTimeout;

  const searchUsers = (query) => {
    clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      updateSearch(query);
    }, 500);
  };

  const onSearch = (e) => {
    const val = e.target.value;
    searchUsers(val);
  };

  const openNewCKYCForm = () => {
    setDocketOpen(false);
  };

  const onCloseDocketForm = () => {
    setDocketOpen(false);
    refetch();
  };

  React.useEffect(() => {
    (async () => {
      await fetchAllStatuses();
    })();
  }, []);

  const getAllStatusDropdownValues = () => {
    let options = [];
    if (allStatuses) {
      options.push({
        label: "All Status",
        icon: null,
        onClick: () => onFilter(null),
      });
      allStatuses.forEach((item) => {
        const status =
          currentRole === "maker"
            ? [2, 3, 6, 10].includes(item.id)
            : [4, 11, 10, 6, 7].includes(item.id);
        if (status) {
          const newOption = {
            label: item.status,
            icon: null,
            onClick: () => onFilter({ status: item.id }),
          };
          options.push(newOption);
        }
      });
    }
    return options;
  };

  console.log(selectedFile);

  // triggers when file is selected with click
  // const handleChange = function (e) {
  //   console.log(e);
  //   e.preventDefault();
  //   const event = e.currentTarget;
  //   console.log(event);
  //   if (event.files && event.files[0]) {
  //     console.log(event.files[0]);
  //     // handleFiles(e.target.files[0], field, index);
  //     setSelectedFile(event.files[0]);
  //   }
  // };
  const handleChange = function (e) {
    e.preventDefault();
    const event = e.currentTarget;
    const fileName = event.files[0]?.name;
    const isRes1File = fileName?.includes("RES1.txt");
    const isRes0File = fileName?.includes("RES0.txt");

    if (!isRes1File && !isRes0File) {
      if (event.files && event.files[0]) {
        setSelectedFile(event.files[0]);
      }
    } else {
      dispatch({
        type: "SET_TOAST",
        payload: {
          open: true,
          text: "Wrong file uploaded",
          severity: "error",
        },
      });
      setSelectedFile(null);
      inputRef.current.value = ""
    }
  };

  const onUploadFile = () => {
    let formData = new FormData();
    formData.append(`files`, selectedFile);
    formData.append("save", false);
    uploadFile(formData);
  };

  const onSave = () => {
    try {
      toggleSaveData(true);
      let formData = new FormData();
      formData.append(`files`, selectedFile);
      formData.append("save", true);
      // if (selectedCustomerIds.length > 0) {
      //   let selectedIds = selectedCustomerIds?.join();
      //   formData.append("customerIds", selectedIds);
      // }
      //   uploadFile(formData);
      setIsSave(true);
      const clearTime = setTimeout(() => {
        uploadFile(formData);
        clearTimeout(clearTime)
      }, 10)
    } catch (e) {
      toggleSaveData(false);
    }
  };

  console.log(responseList);

  return (
    <React.Fragment>
      <PageHeader
        toggleDrawer={toggleDrawer}
        leftSideActions={[]}
        rightSideActions={[]}
        pageTitle={pageTitle}
        isSearch={false}
        searchOnChange={onSearch}
        // totalCount={data?.get("totalCount", 0)}
        totalCount={responseList?.data?.length}
      />
      <Box
        mt="0px"
        sx={{
          // borderTopLeftRadius: "10px",
          // borderTopRightRadius: "10px",
          background: "white",
          border: "1px dashed #E5E5E5",
          padding: "12px 20px",
          display: "flex",
          borderRadius: "4px",
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        <Box px={"32px"} py={"8px"} display="flex" alignItems="center">
          <Text mr="32px" fontSize="14px" fontWeight="500">
            Upload Ckyc Registry File
          </Text>
          {/* <Button
						sx={{ marginRight: "24px" }}
						size="small"
						variant="outlined"
						startIcon={<FileUploadIcon />}>
						Upload File
					</Button> */}
          <Box>
            <Box display="flex" alignItems="center">
              <Box
                display="flex"
                // alignItems="flex-end"
                flexDirection={"column"}
                sx={{ marginRight: "24px" }}>
                <Text
                  mb="4px"
                  style={{ textAlign: "end" }}
                  color="#CDCDCD"
                  fontSize="8px">
                  You can upload only 1 file, 5MB
                </Text>
                <label htmlFor={`contained-button-file`}>
                  <Box
                    sx={{
                      border: "1px dashed #9A9A9A80",
                      borderRadius: "4px",
                      width: "max-content",
                      cursor: "pointer",
                    }}>
                    <input
                      key={selectedFile} // Add a key to the input element
                      style={{ display: "none" }}
                      id={`contained-button-file`}
                      type="file"
                      onChange={handleChange}
                      ref={inputRef}
                    />
                    <Box display="flex" alignItems="center">
                      <Box p="8px" sx={{ borderRight: "1px dashed #9A9A9A80" }}>
                        <FileUploadIcon />
                      </Box>
                      <Box px="12px">
                        <Text mr="12px">File Upload</Text>
                      </Box>
                    </Box>
                  </Box>
                </label>
              </Box>
              <LoadingButton
                loading={uploadingDocs && !saveData}
                style={{ marginTop: "16px" }}
                size="medium"
                variant="contained"
                disabled={!selectedFile}
                onClick={onUploadFile}>
                <Text>Upload</Text>
              </LoadingButton>
            </Box>
            <Text mt={"4px"} color="#ACACAC" fontSize="10px">
              {/* {!selectedFile ? "No file selected" : selectedFile.name} */}
              {!selectedFile ? "No file selected" : isFileUpload ? "" : selectedFile.name}

            </Text>
          </Box>
        </Box>
        <Box>
        {responseList?.data?.length > 0 && (
          <>
            <LoadingButton
              sx={{ marginLeft: "5px" }}
              loading={uploadingDocs && saveData}
              variant="contained"
              onClick={onSave}>
              Save
            </LoadingButton>
          </>
        )}

        </Box>
      </Box>
      <Box sx={{ padding: "15px", height: "100%" }}>
        {!responseList?.isError &&
          Array.isArray(responseList?.data) &&
          responseList?.data && (
            <DataTable
              loading={false}
              getRowId={(row) => row.customerId}
              // pageSize={data?.get("perPage", 5)}
              // totalCount={data?.get("totalCount", 0)}
              columns={getColumns({ onToggleActive, onDocketClick })}
              rows={responseList.data || []}
              onSelectionModelChange={(selected) =>
                setSelectedCustomersIds(selected)
              }
              isNoCheckBox={true}
            />
          )}
        {openFilter && (
          <CustomerFilter
            open={openFilter}
            toggleDrawer={() => toggleFilter(false)}
            onSubmit={onSubmit}
            filters={filters}
            // selectedDistrict={selectedDistrict || Immutable.Map()}
            isLoading={isLoading}
            statuses={allStatuses}
            showStatuses={false}
            dropdownOptions={dropdownOptions}
          />
        )}
      </Box>
      {openDeletePopup && (
        <DeletePopUp
          title="Delete Document"
          closePopup={() => toggleDeletePopup(false)}
          openDeletePopup={openDeletePopup}
          onDelete={() => onDeleteUser()}
          isLoading={isLoading}
        />
      )}
      {docketOpen && customerId && (
        <CustomerDetails
          open={docketOpen}
          closePanel={onCloseDocketForm}
          openNewCKYCForm={openNewCKYCForm}
          customerId={customerId}
          dropdownOptions={dropdownOptions}
        />
      )}
    </React.Fragment>
  );
};

export default CKYCSecondResponse;
