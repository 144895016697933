import { Box, Button } from "@mui/material";
import React, { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Modal from "../Molecules/Modal";
import Text from "../Atoms/Text";
import xlsImage from "../../assets/images/xls.svg";
import StickyFooter from "../Atoms/StickyFooter";
import uploadDoc from "../../assets/icons/uploadDoc.svg";

const UploadFile = ({
  title,
  open,
  toggleDrawer,
  onSubmit,
  uploadingDoc,
  downloadSample,
  hideSampleFile,
  isBulk,
  setPopup,
  supportFile,
  formReset,
  setFormReset,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [dragActive, setDragActive] = React.useState(false);
 
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const checkFile = (file) => {
    if(isBulk){
      const supportedFormats = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        // "text/csv",
        // "text/tsv",
        "text/plain",
        "application/zip",
        "application/x-rar",
        "application/x-zip-compressed"
      ];
      if (0 > supportedFormats.indexOf(file.type)) {
        alert("unsupported format");
        return;
      }
      setSelectedFile(file);
    }else if(supportFile === "districtMaster" || supportFile === "uploadMaster" || supportFile === "createCustomer" ){
      const supportedFormats = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
       // "text/csv",
        // "text/tsv",
        // "text/plain",
      ];
      if (0 > supportedFormats.indexOf(file.type)) {
        alert("Please upload xlsx file format");
        return;
      }
      setSelectedFile(file);
    }
    else{
      const supportedFormats = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
        "text/tsv",
        "text/plain",
        "application/zip",
        "application/x-rar",
        "application/x-zip-compressed"
      ];
      if (0 > supportedFormats.indexOf(file.type)) {
        alert("unsupported format");
        return;
      }
      setSelectedFile(file);
    }
  };

  const
    onFileChange = (e) => {
      e.preventDefault();
      const event = e.currentTarget;
      if (event.files && event.files[0]) {
        checkFile(event.files[0]);
      }
    };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      checkFile(e.dataTransfer.files[0]);
    }
  };

  // const onSubmit = () => {
  // 	onSubmit(selectedFile);
  // };

  const onClose = () => {
    setSelectedFile(null);
    toggleDrawer();
  };
  useEffect(()=>{
    if(formReset){
      onClose();
    }
    setFormReset && setFormReset(false);
  },[formReset])

  useEffect(()=>{
    setSelectedFile(null);
  },[open])
  
  return (
    <Modal title={title} open={open} toggleDrawer={onClose}>
      <Box
        mr="48px"
        ml="48px"
        mt="48px"
        p={"24px"}
        display="flex"
        flexDirection="column"
        sx={{
          border: "1px solid #E5E5E5",
          borderRadius: "12px",
          backgroundColor: dragActive ? "white" : "#FDFAFA",
        }}
        alignItems="center"
        onDragEnter={(e) => handleDrag(e)}
        justifyContent="center">
        <img
          src={!selectedFile ? uploadDoc : xlsImage}
          style={{ width: "50px", height: "50px", marginTop: "24px" }}
        />
        <Text mt="16px" fontSize="14px" fontWeight="500">
          {selectedFile ? selectedFile.name : "Drag and drop file to import"}
        </Text>
        <Box
          mt="16px"
          mb="18px"
          display="flex"
          alignItems="center"
          sx={{ cursor: selectedFile ? "pointer" : "inherit" }}
          onClick={selectedFile ? () => setSelectedFile(null) : null}>
          {selectedFile && (
            <HighlightOffIcon sx={{ color: "#5B58FF", fontSize: "14px" }} />
          )}
          <Text
            ml="4px"
            fontSize="14px"
            color={selectedFile ? "#5B58FF" : "inherit"}>
            {selectedFile ? "Remove" : "OR"}
          </Text>
          {dragActive && (
            <div
              id="drag-file-element"
              onDragEnter={(e) => handleDrag(e)}
              onDragLeave={(e) => handleDrag(e)}
              onDragOver={(e) => handleDrag(e)}
              onDrop={(e) => handleDrop(e)}></div>
          )}
        </Box>

        <label id="html_for_file">
          <Button
            component="span"
            color="primary"
            variant="contained"
            size="sm">
            {" "}
            <input
              style={{ display: "none" }}
              type="file"
              id="html_for_file"
              onChange={onFileChange}
            />
            {!selectedFile ? "Choose Files" : "Replace Files"}
          </Button>
        </label>
        <Text mt="16px" fontSize="10px" color="#9E9E9E">
          {isBulk ? "File Format : ZIP & TXT" :  "File Format : XLSX"}
        </Text>
      </Box>
      {!hideSampleFile && (
        <Box mt="16px" ml="48px" mr="48px" display="flex">
          <Text
            style={{ cursor: "pointer" }}
            onClick={downloadSample}
            color="#5B58FF"
            fontSize="14px"
            fontWeight="500">
            Download
          </Text>
          <Text ml="4px" fontSize="14px" fontWeight="500">
            Sample File
          </Text>
        </Box>
      )}
      <StickyFooter>
        <React.Fragment>
          <LoadingButton
            size="small"
            variant="outlined"
            color="primary"
            sx={{ marginRight: "8px" }}
            disableElevation
            onClick={() => onClose()}
            style={{ textTransform: "none" }}>
            <Text> {"Cancel"}</Text>
          </LoadingButton>
          <LoadingButton
            loading={uploadingDoc}
            // type={"submit"}
            onClick={() => onSubmit(selectedFile)}
            size="small"
            variant="contained"
            color="primary"
            disableElevation
            disabled={!selectedFile?.name}
            style={{ textTransform: "none" }}>
            <Text>Submit</Text>
          </LoadingButton>
        </React.Fragment>
      </StickyFooter>
    </Modal>
  );
};

export default UploadFile;
