import React from "react";
import {
  Box,
  Button,
  Card,
  Drawer,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import * as yup from "yup";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import Text from "../Atoms/Text";

/*
 TODO: Banner component accepts the dynamic component as prop . if 
 prop exists , display it otherwise diplay the generic one
*/

const Modal = ({
  title,
  open,
  toggleDrawer,
  children,
  fullWidth,
  infoBanner = false,
  bannerText,
}) => {
  return (
    <Drawer size="md" anchor={"right"} open={open}>
      <Box sx={{ width: fullWidth ? "100vw" : "40vw" }}>
        <Card
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderRadius: 0,
          }}
          style={{ padding: "20px" }}>
          <Text fontSize="16px" semiBold>
            {title}
          </Text>
          <HighlightOffIcon onClick={toggleDrawer} sx={{ cursor: "pointer" }} />
        </Card>
        {infoBanner && bannerText && (
          <Box
            px={"32px"}
            py="8px"
            alignItems="center"
            display="flex"
            sx={{ backgroundColor: "#EE8E538A" }}>
            <WarningAmberIcon sx={{ fontSize: "16px" }} />
            <Text ml="8px" fontSize="16px" color="#151423">
              {bannerText}
            </Text>
          </Box>
        )}
        {children}
      </Box>
    </Drawer>
  );
};

export default Modal;
