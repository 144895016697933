import { Box } from "@mui/material";
import React from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import Immutable from "immutable";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";

import {
  getClients,
  addOrEditClient,
  getClientConfig,
  activeClient,
} from "../apis/manageClients";
import DataTable from "../components/Molecules/DataTable";
import PageHeader from "../components/Molecules/PageHeader";
import AddClient from "../components/Organisms/AddClient";
import {
  deactivated,
  activate,
  selectAll,
} from "../assets/icons";
import DeletePopUp from "../components/Molecules/PopupModal";
import { useGlobalContext } from "../context/GlobalContext";
import { getColumns } from "../utils/tableHeaders/manageClients";
import { useEffect } from "react";

const pageTitle = "Manage Clients";

const ManageClients = () => {
  const [addUserPanel, setAddUserPanel] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const [openDeletePopup, toggleDeletePopup] = React.useState(false);
  const { dispatch } = useGlobalContext();
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [filter, updateFilter] = React.useState({
    condition: "",
    page: 0,
    perPageSize: 5,
  });
  const [search, updateSearch] = React.useState(null);
  const [totalCount, setTotalCount] = React.useState(0);

  const {
    data,
    refetch,
    error: branchesError,
  } = useQuery(["allUsers", { filter, search }], getClients, {
    select: (response) => response.get("data", []),
    enabled: false,
  });

  const {
    data: clientConfig,
    refetch: refetchConfig,
    error: configError,
    isLoading: fetchingConfig,
  } = useQuery(
    ["clientConfig", { clientUniqueId: selectedUser?.get("clientUniqueId") }],
    getClientConfig,
    {
      manual:false,
      select: (response) => response.get("data", {}),
      retry: false,
      onSuccess: (response) => {
      },
      enabled: false,
    }
  );

  const { mutate: addOrUpdateUser, isLoading } = useMutation(
    (userData) => addOrEditClient(userData),
    {
      onSuccess: (response) => {
        if (response.isError) {
          dispatch({
            type: "SET_TOAST",
            payload: {
              open: true,
              text: response.message,
              severity: "error",
            },
          });
        } else {
          dispatch({
            type: "SET_TOAST",
            payload: {
              open: true,
              text: response.message,
              severity: "success",
            },
          });
          setAddUserPanel(false);
          setSelectedUser(null);
          toggleDeletePopup(false);
          refetch();
        }
      },
      onError: (error) => {},
    }
  );

  const { mutate: clientActive, isLoading : activeIsLoading } = useMutation(
    (userData) => activeClient(userData),
    {
      onSuccess: (response) => {
        if (response?.data?.isError) {
          dispatch({
            type: "SET_TOAST",
            payload: {
              open: true,
              text: response?.data?.message,
              severity: "error",
            },
          });
        } else {
          dispatch({
            type: "SET_TOAST",
            payload: {
              open: true,
              text: response?.data?.message,
              severity: "success",
            },
          });
          setAddUserPanel(false);
          setSelectedUser(null);
          toggleDeletePopup(false);
          refetch();
        }
      },
      onError: (error) => {},
    }
  );

  const onSubmit = (data) => {
    let requestData = data;
    const PollIntervalValue = parseInt(requestData["pollInterval"])
      ? parseInt(requestData["pollInterval"])
      : 0;
    const JobFrequency = parseInt(requestData["jobfrequency"])
      ? parseInt(requestData["jobfrequency"])
      : 0;
    let postData = {};
    let sftp, apiCreds, customerCare;
    sftp = {
      host: requestData["hostinger"],
      port: requestData["port"],
      username: requestData["sftpUsername"],
      password: requestData["sftpPassword"],
      remotePath: requestData["path"],
      pollInterval: PollIntervalValue,
    };
    apiCreds = {
      syntizenBaseUrl: requestData["baseUrl"],
      username: requestData["apiUsername"],
      password: requestData["apiPassword"],
    };

    customerCare = {
      customerCareEmail: requestData["careEmail"],
      customerCareContactNo: requestData["careContact"],
    };
    // }
    let config = {};
    if (sftp && Object.keys(sftp).some((key) => sftp && sftp[key].length > 0)) {
      config["sftp"] = sftp;
    }
    if (
      apiCreds &&
      Object.keys(apiCreds).some((key) => sftp && apiCreds[key].length > 0)
    ) {
      config["api"] = apiCreds;
    }
    if (Object.keys(config).length > 0) {
      postData["config"] = config;
    }
    if (Object.keys(customerCare).some((key) => customerCare[key].length > 0)) {
      postData["customerCare"] = customerCare;
    }
    postData["client"] = {
      ...(selectedUser?.get("id") && { id: selectedUser?.get("id") }),
      clientName: requestData["clientName"],
      email: requestData["email"],
      mobileNo: requestData["mobileNo"],
      username: requestData["username"],
      job: requestData["job"],
      job_period: JobFrequency,
    };
    addOrUpdateUser(postData);
  };

  const onDeleteUser = () => {
    const requestData = {
      id: selectedUser.get("id"),
      isDeleted: true,
    };
    addOrUpdateUser({ client: requestData });
    setSelectedUser(null);
  };

  const toggleDrawer = () => {
    if (addUserPanel) {
      setSelectedUser(null);
    }
    setAddUserPanel(!addUserPanel);
  };

  const onEditClick = async (id) => {
    const user = data?.get("items")?.find((item) => item?.get("id") === id);
    setSelectedUser(user);
    toggleDrawer();
  };

  useEffect(() => {
    if (selectedUser) {
      refetchConfig();
    }
  }, [selectedUser]);

  useEffect(() => {
    if (filter) {
      refetch();
    }
  }, [filter]);

  const onDeleteClick = (id) => {
    const user = data?.get("items")?.find((item) => item?.get("id") === id);
    setSelectedUser(user);
    toggleDeletePopup(true);
  };

  const onToggleActive = async (e, id) => {
    let checked = e.target.checked;
    const requestData = {
      id: id,
      status: checked ? 1 : 0,
    };
    clientActive({ client: requestData });
  };

  const onStatusFilter = (status) => {
    updateFilter((fil) => ({ ...fil, ...status }));
  };
  let searchTimeout;

  const searchUsers = (query) => {
    clearTimeout(searchTimeout);
    updateSearch(query);

    searchTimeout = setTimeout(() => {
      refetch();
    }, 500);
  };

  const onSearch = (e) => {
    const val = e.target.value;
    searchUsers(val);
  };

  const rightSideActions = [
    {
      defaultText: "Add New",
      isDropdown: false,
      isButton: true,
      icon: <PersonAddAltIcon />,
      title: "Add New",
      onClick: toggleDrawer,
      color: "contained",
    },
  ];

  const leftSideActions = [
    {
      options: [
        {
          label: "All Status",
          Icon: selectAll,
          onClick: () => onStatusFilter({ statusFilter: null }),
        },
        {
          label: "Active",
          Icon: activate,
          onClick: () => onStatusFilter({ statusFilter: 1 }),
        },
        {
          label: "InActive",
          Icon: deactivated,
          onClick: () => onStatusFilter({ statusFilter: 0 }),
        },
      ],
      defaultText: "Activate",
      isDropdown: true,
    },
  ];
  useEffect(() => {
    if (data?.get("totalCount") >= 0) {
      setTotalCount(data?.get("totalCount"));
    }
  }, [data?.get("totalCount")]);

  useEffect(() => {
    if (data?.get("items")?.toJS()?.length >= 0) {
      setTableData(data?.get("items")?.toJS())
    }
  }, [data?.get("items")])
  return (
    <React.Fragment>
      <PageHeader
        toggleDrawer={toggleDrawer}
        leftSideActions={[...leftSideActions, ...rightSideActions]}
        pageTitle={pageTitle}
        isSearch={true}
        searchOnChange={onSearch}
        totalCount={totalCount}
      />
      <Box sx={{ padding: "15px", height: "100%" }}>
        <DataTable
          loading={false}
          getRowId={(row) => row.id}
          pageSize={filter?.perPageSize}
          totalCount={totalCount}
          columns={getColumns({ onToggleActive, onDeleteClick, onEditClick })}
          rows={tableData}
          onPageSize={(pageSize) => {
            updateFilter((fil) => ({
              ...fil,
              perPageSize: pageSize,
            }));
          }}
          onPage={(page) => {
            updateFilter((fil) => ({
              ...fil,
              page: page,
            }));
          }}
        />
        {addUserPanel && (selectedUser ? !fetchingConfig : true) && (
          <AddClient
            open={addUserPanel}
            toggleDrawer={toggleDrawer}
            onSubmit={onSubmit}
            clientConfig={
              (clientConfig && clientConfig.get("config")) || Immutable.Map()
            }
            selectedUser={selectedUser || Immutable.Map()}
            isLoading={isLoading}
          />
        )}
      </Box>
      {openDeletePopup && (
        <DeletePopUp
          title="Delete User"
          closePopup={() => toggleDeletePopup(false)}
          openDeletePopup={openDeletePopup}
          onDelete={() => onDeleteUser()}
          isLoading={isLoading}
        />
      )}
    </React.Fragment>
  );
};

export default ManageClients;
