import React,{ useEffect,useState } from "react";
import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { getStates } from "../../apis/branches";
import { useMutation, useQuery, useQueries } from "@tanstack/react-query";

import Text from "../Atoms/Text";
import TextInput from "../Form-Components/TextInput";
import SelectInput from "../Form-Components/SelectInput";
import StickyFooter from "../Atoms/StickyFooter";
import Modal from "../Molecules/Modal";

const schema = yup
  .object()
  .shape({
    districtName: yup.string().max(255).required("First name is required"),
    // stateCode: yup.string().required("Last name is required"),
    stateName: yup.string().max(255).required("First name is required"),
    pincode: yup.string().max(255).required("Last name is required"),
  })
  .required();

const AddDistrictMaster = ({
  open,
  toggleDrawer,
  selectedDistrict,
  isLoading,
  onSubmit,
}) => {
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState,
    errors,
    setValue,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      pincode: selectedDistrict?.get("pincode", ""),
      districtName: selectedDistrict?.get("districtName", ""),
      stateName: selectedDistrict?.get("stateName", ""),
      stateCode: selectedDistrict?.get("stateCode", ""),
    },
    resolver: yupResolver(schema),
  });

  const stateWatcher = watch(
    "stateName"
  );

  const stateCodeWatcher = watch(
    "stateCode"
  );

  const {
    data: states,
    isLoading: statesLoading,
    error: statesError,
  } = useQuery(["states"], getStates, {
    select: (response) => response.data,
    manual: true,
  });
  const [isFormReset, setIsFormReset] = useState(false);

  useEffect(() => {
    if (isFormReset) {
      reset();
      setIsFormReset(false);
    }
  }, [isFormReset, reset]);

   React.useEffect(() => {
    if (stateWatcher) {
      setValue("stateCode", states?.find((i) => i.name === stateWatcher)?.code);
    }
  }, [stateWatcher]);

  const handleReset = () => {
    setIsFormReset(true);
  };
 

  return (
    <Modal
      title={
        selectedDistrict?.get("id")
          ? "Edit District Master"
          : "Create District Master"
      }
      open={open}
      toggleDrawer={toggleDrawer}>
      <form
        style={{ display: "flex", flexDirection: "column" }}
        onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          flexDirection={"column"}
          p="24px"
          sx={{ overflowY: "auto", width: "100%" }}>
          <Box display="flex" sx={{ width: "100%", flexWrap: "wrap" }}>
            <TextInput
              label={"Pin Code"}
              fieldkey="pincode"
              errorText="Pin Code is required"
              placeholder="Enter text"
              control={control}
              register={register}
              mandatory
              isRegex={ /^[0-9]*$/}
              isLimit={7}
             // isNumber={true}
             // pattern="[0-9]{6}"
              setValue={setValue}
            //  onChange={handleInputChange}
              value={getValues()?.["pincode"]}
            />
            <TextInput
              label={"District"}
              fieldkey="districtName"
              errorText="Required"
              placeholder="Enter Text"
              control={control}
              register={register}
              mandatory
              isRegex={/^[a-zA-Z\s]*$/}
              setValue={setValue}
              value={getValues()?.["districtName"]}
            />
            <SelectInput
              label={"State"}
              fieldkey="stateName"
              errorText="State is required"
              placeholder="State"
              control={control}
              register={register}
              mandatory
              setValue={setValue}
              options={states}
              valueRenderer={(option) => option?.name}
              displayName={(option) => option?.name}
              watch={watch}
              
            />
            <TextInput
              label={"State Code"}
              fieldkey="stateCode"
              errorText="Required"
              placeholder="Enter text"
              control={control}
              register={register}
              // mandatory
              isRegex={/^[a-zA-Z]*$/}
              setValue={setValue}
              value={stateCodeWatcher}
            />
          </Box>
        </Box>
        <StickyFooter>
          <React.Fragment>
            <Button
              onClick={handleReset}
              sx={{ marginRight: "8px" }}
              variant="outlined">
             { selectedDistrict?.get("id") ? "Reset" : "Clear"}
            </Button>
            <LoadingButton
              loading={formState.isSubmitting || isLoading}
              type={"submit"}
              size="small"
              variant="contained"
              color="primary"
              disableElevation
              style={{ textTransform: "none" }}>
              <Text>Submit</Text>
            </LoadingButton>
          </React.Fragment>
        </StickyFooter>
      </form>
    </Modal>
  );
};

export default AddDistrictMaster;
