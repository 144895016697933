import React from 'react'
import { useFieldArray } from 'react-hook-form';
import { Box, Button } from "@mui/material"
import SelectInput from "../../Form-Components/SelectInput";
import TextInput from "../../Form-Components/TextInput";
import DatePicker from "../../Form-Components/DatePicker";
const MinorPermanentAddresss = (props) => {
    const { index, formProps, files, dropdownOptions, handleFieldValue } = props
    const { control, register, setValue, getValues, watch } = formProps;
    // const {fields: MinorPermanentAddress, append: appendMinorPermanentAddress, remove: removeMinorPermanentAddress} = useFieldArray({
    //     control,
    //     name: `minorDetails.${index}.relatedProofOfIdentity`
    // })
    return (
        <Box display="flex" flexWrap="wrap">

            <TextInput
                label={"Proof of possession of Aadhaar"}
                fieldkey={`minorDetails[${index}].relatedUID`}
                errorText="Proof of possession of Aadhaar is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={false}
                width="30%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.relatedUID}
            />
            <TextInput
                label={"Voter ID Card"}
                fieldkey={`minorDetails[${index}].relatedVoterCard`}
                errorText="Voter ID Card is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={false}
                width="30%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.relatedVoterCard}
            // isRegex={/^[0-9]*$/}
            />
            <TextInput
                label={"NREGA Job Card"}
                fieldkey={`minorDetails[${index}].NregaJobCard`}
                errorText="NREGA Job Card is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={false}
                width="30%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.NregaJobCard}
            // isRegex={/^[0-9]*$/}
            />
            <TextInput
                label={"National Population RegisterNo"}
                fieldkey={`minorDetails[${index}].nationalPopRegister`}
                errorText="National Population RegisterNo is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={false}
                width="30%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.nationalPopRegister}
            // isRegex={/^[0-9]*$/}
            />
            <TextInput
                label={"Passport No"}
                fieldkey={`minorDetails[${index}].passportNo`}
                errorText="Passport No is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={false}
                width="30%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.passportNo}
            // isRegex={/^[0-9]*$/}
            />
            <TextInput
                label={"Driving Licence No"}
                fieldkey={`minorDetails[${index}].drivingLicenseNo`}
                errorText="Driving Licence No is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={false}
                width="30%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.drivingLicenseNo}
            // isRegex={/^[0-9]*$/}
            />
            <TextInput
                label={"E-KYC Authentication"}
                fieldkey={`minorDetails[${index}].ekycAuthentication`}
                errorText="E-KYC Authentication is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={false}
                width="30%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.ekycAuthentication}
            />
            <SelectInput
                label={"Address Proof"}
                fieldkey={`minorDetails[${index}].relatedAddressProof`}
                errorText="Address Proof is Required"
                placeholder="State"
                control={control}
                register={register}
                mandatory={true}
                setValue={setValue}
                options={dropdownOptions && dropdownOptions["relatedAddressProof"]}
                valueRenderer={(option) => option?.code}
                displayName={(option) => option?.name}
                width="30%"
                watch={watch}
            />
            <TextInput
                label={"Address Proof ID Number"}
                fieldkey={`minorDetails[${index}].relatedAddressProofIdNumber`}
                errorText="Address Proof ID Number is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={true}
                width="30%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.relatedAddressProofIdNumber}
            // isRegex={/^[0-9]*$/}
            isAadhar = {["01", "10"].includes(getValues()?.minorDetails?.[index]?.relatedAddressProof)}
            />
            <TextInput
                label={"Proof Of Address If Others"}
                fieldkey={`minorDetails[${index}].relatedProofOfAddresIfOthers`}
                errorText="Proof Of Address If Others is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={false}
                width="30%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.relatedProofOfAddresIfOthers}
            />
            <TextInput
                label={"Address Line 1"}
                fieldkey={`minorDetails[${index}].relatedPermAddressline1`}
                errorText="Address Line 1 is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={true}
                width="92.5%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.relatedPermAddressline1}
            />
            <TextInput
                label={"Address Line 2"}
                fieldkey={`minorDetails[${index}].relatedPermAddressline2`}
                errorText="Address Line 2 is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={false}
                width="92.5%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.relatedPermAddressline2}
            />
            <TextInput
                label={"Address Line 3"}
                fieldkey={`minorDetails[${index}].relatedPermAddressline3`}
                errorText="Address Line 3 is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={false}
                width="92.5%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.relatedPermAddressline3}
            />
            <SelectInput
                label={"Country"}
                fieldkey={`minorDetails[${index}].relatedPermCountry`}
                errorText="Country is Required"
                placeholder="State"
                control={control}
                register={register}
                mandatory={false}
                setValue={setValue}
                options={dropdownOptions && dropdownOptions["relatedPermCountry"]}
                valueRenderer={(option) => option?.code}
                displayName={(option) => option?.name}
                width="30%"
                watch={watch}
                disabled={true}
            // defaultValue={"IN"}
            // value="IN"
            />
            <SelectInput
                label={"State"}
                fieldkey={`minorDetails[${index}].relatedPermState`}
                errorText="State is Required"
                placeholder="State"
                control={control}
                register={register}
                mandatory={true}
                setValue={setValue}
                options={dropdownOptions && dropdownOptions["relatedPermState"]}
                valueRenderer={(option) => option?.code}
                displayName={(option) => option?.name}
                width="30%"
                watch={watch}
            // onChangeValue={() => handleFieldValue(null,"district")}
            />
            {/* <SelectInput
                label={"District"}
                fieldkey={`minorDetails[${index}].relatedPermDistrict`}
                errorText="District is Required"
                placeholder="State"
                control={control}
                register={register}
                mandatory={true}
                setValue={setValue}
                options={dropdownOptions && dropdownOptions["district"]}
                valueRenderer={(option) => option}
                displayName={(option) => option}
                width="30%"
                watch={watch}
            /> */}
            <TextInput
                label={"District"}
                fieldkey={`minorDetails[${index}].relatedPermDistrict`}
                errorText="District is Required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={false}
                width="30%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.relatedPermDistrict}
            />
            <TextInput
                label={"City/Town/Village"}
                fieldkey={`minorDetails[${index}].relatdPermvillage`}
                errorText="City/Town/Village is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={true}
                width="30%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.relatdPermvillage}
            />
            <TextInput
                label={"PinCode"}
                fieldkey={`minorDetails[${index}].relatedPermPincode`}
                errorText="PinCode is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={true}
                width="30%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.relatedPermPincode}
            />
            {/* <SelectInput
                label={"PinCode"}
                fieldkey={`minorDetails[${index}].relatedPermPincode`}
                errorText="PinCode is Required"
                placeholder="State"
                control={control}
                register={register}
                mandatory={true}
                setValue={setValue}
                options={dropdownOptions && dropdownOptions["pincode"]}
                valueRenderer={(option) => option}
                displayName={(option) => option}
                width="30%"
                watch={watch}
            /> */}
        </Box>
    )
}

export default MinorPermanentAddresss;