import { Box, Button, Card } from "@mui/material";
import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { LoadingButton } from "@mui/lab";

import Modal from "@mui/material/Modal";
import Text from "../../Atoms/Text";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  // bgcolor: "background.paper",
  // border: "2px solid #000",
  // boxShadow: 24,
};

const statuses = {
  audited: { id: 7, title: "Mark as Audited", remarksRequired: true },
  hold: { id: 6, title: "On Hold", remarksRequired: true },
};

const StatusUpdateModal = ({
  toggleDrawer,
  open,
  status,
  updateCkycStatus,
  isLoading,
}) => {
  const [remarks, setRemarks] = useState("");
  return (
    <Modal
      open={open}
      onClose={toggleDrawer}
      // title={statuses[status]?.title || ""}
    >
      <Card sx={style}>
        <Card
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderRadius: 0,
          }}
          style={{ padding: "16px" }}>
          <Text fontSize="16px" semiBold>
            {statuses[status]?.title || ""}
          </Text>
          <HighlightOffIcon onClick={toggleDrawer} sx={{ cursor: "pointer" }} />
        </Card>
        <Box p="16px">
          <Box mb={"16px"}>
            <Text fontSize="16px" mb="8px" mandatory>
              Remarks
            </Text>
            <textarea
              onChange={(e) => setRemarks(e.target.value)}
              value={remarks}
              style={{ width: "100%", height: "100px" }}
            />
          </Box>
          <Box display="flex" justifyContent={"flex-end"}>
            <Button
              variant="outlined"
              size="small"
              onClick={toggleDrawer}
              style={{ marginRight: "12px" }}>
              {"Cancel"}
            </Button>

            <LoadingButton
              loading={isLoading}
              size="small"
              variant="contained"
              color="primary"
              disableElevation
              disabled={
                statuses[status]?.remarksRequired ? remarks.length === 0 : false
              }
              onClick={() => updateCkycStatus(statuses[status]?.id, remarks)}
              style={{
                textTransform: "none",
                marginRight: "10px",
              }}>
              {statuses[status]?.title}
            </LoadingButton>
          </Box>
        </Box>
      </Card>
    </Modal>
  );
};

export default StatusUpdateModal;
