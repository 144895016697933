import React from "react";
import PageHeader from "../components/Molecules/PageHeader";
import { useGlobalContext } from "../context/GlobalContext";
import { Box} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import * as yup from "yup";
import SelectInput from "../components/Form-Components/SelectInput";
import DatePicker from "../components/Form-Components/DatePicker";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useMutation, useQuery } from "@tanstack/react-query";
import { addMISReports } from "../apis/manageClients";
import { getClientsList } from "../apis/manageClients";
import { Parser } from 'json2csv';
import { isSuperAdmin } from "../utils";
import { Snackbar, Alert } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import Backdrop from "../components/Molecules/Backdrop";
const pageTitle = "MIS Reports"


const MISReports = () => {
    const { dispatch } = useGlobalContext();
    const [clientData, setClientData] = React.useState([])
    const [reportType, setReportType] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
    const [alertMessage, setAlertMessage] = React.useState("");
    const [fromDateValue, setFromDateValue] = React.useState(null);
    const [endDateValue, setEndDateValue] = React.useState(null);
    const schema = yup
        .object()
        .shape({
            client: yup.string().required("Required field"),
            report: yup.string().required("Required field"),
            fromDate: yup.date().required("Required field"),
            endDate: yup.date().required("Required field"),
        })
        .required();

    const {
        handleSubmit,
        register,
        reset,
        setValue,
        control,
        watch,
        errors,
        formState,
    } = useForm({
        defaultValues: {
            client: !isSuperAdmin() ? localStorage?.getItem('clientID') : "",
            report: "",
            fromDate: "",
            endDate: "",

        },
        resolver: yupResolver(schema),
    });
    useQuery({
        queryKey: ["clientData"],
        queryFn: () => getClientsList(),
        onSuccess: (response) => {
            let options = [];
            if (response?.data?.items.length > 0) {
                options.push({
                    label: "All Clients",
                    id: -1,
                    onClick: () => ({ clientId: null }),
                });
            }
            response?.data?.items?.forEach((item) => {
                options.push({
                    label: item.clientName,
                    id: item.id,
                    onClick: () => ({ clientId: item.id }),
                });
            });

            setClientData(options);
        },
    });

    const { mutate: addOrUpdateDoc, isLoading: downloadLoding } = useMutation({
        mutationFn: (userData) => addMISReports({ queryKey: userData }),

        onSuccess: (data) => {
            let fields;
            let columnData = []
            setFromDateValue(null);
            setEndDateValue(null);
            const handleDownload = () => {
                if (reportType === "ckyc_status_report") {
                    data?.data?.data?.result?.customer_details?.forEach((item) => {
                        columnData?.push({
                            'Docket ID': item?.docket_no ? item?.docket_no : "-",
                            'Application Reference Number': item?.application_number ? item?.application_number : "-",
                            'Account Number': item?.customer_number ? item?.customer_number : "-",
                            'CKYC/CERSAI Reference Number': item?.fi_reference_ckyc_number ? item?.fi_reference_ckyc_number : "-",
                            'CKYC Status': item?.status_text ? item?.status_text : "-",
                            'Upload Type( Upload/ Update)': item?.account_type ? item?.account_type : "-",
                            'CERSAI Upload Date': item?.created_at ? item?.created_at : "-",
                            'CKYC Error Reason': item?.remarks ? item?.remarks : "-",
                            'Approval Date': item?.remarks_created_at ? item?.remarks_created_at : "-",
                            'CKYC Number': item?.kyc_number ? item?.kyc_number : "-",
                            'Channel/ Promo code': item?.channel_type ? item?.channel_type : "-",
                            'Response Date (Res 0/ Res 1)': "-",
                        });
                    });
                    fields = ['Docket ID', 'Application Reference Number', 'Account Number', 'CKYC/CERSAI Reference Number', 'CKYC Status', 'Upload Type( Upload/ Update)', 'CERSAI Upload Date', 'CKYC Error Reason', 'Approval Date', 'CKYC Number', 'Channel/ Promo code', 'Response Date (Res 0/ Res 1)'];
                } else if (reportType === "ckyc_transaction_report") {
                    columnData?.push({
                        "Total Customers": data?.data?.data?.result?.total_customers,
                        "Search": data?.data?.data?.result?.search_count,
                        "Download": data?.data?.data?.result?.download_count,
                        "New Upload": data?.data?.data?.result?.new_users,
                        "Update": data?.data?.data?.result?.old_users,
                        "SFTP Upload": data?.data?.data?.result?.sftp_count,
                        "Manual Upload": data?.data?.data?.result?.mannual_count,
                    })
                    fields = ['Total Customers', 'Search', 'Download', 'New Upload', 'Update', "SFTP Upload", 'Manual Upload']
                }

                const json2csvParser = new Parser({ fields });
                const csv = json2csvParser.parse(columnData);
                downloadCSV(csv, 'MisReports.csv');

            }
            handleDownload();
            
        },
        onError: (error) => {
            setIsLoading(false);
            setSnackbarSeverity("error")
            setAlertMessage(error?.message)
            setValue("report", '')
            dispatch({
                type: "SET_TOAST",
                payload: {
                  open: true,
                  text: error?.message,
                  severity: "error",
                },
              });
        },
    });
    

    const submit = (data) => {
        setIsLoading(true);
        // setOpenSnackbar(true);
        // setSnackbarSeverity("success")
        // setAlertMessage("Form submitted successfully")
      
        addOrUpdateDoc(data);
        reset();

        dispatch({
            type: "SET_TOAST",
            payload: {
              open: true,
              text: "Form submitted successfully",
              severity: "success",
            },
          });
          
          setValue("report", ""); // Reset "report" field
    };

    const {
        state: { currentUser },
    } = useGlobalContext();
    const [clients] = React.useState([]);


    let currentRole =
        JSON?.parse(window.localStorage.getItem("roles")) ||
        currentUser.role.role_name;

    const leftSideActions = [
        ...[
            currentRole === "SuperAdmin" &&
            clients.length > 0 && {
                options: clients,
                isDropdown: true,
                explicit: true,
                title: "Clients",
                defaultText: clients && clients[0],
            },
        ],
    ];
    function downloadCSV(csvData, fileName) {
        setIsLoading(false);
        const blob = new Blob([csvData], { type: 'text/csv' });
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName);
        } else {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        }
    };
    const fromDate = watch("fromDate");
    console.log("fromdata=====mkil",fromDate)
    return (
        <React.Fragment>
            {/* <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={openSnackbar}
                autoHideDuration={3000} // Hide the snackbar after 3 seconds
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert severity={`${snackbarSeverity}`} onClose={() => setOpenSnackbar(false)}>
                    {alertMessage}
                </Alert>
            </Snackbar> */}
            <PageHeader
                toggleDrawer={null}
                leftSideActions={leftSideActions}
                pageTitle={pageTitle}
                isSearch={false}
            />
            <Box sx={{ width: "100%", paddingLeft: "40px" }}>
                <form onSubmit={handleSubmit(submit)}>
                    <Box display="flex" sx={{ width: "50%", flexWrap: "wrap", flexDirection: "column" }}>
                        {
                            isSuperAdmin() &&
                            <SelectInput
                                label="Client"
                                fieldkey="client"
                                errorText="Required"
                                error={true}
                                placeholder="Select"
                                control={control}
                                register={register}
                                setValue={setValue}
                                options={clientData}
                                valueRenderer={(option) => option?.id}
                                displayName={(option) => option?.label}
                                watch={watch}
                                mandatory
                            />
                        }
                        <SelectInput
                            label="Select Report"
                            fieldkey="report"
                            errorText="Required"
                            error={true}
                            placeholder="Select"
                            control={control}
                            register={register}
                            setValue={setValue}
                            options={isSuperAdmin() ? [{ label: "CKYC Transaction Report", value: "ckyc_transaction_report" }] : [{ label: "CKYC Status Report", value: "ckyc_status_report" }, { label: "CKYC Transaction Report", value: "ckyc_transaction_report" }]}
                            valueRenderer={(option) => option?.value}
                            displayName={(option) => option?.label}
                            watch={watch}
                            mandatory
                            setReportType={setReportType}
                        />
                        
                        <Box sx={{ width: "100%", flexWrap: "wrap", display: "flex", alignItems: "flex-end" }}>
                       
                            <DatePicker
                                label="Date range"
                                fieldkey="fromDate"
                                errorText="Required date"
                                placeholder="select"
                                control={control}
                                register={register}
                                mandatory ={true}
                                width="30%"
                                setValue={setValue}
                                error={formState?.errors["fromDate"]}
                                // isEmpty={true}
                                format={"YYYY-MM-DD"}
                                maxDate={new Date()}
                                value={fromDateValue}
                                setDateValue={setFromDateValue}
                                />

                            <DatePicker
                                width="30%"
                                label=""
                                fieldkey="endDate"
                                errorText="Required date"
                                placeholder="select"
                                control={control}
                                register={register}
                                setValue={setValue}
                                mandatory = {true}
                                error={formState?.errors["endDate"]}
                                format={"YYYY-MM-DD"}
                                // isEmpty={true}
                                 disabled = {!fromDate} 
                                 minDate = {fromDate}
                                maxDate={new Date()}
                                value={endDateValue}
                                setDateValue={setEndDateValue}
                            />

                        </Box>
                    </Box>
                    <LoadingButton
                        type="submit"
                        sx={{
                            border: "1px solid #BFBFBF",
                            background: "#FFFFFF",
                            borderRadius: "6px",
                            display: "flex",
                            marginTop: "20px",
                            color: "#4E4E4E",
                        }}
                        loading={isLoading}
                        loadingPosition="start"
                        startIcon={<FileDownloadOutlinedIcon />}
                        variant="outlined"
                    >
                        Download
                    </LoadingButton>
                </form>
            </Box>
            {/* <Backdrop loading={downloadLoding}/> */}
        </React.Fragment>
    );
};

export default MISReports;