import React, { useState } from "react";
import { Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "@mui/icons-material/Download";
import Text from "../Atoms/Text";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { arrCodes } from "./new-customer/formUtils";
import TabPanel from "@mui/lab/TabPanel";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useMutation, useQuery } from "@tanstack/react-query";
import NewCustomerAccount from "./new-customer/NewCustomerAccount";
// import dayjs from "dayjs";
import RedoIcon from "@mui/icons-material/Redo";
import UndoIcon from "@mui/icons-material/Undo";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import HistoryIcon from "@mui/icons-material/History";

import {
  downloadApi,
  searchCkycUser,
  getCustomer,
  updateCustomer,
  updateStatus,
  fetchImages,
  uploadDocs,
} from "../../apis/newCustomer";
import { useGlobalContext } from "../../context/GlobalContext";
import Modal from "../Molecules/Modal";
import CustomerAccordon from "../Molecules/CustomerAccordon";
import CertsaiSearch from "../Organisms/CersaiSearch";
import MenuInput from "../Molecules/MenuInput";
import NoResults from "../Atoms/NoResults";
import {
  customerDetailsObject,
  formDefaultValues,
  constructRequestObject,
  formatDate,
  // steps,
} from "./new-customer/formUtils";
import Backdrop from "../Molecules/Backdrop";
import { allElements } from "../Organisms/new-customer/formElements";
import { renderCKYCStatus } from "../../../src/utils/tableHeaders/newCustomers";
import StatusUpdateModal from "../Organisms/new-customer/StatusUpdateModal";
import PreviewDetails from "../Organisms/new-customer/PreviewDetails";
import {
  displayUserGeneralDetails,
  headers,
  renderLabel,
  getImageName,
  base64ToFile,
  urltoFile,
} from "../Organisms/new-customer/utils";
// import { turingActive } from "../../assets/icons";
import MismatchModal from "../Molecules/MismatchModal";
import CustomerJoruneyPanel from "../Organisms/CustomerJourneyPanel";
import { displayAadhaar, isChecker } from "../../utils";
import {
  addressDocs,
  applicantDeclaration,
  constructStageFlags,
  currentAddress,
  currentProofOfAddress,
  defaultDocs,
  fieldValues,
  identityDocs,
  kycVerification,
  minorFlow,
  officeUseOnly,
  permanantAddress,
  personalDetails,
  proofOfAddress,
} from "../Organisms/new-customer/formElements";
const compare = (a, b) =>
  Object.entries(a).some(([key, value]) => value === b[key]);

/* 
  There is large room for code refractoring , modularisation and more.
  We had to develop this in quick time .
  We will be refractoring this when we get some bandwidth in sometime.
  --/\/\---
    -  -
     --
*/

/*
 showSearchAndCompareSection:  only displays the preview section
*/

export default function CustomerDetails({
  open,
  closePanel,
  customerId,
  dropdownOptions,
  openUpdateForm,
  displayActionItems = true,
}) {
  const {
    state: { currentUser },
    dispatch,
  } = useGlobalContext();

  const [openNewCustomerModal, setNewCustomerModal] = React.useState(false);
  const [searchDob, setSearchDOB] = React.useState("");
  const [recentlyUpdate, setRecentlyUpdate] = React.useState([]);
  const [currentStep, setCurrentStep] = React.useState(0);
  const [appType, setAppType] = React.useState("01");
  const [ckycImages, setCkycImages] = React.useState([]);
  const [prevCustomerData, setPrevCustomerData] = React.useState({});
  const [prevCustomerFiles, setPrevCustomerFiles] = React.useState({});
  const [statusUpdateModal, toggleStatusUpdateModal] = React.useState({
    status: "",
    open: false,
  });
  const [authTypeSelected, setAuthTypeSelected] = useState({});
  const [authType, setAuthType] = useState("1");

  const [openMismatchModal, setMisMatchModal] = React.useState(false);
  const [openCustomerJourneyPanel, toggleCustomerJourneyPanel] =
    React.useState(false);
  // const [panID, setPanID] = React.useState([])

  // Need to move this to utils
  const skipMismatching = [
    "kycVerifiDate",
    "employeeName",
    "employeeCode",
    "employeeDesignation",
    "employeeBranch",
    "documentRecieved",
    "instCode",
    "instName",
    "remarks",
    "dateOfDeclaration",
    "placeOfDeclaration",
  ];
  let currentRole =
    JSON?.parse(window.localStorage.getItem("roles")) ||
    currentUser.role.role_name;
  currentRole = currentRole.toLowerCase();
  const hideElements = ["addressProofIdNumber"];
  const isChecker = currentRole === "checker";
  const currentCustomerId = customerId;

  const { data: customerInfo, refetch: fetchCustomerDetails } = useQuery(
    ["customerData", { id: currentCustomerId }],
    getCustomer,
    {
      manual: true,
      select: (response) => response?.data,
      onSuccess: (response) => {
        if (response?.dob !== "") {
          setSearchDOB(response.dob);
        }
        getImageFiles();
      },
      enabled: false,
      retry: false,
    }
  );
  const displayRecords = customerInfo
    ? customerDetailsObject(customerInfo, true)
    : [];

  const onCloseNewCustomerAccountForm = () => {
    if (openUpdateForm) closePanel();
    else setNewCustomerModal(false);
  };
  // ckyc number function
  function maskCKYCNumber(inputString) {
    const startIndex =
      inputString.indexOf("CKYC Number") + "CKYC Number".length + 1;
    const endIndex = startIndex + 14;
    const ckycNumber = inputString.slice(startIndex, endIndex);
    const maskedCKYCNumber = "X".repeat(10) + ckycNumber.slice(-4);
    const modifiedString = inputString.slice(0, startIndex) + maskedCKYCNumber;
    return modifiedString;
  }
  const {
    mutate: downloadData,
    isLoading: downoadingData,
    data: ckycData,
  } = useMutation((userData) => downloadApi(userData), {
    onSuccess: (response) => {
      console.log("success response download", response?.message);
      if (response.isError) {
        if (response.message.indexOf("CKYC Number") !== -1) {
          dispatch({
            type: "SET_TOAST",
            payload: {
              open: true,
              text: maskCKYCNumber(response.message),
              severity: "error",
            },
          });
        } else {
          dispatch({
            type: "SET_TOAST",
            payload: {
              open: true,
              text: response.message,
              severity: "error",
            },
          });
        }
        setAuthTypeSelected((prev) => ({
          ...prev,
          [prev.selectedAuthType]: true,
        }));
      }
    },
  });

  const ckycImageDetails =
    ckycData?.data?.PID_DATA?.IMAGE_DETAILS?.length > 0
      ? ckycData?.data?.PID_DATA?.IMAGE_DETAILS[0].IMAGE
      : [];
  const displayDetails = ckycData
    ? customerDetailsObject(ckycData?.data?.PID_DATA)
    : [];
  const showNewSection =
    (currentRole === "checker" && customerInfo?.applicationType === "01") ||
    (currentRole === "checker" && displayDetails?.length === 0);
  const showSearchAndCompareSection =
    currentRole === "maker" ||
    (currentRole === "checker" &&
      customerInfo?.applicationType === "03" &&
      displayDetails?.length > 0 &&
      displayActionItems);
  const { mutate: updateCkycStatus, isLoading: updatingStatus } = useMutation(
    (userData) => updateStatus(userData),
    {
      onSuccess: (response) => {
        if (response.isError) {
          dispatch({
            type: "SET_TOAST",
            payload: {
              open: true,
              text: response.message,
              severity: "error",
            },
          });
        } else {
          toggleStatusUpdateModal({ status: "", open: false });

          dispatch({
            type: "SET_TOAST",
            payload: {
              open: true,
              text: "Status updated Successfully",
              severity: "success",
            },
          });
          setRecentlyUpdate([]);
          closePanel();
        }
      },
    }
  );

  const {
    mutate: searchUser,
    data: searchData,
    isLoading: fetchingData,
  } = useMutation((userData) => searchCkycUser(userData, customerId), {
    select: (response) => response.data,
    onSuccess: (response) => {
      if (response.isError && currentRole !== "checker") {
        console.log("success response search", response?.message);
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.message,
            severity: "error",
          },
        });
      }

      if (response?.data?.length !== 0) {
        const test = {
          ckycnumber: response?.data?.ckycnumber,
          authtype: authType,
          authfactor:
            authType === "1"
              ? (searchDob != "" && searchDob) || customerInfo?.dob
              : authType === "2"
              ? customerInfo?.address?.["current/permenantAddress"]?.PERM_PIN +
                ((searchDob != "" && searchDob) || customerInfo?.dob)?.split(
                  "-"
                )?.[2]
              : customerInfo?.MOB_NUM,
          rrn: response?.data?.rrn || "2",
        };
        if (!isChecker) {
          downloadData({
            search: test,
            params: {
              customer_id: customerId,
              client_id: JSON.parse(localStorage.getItem("cliendid")),
              job_id: response?.data?.logId,
            },
          });
        }
      }
    },
    //mutationKey :["customerData",{id:customerId}]
  });

  const { mutate: updateCkycUser, isLoading: updtatingUser } = useMutation(
    (userData) => updateCustomer(userData),
    {
      onSuccess: (response) => {
        if (response.isError) {
          dispatch({
            type: "SET_TOAST",
            payload: {
              open: true,
              text: response.message,
              severity: "error",
            },
          });
        } else {
          dispatch({
            type: "SET_TOAST",
            payload: {
              open: true,
              text: response.message,
              severity: "success",
            },
          });
          fetchCustomerDetails();
        }
      },
      onError: (error) => {},
    }
  );

  const { data: images, refetch: getImageFiles } = useQuery(
    ["images", { customerId: customerId }],
    fetchImages,
    {
      select: (response) => response.data,
      manual: true,
      enabled: false,
    }
  );

  const {
    mutate: uploadDocuments,
    isLoading: uploadingDocs,
    // isSuccess: stepUpdated,
  } = useMutation((userData) => uploadDocs(userData), {
    onSuccess: (response) => {
      if (response.isError) {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.message,
            severity: "error",
          },
        });
      } else {
        fetchCustomerDetails();
        // handleNext();
      }
    },
    onError: (error) => {},
  });

  const getText = (selected, label, isMisMatched) => {
    let value = label;
    const element = getElement(selected);
    if (!element) return "-";
    const dropdown = dropdownOptions[element.key] || element?.options;

    if (dropdownOptions && customerInfo && element) {
      if (element.type === "dropdown" && dropdown) {
        const dropdownValue = dropdown.find((item) =>
          [item?.key, `${item?.id}`, `${item?.code}`, item?.name].includes(
            `${label}`
          )
        );
        value =
          (dropdownValue && dropdownValue?.name) ||
          dropdownValue?.type ||
          label ||
          "-";
      } else {
        value = label;
      }
    }
    if (element?.type === "date") {
      // There is issues related to date format. DatePicker giving the mm-dd-yyy and accepting same
      // so had to format across apps
      if (typeof label === "object") {
        value = "";
      } else if (recentlyUpdate.includes(element.key) || !isMisMatched) {
        value =
          typeof label === "object"
            ? ""
            : label === "" || label.includes("*")
            ? value
            : label;
      }
    }
    if(element?.key === "nationality"){
      const dropdown = dropdownOptions["country"]?.find((item, index) => item?.code === label)
      value = dropdown?.name
    }
    if (value === "") return "-";
    return value;
  };

  const getElement = (selected) =>
    allElements.find((item) => item.key === selected.key);

  const getAadharNumber = () => {
    let identityDetails = ckycData?.data?.PID_DATA?.IDENTITY_DETAILS;
    identityDetails =
      identityDetails &&
      identityDetails[0] &&
      identityDetails[0]?.IDENTITY &&
      identityDetails[0].IDENTITY.length > 0
        ? identityDetails[0].IDENTITY
        : [];

    // const ckycAadhar = identityDetails
    //   ? identityDetails.find((item) => item.IDENT_TYPE[0] === "E")?.IDENT_NUM[0]
    //   : "-";
    const ckycAadhar = identityDetails
  ? identityDetails.find((item) => item.IDENT_TYPE[0] === "E")?.IDENT_NUM[0]
  : "-".trim();

    return ckycAadhar;

  };


  const constructCkycObject = (values, ckycFound) => {
    const ckycArray = values.flat();
    let newObject = {};
    ckycArray.forEach((x) => {
      if (ckycFound) {
        newObject[x.key] = mappedCkycRecords[x.key];
      } else if (x.key === "pesonalAadhar")
        newObject[x.key] = getAadharNumber();
      else newObject[x.key] = x.value;
    });
    return newObject;
  };

  const mappedRecords = customerInfo ? formDefaultValues(customerInfo) : {};
  const mappedCkycRecords = constructCkycObject(displayDetails);
  const isDataMismatched = compare(mappedRecords, mappedCkycRecords);
  const userGeneralDetails = displayUserGeneralDetails(
    customerInfo,
    renderCKYCStatus
  );
  const disableNextButton =
    customerInfo?.job_type === "AUTOMATION" && customerInfo?.count < 2;

  const isElementEqual = (key, equal = true) => {
    if (key === "pesonalAadhar") {
      
      return !(
        mappedCkycRecords[key] == "-" ||
        mappedRecords[key] == "-" ||
        mappedCkycRecords[key]?.substr(-4) === mappedRecords[key]?.substr(-4)
      );
    } else if (
      typeof mappedCkycRecords[key] === "string" &&
      typeof mappedRecords[key] === "string"
    ) {
      const value =
        mappedCkycRecords[key]?.toLowerCase()?.trim() ===
        mappedRecords[key]?.toLowerCase()?.trim();
      // if (key === "personalGender") {
      // 	debugger;
      // }
      return equal ? value : !value;
    } else {
      return mappedCkycRecords[key] === mappedRecords[key];
    }
  };

  const matchingConditions = {
    pesonalAadhar: {
      condition: (key) =>
        mappedCkycRecords[key] == "-" ||
        mappedRecords[key] == "-" ||
        mappedCkycRecords[key]?.substr(-4) === mappedRecords[key]?.substr(-4),
    },
    default: {
      condition: (key) =>
        skipMismatching.includes(key) ||
        mappedRecords[key] == "" ||
        !mappedRecords[key] ||
        mappedCkycRecords[key] == "" ||
        !mappedCkycRecords[key] ||
        isElementEqual(key),
    },
  };

  const showMissMatchingKey = (key) => {
    return (
      (matchingConditions[key]
        ? matchingConditions[key].condition(key)
        : matchingConditions["default"].condition(key)) &&
      (recentlyUpdate ? recentlyUpdate !== key : true)
    );
  };

  const getMappedTextValue = (element, values, isMatched = false) =>
    getText(element, (element.key && values[element.key]) || "", isMatched);

  // Need to move the all the code related
  // to comparision into other util module
  const getMismatchedValues = () => {
    let values = [];
    if (displayRecords?.length == 0 || !mappedCkycRecords || !mappedRecords)
      return [];
    headers.forEach((item, index) => {
      let misMatched =
        displayRecords[index] &&
        displayRecords[index]
          .filter((element) =>
            !skipMismatching.includes(element.key) &&
            mappedRecords[element.key] &&
            mappedRecords[element.key] !== "" &&
            mappedCkycRecords &&
            mappedCkycRecords[element.key] !== "" &&
            isElementEqual(element.key, false)
              ? element
              : null
          )
          .filter((i) => i);

      if (misMatched.length > 0) {
        values.push({ label: item, values: misMatched });
      }
    });
    return values;
  };
  const misMatchedValues = getMismatchedValues();

  const onUpdateCustomer = async (key, value, handleClose) => {
    let values = formDefaultValues(customerInfo);
    values[key] = value;
    const requestObject = constructRequestObject(values);
    updateCkycUser(requestObject);
    setRecentlyUpdate([...recentlyUpdate, key]);
    handleClose();
  };

  const getFileName = (item) => {
    const addressFiles = dropdownOptions["addressProof"];
    const identitiyProofs = dropdownOptions["identityProof"] || [];
    const files = [...addressFiles, ...identitiyProofs];
    const file = files.find(
      (i) =>
        parseInt(i.documentCode) === parseInt(item.documentType) ||
        parseInt(i.documentCode) === parseInt(item.documentCode) ||
        parseInt(i.documentCode) === parseInt(item.docCode)
    );
    if (file) {
      {
        return file.documentName;
      }
    } else {
      return "";
    }
  };

  const updateCersai = () => {
    if (misMatchedValues?.length === 0) {
      continueAfterMismatch();
    } else setMisMatchModal(true);
  };

  React.useEffect(() => {
    if (customerId) {
      (async () => {
        await fetchCustomerDetails();
        await getImageFiles();
      })();
    }
  }, [customerId]);

  const getApplicationType = (info) => {
    const appType = info?.CKYC_NO ? "03" : "01";
    return appType;
  };

  React.useEffect(() => {
    if (customerInfo) {
      setAppType(getApplicationType(customerInfo));
      const test = {
        ckycnumber: customerInfo?.CKYC_NO,
        authtype: "1",
        authfactor: (searchDob != "" && searchDob) || customerInfo?.dob,
        rrn: "-1",
      };
      if (
        !isChecker &&
        customerInfo?.CKYC_NO &&
        showSearchAndCompareSection &&
        !ckycData?.data?.PID_DATA &&
        (customerInfo?.status === "In Progress" ||
          customerInfo?.status_remark === "Download found")
      ) {
        downloadData({
          search: test,
          params: {
            customer_id: customerInfo?.customerId,
            client_id: JSON.parse(localStorage.getItem("cliendid")),
            job_id: "1",
          },
        });
      }
    }
  }, [customerInfo]);

  React.useEffect(() => {
    if (!displayActionItems) {
      return;
    }
    if (customerInfo && openUpdateForm) {
      setNewCustomerModal(true);
      return;
    }
    if (
      customerInfo &&
      customerInfo.applicationType === "03" &&
      currentRole === "checker" &&
      !openUpdateForm
    ) {
      const object = {
        // pannumber: window.btoa(customerInfo?.pan),
        // rrn: "20220512172131275",
        idtype: "PAN",
        idNumber: window.btoa(customerInfo?.pan),
      };
      searchUser({ search: object });
    }
  }, [customerInfo]);

  React.useEffect(() => {
    if (ckycData) {
      (async () => {
        const images = ckycData?.data?.PID_DATA?.IMAGE_DETAILS?.[0]?.IMAGE;
        images?.forEach(async (item) => {
          try {
            let res = await fetch(
              `data:image/${item.IMAGE_TYPE[0]};base64,${item.IMAGE_DATA[0]}`
            );
            res = await res.blob();
            const buf = await res.arrayBuffer();
            const file = new File([buf], "image", {
              type: `image/jpeg`,
            });
            let objectURL = URL.createObjectURL(res);
            setCkycImages([...ckycImages, objectURL]);
          } catch (e) {}
        });
      })();
    }
  }, [ckycData]);

  const actionButtons = {
    maker: [
      {
        title: "Update Cersai",
        onClick: () => updateCersai(),
        disabled:
          recentlyUpdate?.length === 0 &&
          Object.keys(prevCustomerData).length === 0 &&
          Object.keys(prevCustomerFiles).length == 0,
      },
      {
        title: "CKYC Found",
        onClick: () => onCKYCFound(),
        disabled:
          recentlyUpdate?.length !== 0 ||
          Object.keys(prevCustomerData).length !== 0 ||
          Object.keys(prevCustomerFiles).length !== 0,
      },
    ],
    checker: [
      {
        title: "On Hold",
        onClick: () => toggleStatusUpdateModal({ status: "hold", open: true }),
        disabled: false,
        displayDetails,
      },
      ...(customerInfo?.status !== "Audited"
        ? [
            {
              title: "Audited",
              onClick: () =>
                toggleStatusUpdateModal({ status: "audited", open: true }),
              disabled: false,
            },
          ]
        : []),
    ],
  };

  const syncSection = (index) => {
    let currentRecordsValues = mappedRecords;
    let newPrev = displayDetails[index];
    newPrev = newPrev.map((item) => ({
      ...item,
      value: mappedRecords[item.key],
    }));
    setPrevCustomerData({
      ...prevCustomerData,
      ...{ [index]: newPrev },
    });
    displayDetails[index]?.map((item) =>{
      if (
        mappedCkycRecords[item.key] &&
        (!currentRecordsValues[item.key] ||
          ["", null, undefined, "-"].includes(currentRecordsValues[item.key]))
      ) {
        currentRecordsValues[item.key] = item.value;
      }
    });
    currentRecordsValues["applicationType"] = "01";
    if (currentRecordsValues["kycNumber"]) {
      currentRecordsValues["applicationType"] = "03";
    }
    const requestObject = constructRequestObject(currentRecordsValues);
    // console.log(mappedCkycRecords, requestObject);
    updateCkycUser(requestObject);
  };

  const unsyncSection = (index) => {
    let newRequest = mappedRecords;
    prevCustomerData[`${index}`] &&
      prevCustomerData[`${index}`].map((item) => {
        newRequest[item.key] = item.value;
      });
    const requestObject = constructRequestObject(newRequest);
    updateCkycUser(requestObject);
    let newPrev = {};
    prevCustomerData &&
      Object.keys(prevCustomerData).map((key) => {
        if (!prevCustomerData[`${key}`]) {
          newPrev[key] = prevCustomerData[key];
        }
      });
    setPrevCustomerData(newPrev);
  };

  const urltoFile = async (url, filename, mimeType) => {
    try {
      let res = await fetch(url);
      res = await res.arrayBuffer();
      const file = new File([res], filename, { type: mimeType });
      return file;
      // fileDownload(file, filename, mimeType);
    } catch (e) {
      //handle error
    }
  };

  // push to utils
  const getDoc = (dropdownOptions, code) => {
    const docs = [
      ...dropdownOptions["identityProof"],
      ...dropdownOptions["addressProof"],
    ];

    const doc = docs.find(
      (item) =>
        item.documentCode === code ||
        parseInt(item.documentCode) === parseInt(code)
    );
    // const doc =  allDocs.filter((item) => !item.documentName && item.documentCode === code);
    return doc;
  };

  const syncFiles = async () => {
    let formData = new FormData();
    let filesData = [];
    let docs = [];
    await Promise.all(
      ckycImageDetails.map(async (item) => {
        const fileData = getDoc(dropdownOptions, item?.IMAGE_CODE[0]);
        const name = `${customerInfo.FNAME}_${
          fileData?.documentName?.toLowerCase().split(" ")[0] || ""
        }.${item?.IMAGE_TYPE[0]}`;
        const file = await urltoFile(
          `data:image/${item.IMAGE_TYPE[0]};base64,${item?.IMAGE_DATA[0]}`,
          `${name}`,
          `image/${item?.IMAGE_TYPE[0]}`
        );

        const fileExist = images.find(
          (item) => parseInt(item.docCode) === parseInt(fileData?.documentCode)
        );
        // const excludeids =filter((items)=> !arrCodes?.includes(items?.id));
        if (fileData && !fileExist) {
          if (file) {
            docs.push(file);
          }
          filesData.push({
            id: fileExist ? fileExist.id : "null",
            documentType: fileData?.documentCode,
            number: fileExist?.number || "",
            fileName: name,
            remarks: fileExist?.remarks || "",
            docCode: fileData?.documentCode,
            docCateg: ["signature", "photograph", ...arrCodes].includes(
              fileData?.documentName?.toLowerCase()
            )
              ? "common"
              : ["signature", "photograph", ...arrCodes].includes(
                fileData?.name?.toLowerCase()
              ) ? "common": "identity",
          });
          //  }
        }
      })
    );
    docs.forEach((item, i) => {
      formData.append(`files`, item);
    });
    formData.append("filesData", JSON.stringify(filesData));
    formData.append("customerId", customerInfo.customerId);
    uploadDocuments(formData);
    setPrevCustomerFiles({ files: { files: docs, filesData } });
  };

  const onCKYCFound = async () => {
    const ckycValues = constructCkycObject(displayDetails, true);
    ckycValues["applicationType"] = "03";
    ckycValues["officialTel"] = mappedCkycRecords["officialTel"];
    const reqObj = constructRequestObject(ckycValues, customerId);
    console.log(reqObj);
    updateCkycUser(reqObj);
    await syncFiles();
    updateCkycStatus({ customerId: customerId, status: 5 });
  };

  const continueAfterMismatch = () => {
    setAppType(getApplicationType(customerInfo));
    setCurrentStep(0);
    setNewCustomerModal(true);
  };

  /* 
    Currently remarks banner is showing for preview details view only
  */

    const steps = [
      {
        label: "For office use only",
        formElements: officeUseOnly,
        ...(mappedRecords?.accountType === "05"
          ? {
              displayName: "For office use only",
              subDivs: {
                label: "Related Person Details",
                formElements: minorFlow,
              },
            }
          : {}),
      },
      {
        label: "Personal Details",
        formElements: personalDetails,
      },
      {
        label: "Proof of Address",
        formElements: proofOfAddress,
        displayName: "Permanent Address",
        subDivs: {
          label: "Current Address",
          formElements: currentProofOfAddress,
        },
      },
      // {
      // 	label: "FATCA/CRS Details",
      // 	formElements: fatca,
      // },
      {
        label: "KYC Verification",
        formElements: kycVerification,
        subDivs: {
          label: "Applicant Declaration",
          formElements: applicantDeclaration,
        },
      },
      {
        label: "Upload",
        component: "",
      },
      {
        label: "Preview",
        component: "",
      },
    ];
  return (
    <Modal
      title={"Customer Details"}
      fullWidth
      open={open}
      infoBanner={displayActionItems}
      bannerText={
        displayActionItems &&
        customerInfo?.remarksList &&
        customerInfo?.remarksList[0]?.remarks
      }
      toggleDrawer={closePanel}
    >
      <Box p="32px" pb="0">
        <Box
          style={{
            padding: "24px 24px",
            background: "#FBFBFF",
            borderRadius: "6px",
          }}
        >
          {customerInfo && (
            <Box>
              <Box display="flex" width="100%" justifyContent={"space-between"}>
                <Box>
                  <Text fontWeight="700" fontSize="16px">
                    {customerInfo
                      ? `${customerInfo?.FNAME ? customerInfo?.FNAME : ""} ${
                          customerInfo?.LNAME ? customerInfo?.LNAME : ""
                        }`
                      : ""}
                  </Text>
                  <hr style={{ background: "#5B58FF", marginTop: "4px" }} />
                </Box>
                {(customerInfo.status !== "Pending Mapping" ||
                  customerInfo.error ||
                  customerInfo.job_status ||
                  customerInfo.job_error) && (
                  <Box
                    style={{ cursor: "pointer" }}
                    onClick={() => toggleCustomerJourneyPanel(true)}
                  >
                    <Box display="flex" justifyContent="center">
                      <HistoryIcon
                        sx={{
                          marginRight: "2px",
                          cursor: "pointer",
                          color: "#5B58FF",
                          fontSize: "16px",
                          marginTop: "2px",
                        }}
                      />
                      <Box>
                        <Text fontWeight="700" fontSize="16px" color="#5B58FF">
                          History
                        </Text>
                        <hr
                          style={{ background: "#5B58FF", marginTop: "4px" }}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
                {/* <EditIcon
									sx={{ marginLeft: "16px", cursor: "pointer" }}
									onClick={() => {
										setAppType("update");
										setNewCustomerModal(!openNewCustomerModal);
									}}
								/> */}
              </Box>
            </Box>
          )}
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent={"space-between"}
            flexDirection={"row"}
          >
            {userGeneralDetails.map((item, index) => {
              return (
                <Box mt={2} width="20%">
                  <Text
                    fontSize="16px"
                    color="#494E59"
                    style={{ opacity: "50%" }}
                  >
                    {item.label}
                  </Text>
                  <Text mt={"6px"} fontSize="14px">
                    {item?.key === "pesonalAadhar" ? item?.value ? displayAadhaar(item?.value) : displayAadhaar(mappedRecords[item.key]) : item?.value ?? mappedRecords[item.key] ?? ""}
                  </Text>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      {showSearchAndCompareSection && (
        <Box p="32px" pb="0">
          {currentRole !== "checker" && customerInfo && (
            <Box my={"20px"}>
              <CustomerAccordon title="CERSAI Search">
                <CertsaiSearch
                  searchDob={customerInfo?.dob}
                  panNumber={customerInfo?.pan}
                  customerInfo={customerInfo}
                  onSearch={searchUser}
                  setSearchDOB={(dob) => setSearchDOB(dob)}
                  searchData={searchData}
                  fetchingData={fetchingData || downoadingData}
                  hasCkycNo={customerInfo?.CKYC_NO}
                  authTypeSelected={authTypeSelected}
                  setAuthTypeSelected={setAuthTypeSelected}
                  setAuthType={setAuthType}
                  mobileNumber={customerInfo?.MOB_NUM}
                  pincode={customerInfo?.address}
                  downloadData={(data) => {
                    const test = {
                      ckycnumber: customerInfo?.CKYC_NO,
                      authtype: data.authType,
                      authfactor:
                        data.authType === "1"
                          ? data.dateOfBirth
                          : data.authType === "2"
                          ? data.pincode + data.dateOfBirth?.split("-")?.[2]
                          : data.mobile,
                      rrn: "-1",
                    };
                    if (!isChecker) {
                      downloadData({
                        search: test,
                        params: {
                          customer_id: customerId,
                          client_id: JSON.parse(
                            localStorage.getItem("cliendid")
                          ),
                          job_id: "2",
                        },
                      });
                    }
                  }}
                />
              </CustomerAccordon>
            </Box>
          )}
          {((searchData && searchData.data?.length == 0) ||
            (displayDetails?.length > 0 &&
              isDataMismatched &&
              misMatchedValues?.length > 0)) && (
            <Box
              my="20px"
              style={{ border: "1px solid #D6D5FC", borderRadius: "6px" }}
            >
              <TabContext value={"1"}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    textColor="#EE8E53"
                    sx={{
                      "& .MuiTabs-indicator": {
                        backgroundColor: "#EE8E53",
                      },
                    }}
                  >
                    <Tab
                      sx={{ textTransform: "none", color: "#EE8E53" }}
                      label={
                        searchData && searchData.data?.length === 0
                          ? "CERSAI Result"
                          : "CERSAI Data Mismatch"
                      }
                      value="1"
                    />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  {searchData && searchData.data?.length === 0 ? (
                    <NoResults
                      openNewCKYCForm={() => {
                        setAppType("01");
                        setNewCustomerModal(true);
                      }}
                    />
                  ) : (
                    <Box
                      display="flex"
                      flexDirection="row"
                      flexWrap="wrap"
                      justifyContent={"space-between"}
                    >
                      {misMatchedValues.map((item, index) => {
                        return (
                          <Box
                            display="flex"
                            flexDirection={"row"}
                            flexWrap="wrap"
                          >
                            <Box>
                              <Text
                                mb="12px"
                                mt="8px"
                                fontSize="16px"
                                fontWeight="500"
                                color="#EE8E53"
                              >
                                {item.label}
                              </Text>
                              {item.values.map((element) => {
                                return (
                                  <Box display="flex" flexDirection={"row"}>
                                    <WarningAmberIcon
                                      sx={{
                                        color: "#EE8E53",
                                        fontSize: "16px",
                                      }}
                                    />
                                    <Text ml="8px" mb={"8px"} fontSize="14px">
                                      {`${element.label} is not matched`}
                                    </Text>
                                  </Box>
                                );
                              })}
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                </TabPanel>
              </TabContext>
            </Box>
          )}
          {searchData?.data?.length !== 0 && (
            <Box my={"20px"}>
              {displayDetails?.length > 0 &&
                headers.map((item, index) => {
                  const showWarningIcon = misMatchedValues.find(
                    (i) => i.label === item
                  );
                  return (
                    <Box my={"20px"}>
                      <CustomerAccordon title={item} icon={showWarningIcon}>
                        <Box display="flex" justifyContent={"space-between"}>
                          <Box mt="8px" width="47%">
                            <Box display="flex" alignItems="center">
                              <Text
                                mb={"16px"}
                                mr="16px"
                                fontSize="14px"
                                fontWeight="500"
                                semBold
                              >
                                CKYC Details
                              </Text>
                              <Box
                                py="4px"
                                px="10px"
                                mb="12px"
                                style={{
                                  backgroundColor: "#E4EFE0",
                                  borderRadius: "3px",
                                }}
                              >
                                <Text color="#008F28" fontSize="10px">
                                  CERSAI
                                </Text>
                              </Box>
                            </Box>

                            <Box
                              display="flex"
                              flexWrap="wrap"
                              // justifyContent={"center"}
                              style={{
                                border: "1px solid #D6D5FC",
                                borderRadius: "8px",
                                background: "#F2F2F2",
                              }}
                              px="24px"
                              pt={"16px"}
                            >
                              {displayDetails[index].map((element) => {
                                if (hideElements.includes(element.key))
                                  return null;
                                return (
                                  <Box
                                    width="33%"
                                    display="flex"
                                    flexDirection="column"
                                    alignContent={"center"}
                                    justifyContent="flex-start"
                                    mt={"12px"}
                                    mb={"16px"}
                                  >
                                    <Text fontSize="14px" color="#6C6C6C">
                                      {renderLabel(
                                        element,
                                        dropdownOptions,
                                        mappedCkycRecords
                                      )}
                                    </Text>
                                    <Text
                                      mt="8px"
                                      semBold
                                      fontWeight="600"
                                      fontSize="14px"
                                      mr="2px"
                                      style={{ wordBreak: "break-word" }}
                                      color="#6C6C6C"
                                    >
                                      {getMappedTextValue(
                                        element,
                                        mappedCkycRecords
                                      )}
                                    </Text>
                                  </Box>
                                );
                              })}
                            </Box>
                          </Box>
                          {!isChecker && (
                            <Box
                              mt={"8px"}
                              width={"4%"}
                              display="flex"
                              flexDirection={"column"}
                              justifyContent={"center"}
                              alignItems="center"
                            >
                              {![
                                "KYC Verification",
                                "Applicant Declaration",
                              ].includes(item) && (
                                <React.Fragment>
                                  {!prevCustomerData[index] ? (
                                    <Tooltip
                                      title="Sync"
                                      onClick={() => syncSection(index)}
                                    >
                                      <IconButton>
                                        <RedoIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      title="Undo"
                                      onClick={() => unsyncSection(index)}
                                    >
                                      <IconButton>
                                        <UndoIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </React.Fragment>
                              )}

                              {/* <Tooltip
															title="Sync"
															onClick={() => syncSection(index)}>
															<IconButton>
																<RedoIcon />
															</IconButton>
														</Tooltip> */}
                            </Box>
                          )}
                          <Box mt="8px" width="47%">
                            <Text
                              mb={"16px"}
                              fontSize="14px"
                              fontWeight="500"
                              semBold
                            >
                              Track from Records
                            </Text>
                            <Box
                              display="flex"
                              flexWrap="wrap"
                              // justifyContent={"center"}
                              style={{
                                border: "1px solid #D6D5FC",
                                borderRadius: "8px",
                              }}
                              px="24px"
                              pt={"16px"}
                            >
                              {displayDetails[index].map((element) => {
                                if (hideElements.includes(element.key))
                                  return null;
                                return (
                                  <Box
                                    width="33%"
                                    display="flex"
                                    flexDirection="column"
                                    alignContent={"center"}
                                    justifyContent="flex-start"
                                    mt={"12px"}
                                    mb={"16px"}
                                  >
                                    <Text fontSize="14px" color="#6C6C6C">
                                      {renderLabel(
                                        element,
                                        dropdownOptions,
                                        mappedRecords
                                      )}
                                    </Text>
                                    {showMissMatchingKey(element.key) ? (
                                      <Box
                                        display="flex"
                                        width="90%"
                                        height="18px"
                                        mt={"8px"}
                                        mb={"16px"}
                                        flexWrap="wrap"
                                      >
                                        <Text
                                          mt="8px"
                                          semBold
                                          fontWeight="600"
                                          fontSize="14px"
                                          mr="2px"
                                          style={{ wordBreak: "break-word" }}
                                          color="#6C6C6C"
                                        >
                                          {getMappedTextValue(
                                            element,
                                            mappedRecords
                                          )}
                                        </Text>
                                      </Box>
                                    ) : (
                                      <Box
                                        width="90%"
                                        display="flex"
                                        flexDirection="column"
                                        alignContent={"center"}
                                        justifyContent="center"
                                        mt={"8px"}
                                        mb={"16px"}
                                      >
                                        <MenuInput
                                          dropdownOptions={dropdownOptions}
                                          label={element.label}
                                          ckycValue={getMappedTextValue(
                                            element,
                                            mappedCkycRecords
                                          )}
                                          bankRecordsValue={getMappedTextValue(
                                            element,
                                            mappedRecords,
                                            true
                                          )}
                                          rawValue={mappedRecords[element.key]}
                                          rawCKYCValue={
                                            mappedCkycRecords[element.key]
                                          }
                                          mappedValue={getMappedTextValue(
                                            element,
                                            mappedRecords
                                          )}
                                          element={getElement(element)}
                                          recentlyUpdate={recentlyUpdate}
                                          labelKey={element.key}
                                          onUpdate={onUpdateCustomer}
                                          isChecker={currentRole === "checker"}
                                        />
                                      </Box>
                                    )}
                                  </Box>
                                );
                              })}
                            </Box>
                          </Box>
                        </Box>
                      </CustomerAccordon>
                    </Box>
                  );
                })}
              {displayDetails?.length > 0 && (
                <Box my={"20px"}>
                  <CustomerAccordon title={"Upload"} icon={false}>
                    <Box display="flex" justifyContent={"space-between"}>
                      <Box mt="8px" width="47%">
                        <Box display="flex" alignItems="center">
                          <Text
                            mb={"16px"}
                            mr="16px"
                            fontSize="14px"
                            fontWeight="500"
                            semBold
                          >
                            CKYC Details
                          </Text>
                          <Box
                            py="4px"
                            px="10px"
                            mb="12px"
                            style={{
                              backgroundColor: "#E4EFE0",
                              borderRadius: "3px",
                            }}
                          >
                            <Text color="#008F28" fontSize="10px">
                              CERSAI
                            </Text>
                          </Box>
                        </Box>

                        <Box
                          display="flex"
                          flexWrap="wrap"
                          // justifyContent={"center"}
                          style={{
                            border: "1px solid #D6D5FC",
                            borderRadius: "8px",
                            background: "#F2F2F2",
                          }}
                        >
                          {ckycImages.length > 0 &&
                            ckycImageDetails.map((item, index) => {
                              return (
                                <Box
                                  my="20px"
                                  mx="16px"
                                  display="flex"
                                  flexDirection="row"
                                  flexWrap={"column"}
                                >
                                  <Box>
                                    <Text>
                                      {getDoc(
                                        dropdownOptions,
                                        item?.IMAGE_CODE[0]
                                      )
                                        ? getDoc(
                                            dropdownOptions,
                                            item?.IMAGE_CODE[0]
                                          ).documentName
                                        : ""}
                                    </Text>
                                    <Text
                                      color="#5C94F6"
                                      fontSize="14px"
                                      mt="8px"
                                      mb="16px"
                                    ></Text>
                                    <Box
                                      width="160px"
                                      height="160px"
                                      sx={{
                                        backgroundColor: "rgba(0, 0, 0, 0.06)",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      {!["tif", "tif", "tiff"].includes(
                                        item?.IMAGE_TYPE[0]?.toLowerCase()
                                      ) ? (
                                        <img
                                          width="160px"
                                          height="160px"
                                          src={`data:image/${item.IMAGE_TYPE[0]};base64,${item.IMAGE_DATA[0]}`}
                                          alt="icon-thumbnail"
                                        />
                                      ) : (
                                        <React.Fragment>
                                          <Text
                                            fontSize="12px"
                                            style={{ padding: "12px" }}
                                          >
                                            Please download the image to preview
                                          </Text>
                                          <Box
                                            display="flex"
                                            justifyContent={"center"}
                                            alignItems="start"
                                            width="160px"
                                            height="160px"
                                            flexWrap="wrap"
                                          >
                                            <Box
                                              display="flex"
                                              alignItems="center"
                                              flexDirection={"column"}
                                            >
                                              <DownloadIcon
                                                color="primary"
                                                sx={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  urltoFile(
                                                    `data:image/${item.IMAGE_TYPE[0]};base64,${item.IMAGE_DATA[0]}`,
                                                    `untitled.${item.IMAGE_TYPE[0]}`,
                                                    `image/${item.IMAGE_TYPE[0]}`
                                                  )
                                                }
                                              />
                                              {/* Download and View */}
                                            </Box>
                                          </Box>
                                        </React.Fragment>
                                      )}
                                    </Box>
                                  </Box>
                                </Box>
                              );
                            })}
                        </Box>
                      </Box>
                      {!isChecker && (
                        <Box
                          mt={"8px"}
                          width={"4%"}
                          display="flex"
                          flexDirection={"column"}
                          justifyContent={"center"}
                          alignItems="center"
                        >
                          {/* {!prevCustomerData[`files`] ? (
													<Tooltip title="Sync" onClick={() => syncFiles()}>
														<IconButton>
															<RedoIcon />
														</IconButton>
													</Tooltip>
												) : (
													<Tooltip title="Undo" onClick={() => unsyncFiles()}>
														<IconButton>
															<UndoIcon />
														</IconButton>
													</Tooltip>
												)} */}
                          <Tooltip title="Sync" onClick={() => syncFiles()}>
                            <IconButton>
                              <RedoIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                      <Box mt="8px" width="47%">
                        <Text
                          mb={"16px"}
                          fontSize="14px"
                          fontWeight="500"
                          semBold
                        >
                          Track from Records
                        </Text>
                        <Box
                          display="flex"
                          flexWrap="wrap"
                          // justifyContent={"center"}
                          style={{
                            border: "1px solid #D6D5FC",
                            borderRadius: "8px",
                            background: "#F2F2F2",
                          }}
                        >
                          {images &&
                            images.map((item) => {
                              return (
                                <Box
                                  my="20px"
                                  mx="16px"
                                  width="160px"
                                  display="flex"
                                  flexDirection="row"
                                  flexWrap={"column"}
                                >
                                  <Box>
                                    <Text>{getFileName(item)}</Text>
                                    <Text
                                      color="#5C94F6"
                                      fontSize="14px"
                                      mt="8px"
                                      cursor="pointer"
                                      style={{
                                        overflowWrap: "break-word",
                                        wordBreak: "break-word",
                                      }}
                                      onClick={() =>
                                        window.open(item?.fileUrl, "_blank")
                                      }
                                      mb="16px"
                                    >
                                      {item.fileName}
                                    </Text>
                                    <Box
                                      width="160px"
                                      height="160px"
                                      sx={{
                                        backgroundColor: "rgba(0, 0, 0, 0.06)",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      <img
                                        width="160px"
                                        height="160px"
                                        src={item.fileUrl}
                                        alt="file-url"
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              );
                            })}
                        </Box>
                      </Box>
                    </Box>
                  </CustomerAccordon>
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
      {showNewSection && (
        <Box p={"32px"}>
          {mappedRecords && images && (
            <PreviewDetails
              steps={steps}
              values={mappedRecords}
              getMappedLabel={(element) =>
                getMappedTextValue(element, mappedRecords)
              }
              dropdownOptions={dropdownOptions}
              // files={images}

              // steps={steps}
              // values={getValues()}
              // getMappedLabel={getMappedLabel}
              // dropdownOptions={dropdownOptions}
              files={images?.filter((ele) => !ele.related_person_index) || []}
              minorFiles={images?.filter((ele) => ele.related_person_index) || []}
            />
          )}
        </Box>
      )}
      {displayActionItems && (
        <Box mr="24px" mt={"24px"}>
          {(showNewSection ||
            (ckycData &&
              ckycData.data &&
              displayDetails?.length > 0 &&
              Object.keys(ckycData.data).length > 0) ||
            (searchData &&
              searchData.data &&
              displayDetails?.length > 0 &&
              Object.keys(searchData.data).length > 0)) && (
            <Box mb={"24px"} display="flex" justifyContent={"flex-end"}>
              {customerInfo?.status != "Audited" &&
                prevCustomerData &&
                actionButtons[currentRole].map((item) => {
                  return (
                    <LoadingButton
                      loading={false}
                      size="small"
                      variant="contained"
                      color="primary"
                      disableElevation
                      disabled={item.disabled || disableNextButton}
                      // onClick={() =>
                      // 	updateCkycStatus({ customerId: customerId, status: 4 })
                      // }

                      onClick={() => item.onClick()}
                      style={{ textTransform: "none", marginRight: "10px" }}
                    >
                      <Text>{item.title}</Text>
                    </LoadingButton>
                  );
                })}
            </Box>
          )}
        </Box>
      )}
      {openNewCustomerModal && customerInfo && dropdownOptions && images && (
        <NewCustomerAccount
          appType={appType}
          open={openNewCustomerModal}
          closePanel={closePanel}
          currentStep={currentStep}
          toggleDrawer={onCloseNewCustomerAccountForm}
          customerInfo={customerInfo}
          dropdownOptions={dropdownOptions}
          getMappedLabel={(element, values) =>
            getMappedTextValue(element, values ? values : mappedRecords)
          }
          files={images}
          fetchCustomerInfo={fetchCustomerDetails}
          openUpdateForm={openUpdateForm}
        />
      )}
      {statusUpdateModal.open && (
        <StatusUpdateModal
          status={statusUpdateModal.status}
          open={statusUpdateModal.open}
          toggleDrawer={() =>
            toggleStatusUpdateModal({ status: "", open: false })
          }
          updateCkycStatus={(id, remarks) =>
            updateCkycStatus({
              customerId: customerId,
              status: id,
              ...(remarks !== "" && { remarks }),
            })
          }
          isLoading={updatingStatus}
        />
      )}

      {openMismatchModal && (
        <MismatchModal
          open={openMismatchModal}
          closePanel={() => setMisMatchModal(false)}
          onContinue={continueAfterMismatch}
          misMatchedValues={misMatchedValues}
          recentlyUpdate={recentlyUpdate}
        />
      )}

      {openCustomerJourneyPanel && (
        <CustomerJoruneyPanel
          open={openCustomerJourneyPanel}
          toggleDrawer={() =>
            toggleCustomerJourneyPanel(!openCustomerJourneyPanel)
          }
          customerId={customerId}
          error={customerInfo?.job_error}
          status={customerInfo?.job_status}
        />
      )}

      <Backdrop
        loading={
          downoadingData ||
          fetchingData ||
          updatingStatus ||
          uploadingDocs ||
          updtatingUser
        }
      />
    </Modal>
  );
}
