import { Box} from "@mui/material";
// import { DeleteOutlineIcon, EditIcon } from "@mui/base";
import Text from "../../components/Atoms/Text";

const HeaderWrapper = (params) => {
  return (
    <Box display="flex" flexWrap="wrap">
      <Text fontSize="14px" semiBold fontWeight="600">
        {params.colDef.headerName}
      </Text>
    </Box>
  );
};

export const getColumns = ({ onToggleActive, onDeleteClick, onEditClick }) => {
  return [
    {
      field: "branch_name",
      headerName: "Branch Code",
      // flex: 1,		},
      width: 150,
    },
    {
      field: "total_values",
      headerName: "Total Count",
      // flex: 1,		},
      width: 150,
    },
    {
      field: "pending_mapping",
      headerName: "Pending Mapping",
      renderHeader: (params) => HeaderWrapper(params),
      width: 180,
    },
    {
      field: "in_progress",
      headerName: "In-Progress",
      renderHeader: (params) => HeaderWrapper(params),
      width: 180,
    },
    {
      field: "ckyc_found",
      headerName: "CKYC Found",
      renderHeader: (params) => HeaderWrapper(params),
      width: 180,
    },
    {
      field: "pending_verification",
      headerName: "Pending Verification",
      width: 180,

      renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "on_hold",
      headerName: "OnHold",
      renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "audited",
      headerName: "Audited",
      width: 180,

      renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "ckyc_file_generated",
      headerName: " CKYC File Generated",
      width: 180,

      renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "ckyc_approved",
      headerName: "CKYC Approved",
      width: 180,

      renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "ckyc_rejected",
      width: 180,

      headerName: "CKYC Rejected",
    },
    {
      field: "ckyc_uploaded",
      width: 180,

      headerName: "CKYC Uploaded",
    },
    {
      field: "ckyc_onhold",
      width: 180,

      //headerName: "CKYC OnHold",
      headerName: "CKYC Hold",
    },
    {
      field: "ckyc_found_and_download_failed",
      width: 180,

      //headerName: "CKYC OnHold",
      headerName: "Search Found and Download Failed",
    },
  ];
};

export const getColumnsBulk = ({
  onToggleActive,
  onEditClick,
  onDeleteClick,
}) => {
  return [
    {
      field: "ckyc_rejected",
      width: 180,

      headerName: "CKYC Rejected",
    },
    {
      field: "ckyc_uploaded",
      width: 180,

      headerName: "CKYC Uploaded",
    },
    {
      field: "ckyc_onhold",
      width: 180,

      //headerName: "CKYC OnHold",
      headerName: "CKYC Hold",
    },
  ];
};
