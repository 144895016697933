import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";
import Modal from "@mui/material/Modal";
import { Card } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Text from "../Atoms/Text";
import deletePopup from "../../assets/icons/delete-popup.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  // bgcolor: "background.paper",
  // border: "2px solid #000",
  // boxShadow: 24,
  p: 2,
};

export default function DeletePopUp(props) {
  const { closePopup, onDelete, openDeletePopup, isLoading, title, openPop } = props;
  const [open, setOpen] = React.useState(openDeletePopup);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onClose = () => {
    closePopup();
    handleClose();
  };

  return (
    <div>
      <Button onClick={handleOpen}>Open modal</Button>
      <Modal
        open={openPop || open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Card sx={style}>
          <Box display="flex" justifyContent="flex-end">
            <HighlightOffIcon sx={{ cursor: "pointer" }} onClick={onClose} />
          </Box>
          <Box
            display="flex"
            flexDirection={"row"}
            alignItems="flex-start"
            width="100%">
            <img style={{ marginTop: "12px" }} src={deletePopup} />
            <Box sx={{ marginLeft: "16px" }}>
              <Text mb={"4px"} semiBold fontSize="22px">
                {title}
              </Text>
              <Text fontSize="14px">
                Are you sure you want to permanently delete the selected item ?
              </Text>
            </Box>
          </Box>
          <Box mt={"16px"} display="flex" justifyContent={"flex-end"}>
            <Button
              onClick={onClose}
              size="small"
              variant="outlined"
              sx={{ marginRight: "8px" }}>
              Cancel
            </Button>

            <LoadingButton
              loading={isLoading}
              type={"submit"}
              size="small"
              variant="contained"
              color="primary"
              disableElevation
              onClick={onDelete}
              style={{ textTransform: "none" }}>
              <Text>Submit</Text>
            </LoadingButton>
          </Box>
        </Card>
      </Modal>
    </div>
  );
}
