import { Box } from "@mui/material";
import React from "react";
import { Switch } from "@mui/material";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

import DataTable from "../components/Molecules/DataTable";
import PageHeader from "../components/Molecules/PageHeader";
import { useGlobalContext } from "../context/GlobalContext";

const dataRows = [
  {
    id: 1,
    col1: "Adhaar 1954",
    col2: "10AM 19Th Jan, 2019",
    col3: "empty",
    col4: "empty",
  },
];

const RenderTextField = (props) => {
  const { params, editedText } = props;

  const handleValueChange = (event) => {
    const newValue = event.target.value; // The new value entered by the user
    params.api.setEditCellValue({
      id: params.id,
      field: params.field,
      value: newValue,
    });
  };
  return (
    <TextField
      sx={{
        "& .MuiOutlinedInput-input": {
          padding: "4px",
          background: "white",
        },
      }}
      type="text"
      // label="Name"
      id="outlined-name"
      defaultValue={params.value}
      // value={params.value}
      onChange={handleValueChange}
      error={params.error}
      // error={true}
    />
  );
};

const Documents = () => {
  const { dispatch } = useGlobalContext();
  const renderCell = (params) => {
    return (
      <Box display="flex" width="80%" justifyContent={"space-between"}>
        <React.Fragment>
          {params.value}
          <EditIcon
            onClick={() =>
              params.api.startCellEditMode({
                id: params.id,
                field: params.field,
              })
            }
          />
        </React.Fragment>
      </Box>
    );
  };

  const renderEditCell = (params) => {
    return (
      <Box display="flex" width="80%">
        <React.Fragment>
          <Box display="flex" alignItems="center" mr={"32px"}>
            <RenderTextField params={params} />
          </Box>
          <Box display="flex" alignItems={"center"}>
            <Box
              p="4px 6px"
              mr="8px"
              display="flex"
              alignItems="center"
              sx={{ background: "white", border: "1px solid #E3FFE2" }}>
              <CheckIcon
                sx={{ color: "green", fontSize: "16px" }}
                color="green"
                onClick={() =>
                  params.api.stopCellEditMode({
                    id: params.id,
                    field: params.field,
                  })
                }
              />
            </Box>
            <Box
              p="4px 6px"
              mr="24px"
              display="flex"
              alignItems="center"
              sx={{ background: "white", border: "1px solid #FFE2E4" }}>
              <ClearIcon
                sx={{ color: "red", fontSize: "14px" }}
                onClick={() =>
                  params.api.stopCellEditMode({
                    id: params.id,
                    field: params.field,
                    value: params.value,
                    ignoreModifications: true, // will also discard the changes made
                  })
                }
              />
            </Box>
          </Box>
        </React.Fragment>
      </Box>
    );
  };

  const columns = [
    {
      field: "col1",
      headerName: "Document Name",
      flex: 2,
      renderCell: renderCell,
      renderEditCell: renderEditCell,
      editable: true,
      preProcessEditCellProps: (params) => {
        const hasError = params.props.value.length === 0;
        return { ...params.props, error: hasError };
      },
    },
    { field: "col2", headerName: "Last updated", flex: 1 },
    {
      field: "col3",
      headerName: "Active",
      flex: 0.5,
      sortable: false,
      renderCell: () => <Switch defaultChecked />,
    },
    { field: "col4", headerName: "Actions", flex: 0.5, sortable: false },
  ];

  const processRowUpdate = React.useCallback(async (newRow, oldRow) => {
    if (JSON.stringify(oldRow) == JSON.stringify(newRow)) {
      return oldRow;
    }
    // Make the HTTP request to save in the backend
    // const response = await mutateRow(newRow);
    // let newRows = [];
    // newRows = .map((row) => {
    // 	if (row.id === newRow.id) return newRow;
    // });
    // setSnackbar({
    // 	children: "User successfully saved",
    // 	severity: "success",
    // });
    dispatch({
      type: "SET_TOAST",
      payload: {
        open: true,
        text: "Document name successfully updated",
        severity: "success",
      },
    });
    return newRow;
  }, []);

  const handleProcessRowUpdateError = React.useCallback((newRow, oldRow) => {
    // setSnackbar({ children: error.message, severity: "error" });
    if (JSON.stringify(oldRow) == JSON.stringify(newRow)) {
      dispatch({
        type: "SET_TOAST",
        payload: {
          open: true,
          text: "It can't be the previous name",
          severity: "error",
        },
      });
      return oldRow;
    }
  }, []);

  return (
    <React.Fragment>
      <PageHeader />
      <Box sx={{ padding: "10px", height: "100%" }}>
        <DataTable
          handleProcessRowUpdateError={handleProcessRowUpdateError}
          processRowUpdate={processRowUpdate}
          rows={dataRows}
          columns={columns}
        />
      </Box>
    </React.Fragment>
  );
};

export default Documents;
