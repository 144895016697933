import React, { useEffect } from "react";

import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

import {
  createOrEditDistrict
} from "../apis/districtMaster";
import { downloadZip, getAllStatuses, getCustomers } from "../apis/newCustomer";
import DataTable from "../components/Molecules/DataTable";
import PageHeader from "../components/Molecules/PageHeader";
import DeletePopUp from "../components/Molecules/PopupModal";
import CustomerDetails from "../components/Organisms/CustomerDetails";
import CustomerFilter from "../components/Organisms/new-customer/CustomerFilter";
import { formatDate } from "../components/Organisms/new-customer/formUtils";
import { useGlobalContext } from "../context/GlobalContext";
import useDropdownOptions from "../hooks/useDropdownOptions";
import { getColumns } from "../utils/tableHeaders/ckycUpload";

const NewCustomers = () => {
  const [addUserPanel, setAddUserPanel] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [openFilter, toggleFilter] = React.useState(false);
  const [openDeletePopup, toggleDeletePopup] = React.useState(false);
  const {
    state: { currentUser },
    dispatch,
  } = useGlobalContext();
  const [selectedDistrict, setSelectedDoc] = React.useState(null);
  const [filter, updateFilter] = React.useState({});
  const [search, updateSearch] = React.useState(null);
  const [docketOpen, setDocketOpen] = React.useState(false);
  const [customerId, setCustomerId] = React.useState(null);
  const { dropdownOptions } = useDropdownOptions();
  const [filters, setFiltes] = React.useState(null);
  const [selectedCustomerIds, setSelectedCustomersIds] = React.useState([]);
  const [isSFTPGenerating, setIsSFTPGenerating] = React.useState(false);
  const [extraFilters, setExtraFilters] = React.useState({
    applicationType: "01",
    constitutionType: "01",
  });
  // const [isSFTP, setIsSFTP] = React.useState(false);

  // Pass this to customer details to avoid duplicate code
  let currentRole =
    JSON?.parse(window.localStorage.getItem("roles")) ||
    currentUser.role.role_name;
  currentRole = currentRole.toLowerCase();

  const pageTitle = "CKYC Upload";

  const {
    data,
    refetch,
    isSuccess: customersFetched,
  } = useQuery(
    [
      "customers",
      { filter: { status: 7 }, search, filters, extraFilters, module: "CU" },
    ],
    getCustomers,
    {
      manual: true,
      select: (response) => response.get("data", []),
      retry: false,
    }
  );

  const { data: allStatuses, refetch: fetchAllStatuses } = useQuery(
    ["statuses"],
    getAllStatuses,
    {
      manual: true,
      select: (resp) => resp.data,
      retry: false,
      enabled: false,
    }
  );

  const { mutate: addOrUpdateDoc, isLoading } = useMutation(
    (userData) => createOrEditDistrict(userData),
    {
      onSuccess: (response) => {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.message,
            severity: response.isError ? "error" : "success",
          },
        });
        // updateFilter({});
        if (!response.isError) {
          setAddUserPanel(false);
          toggleDeletePopup(false);
          refetch();
        }
      },
      onError: (error) => {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: error.response.data.message,
            severity: "error",
          },
        });
      },
    }
  );

  const { mutate: generateCkyc, isLoading: downloadingZip } = useMutation(
    (userData) => downloadZip(userData),
    {
      onSuccess: async (response) => {
        setIsSFTPGenerating(false);
        if (response && response?.isError) {
          dispatch({
            type: "SET_TOAST",
            payload: {
              open: true,
              text: response.message,
              severity: "error",
            },
          });
        } else {
          if (response?.data?.length === 0) {
            dispatch({
              type: "SET_TOAST",
              payload: {
                open: true,
                text: response.message,
                severity: "success",
              },
            });
          } else {
            const url = window.URL.createObjectURL(
              new Blob([response?.data], { type: "application/zip" })
            );
            if (url) {
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", response?.headers["file-name"]);
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
            }
          }
          refetch();
        }
      },
      onError: (error) => {
        setIsSFTPGenerating(false);
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: error.response.data.message,
            severity: "error",
          },
        });
      },
    }
  );

  const onSubmit = (data) => {
    let reqData = data;
    if (reqData["toDate"])
      reqData["toDate"] = !dayjs(
        reqData["toDate"],
        "MM-DD-YYYY",
        true
      ).isValid()
        ? reqData["toDate"]
        : formatDate(reqData["toDate"]);
    if (reqData["fromDate"])
      reqData["fromDate"] = !dayjs(
        reqData["fromDate"],
        "MM-DD-YYYY",
        true
      ).isValid()
        ? reqData["fromDate"]
        : formatDate(reqData["fromDate"]);
    setFiltes(reqData);
    refetch();
    if (customersFetched) {
      toggleFilter(false);
    }
  };

  const disableNextButton = () =>
    data &&
    data
      .get("items", [])
      .some((item) => item?.job_type === "AUTOMATION" && item?.count < 2);

  const onDeleteUser = () => {
    const requestData = {
      id: selectedDistrict.get("id"),
    };
    addOrUpdateDoc(requestData);
    setSelectedDoc(null);
  };

  const toggleDrawer = () => {
    // setNewCustomerModal(!openNewCustomerModal);
  };

  const onDocketClick = (id) => {
    // fetch the ckyc user info
    setCustomerId(id);
    setDocketOpen(true);
  };

  const onToggleActive = (e, id) => {
    let checked = e.target.checked;
    let requestData = {
      id: id,
      status: checked ? 1 : 0,
    };
    requestData = { district: requestData };
    addOrUpdateDoc(requestData);
  };

  const onFilter = (status) => {
    updateFilter(status);
    refetch();
  };

  let searchTimeout;

  const searchUsers = (query) => {
    clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      updateSearch(query);
    }, 500);
  };

  const onSearch = (e) => {
    const val = e.target.value;
    searchUsers(val);
  };

  const openNewCKYCForm = () => {
    setDocketOpen(false);
  };

  const onCloseDocketForm = () => {
    setDocketOpen(false);
    refetch();
  };

  React.useEffect(() => {
    (async () => {
      await fetchAllStatuses();
    })();
  }, []);

  // const rightSideActions = [
  // 	{
  // 		icon: EditIcon,
  // 		options: [
  // 			{ label: "Add New", Icon: addNewUser, onClick: toggleDrawer },
  // 			{ label: "Upload", Icon: uploadUser, EditIcon, onClick: null },
  // 		],
  // 		title: "Add New",
  // 		onClick: null,
  // 		isSplit: true,
  // 	},
  // ];

  const onGenerateCkyc = (isSftp) => {
    if (selectedCustomerIds.length === 0) {
      dispatch({
        type: "SET_TOAST",
        payload: {
          open: true,
          text: "Please select atlease one customer to perform this action",
          severity: "error",
        },
      });
      return;
    }
    const request = {
      customerIds: selectedCustomerIds,
      isSftp,
    };
    if (isSftp) {
      setIsSFTPGenerating(true);
    }
    generateCkyc(request);
  };

  const getAllStatusDropdownValues = () => {
    let options = [];
    if (allStatuses) {
      options.push({
        label: "All Status",
        icon: null,
        onClick: () => onFilter(null),
      });
      allStatuses.forEach((item) => {
        const status =
          currentRole === "maker"
            ? [2, 3, 6, 10].includes(item.id)
            : [4, 11, 10, 6, 7].includes(item.id);
        if (status) {
          const newOption = {
            label: item.status,
            icon: null,
            onClick: () => onFilter({ status: item.id }),
          };
          options.push(newOption);
        }
      });
    }
    return options;
  };

  const buildOptionsForConstitutionTypes = (options) => {
   
      return [
        {
          label: "Individual",
          onClick: () =>
            setExtraFilters({ ...extraFilters, constitutionType: "01" }),
        },
        {
          label: "Legal Entity",
          onClick: () =>
            setExtraFilters({ ...extraFilters, constitutionType: "LE" }),
        },
      ];
    
  //   return options.map((item) => {
  //    return {
  //       label: item.name,
  //       icon: null,
  //       onClick: () =>
  //         setExtraFilters({ ...extraFilters, constitutionType: item.code }),
  //     };
  //  });
  };

  const leftSideActions = [
    {
      defaultText: "Filter",
      isDropdown: false,
      isButton: true,
      icon: <EditIcon />,
      title: "Filter",
      onClick: () => toggleFilter(true),
    },
    {
      options: buildOptionsForConstitutionTypes(
        dropdownOptions["constitutionType"]
      ),
      defaultText: "01",
      icon: null,
      isDropdown: true,
      groupTitle: "Constitution Type",
    },
    {
      options: [
        {
          label: "New",
          onClick: () =>
            setExtraFilters({ ...extraFilters, applicationType: "01" }),
        },
        {
          label: "Update",
          onClick: () =>
            setExtraFilters({ ...extraFilters, applicationType: "03" }),
        },
      ],
      defaultText: "New",
      icon: null,
      isDropdown: true,
      groupTitle: "Application Type",
    },
  ];

  const rightSideActions = [
    {
      defaultText: "Generate Zip",
      isDropdown: false,
      isButton: true,
      icon: null,
      loading: downloadingZip && !isSFTPGenerating,
      title: "Generate Zip",
      disabled: disableNextButton(),
      onClick: () => onGenerateCkyc(false),
    },
    {
      defaultText: "Generate Zip",
      isDropdown: false,
      isButton: true,
      icon: null,
      disabled: disableNextButton(),
      loading: isSFTPGenerating,
      title: "Generate SFTP",
      onClick: () => onGenerateCkyc(true),
    },
  ];

  useEffect(() => {
    if (totalCount === 0 && data?.get("totalCount") >= 0) {
      setTotalCount(data?.get("totalCount"));
    }
  }, [data?.get("totalCount")]);
  useEffect(() => {
    if (data?.get("items")?.toJS()?.length >= 0 )  {
      setTableData(data?.get("items")?.toJS())
    }
  }, [data?.get("items")])
  return (
    <React.Fragment>
      <PageHeader
        toggleDrawer={toggleDrawer}
        leftSideActions={leftSideActions}
        rightSideActions={rightSideActions}
        pageTitle={pageTitle}
        isSearch={true}
        searchOnChange={onSearch}
        totalCount={totalCount}
      />
      <Box sx={{ padding: "15px", height: "100%" }}>
        <DataTable
          loading={false}
          getRowId={(row) => row.customerId}
          pageSize={data?.get("perPage", 5)}
          totalCount={totalCount}
          columns={getColumns({ onToggleActive, onDocketClick })}
          rows={tableData}
          onSelectionModelChange={(selected) =>
            setSelectedCustomersIds(selected)
          }
        />
        {openFilter && (
          <CustomerFilter
            open={openFilter}
            toggleDrawer={() => toggleFilter(false)}
            onSubmit={onSubmit}
            filters={filters}
            // selectedDistrict={selectedDistrict || Immutable.Map()}
            isLoading={isLoading}
            statuses={allStatuses}
            showStatuses={false}
            dropdownOptions={dropdownOptions}
          />
        )}
      </Box>
      {openDeletePopup && (
        <DeletePopUp
          title="Delete Document"
          closePopup={() => toggleDeletePopup(false)}
          openDeletePopup={openDeletePopup}
          onDelete={() => onDeleteUser()}
          isLoading={isLoading}
        />
      )}
      {docketOpen && customerId && (
        <CustomerDetails
          open={docketOpen}
          closePanel={onCloseDocketForm}
          openNewCKYCForm={openNewCKYCForm}
          customerId={customerId}
          dropdownOptions={dropdownOptions}
        />
      )}
    </React.Fragment>
  );
};

export default NewCustomers;
