import React from "react";
import { TextField, Box, Button } from "@mui/material";
import { useMutation} from "@tanstack/react-query";
import { LoadingButton } from "@mui/lab";

import Text from "../Atoms/Text";
import { recoverPassword } from "../../apis/auth";
import { useGlobalContext } from "../../context/GlobalContext";
import { useNavigate } from "react-router-dom";

export default function ForgetForm() {
  const [email, setEmail] = React.useState("");
  const { dispatch } = useGlobalContext();
  const navigate = useNavigate();
  const [error, setError] = React.useState(false);

  const { mutate: sendResetEmail, isLoading: sendingEmail } = useMutation(
    (data) => recoverPassword(data),
    {
      onSuccess: (response) => {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.data.message,
            severity: response.data.isError ? "error" : "success",
          },
        });
      },
      onError: (error) => {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: "Something went wrong in our side, Please try again later",
            severity: "error",
          },
        });
      },
    }
  );

  const onSubmit = (e) => {
    e.preventDefault();
    const regex = /\S+@\S+\.\S+/;
    if (email.length === 0 || !regex.test(email)) {
      setError(true);
      return;
    } else {
      setError(false);
      const requestData = { email };
      sendResetEmail(requestData);
    }
  };

  return (
    <React.Fragment>
      <Box mt={5}>
        <Text color="#494E59" fontSize="18px">
          Forgot your password?
        </Text>
        <Text mt={"8px"}>
          Enter your e-mail address and we’ll send you a link to reset your
          password
        </Text>
      </Box>
      <form onSubmit={onSubmit}>
        <Text mt={"24px"} mb="4px">
          Email
        </Text>
        <TextField
          id="email"
          defaultValue=""
          size="small"
          textFieldProps={{ shrink: false }}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          // required
          error={error}
          helperText={error && "Please enter the valid email address"}
          value={email}
          fullWidth
        />
        <LoadingButton
          loading={sendingEmail}
          type={"submit"}
          size="medium"
          sx={{ marginTop: "16px" }}
          variant="contained"
          color="primary"
          disableElevation
          fullWidth
          style={{ textTransform: "none" }}>
          <Text>Send Email</Text>
        </LoadingButton>
        <Button
          size="medium"
          sx={{ marginTop: "16px" }}
          variant="outlined"
          color="primary"
          disableElevation
          fullWidth
          onClick={() => navigate("/login")}
          style={{ textTransform: "none" }}>
          <Text>Back to Login</Text>
        </Button>
      </form>
    </React.Fragment>
  );
}
