import axiosInstance from "./axios";
import Immutable from "immutable";

export const createOrEditDistrict = async (district) => {
	let response = null;
	try {
		if (district?.id)
			response = await axiosInstance.post(`/districts/delete`, district);
		else if (district.district.id)
			response = await axiosInstance.post("/districts/update", district);
		else response = await axiosInstance.post("/districts", district);
		return response.data;
	} catch (e) {}
};

export const getCustomers = async ({ queryKey }) => {
	const [_key, { filter, search, filters, extraFilters, module, identity }] = queryKey;
	const pageSize = filter.perPageSize || 10;
	const fils = { ...filter };
	console.log("onfil",filter,filters,extraFilters);
	try {
		let baseUrl = `/customers/list?perPage=${pageSize}&offset=${filter.page ? filter.page : 0}`;
		delete fils.page;
		delete fils.perPageSize;
		delete fils.condition;
		let filtered = baseUrl;
		if (filters && Object.keys(filters).length > 0) {
			let keys = Object.keys(filters);
			keys.forEach((item) => {
				if (!["", null, undefined].includes(filters[item]))
					filtered = `${filtered}&${item}=${filters[item]}`;
			});
		}
		if (module) filtered = `${filtered}&module=${module}`;
		if (identity) filtered = `${filtered}&identity=${identity}`;

		if (filter?.status && Object.keys(filter).length > 0) {
			let keys = Object.keys(filter);
			keys.forEach((item) => {
				if (!["", null, undefined].includes(filter[item]))
					filtered = `${filtered}&${item}=${filter[item]}`;
			});
		}
		if (![null, "", undefined].includes(search))
			filtered = `${filtered}&search=${search}`;
		if (extraFilters) {
			const keys = Object.keys(extraFilters);
			keys.forEach((item) => {
				if (!["", null, undefined].includes(extraFilters[item]))
					filtered = `${filtered}&${item}=${extraFilters[item]}`;
			});
		}
		
		const response = await axiosInstance.get(filtered);
		return Immutable.fromJS(response.data);
	} catch (e) {}
};

export const createCustomer = async (data) => {
	let type = data.type
	delete data.type;
	const response = await axiosInstance.post(`/customers/create_customer?type=${type === "LE" ? "Legal" : "Individual"}`, data);
	return response.data;
};
export const getDistrict = async (id) => {
	const response = await axiosInstance.get(`/districts/${id.queryKey[1]}`);
	return response.data;
};
const clientId = JSON.parse(localStorage.getItem('cliendid'))


export const downloadApi = async (data) => {
	const params = data.params;
	delete data.params;
	const response = await axiosInstance.post(`/customers/download`, data,{params});
	return response.data;
};

export const searchCkycUser = async (data,customerId) => {
	const response = await axiosInstance.post(`/customers/search`,data,{params:{customer_id:customerId,client_id:clientId}});
	return response.data;
};

export const getDownloadZip = async (data) => {
	const response = await axiosInstance.get(`/customers/multiple_update_status`);
	return response.data;
};
// customers/details?customerId=1

export const getCustomer = async ({ queryKey }) => {
	const [_key, { id }] = queryKey;
	try {
		const response = await axiosInstance.get(
			`/customers/details?customerId=${id}`
		);
		return response.data;
	} catch (e) {}
};

export const getMasterData = async () => {
	const response = await axiosInstance.get(`/static/masterList `);
	return response.data;
};
export const getAllProofData = async () => {
	try {
		const response = await axiosInstance.get(`/documents?perPage=51&offset=0&statusFilter=1`);
		return response.data;
	} catch (e) {}
};

export const updateCustomer = async (data) => {
	const requestData = { requestFields: data };
	try {
		const response = await axiosInstance.put(`/customers/update`, requestData);
		return response.data;
	} catch (e) {}
};

export const uploadDocs = async (data) => {
	const headers = {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	};
	const response = await axiosInstance.post(`/customers/files`, data, headers);
	return response.data;
};

export const downloadZip = async (data) => {
	const headers = {
		responseType: "stream",
	};
	const response = await axiosInstance.post(`/customers/downloadZip`, data, {
		responseType: data?.isSftp ? "application/json" : "arraybuffer",
	});
	// This the last resort logic to know that api is returning error or fileobject
	// Current backend response type is stream/buffer so it should send the same at all times
	// but it is sending the normal json when there is error
	// So as we sending the responsetype is arraybuffer , we can't read at directly
	if (data?.isSftp) {
		return response.data;
	} else {
		let jsonRespnse;
		try {
			jsonRespnse = new Response(response?.data);
			jsonRespnse = await jsonRespnse?.json();
		} catch (e) {}
		return jsonRespnse && jsonRespnse?.isError ? jsonRespnse : response;
	}
};

// api / static / statuses;

export const getAllStatuses = async () => {
	const response = await axiosInstance.get(`/static/statuses `);
	return response.data;
};

export const updateStatus = async (data) => {
	try {
		const response = await axiosInstance.post(`/customers/update_status`, data);
		return response.data;
	} catch (e) {}
};

export const fetchImages = async ({ queryKey }) => {
	const [_key, { customerId }] = queryKey;

	const response = await axiosInstance.get(
		`/customers/files?customerId=${customerId} `
	);
	return response.data;
};

export const deleteFiles = async (data) => {
	const response = await axiosInstance.delete(`/customers/files`, data);
	return response.data;
};
