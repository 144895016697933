import axiosInstance from "./axios";

export const createUser = async (user) => {
	let response = null;
	try {
		if (user.user.id) response = await axiosInstance.put(`/users/${user.user.id}`, user);
		else response = await axiosInstance.post("/users", user);
		if(!response?.data?.isError && (user?.user?.status === 1 || user?.user?.status === 0)){
			response.data["message"] = response.data["message"]?.replace("updated",user?.user?.status ? "enabled" : "disabled");
		}
		return response.data;
	} catch (e) {}
	// try {
	// 	const response = await axiosInstance.post("/users", user);
	// 	console.log(response, "resssssss");
	// 	return response.data;
	// } catch (e) {
	// 	return e;
	// }
};

export const getCustomerHistory = async ({ queryKey }) => {
	const [ , { customerId }] = queryKey;
	const response = await axiosInstance.get(
		`/customers/status_history?customer_id=${customerId}`
	);
	return response.data;
};

export const getRoles = async () => {
	const response = await axiosInstance.get("/roles");
	return response.data;
};

export const getBranches = async () => {
	const response = await axiosInstance.get(
		"/branches/list?perPage=25&offset=0"
	);
	return response.data;
};

export const getUsers = async ({ queryKey }) => {
	const [_key , { filter, search }] = queryKey;
	 const fils = {...filter};
	try {
		//let baseUrl = `/users/?perPage=${pageSize}&offset=${filter?.page ? filter?.page : 0}`;
		let baseUrl = `/users/?perPage=${filter?.perPageSize ? filter?.perPageSize : 10}&offset=${filter?.page ? filter?.page : 0}`;

		let filtered = baseUrl;
		if (
			![null, "", undefined].includes(filter) &&
			Object.keys(filter).length > 0
		) {
			delete fils.page;
			delete fils.perPageSize;
			const keys = Object.keys(fils);
			keys.forEach((item) => {
				if (!["", null, undefined].includes(filter[item]))
					filtered = `${filtered}&${item}=${filter[item]}`;
			});
		}
		if (![null, "", undefined].includes(search))
			filtered = `${baseUrl}&search=${search}`;

		const response = await axiosInstance.get(filtered);
		return response.data;
	} catch (e) {}
};

export const getUser = async ({ queryKey }) => {
	const [ , { id }] = queryKey;
	const response = await axiosInstance.get(`/users/${id}`);
	return response.data;
};

export const deleteUser = async (id) => {
	const response = await axiosInstance.delete(`/users/${id}`);
	return response.data;
};

export const getDesignations = async () => {
	const response = await axiosInstance.get(`/static/designations
`);
	return response.data;
};

