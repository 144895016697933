import { isMaker } from "../utils";
import axiosInstance from "./axios";

export const viewBraches = async ({ queryKey }) => {
	const [_key, { filter, search }] = queryKey;
	// const pageSize = filter.perPageSize || 10;
	// const fils = {...filter};
	let baseUrl = `/branches/list/?perPage=${filter?.perPageSize ? filter?.perPageSize : 10}&offset=${filter?.page ? filter?.page : 0}`;
	if(isMaker()){
		baseUrl = "/branches/list/?perPage=500&offset=0&filter=1";
	}
	let filtered = baseUrl;
	if (!isMaker() && filter?.statusFilter !=null && filter?.statusFilter >=0 && ![null, "", undefined].includes(filter))
		filtered = `${baseUrl}&filter=${filter?.statusFilter}`;
	if (!isMaker() && ![null, "", undefined].includes(search))
		filtered = `${baseUrl}&search=${search}`;

	const response = await axiosInstance.get(filtered);
	return response.data;
};
export const getStates = async () => {
	const response = await axiosInstance.get("/static/getStates");
	return response.data;
};

export const getCities = async ({ queryKey }) => {
	const [_key, { state }] = queryKey;
	try {
		const response = await axiosInstance.get(
			`/static/getCities?stateName=${state}`
		);
		return response.data;
	} catch (e) {}
};

export const getCountries = async () => {
	try {
		const response = await axiosInstance.get(`/static/getCountries`);
		return response.data;
	} catch (e) {}
};
export const deleteBranch = async (data) => {
	const response = await axiosInstance.delete(`/branches/delete`, data);
	return response.data;
};

export const addOrEditBranch = async (data) => {
	let response;
	if (data.brId && !data?.isDeleted)
		response = await axiosInstance.put(`/branches/edit`, data);
	else if (data.isDeleted) {
		response = await axiosInstance.delete(`/branches/delete`, {
			data: data,
		});
	} else response = await axiosInstance.post(`/branches/create`, data);
	if(!response?.data?.isError && (data?.status === 1 || data?.status === 0)){
		response.data["message"] = response.data["message"]?.replace("updated",data?.status ? "enabled" : "disabled");
	}
	return response.data;
};

export const editBranch = async (data) => {
	const response = await axiosInstance.post(`/branches/edit`, data);
	return response.data;
};

