import { Box } from "@mui/material";
import React from "react";
import { Switch } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { useMutation, useQuery, useQueries } from "@tanstack/react-query";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { getStates, viewBraches, addOrEditBranch } from "../apis/branches";
import DataTable from "../components/Molecules/DataTable";
import PageHeader from "../components/Molecules/PageHeader";
import AddBranch from "../components/Organisms/AddBranch";
import {
  deactivated,
  activate,
  selectAll,
  users,
  uploadUser,
  addNewUser,
  deletePopup,
} from "../assets/icons";
import DeletePopUp from "../components/Molecules/PopupModal";
import { useGlobalContext } from "../context/GlobalContext";
import { useEffect } from "react";

const pageTitle = "Manage Branch";

const Branch = () => {
  const [addUserPanel, setAddUserPanel] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const [openDeletePopup, toggleDeletePopup] = React.useState(false);
  const { dispatch } = useGlobalContext();
  const [selectedBranch, setSelectedBranch] = React.useState(null);
  const [search, updateSearch] = React.useState(null);
  const [filter, updateFilter] = React.useState({ 
  condition: "" ,
  page:0,
  perPageSize:5
  });
  const [totalCount, setTotalCount] = React.useState(0);
  const {
    data,
    isLoading: branchesLoading,
    refetch,
    
    error: branchesError,
  } = useQuery(["allBranches", { filter, search }], viewBraches, {
    manual: true,
    select: (response) => response.data,
    retry: false,
  });

  const { mutate: addOrUpdateBranch, isLoading } = useMutation(
    (userData) => addOrEditBranch(userData),
    {
      onSuccess: (response) => {
        if (response.isError) {
          dispatch({
            type: "SET_TOAST",
            payload: {
              open: true,
              text: response.message,
              severity: "error",
            },
          });
        } else {
          dispatch({
            type: "SET_TOAST",
            payload: {
              open: true,
              text: response.message,
              severity: "success",
            },
          });
          setSelectedBranch(null)
           // updateFilter(null);
          setAddUserPanel(false);
          toggleDeletePopup(false);
          refetch();
        }
      },
      onError: (error) => {},
    }
  );

  const {
    data: states,
    isLoading: statesLoading,
    error: statesError,
  } = useQuery(["states"], getStates, {
    manual: true,
    select: (response) => response.data,
    retry: false,
  });

  const onSubmit = (data) => {
    let requestData = {
      brName: data.branch_name,
      brCode: data.branch_code,
      brManagerName: data.branch_manager,
      contNumber: data.contact_number,
      pincode: data.pincode,
      address: data.address,
      state: data.state,
      country: data.country,
      city: data.city,
    };
    if (selectedBranch?.brId) {
      requestData["brId"] = selectedBranch.brId;
      requestData["brManager"] = data.branch_manager;
      // setSelectedBranch(null);
    }
    addOrUpdateBranch(requestData);
  };

  const onDeleteBranch = () => {
    const requestData = {
      brId: selectedBranch?.brId,
      isDeleted: "1",
    };
    addOrUpdateBranch(requestData);
    setSelectedBranch(null);
  };

  const toggleDrawer = () => {
    if (addUserPanel) {
      setSelectedBranch(null);
    }
    setAddUserPanel(!addUserPanel);
  };

  const onEditClick = async (id) => {
    const branch = data.items.find((item) => item.brId === id);
    setSelectedBranch(branch);
    toggleDrawer();
  };

  const onDeleteClick = (id) => {
    const branch = data.items.find((item) => item.brId === id);
    setSelectedBranch(branch);
    toggleDeletePopup(true);
  };

  const onToggleActive = async (e, id) => {
    let checked = e.target.checked;
    const requestData = {
      brId: id,
      status: checked ? 1 : 0,
    };
    addOrUpdateBranch(requestData);
  };

  const onFilter = (statusFilter) => {
    updateFilter({...filter, ...statusFilter});
    refetch();
  };

  let searchTimeout;

  const searchBranches = (query) => {
    clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      updateSearch(query);
    }, 500);
  };

  const onSearch = (e) => {
    const val = e.target.value;
    searchBranches(val);
  };

  const columns = [
    {
      field: "brName",
      headerName: "Branch Name",
      flex: 1,
    },
    { field: "brCode", headerName: "Branch Code", flex: 1 },
    { field: "brManager", headerName: "Branch Manager", flex: 1 },
    { field: "contNumber", headerName: "Contact Number", flex: 1 },
    { field: "stateName", headerName: "State", flex: 1 },

    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      disableExport: true,
      renderCell: (params) => {
        return (
          <Switch
            checked={params.value}
            onChange={(e) => onToggleActive(e, params.row.brId)}
            // defaultChecked
          />
        );
      },
    },
    {
      field: "col9",
      headerName: "Actions",
      sortable: false,
      disableExport: true,
      renderCell: (params) => {
        return (
          <Box display="flex" justifyContent={"space-between"}>
            <EditIcon
              onClick={() => onEditClick(params.row.brId)}
              sx={{ marginRight: "4px", cursor: "pointer", marginRight: "8px" }}
            />
            <DeleteOutlineIcon
              onClick={() => onDeleteClick(params.row.brId)}
              sx={{ color: "red", cursor: "pointer", marginLeft: "8px" }}
            />
          </Box>
        );
      },
    },
  ];

  const rightSideActions = [
    // {
    // 	icon: EditIcon,
    // 	options: [
    // 		{ label: "Add New", Icon: addNewUser, onClick: toggleDrawer },
    // 		{ label: "Upload", Icon: uploadUser, EditIcon, onClick: null },
    // 	],
    // 	title: "Add New",
    // 	onClick: null,
    // 	isSplit: true,
    // },
    {
      defaultText: "Add New",
      isDropdown: false,
      isButton: true,
      icon: <PersonAddAltIcon />,
      title: "Add New",
      onClick: toggleDrawer,
      color: "contained",
    },
  ];

  const leftSideActions = [
    {
      options: [
        {
          label: "All Status",
          Icon: selectAll,
          onClick: () => onFilter({statusFilter:null}),
        },
        { label: "Active", Icon: activate, onClick: () => onFilter({ statusFilter: 1 }) },
        { label: "InActive", Icon: deactivated, onClick: () => onFilter({ statusFilter: 0 }) },
      ],
      defaultText: "Activate",
      isDropdown: true,
      groupTitle: "Status",
    },
  ];

  useEffect(() =>{
    if(data?.totalCount >= 0){
      setTotalCount(data?.totalCount);
    }
  },[data?.totalCount])
  useEffect(() => {
    if (data?.items?.length >= 0) {
      setTableData(data?.items)
    }
  }, [data?.items])
  return (
    <React.Fragment>
      <PageHeader
        toggleDrawer={toggleDrawer}
        leftSideActions={[...leftSideActions, ...rightSideActions]}
        // rightSideActions={rightSideActions}
        pageTitle={pageTitle}
        isSearch={true}
        searchOnChange={onSearch}
        totalCount={totalCount}
      />
      <Box sx={{ padding: "10px 20px", height: "100%" }}>
        <DataTable
          loading={branchesLoading}
          rows={tableData}
          columns={columns}
          getRowId={(row) => row.brId}
          totalCount={totalCount}
          pageSize={filter?.perPageSize}
          onPageSize={(pageSize) => {
            updateFilter((fil) => ({
              ...fil,
              perPageSize: pageSize,
            }));
          }}
          onPage={(page) => {
            updateFilter((fil) => ({
              ...fil,
              page: page,
            }));
          }}
        />
        {addUserPanel && (
          <AddBranch
            states={states && states}
            open={addUserPanel}
            toggleDrawer={toggleDrawer}
            onSubmit={onSubmit}
            selectedBranch={selectedBranch}
            isLoading={isLoading}
          />
        )}
      </Box>
      {openDeletePopup && (
        <DeletePopUp
          title="Delete Branch"
          closePopup={() => toggleDeletePopup(false)}
          openDeletePopup={openDeletePopup}
          onDelete={() => onDeleteBranch()}
          isLoading={isLoading}
        />
      )}
    </React.Fragment>
  );
};

export default Branch;
