import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Box } from "@mui/material";
import Icon from "@mui/material/Icon";

import Text from "./Text";

export default function DropdownButton(props) {
  const { options, variant, groupTitle, defaultText } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [selectedText, setSelectedText] = React.useState(
    defaultText || options[0]?.label
  );
  const [selectedIcon, setSelectedIcon] = React.useState(options[0]?.Icon);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = ({ label, Icon, onClick }) => {
    if (label) setSelectedText(label);
    // callback(value);
    setSelectedIcon(Icon);
    handleClose();
    onClick();
  };

  return (
    <div>
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant={variant || "contained"}
        sx={{
          marginRight: "8px",
          textTransform: "none",
          width: "max-content",
          height: "32px",
          backgroundColor: "white",
          color: "black",
          border: variant === "outlined" ? "1px solid #BFBFBF" : "inherit",
        }}
        size="small"
        startIcon={<img src={selectedIcon} />}
        endIcon={<KeyboardArrowDownIcon />}>
        <Text fontSize="14px">{selectedText}</Text>
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableAutoFocusItem
        PaperProps={{
          style: {
            // left: "50%",
            // transform: "translateX(-77%) translateY(32%)",
          },
        }}
        MenuListProps={{
          style: {
            padding: "4px",
            maxHeight: "250px",
          },
        }}>
        {groupTitle && (
          <Box pt="4px" mb={"8px"} pl={"12px"}>
            <Text fontSize="10px">{groupTitle}</Text>
          </Box>
        )}

        {options.map((option) => {
          return (
            <MenuItem
              sx={{
                padding: "0px 0px",
                minWidth: "fit-content",
                fontSize: "14px",
              }}
              onClick={() => handleChange(option)}>
              <Box
                style={{ padding: "4px 8px" }}
                display="flex"
                justifyContent={"center"}
                alignItems="center">
                {/* <PersonAddIcon
									style={{ fontSize: "16px", opacity: 1, marginRight: "8px" }}
								/> */}
                {/* {option.Icon} */}
                <Box
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: "16px",
                      opacity: 1,
                      marginRight: "8px",
                    },
                  }}>
                  <img style={{ marginRight: "8px" }} src={option.Icon} />
                </Box>
                {/* <Icon fontSize="16px">{option.Icon}</Icon> */}
                <Text fontSize="12px">{option.label}</Text>
              </Box>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
