// import { Box } from "@mui/material";
// import { DeleteOutlineIcon, EditIcon } from "@mui/base";
// import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
// import EditIcon from "@mui/icons-material/Edit";
// import Text from "../../components/Atoms/Text";

// const HeaderWrapper = (params) => {
//   return (
//     <Box display="flex" flexWrap="wrap">
//       <Text fontSize="14px" semiBold fontWeight="600">
//         {params.colDef.headerName}
//       </Text>
//     </Box>
//   );
// };

export const getColumns = (data) => {
  console.log(Object.keys(data));
  return Object.keys(data).map((x) => ({
    field: x,
    headerName:
      x?.replaceAll("_", " ").charAt(0).toUpperCase() +
      x?.replaceAll("_", " ").slice(1),
    // flex: 1,		},
    width: "150",
  }));
};
