import { Box, Switch } from "@mui/material";
// import { DeleteOutlineIcon, EditIcon } from "@mui/base";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import Text from "../../components/Atoms/Text";
import moment from "moment";
const HeaderWrapper = (params) => {
  return (
    <Box display="flex" flexWrap="wrap">
      <Text fontSize="14px" semiBold fontWeight="600">
        {params.colDef.headerName}
      </Text>
    </Box>
  );
};

export const getColumns = ({ onToggleActive, onDeleteClick, onEditClick }) => {
  return [
    {
      field: "client_id",
      headerName: "Client ID",
      // flex: 1,
      width: 100,
    },
    {
      field: "clientName",
      headerName: "Client Name",
      width: 180,
      renderHeader: (params) => HeaderWrapper(params),
    },
    { field: "email", headerName: "Email", minWidth:350 },
    {
      field: "mobileNo",
      headerName: "Mobile",
      width: 180,
      // renderCell: (params) => mobileNo,
      // renderHeader: (params) => {
      // 	return (
      // 		<Box display="flex" flexWrap="wrap">
      // 			<Text fontSize="16px" semiBold fontWeight="600">
      // 				{"Inst. User ID"}
      // 			</Text>
      // 		</Box>
      // 	);
      // },
    },
    {
      field: "noOfBranches",
      headerName: "No of Branches",
      width: 180,
      renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "noOfUsers",
      headerName: "No of Users",
      width: 180,
      renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "lastUpdatedAt",
      headerName: "Update at",
      width: 300,
      renderCell: (params) => {
        const date = params?.formattedValue;
        if (date) {
          return moment(date).format('lll');
        }
        return "";
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      disableExport: true,
      renderCell: (params) => {
        return (
          <Switch
            checked={params.value}
            onChange={(e) => onToggleActive(e, params.row.id)}
            defaultChecked
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 90,
      sortable: false,
      disableExport: true,
      renderCell: (params) => {
        return (
          <Box display="flex" justifyContent={"space-between"} width="60%">
            <EditIcon
              onClick={() => onEditClick(params.row.id)}
              sx={{cursor: "pointer", marginRight: "8px" }}
            />
            <DeleteOutlineIcon
              onClick={() => onDeleteClick(params.row.id)}
              sx={{ color: "red", cursor: "pointer", marginLeft: "8px" }}
            />    
          </Box>
        );
      },
    },
  ];
};
