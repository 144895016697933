import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useFieldArray } from "react-hook-form";

import CustomerAccordon from "../../Molecules/CustomerAccordon";
import TextInput from "../../Form-Components/TextInput";
import SelectInput from "../../Form-Components/SelectInput";
import Text from "../../Atoms/Text";
import { documents, uploadDoc } from "../../../assets/icons";
import { addressDocs, defaultDocs, identityDocs } from "./formElements";
import PDFIcon from "../../../assets/icons/PDFIcon.png";
import TiffIcon from "../../../assets/icons/TiffImage.png";
import tifImage from "../../../assets/icons/tifImage.png";
import { addressByIdentityMandatoryArray } from "../../../utils";

const UploadDocument = (props) => {
  const {
    formProps,
    addedDocs,
    setAddedDocs,
    dropdownOptions,
    getUniqOptions,
    files
  } = props;
  const { register, setValue, control, getValues, watch, errors, trigger } =
    formProps;
  const { fields, append } = useFieldArray({
    control,
    name: "commonDocs",
  });

  const { fields: addressFields, append: addrAppend } = useFieldArray({
    control,
    name: "addressDocs",
  });

  const { fields: identityFields, append: identityAppend } = useFieldArray({
    control,
    name: "identityDocs",
  });

  const [dragActive, setDragActive] = React.useState(false);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleFiles = (file, field, index) => {
    //accepted: '.tif', '.tiff', '.pdf', '.jpeg', '.jpg'

    const maxFieldFileSize = {
      Signature: 50000,
      Photograph: 50000,
    };

    const maxSize = maxFieldFileSize[field.label] || 100000;

    const supportedFormats = [
      "image/jpeg",
      "image/tif",
      "image/tiff",
      "application/pdf",
      "image/jpg",
    ];
    if (0 > supportedFormats.indexOf(file.type)) {
      alert("unsupported format");
      return;
    }
    if (file.size > maxSize) {
      alert(`File should be less than ${maxSize / 1000}kb`);
      return;
    }
    setValue(`${field.key}.${index}.file`, file);

    setAddedDocs({
      ...addedDocs,
      files: [...addedDocs.files, file],
      filesData: [
        ...addedDocs.filesData,
        { id: null, fileName: file.name, key: field.key },
      ],
    });
  };

  // triggers when file is dropped
  const handleDrop = function (e, field, index) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files[0], field, index);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e, field, index) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files[0], field, index);
    }
  };

  const onSelect = (key, value, field) => {
    getOptions(field);
  };

  const removeAddedFile = (key, index) => {
    let values = getValues(key);
    values = values.map((val, i) =>
      index === i ? { ...val, file: null } : val
    );
    setValue(key, values);
  };

  const addressDocNeeded = () =>
    getValues("identityDocs").every(
      (i) =>
        dropdownOptions["identityProof"].find(
          (p) => p?.documentCode === i?.documentType
        )?.address === "N"
    );

  const docs = [
    {
      label: "Identity Proof",
      docs: identityFields,
      key: "identityDocs",
      add: identityAppend,
      addMoreNeeded: false,
    },
    {
      label: "Address Proof",
      docs: addressFields,
      key: "addressDocs",
      add: addrAppend,
      addMoreNeeded: false,
    },

    {
      label: "Other Documents",
      docs: fields,
      add: append,
      key: "commonDocs",
      addMoreNeeded: true,
    },
  ];

  const docsOptions = {
    addressDocs: "addressProof",
    identityDocs: "identityProof",
    commonDocs: "identityProof",
  };

  const getDropdownOptions = (options, field) => {
    if (field.key === "identityDocs") {
      return options.filter((x) => !["Photograph", "Signature"].includes(x.name));
    }
    return options;
  };

  const getOptions = (field, index) => {
    const options = getUniqOptions(
      [
        ...dropdownOptions["addressProof"],
        ...dropdownOptions["identityProof"],
        ...defaultDocs,
      ],
      "documentCode"
    );
    let arr = docs?.map((ele) =>
      getValues()?.[ele.key]?.map((itm) => itm.documentType)
    );

    if (field.key === "commonDocs") {
      const exludeItems = ["Photograph", "Signature"];
      if (exludeItems.includes(field.label))
        return options?.filter(
          (ele) =>
            !arr.flat(1)?.includes(parseInt(ele.documentCode)) ||
            ele.documentCode == getValues()?.[field.key]?.[index]?.documentType
        );
      else
        return options.filter(
          (x) =>
            !exludeItems.includes(x.name) &&
            (!arr.flat(1)?.includes(parseInt(x.documentCode)) ||
              x.documentCode == getValues()?.[field.key]?.[index]?.documentType)
        );
    }

    return getUniqOptions(
      getDropdownOptions(dropdownOptions[docsOptions[field.key]], field)
    )?.filter(
      (ele) =>
        !arr.flat(1)?.includes(parseInt(ele.documentCode)) ||
        ele.documentCode == getValues()?.[field.key]?.[index]?.documentType
    )?.filter((items, index) => watch("constitutionType") === "01" ? !["18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "98", "99"].includes(items.documentCode) : ["18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "98", "99"].includes(items.documentCode));
  };

  const getFieldLabel = (key) => {
    const options = getUniqOptions([
      ...dropdownOptions["addressProof"],
      ...dropdownOptions["identityProof"],
      ...dropdownOptions["additional"],
    ]);
    const currentSelected = getValues(key);
    const selectedItem = options.find(
      (x) => parseInt(x?.documentCode) === currentSelected
    );
    return selectedItem ? selectedItem?.name : null;
  };

  const isOtherFile = (field) => {
    if (field.key === "commonDocs") {
      const exludeItems = ["Photograph", "Signature"];
      const isOther =
        exludeItems.includes(field.label) || exludeItems.includes(field.name);
      return !isOther;
    }
    return false;
  };

  const identityDoc = watch("identityDocs");
  const FieldValidation = [
    {
      id: "3",
      label: "PAN",
      pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/
    },
    {
      id: "4",
      label: "Proof of Possession of Aadhaar",
      pattern: /^\d{4}$/,
    },
    {
      id: "5",
      label: "Passport",
      pattern: /^[A-Za-z]{1}[0-9]{7}$/,
    },
    {
      id: "6",
      label: "Driving License",
      pattern: /^[A-Z]{2}[0-9]{13}$/,
    },
    {
      id: "7",
      label: "Voters Identity Card",
      pattern: /^[A-Za-z]{3}[0-9]{7}$/,
    },
    {
      id: "8",
      label: "NREGA Job Card",
      pattern: /^\d{2}\/\d{4}\/\d{5}$/,
    },
    {
      id: "35",
      label: "National Population Register Letter",
      pattern: "",
    },
    {
      id: "36",
      label: "E-KYC Authentication",
      pattern: "",
    },
    {
      id: "37",
      label: "Offline Verification of Aadhaar",
      pattern: /^\d{4}$/,
    },
  ];
  const patternValidation = (fieldValue) => {
    return FieldValidation?.find(
      (items) => items?.id == fieldValue?.documentType
    )?.pattern;
  };

  return (
    <Box>
      {docs?.map((item) => {
        // if (item.label === "Address Proof" && !addressDocNeeded()) return;
        // else

        return (
          <React.Fragment>
            {item.label && (
              <Text fontWeight="500" fontSize="16px" mb="16px">
                {item.label}
              </Text>
            )}
            {item?.docs.map((field, index) => {
              const file = getValues()?.[item.key]?.[index]?.file;
              const fileName = file ? file?.name || file?.fileName : "";
              const fileUrl = file
                ? file?.fileUrl || URL.createObjectURL(file)
                : null;
              const opts =
                field.key === "identityDocs" || field.key === "addressDocs"
                  ? getOptions(field, index).filter(
                      (item) => item.name !== "PAN"
                    )
                  : getOptions(field, index);
              return (
                <React.Fragment>
                  <Box
                    mt={"8px"}
                    mb={"20px"}
                    width="100%"
                    alignItems="flex-start"
                    // flexDirection={"column"}
                    display="flex"
                  >
                    <Box display="flex" width="95%" alignItems="center">
                      <CustomerAccordon
                        width="100%"
                        title={
                          getFieldLabel(`${item.key}.${index}.documentType`) ||
                          field.label
                        }
                        mandatory={
                          field.label === "Address Proof" 
                            ? !addressByIdentityMandatoryArray?.includes(identityDoc?.[0]?.documentType) ? true :  getValues()?.[item.key]?.[index]?.mandatory 
                            : field.label === "Identity Proof"
                            ? true
                            : getValues()?.[item.key]?.[index]?.mandatory
                        }
                      >
                        <Box
                          display="flex"
                          width="100%"
                          flexWrap="wrap"
                          justifyContent={"space-between"}
                        >
                          <Box width="40%">
                            {field.key === "identityDocs" ||
                            field.key === "addressDocs" ? (
                              <SelectInput
                                label={"Document Type"}
                                showNone
                                fieldkey={`${item.key}.${index}.documentType`}
                                errorText=""
                                value={
                                  getValues()?.[item.key]?.[index]?.documentType
                                }
                                placeholder="Select Document Type"
                                control={control}
                                register={register}
                                mandatory={!addressByIdentityMandatoryArray?.includes(identityDoc?.[0]?.documentType) ? true 
                                  : getValues()?.[item.key]?.[index]?.mandatory
                                }
                                setValue={setValue}
                                options={opts}
                                onSelect={(key, value) => {
                                  onSelect(key, value, field, index);
                                  setValue(
                                    `${item.key}.${index}.mandatory`,
                                    value !== ""
                                  );
                                  setValue(
                                    `${item.key}.${index}.mandatory`,
                                    value !== ""
                                  );
                                }}
                                disabled={!field.docTypeNeeded}
                                // valueRenderer={(option) =>
                                // 	option.id ||
                                // 	option.docCode ||
                                // 	option.documentType
                                // }
                                valueRenderer={(option) =>
                                  parseInt(option?.documentCode)
                                }
                                displayName={(option) => option?.documentName || option?.name}
                                width="100%"
                                watch={watch}
                              />
                            ) : (
                              <SelectInput
                                label={"Document Type"}
                                showNone
                                fieldkey={`${item.key}.${index}.documentType`}
                                errorText=""
                                placeholder="Select Document Type"
                                control={control}
                                register={register}
                                value={parseInt(
                                  getValues()?.[item.key]?.[index]?.documentType
                                )}
                                mandatory={
                                  field.label === "Address Proof"
                                    ? addressDocNeeded()
                                    : getValues()?.[item.key]?.[index]
                                        ?.mandatory
                                }
                                setValue={setValue}
                                options={[
                                  ...opts,
                                  ...dropdownOptions["additional"],
                                ]}
                                onSelect={(key, value) => {
                                  onSelect(key, value, field, index);
                                  setValue(
                                    `${item.key}.${index}.mandatory`,
                                    value !== ""
                                  );
                                }}
                                disabled={!field.docTypeNeeded}
                                // valueRenderer={(option) =>
                                // 	option.id ||
                                // 	option.docCode ||
                                // 	option.documentType
                                // }
                                valueRenderer={(option) =>
                                  parseInt(option?.documentCode)
                                }
                                displayName={(option) =>  option?.documentName || option?.name}
                                width="100%"
                                watch={watch}
                              />
                            )}
                            {field?.numberNeeded && (
                              <TextInput
                                label={`${
                                  getFieldLabel(
                                    `${item.key}.${index}.documentType`
                                  ) || field.label
                                } Number`}
                                fieldkey={`${item.key}.${index}.number`}
                                errorText={
                                  errors[item.key]?.[index]?.number?.message
                                }
                                fieldErr
                                placeholder=""
                                control={control}
                                register={register}
                                mandatory={!addressByIdentityMandatoryArray?.includes(identityDoc?.[0]?.documentType) ? true 
                                  : getValues(`${item.key}.${index}.mandatory`)
                                }
                                pattern={patternValidation(
                                  getValues()?.[item?.key]?.[index]
                                )}
                                // pattern={/^[0-9]*$/}
                                width="100%"
                                getValues={getValues}
                                setValue={setValue}
                                value={getValues()?.[item?.key]?.[index].number}
                                trigger={formProps?.trigger}
                                clearErrors={formProps?.clearErrors}
                                isAadhar={[4, 37].includes(getValues()?.[item.key]?.[index]?.documentType) ? true : false}
                              />
                            )}
                            <TextInput
                              label={"Remarks"}
                              fieldkey={`${item.key}.${index}.remarks`}
                              errorText="Branch name is required"
                              placeholder=""
                              control={control}
                              register={register}
                              // mandatory
                              width="100%"
                              getValues={getValues}
                              setValue={setValue}
                              value={getValues()?.[item?.key]?.[index]?.remarks}
                            />
                          </Box>
                          <Box display="flex" mt={"32px"} width="50%">
                            {file ? (
                              <Box
                                py="16px"
                                width="100%"
                                px={"12px"}
                                display="flex"
                                alignItems="center"
                                justifyContent={"space-around"}
                                style={{
                                  border: "1px dashed gray",
                                }}
                              >
                                <Box width="30%">
                                  {file?.fileName
                                    ?.toLowerCase()
                                    .endsWith(".pdf") ||
                                  file?.name?.toLowerCase().endsWith(".pdf") ? (
                                    <img
                                      src={PDFIcon}
                                      height="120px"
                                      width="120px"
                                      alt="filer url"
                                    />
                                  ) : file?.fileName
                                      ?.toLowerCase()
                                      .endsWith(".tiff") ||
                                    file?.name
                                      ?.toLowerCase()
                                      .endsWith(".tiff") ? (
                                    <img
                                      src={TiffIcon}
                                      height="120px"
                                      width="120px"
                                      alt="filer url"
                                    />
                                  ) : file?.fileName
                                      ?.toLowerCase()
                                      .endsWith(".tif") ||
                                    file?.name
                                      ?.toLowerCase()
                                      .endsWith(".tif") ? (
                                    <img
                                      src={tifImage}
                                      height="120px"
                                      width="120px"
                                      alt="filer url"
                                    />
                                  ) : (
                                    <img
                                      src={fileUrl}
                                      height="100px"
                                      width="150px"
                                      alt="filer url"
                                    />
                                  )}
                                </Box>
                                <Box width="40%" mx={"16px"}>
                                  <Text
                                    fontSize="16px"
                                    style={{ wordBreak: "break-word" }}
                                  >
                                    {fileName}
                                  </Text>
                                  <Text
                                    mt="16px"
                                    fontSize="16px"
                                    color="#5C94F6"
                                    cursor="pointer"
                                    onClick={() =>
                                      window.open(fileUrl, "_blank")
                                    }
                                  >
                                    {"View Image"}
                                  </Text>
                                </Box>
                                <Box>
                                  <DeleteOutlineIcon
                                    sx={{ fontSize: "40px", color: "red" }}
                                    onClick={() =>
                                      removeAddedFile(item.key, index)
                                    }
                                  />
                                </Box>
                              </Box>
                            ) : (
                              <Box
                                p="16px"
                                width="80%"
                                px={"24px"}
                                onDragEnter={(e) => handleDrag(e, field, index)}
                                style={{
                                  background: dragActive ? "white" : "#FBFBFF",
                                  border: "1px solid #E5E5E5",
                                  borderRadius: "12px",
                                  position: "relative",
                                }}
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <img src={uploadDoc} alt="upload-img" />
                                <Text
                                  fontSize="14px"
                                  mb="8px"
                                  mt="4px"
                                  fontWeight="500"
                                >
                                  Drag and drop file to import OR{" "}
                                  {!addressByIdentityMandatoryArray?.includes(identityDoc?.[0]?.documentType) ? (<span style={{ color: "red" }}>*</span>) 
                                  : getValues()?.[item.key]?.[index]
                                    ?.mandatory ? (
                                    <span style={{ color: "red" }}>*</span>
                                  ) : (
                                    ""
                                  )}
                                </Text>

                                <label
                                  htmlFor={`contained-button-file-${field.key}-${index}`}
                                >
                                  <Button
                                    // onClick={onButtonClick}
                                    component="span"
                                    size="small"
                                    variant="contained"
                                  >
                                    <input
                                      style={{ display: "none" }}
                                      id={`contained-button-file-${field.key}-${index}`}
                                      type="file"
                                      data-key={field.key}
                                      onChange={(e) =>
                                        handleChange(e, field, index)
                                      }
                                    />
                                    Choose Files
                                  </Button>
                                </label>

                                <Text mt="8px" fontSize="10px" fontWeight="500">
                                  File Format : JPG, JPEG, TIF, TIFF or PDF
                                </Text>
                                <Text mt="6px" fontSize="10px" fontWeight="500">
                                  {`Maximum File Size:  ${
                                    ["Photograph", "Signature"].includes(
                                      field?.label
                                    )
                                      ? "50kb"
                                      : "100kb"
                                  }`}
                                </Text>
                                {fileName && (
                                  <Text
                                    mt="8px"
                                    fontSize="10px"
                                    fontWeight="500"
                                  >
                                    {`Selected File: ${fileName}`}
                                  </Text>
                                )}
                                {dragActive && (
                                  <div
                                    id="drag-file-element"
                                    data-key={field.key}
                                    onDragEnter={(e) =>
                                      handleDrag(e, field, index)
                                    }
                                    onDragLeave={(e) =>
                                      handleDrag(e, field, index)
                                    }
                                    onDragOver={(e) =>
                                      handleDrag(e, field, index)
                                    }
                                    onDrop={(e) => handleDrop(e, field, index)}
                                  ></div>
                                )}
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </CustomerAccordon>
                    </Box>
                    {(field.canBeDeleted || isOtherFile(field)) && (
                      <Box mt="10px" ml="4px">
                        <DeleteOutlineIcon
                          onClick={() => {
                            const docments = [...getValues()?.[item.key]];
                            let idx = docments?.findIndex(
                              (ele) =>
                                ele.documentType ===
                                getValues()?.[item.key]?.[index]?.documentType
                            );
                            docments.splice(idx, 1);
                            setValue(item.key, docments);
                          }}
                          sx={{
                            marginLeft: "8px",
                            color: "red",
                            cursor: "pointer",
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </React.Fragment>
              );
            })}
            {item?.label && item.addMoreNeeded && (
              <Box
                display={"flex"}
                alignItems="center"
                mb={"20px"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  item.add({
                    label: "New Document",
                    key: item.key,
                    mandatory: true,
                    documentType: "",
                    remarks: "",
                    number: "",
                    docTypeNeeded: true,
                    numberNeeded: true,
                    canBeDeleted: true,
                  });
                }}
                // onClick={addOneMoreDoc}
              >
                <AddCircleOutlineIcon sx={{ color: "#5B58FF" }} />
                <Text color="#5B58FF" ml="6px" fontSize="16px" fontWeight="500">
                  Add More Documents
                </Text>
              </Box>
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default UploadDocument;
