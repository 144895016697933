import React, { Component } from "react";
import Routes from "./routes";
import { ThemeProvider } from "@mui/material";
import theme from "./themes";
import { GlobalContextProvider } from "../src/context/GlobalContext";
import PublicRoutes from "./routes/PublicRoutes";
import PrivateRoutes from "./routes/PrivateRoutes";
import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);
    // window.addEventListener("storage", ({ oldValue, newValue }) => {
    // 	// Note: For this app we don't have any server to verify role/roles, in your case you can verify role/roles from your server and update accordingly.
    // 	alert(
    // 		`You can not change role/roles from ${oldValue} to ${newValue}, if you want to change role/roles please log out and then log in with a different roles.`
    // 	);
    // 	localStorage.setItem("roles", oldValue);
    // });
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <GlobalContextProvider>
          <Routes />
        </GlobalContextProvider>
      </ThemeProvider>
    );
  }
}

export default App;
