import React, { memo } from "react";
// import { Route, Link, Navigate } from "react-router-dom";

import PrivateRoutes from "./PrivateRoutes";
import { isLoggedIn } from "../utils";
import PublicRoutes from "./PublicRoutes";
import PopUp from "../components/Molecules/PopUp";
import { useGlobalContext } from "../context/GlobalContext";

function Routes() {
  const {
    state: { popUp, isLoggedin: isUserExist },
  } = useGlobalContext();
  return (
    <div>
      {(isLoggedIn() || isUserExist) && <PrivateRoutes />}
      {!isLoggedIn() && !isUserExist && <PublicRoutes />}
      {<PopUp open={popUp.open} />}
    </div>
  );
}

export default memo(Routes);
