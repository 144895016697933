import React from "react";
import { Box, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";

import Text from "../Atoms/Text";

const PassordInput = (props) => {
  const {
    label,
    fieldkey,
    defaultValue,
    errorText,
    placeholder,
    control,
    register,
    mandatory,
    fullWidth,
    disabled,
    width,
  } = props;

  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <Box
      display="flex"
      key={fieldkey}
      flexDirection="column"
      sx={{
        marginTop: "1rem",
        width: fullWidth ? "92%" : width || "45%",
        marginRight: "1rem",
      }}>
      <Text mandatory={mandatory} mb="4px">
        {label}
      </Text>
      <Controller
        render={({ field, fieldState: { isTouched, error } }) => (
          <Box>
            <OutlinedInput
              {...field}
              {...register(fieldkey)}
              id={fieldkey}
              size="small"
              error={isTouched && error}
              helperText={isTouched && error && errorText}
              autoComplete="new-password"
              type={showPassword ? "text" : "password"}
              placeholder={placeholder || ""}
              disabled={disabled}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {!showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {error && errorText && (
              <Text
                mt={"4px"}
                letterSpacing="0.4px"
                fontSize="12px"
                ml={"16px"}
                color="#d32f2f">
                {errorText}
              </Text>
            )}
          </Box>
        )}
        name={fieldkey}
        control={control}
      // defaultValue={defaultValue}
      />
    </Box>
  );
};

export default PassordInput;
