import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';
import { tooltip } from '../assets/icons';
import { Box, Button } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from "@mui/icons-material/Download";
//const pageTitle = "Automation Dashboard";

import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,

} from "@mui/x-data-grid";

import { Tooltip, IconButton } from '@mui/material';

const AutomationOverview = ({ columns, rows }) => {
  const renderKeyCell = (params) => {

      
  if(params.value === "CKYC Rejected"){
    return (
      <Tooltip title={`Navigate to rejected `} placement="top" >
    
          <span>
        {params.value}
        <IconButton size="small" style={{ fontSize: '0.8rem' }}>
          <img src={tooltip} alt="tool-tip" style={{ fontSize: '1.1rem',color: 'red'}} />
        </IconButton>
      </span>
    </Tooltip>
    );
  }
    if(params.value === "Failed Customer Count"){
    return (
      <Tooltip title={`Navigate to New customers and filter by status`} placement="top" >
    
          <span>
        {params.value}
        <IconButton size="small" style={{ fontSize: '0.8rem' }}>
          <img src={tooltip} alt="tool-tip" style={{ fontSize: '1.1rem', color: 'red'}} />
        </IconButton>
      </span>
    </Tooltip>
    );
  }
  if(params.value === "CERSAI Download"){
    return (
      <Tooltip title={`Navigate to Update customers and filter by automation remarks `} placement="top" >
    
          <span>
        {params.value}
        <IconButton size="small" style={{ fontSize: '0.8rem',}}>
          <img src={tooltip} alt="tool-tip" style={{ fontSize: '1.1rem',color: 'red'}} />
        </IconButton>
      </span>
    </Tooltip>
    );
  }

  if(params.value === "ZIP Download"){

    return (
      <Tooltip title={`Navigate to CKYC Upload and filter by status`} placement="top" >
    
          <span>
        {params.value}
        <IconButton size="small" style={{ fontSize: '0.8rem' }}>
          <img src={tooltip} alt="tool-tip" style={{ fontSize: '1.1rem',color: 'red'}} />
        </IconButton>
      </span>
    </Tooltip>
    );
  }
  };
  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    return (
      [<Button
        startIcon={<DownloadIcon />}
        size="small"
        variant="outlined"
        sx={{ textTransform: "none", marginRight: "480px" }}
     //   className='classButton'
        onClick={() => apiRef.current.exportDataAsCsv()}
        >
        Export
      </Button>]
    )
  };

  
const CustomHeaderCell = () => {
  return null; // Return null to remove the header cell
};

  return (
    <div>
      <Box sx={{ height: 400, width: '100%', background: "#FFFFFF", border: "1px solid #FFDCDD", borderRadius: "8px" }}>
        <DataGrid
                disableSelectionOnClick = {true}

          sx={{
            "& .MuiDataGrid-columnSeparator": {
              opacity: 0,
              visibility: "hidden",
            },
            "& .MuiDataGrid-columnHeaders": {
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              height: "42px",
              minHeight: "42px !important",
              outline: "none !important",
              fontWeight: 500,
              background: "rgba(63,129,244,.09)",
            },
            "&.MuiDataGrid-root": {
              border: "none",
              overflowX: "scroll",
            },

            "& .MuiDataGrid-columnHeader:hover .MuiDataGrid-columnMenuIcon ": {
              display: "none !important"
            },

            "& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell,  .MuiDataGrid-cell:focus ":
            {
              outline: "none !important",
              border: "none",
              // justifyContent: "center",
            },
            " & .MuiDataGrid-iconButtonContainer": {
              display: "none"
            },
            
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: "14px",
              fontWeight: 500,
            },
            "&. MuiDataGrid-row": {
              overflowX: "scroll",
              OverflowY: "hidden",
            },
            "& .MuiDataGrid-cell": {
              border: "none",
              justifyContent:"left"
              //   justifyContent: "center",
            },
            "& .MuiDataGrid-menuIcon ":{
                   display:"none"
            },
            "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
              background: "inherit",
              boxShadow: "none",
              // padding: "4px",
            },

            "&  .MuiDataGrid-columnHeader:hover .MuiDataGrid-sortIcon": {
              display: "none !important"
            },
            "& .MuiDataGrid-selectedRowCount ":{
               display :"none !important"
            },

            "& .MuiDataGrid-columnHeaderTitleContainer": {
              // justifyContent: "center",
            },
            overflowX: "scroll",
          }}
          rows={rows}
          columns={columns.map((column) => {
            if (column.field === 'key') {
              return {
                ...column,
                renderCell: renderKeyCell,
              };
            }
            return column;
          })}
          
          // components={{ Pagination: CustomPagination }}
          components={{ Pagination: CustomPagination,
           // HeaderCell: CustomHeaderCell,
           HeaderCell: CustomHeaderCell,
          }}
          componentsProps={{
            header: {
              disableColumnMenu: true,
              disableColumnSort: true,
            },
          }}
          
          checkboxSelection={false}
          disableRowSelectionOnClick={false}
          disableColumnSelector={false}
        // pagination={null}
        />
      </Box>
    </div>
  )
}

export default AutomationOverview;