import PersonIcon from "@mui/icons-material/Person";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import React from "react";

import { getCustomerHistory } from "../../apis/users";
import Text from "../Atoms/Text";
import Modal from "../Molecules/Modal";

const CustomerJoruneyPanel = (props) => {
  const { toggleDrawer, open, customerId, error, status } = props;

  const {
    data,
    refetch,
    isSuccess: customersFetched,
  } = useQuery(
    [
      "history",
      {
        customerId,
      },
    ],
    getCustomerHistory,
    {
      manual: true,
      select: (response) => response.data,
      retry: false,
    }
  );

  return (
    <Modal title={`History`} open={open} toggleDrawer={toggleDrawer}>
      <Box
        display="flex"
        flexDirection={"column"}
        sx={{ overflowY: "auto", width: "100%", padding: "16px" }}
      >
        {!data ||
          (data && data[0].length === 0 && !error && <Box>No History</Box>)}
        {error && (
          <Box
            ml="4px"
            px="4px"
            py="2px"
            sx={{
              backgroundColor: "#FFBABA80",
              borderRadius: "4px",
              height: "fit-content",
              width: "80%",
              padding: "0.7rem 0.9rem",
            }}
          >
            <Text mb="4px" fontSize="16px">
              Error: {error}
            </Text>
            <Text fontSize="10px">Job Status: {status}</Text>
          </Box>
        )}
        {data && data[0].length > 0 && (
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {data[0]
              ?.sort(function (a, b) {
                return new Date(b.createdDate) - new Date(a.createdDate);
              })
              ?.map((item, index) => {
                return data[0].length - 1 !== index ? (
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        variant="outlined"
                        sx={{
                          backgroundColor: "#D9D9FFAD",
                          borderColor: "white",
                        }}
                      >
                        <PersonIcon />
                      </TimelineDot>
                      <TimelineConnector sx={{ bgcolor: "#D9D9FFAD" }} />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: "10px", px: 2 }}>
                      <Box display="flex" alignItems="center" mb="4px">
                        <Text fontSize="16px" semiBold>
                          {item.customerName}
                        </Text>
                        <Box
                          ml="4px"
                          px="4px"
                          py="2px"
                          sx={{
                            backgroundColor: "blue",
                            color: "white",
                            borderRadius: "4px",
                            height: "fit-content",
                          }}
                        >
                          <Text fontSize="8px">{item.role}</Text>
                        </Box>
                        <Text ml="8px" fontSize="12px" color="#A1A1A1">
                          {/* 01-Dec-2022 @ 10am */}
                          {item.createdDate
                            ? dayjs(item.createdDate).format("lll")
                            : ""}
                        </Text>
                      </Box>
                      <Text mb="4px" fontSize="14px">
                        {item.status}
                      </Text>
                      <Box display="flex" mb="12px" alignItems={"center"}>
                        <Text fontSize="14px" semiBold mr="8px">
                          Remarks:
                        </Text>
                        <Text fontSize="14px">
                          {item?.remarks !== "null" ? item.remarks : "NA"}
                        </Text>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                ) : (
                  <TimelineItem>
                    <TimelineDot
                      variant="outlined"
                      sx={{
                        backgroundColor: "#D9D9FFAD",
                        borderColor: "white",
                      }}
                    >
                      <PersonIcon />
                    </TimelineDot>
                    <TimelineContent sx={{ py: "10px", px: 2 }}>
                      <Box display="flex" alignItems="center" mb="4px">
                        <Text fontSize="16px" semiBold>
                          {item.customerName}
                        </Text>
                        <Box
                          ml="4px"
                          px="4px"
                          py="2px"
                          sx={{
                            backgroundColor: "blue",
                            color: "white",
                            borderRadius: "4px",
                            height: "fit-content",
                          }}
                        >
                          <Text fontSize="8px">{item.role}</Text>
                        </Box>
                        <Text ml="8px" fontSize="12px" color="#A1A1A1">
                          {/* 01-Dec-2022 @ 10am */}
                          {item.createdDate
                            ? dayjs(item.createdDate).format("lll")
                            : ""}
                        </Text>
                      </Box>
                      <Text mb="4px" fontSize="14px">
                        {item.status}
                      </Text>
                      <Box display="flex" mb="12px" alignItems={"center"}>
                        <Text fontSize="14px" semiBold mr="8px">
                          Remarks:
                        </Text>
                        <Text fontSize="14px">
                          {item?.remarks !== "null" ? item.remarks : "NA"}
                        </Text>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
          </Timeline>
        )}
      </Box>
    </Modal>
  );
};

export default CustomerJoruneyPanel;
