import React from "react";
import { Box, Tabs, Tab } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import PublishIcon from "@mui/icons-material/Publish";
import { LoadingButton } from "@mui/lab";

import Text from "../Atoms/Text";
import DropdownButton from "../Atoms/DropdownButton";
import SplitButton from "../Atoms/SplitButton";

const PageHeader = ({
  pageTitle,
  isSearch,
  handleTabChange,
  TabValue,
  job,
  searchOnChange,
  leftSideActions,
  rightSideActions,
  totalCount,
  activeTab,
  activeTabPopup,
  handlePopup,
  currentRole,
  leftSideOptions,
}) => {
  return (
    <Box>
      <Box
        style={{
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          background: "rgba(63,129,244,.09)",
          padding: "12px 20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box display="flex" alignItems="center">
          <Text fontSize="20px" mr="14px" semiBold>
            {`${pageTitle} ${
              totalCount && totalCount !== 0 ? `(${totalCount})` : ""
            }`}
          </Text>
        </Box>
        <Box display="flex">
          {isSearch && (
            <TextField
              id="input-with-icon-textfield"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder="Search by..."
              variant="outlined"
              onChange={searchOnChange}
              size="small"
              sx={{
                backgroundColor: "rgba(63,129,244,.09)",
                borderRadius: "6px",
                fieldset: { borderColor: "rgba(63,129,244,.09)" },
              }}
            />
          )}
        </Box>
      </Box>
      <Box display={"flex"} justifyContent="space-between">
        {/* <Box px={"20px"} pt={"10px"} display="flex" justifyContent="flex-start">
          {rightSideActions &&
            rightSideActions.map((action) => {
        <Box px={"20px"} pt={"10px"} display="flex" justifyContent="flex-start">
          {currentRole === "Admin"  || currentRole === "SuperAdmin" ? (
            <Tabs 
            value={TabValue} 
            onChange={handleTabChange} aria-label="basic tabs example">
              {rightSideActions?.map((action, index) => {
                return (
                  <Tab label={action?.title}  value={index}/>
                )
              })}
            </Tabs>
          ) : (
            rightSideActions && rightSideActions?.map((action) => {

              return (
                <div>
                  {action.isDropdown && (
                    <DropdownButton
                      variant="outlined"
                      options={action.options}
                      onClick={action?.onClick}
                      groupTitle={action?.groupTitle}
                    />
                  )}
                  {action.isButton && (
                    // <Button
                    // 	variant="contained"
                    // 	size="small"
                    // 	onClick={action?.onClick}
                    // 	sx={{ marginRight: "4px" }}
                    // 	startIcon={action?.icon}>
                    // 	{action.title}
                    // </Button>
                    <LoadingButton
                      variant="contained"
                      size="small"
                      loading={action?.loading}
                      onClick={action?.onClick}
                      sx={{ marginRight: "4px" }}
                      startIcon={action?.icon}
                      disabled={action?.disabled}
                      style={{ textTransform: "none" }}>
                      {action.title}
                    </LoadingButton>
                  )}
                  {action.isSplit && (
                    <SplitButton
                      title={action.title}
                      addUser={action.onClick}
                      options={action.options}
                      onMenuClick={action.onClick}
                    />
                  )}
                </div>
              );
            })}
        </Box>  */}
        <Box px={"20px"} pt={"10px"} display="flex" justifyContent="flex-start">
          {pageTitle === "Docket Listing" ||
          pageTitle === "New Customers" ||
          pageTitle === "Update Customers" ||
          pageTitle === "Customers" ? (
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              {rightSideActions.map((action, index) => {
                return (
                  <Tab key={index} label={action.title} value={action.value} />
                );
              })}
            </Tabs>
          ) : pageTitle.includes("Bulk") ? (
            <Tabs value={activeTabPopup} onChange={handlePopup}>
              {rightSideActions.map((action, index) => (
                <Tab key={index} label={action.title} />
              ))}
            </Tabs>
          ) : currentRole === "Admin" ||
            currentRole === "SuperAdmin" ||
            currentRole === "Maker" ||
            currentRole === "Checker" ? (
            <Tabs
              value={TabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              {rightSideActions?.map((action, index) => {
                return <Tab label={action?.title} value={index} />;
              })}
            </Tabs>
          ) : rightSideActions ? (
            rightSideActions.map((action, index) => (
              <div key={index}>
                {action.isDropdown && (
                  <DropdownButton
                    variant="outlined"
                    options={action.options}
                    onClick={action?.onClick}
                    groupTitle={action?.groupTitle}
                  />
                )}
                {action.isButton && (
                  <LoadingButton
                    variant="contained"
                    size="small"
                    loading={action?.loading}
                    onClick={action?.onClick}
                    sx={{ marginRight: "4px" }}
                    startIcon={action?.icon}
                    disabled={action?.disabled}
                    style={{ textTransform: "none" }}
                  >
                    {action.title}
                  </LoadingButton>
                )}
                {action.isSplit && (
                  <SplitButton
                    title={action.title}
                    addUser={action.onClick}
                    options={action.options}
                    onMenuClick={action.onClick}
                  />
                )}
              </div>
            ))
          ) : (
            ""
          )}
        </Box>
        {activeTabPopup === 1 ? (
          <Box px={"20px"} pt={"10px"} display="flex" justifyContent="flex-end">
            {leftSideOptions?.map((action) => {
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {action.isTitle && (
                    <span
                      style={{
                        color: "#696969",
                        fontSize: "12px",
                        fontWeight: "400",
                        marginRight: "10px",
                      }}
                    >
                      {action.title}
                    </span>
                  )}
                  {action.isDropdown && (
                    <DropdownButton
                      variant="outlined"
                      options={action.options}
                      onClick={action?.onClick}
                      defaultText={
                        action?.displayDefaultText && action?.defaultText
                      }
                      groupTitle={action?.groupTitle}
                    />
                  )}
                  {action.isSplit && (
                    <SplitButton
                      title={action.title}
                      addUser={action.onClick}
                      options={action.options}
                      onMenuClick={action.onClick}
                    />
                  )}
                  {action.isButton && (
                    <Button
                      variant={action?.color || "outlined"}
                      size="small"
                      onClick={action?.onClick}
                      sx={{ marginRight: "4px" }}
                      startIcon={action?.icon}
                      disabled={action?.disabled}
                    >
                      {action.title}
                    </Button>
                  )}
                  {action?.customComponent && action.component()}
                </div>
              );
            })}
          </Box>
        ) : (
          <Box px={"20px"} pt={"10px"} display="flex" justifyContent="flex-end">
            {leftSideActions?.map((action) => {
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {action.isTitle && (
                    <span
                      style={{
                        color: "#696969",
                        fontSize: "12px",
                        fontWeight: "400",
                        marginRight: "10px",
                      }}
                    >
                      {action.title}
                    </span>
                  )}
                  {action.isDropdown && (
                    <DropdownButton
                      variant="outlined"
                      options={action.options}
                      onClick={action?.onClick}
                      defaultText={
                        action?.displayDefaultText && action?.defaultText
                      }
                      groupTitle={action?.groupTitle}
                    />
                  )}
                  {action.isSplit && (
                    <SplitButton
                      title={action.title}
                      addUser={action.onClick}
                      options={action.options}
                      onMenuClick={action.onClick}
                    />
                  )}
                  {action.isButton && (
                    <Button
                      variant={action?.color || "outlined"}
                      size="small"
                      onClick={action?.onClick}
                      sx={{ marginRight: "4px" }}
                      startIcon={action?.icon}
                      disabled={action?.disabled}
                    >
                      {action.title}
                    </Button>
                  )}
                  {action?.customComponent && action.component()}
                </div>
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PageHeader;
