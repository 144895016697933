import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

export default function PlainDatePicker({
  onChange,
  maxDate,
  disabled,
  minDate,
  value: initialValue,
}) {
  const [value, setValue] = React.useState(initialValue || dayjs(""));

  React.useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        <DesktopDatePicker
          label=""
          value={value}
          disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(newValue) => {
            // onChange(dayjs(newValue).format("MM-DD-YYYY"));
            setValue(newValue);
            onChange(newValue);
          }}
          inputFormat="DD-MM-YYYY"
          format="DD-MM-YYYY"
          size="small"
          renderInput={(params) => (
            <TextField sx={{ height: "16px" }} size="small" {...params} />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
}
