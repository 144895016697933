import React from 'react'
import { useFieldArray } from 'react-hook-form';
import { Box, Button } from "@mui/material"
// import SelectInput from "../../Form-Components/SelectInput";
import TextInput from "../../Form-Components/TextInput";
import DatePicker from "../../Form-Components/DatePicker";
import SelectInput from "../../Form-Components/SelectInput";
const MinorKYCVerification = (props) => {
    const { index, formProps, files, dropdownOptions, handleFieldValue } = props
    const { control, register, setValue, getValues, watch } = formProps;
    const [fromDateValue, setFromDateValue] = React.useState(null);
    const [dateOfDeclarion, setDateOfDeclarion] = React.useState(null);
    return (
        <Box display="flex" flexWrap="wrap">
            <SelectInput
                label={"Document Received"}
                fieldkey={`minorDetails[${index}].typeOfDocSubmitted`}
                errorText="Document Received is Required"
                placeholder="State"
                control={control}
                register={register}
                mandatory={true}
                setValue={setValue}
                options={dropdownOptions && dropdownOptions["documentRecieved"]}
                valueRenderer={(option) => option?.code}
                displayName={(option) => option?.name}
                width="30%"
                watch={watch}

            />
            <DatePicker
                width={"30%"}
                label={"Verification Date"}
                fieldkey={`minorDetails[${index}].kycVerifiDate`}
                errorText="Verification Date is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={true}
                format={"DD-MM-YYYY"}
                setValue={setValue}
                getValues={getValues}
                // value={getValues()?.minorDetails?.[index]?.kycVerifiDate}
                value={getValues()?.minorDetails?.[index]?.kycVerifiDate || fromDateValue}
                setDateValue={setFromDateValue}
            />
            <SelectInput
                label={"Employee Name"}
                fieldkey={`minorDetails[${index}].kycVerificationName`}
                errorText="Employee Name is required"
                placeholder="State"
                control={control}
                register={register}
                mandatory={true}
                setValue={setValue}
                options={
                    dropdownOptions && dropdownOptions["employeeNameNew"]
                      ? dropdownOptions["employeeNameNew"]?.filter((item) => item?.roleId == 3 )
                      : [{dmsUserId : "Testing", dmsUserName : "Testing"}]
                  }
                valueRenderer={(option) => option.dmsUserId}
                // valueIdRenderer={(option) => option.dmsUserId}
                displayName={(option) => option?.dmsUserName}
                width="30%"
                watch={watch}
                onChangeValue ={()=> handleFieldValue(index, "employeeName")}
            />
            <TextInput
                label={"Employee Code"}
                fieldkey={`minorDetails[${index}].kycVerifiEmpCode`}
                errorText="Employee Code is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={true}
                width="30%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.kycVerifiEmpCode}
            />
            <SelectInput
                label={"Employee Designation"}
                fieldkey={`minorDetails[${index}].kycVerificationDesignation`}
                errorText="Employee Designation is required"
                placeholder="State"
                control={control}
                register={register}
                mandatory={true}
                setValue={setValue}
                options={dropdownOptions && dropdownOptions["employeeDesignation"]}
                valueRenderer={(option) => option.name}
                valueIdRenderer={(option) => option.id}
                displayName={(option) => option?.name}
                width="30%"
                watch={watch}
            />
            <TextInput
                label={"Employee Branch"}
                fieldkey={`minorDetails[${index}].kycVerifiBranch`}
                errorText="Employee Branch is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={true}
                width="30%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.kycVerifiBranch}

            />
            <TextInput
                label={"Institution Name"}
                fieldkey={`minorDetails[${index}].orgName`}
                errorText="Institution Name is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={true}
                width="30%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.orgName}
            />
            <TextInput
                label={"Institution Code"}
                fieldkey={`minorDetails[${index}].orgCode`}
                errorText="Institution Code is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={true}
                width="30%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.orgCode}
            />

            <DatePicker
                width={"30%"}
                fieldkey={`minorDetails[${index}].relatedDateOfDeclarion`}
                label={"Date of Declaration"}
                errorText="Date of Declaration is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={true}
                format={"DD-MM-YYYY"}
                setValue={setValue}
                // getValues={getValues}
                // value={getValues()?.minorDetails?.[index]?.relatedDateOfDeclarion}
                value={getValues()?.minorDetails?.[index]?.relatedDateOfDeclarion || dateOfDeclarion}
                setDateValue={setDateOfDeclarion}
            />
            <TextInput
                label={"Place of Declaration"}
                fieldkey={`minorDetails[${index}].relatedPlaceOfDeclarion`}
                errorText="Place of Declaration is required"
                placeholder={"Enter text"}
                control={control}
                register={register}
                mandatory={false}
                width="30%"
                setValue={setValue}
                value={getValues()?.minorDetails?.[index]?.relatedPlaceOfDeclarion}
            // isRegex={/^[0-9]*$/}
            />
        </Box>
    )
}

export default MinorKYCVerification;