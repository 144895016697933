class TokenService {
  getLocalAccessToken() {
    const token = JSON.parse(localStorage.getItem("token"));
    return token;

    
  }

  updateLocalAccessToken(newToken) {
    localStorage.setItem("token", JSON.stringify(newToken));
  }
}

export default new TokenService();
