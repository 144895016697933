import React from "react";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";

import SelectInput from "../Form-Components/SelectInput";
import PasswordInput from "../Form-Components/PasswordInput";
import TextInput from "../Form-Components/TextInput";
import CheckBox from "../Form-Components/CheckBox";
import RadioGroup from "../Form-Components/RadioGroup";
import DatePicker from "../Form-Components/DatePicker";

export default (props) => {
  const { formProps, dropdownOptions = {}, element: item } = props;
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    control,
    formState,
    watch,
    getValues,
    error,
  } = formProps;

  return (
    <React.Fragment>
      {item.type === "emptyDiv" && <Box mr="1rem" width={item.width} />}
      {item.type === "radioGroup" && (
        <RadioGroup
          width={item.width || "30%"}
          label={item.label}
          fieldkey={item.key}
          errorText={item?.errorText}
          placeholder={item?.placeholder}
          control={control}
          options={item?.options || []}
          register={register}
          mandatory={item?.mandatory}
          setValue={setValue}
        />
      )}
      {item.type === "checkbox" && (
        <CheckBox
          width={item.width || "30%"}
          label={item.label}
          fieldkey={item.key}
          errorText={item?.errorText}
          placeholder={item?.placeholder}
          control={control}
          register={register}
          setValue={setValue}
          mandatory={item?.mandatory}
        />
      )}
      {item.type === "dropdown" && (
        <SelectInput
          width={item.width || "30%"}
          label={item.label}
          fieldkey={item.key}
          errorText={item.errorText}
          placeholder={item.placeholder}
          control={control}
          register={register}
          mandatory={item.mandatory}
          setValue={setValue}
          options={
            dropdownOptions[item.key]
              ? dropdownOptions[item.key]
              : item?.options
          }
          valueRenderer={(option) => item.valueRenderer(option)}
          displayName={(option) => item.displayName(option)}
          watch={watch}
        />
      )}
      {item.type === "text" && (
        <TextInput
          width={item.width || "30%"}
          label={item.label}
          fieldkey={item.key}
          errorText={item.errorText}
          placeholder={item.placeholder}
          control={control}
          register={register}
          mandatory={item.mandatory}
          setValue={setValue}
          value={getValues()?.[item.key]}
        />
      )}
      {item.type === "date" && (
        <DatePicker
          width={item.width || "30%"}
          label={item.label}
          fieldkey={item.key}
          errorText={item.errorText}
          placeholder={item.placeholder}
          control={control}
          register={register}
          mandatory={item.mandatory}
          setValue={setValue}
          value={getValues()?.[item.key]}
        />
      )}
      {item.type === "password" && (
        <PasswordInput
          width={item.width || "30%"}
          label={item.label}
          fieldkey={item.key}
          errorText={item.errorText}
          placeholder={item.placeholder}
          control={control}
          register={register}
          mandatory={item.mandatory}
        />
      )}
    </React.Fragment>
  );
};
