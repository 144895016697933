import React from 'react'
import VisibilityIcon from "@mui/icons-material/Visibility";
import PDFIcon from "../../../assets/icons/PDFIcon.png";
import { Box } from '@mui/material';
import Text from '../../Atoms/Text';

const getFileName = (item, dropdownOptions) => {
    const addressFiles = dropdownOptions["addressProof"];
    const identitiyProofs = dropdownOptions["identityProof"] || [];
    const files = [...addressFiles, ...identitiyProofs];
    const file = files.find(
        (i) => parseInt(i.documentCode) === parseInt(item.documentType)
    );
    if (file) {
        return file?.name;
    } else {
        return "";
    }
};

const getImageName = (item, dropdownOptions) => {
    const addressFiles = dropdownOptions["addressProof"] || [];

    const identitiyProofs = dropdownOptions["identityProof"] || [];
    const files = [...addressFiles, ...identitiyProofs];
    let proof = files.find(
        (img) =>
            parseInt(img.documentCode) === parseInt(item.docCode) ||
            parseInt(img.documentCode) === parseInt(item.documentCode)
    );

    if (proof) {
        return proof.documentName;
    }
    return "";
};
const UploadImageSection = ({ item, dropdownOptions }) => {
    const handleImage = (item) => {
        console.log("item", item);
        window.open(item, "_blank");
    };
    return (
        <React.Fragment>
            <Box
                my="20px"
                mx="16px"
                width="fit-content"
                display="flex"
                flexDirection="column"
                alignItems="center"
                position="relative"
            >
                <Text style={{ wordBreak: "break-all" }}>
                    {getImageName(item, dropdownOptions) ||
                        getFileName(item, dropdownOptions)}
                </Text>
            </Box>
            <Box
                key={item.fileName}
                my="20px"
                mx="16px"
                width="160px"
                height="160px"
                position="relative"
                onClick={() => handleImage(item.fileUrl)}
                onMouseEnter={(event) => {
                    const fileImage =
                        event.currentTarget.querySelector(
                            ".file-image"
                        );
                    const previewIcon =
                        event.currentTarget.querySelector(
                            ".preview-icon"
                        );
                    if (fileImage) {
                        fileImage.style.filter = "blur(2px)";
                    }
                    if (previewIcon) {
                        previewIcon.style.display = "flex";
                    }
                }}
                onMouseLeave={(event) => {
                    const fileImage =
                        event.currentTarget.querySelector(
                            ".file-image"
                        );
                    const previewIcon =
                        event.currentTarget.querySelector(
                            ".preview-icon"
                        );
                    if (fileImage) {
                        fileImage.style.filter = "none";
                    }
                    if (previewIcon) {
                        previewIcon.style.display = "none";
                    }
                }}
            >
                {item?.fileName.toLowerCase().endsWith(".jpg") ||
                    item?.fileName.toLowerCase().endsWith(".jpeg") ||
                    item?.fileName.toLowerCase().endsWith(".tif") ||
                    item?.fileName.toLowerCase().endsWith(".tiff") ? (
                    <img
                        className="file-image"
                        width="160px"
                        height="160px"
                        src={item.fileUrl}
                        // onClick={() => handleFileClick(item)}
                        onClick={() => handleImage(item.fileUrl)}
                        style={{ cursor: "pointer" }}
                        alt="upload-jpg"
                    />
                ) : item?.fileName
                    .toLowerCase()
                    .endsWith(".pdf") ? (
                    <Box
                        sx={{
                            width: "160px",
                            height: "160px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <img
                            className="file-image"
                            width="80px"
                            height="80px"
                            src={PDFIcon}
                            // onClick={() => handleFileClick(item)}
                            onClick={() =>
                                window.open(item.fileUrl, "_blank")
                            }
                            style={{ cursor: "pointer" }}
                            alt="upload-pdf"
                        />
                    </Box>
                ) : (
                    <div>Unsupported file format</div>
                )}

                <VisibilityIcon
                    className="preview-icon"
                    style={{
                        display: "none",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        cursor: "pointer",
                        zIndex: 1,
                    }}
                />
            </Box>
        </React.Fragment>
    )
}

export default UploadImageSection;