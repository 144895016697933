import React from "react";
import { Box, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Text from "../Atoms/Text";
import TextInput from "../Form-Components/TextInput";
import SelectInput from "../Form-Components/SelectInput";
import DatePicker from "../Form-Components/DatePicker";
import StickyFooter from "../Atoms/StickyFooter";
import Modal from "../Molecules/Modal";
// import PasswordInput from "../Form-Components/PasswordInput";
import { useEffect } from "react";
import { useState } from "react";
import useDropdownOptions from "../../hooks/useDropdownOptions";

const CreateCustomerPanel = ({
  open,
  toggleDrawer,
  states,
  onSubmit,
  selectedUser,
  isLoading,

}) => {

  // const [isMinor, setIsMinor] = useState(false);
  const [emailValue, setEmailValue] = useState(false);
  // const schema = yup
  //   .object()
  //   .shape({
  //     prefix: yup.string().max(255).required("Required field"),
  //     fname: yup.string().max(255).required("Required field"),
  //     mobileCode: yup.string().max(255).required("Required field"),

  //     dob: yup.string().required("DOB is required").nullable("DOB is required"),
  //     // mobileNum: yup
  //     //   .string()
  //     //   .required("required")
  //     //   .matches(/^[6-9][0-9]{9}$/, "Enter valid phone number"),
  //     pan: yup.string().max(255).when("accountType", {
  //       is: "05",
  //       then: yup.string(),
  //       otherwise: yup.string().required("Required field"),
  //     }),
  //     aadharNumber: yup
  //       .string()
  //       .max(255)
  //       .when("accountType", {
  //         is: "05",
  //         then: yup.string().required("Required field"),
  //         otherwise: yup.string()
  //       }),

  //     accountType: yup.string().max(255).required("Required field"),
  //     branch: yup.string().max(255).required("Branch Required"),
  //     // email : yup.string().max(255).when("email", {
  //     //   is: "",
  //     //   then: yup.string(),
  //     //   otherwise: yup.string().email().required("Required field"),
  //     // })
  //   })
  //   .required();
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    control,
    formState,
    watch,
    getValues,
  } = useForm({
    mode: "onChange",
    // defaultValues: {
    //   dob: null,
    // },
    // defaultValues: {
    // 	dmsUserName: selectedUser.get("dmsUserId", ""),
    // 	fiCode: selectedUser.get("fiCode", ""),
    // 	version: selectedUser.get("version", ""),
    // 	instUserId: selectedUser.get("instUserId", ""),
    // 	instName: selectedUser.get("instName", ""),
    // 	// empCode: selectedUser.get("empCode", ""),
    // 	instBranchCode: selectedUser.get("instBranchCode", ""),
    // 	instRegionCode: selectedUser.get("instRegionCode", ""),
    // 	ckycBranchCode: selectedUser.get("ckycBranchCode", ""),
    // 	ckycRegionCode: selectedUser.get("ckycRegionCode", ""),
    // 	password: selectedUser.get("password", ""),
    // },
    defaultValues: {
      prefix: "",
      fname: "",
      mname: "",
      lname: "",
      mobileCode: "",
      mobileNum: "",
      email: "",
      accountType: "",
      aadharNumber: "",
      pan: "",
      dob: null,
      ckycNo: "",
      branch: "",
    },
    // resolver: yupResolver(schema),
  });
  const fieldsKey = {
    prefix: "",
    fname: "",
    mname: "",
    lname: "",
    mobileCode: "",
    mobileNum: "",
    email: "",
    accountType: "",
    aadharNumber: "",
    pan: "",
    dob: null,
    ckycNo: "",
    branch: "",
  }
  console.log("create customer panel dirtyFields ", { dirtyFields: formState.dirtyFields, isValid: formState.isValid, errors : formState.errors })
  const accountType = watch("accountType");
  const mandatoryArray= ["prefix", "fname", "mobileCode", "mobileNum", "accountType", "dob", "ckycNo", "branch"]

  const { dropdownOptions } = useDropdownOptions();
  const submit = (data) => {
    data.type = "01"
    onSubmit(data);
  };

  // const fields = [
  //   // {
  //   //   key: "prefix",
  //   //   label: "PREFIX",
  //   //   placeholder: "Enter text",
  //   //   errorText: "required",
  //   //   type: "dropdown",
  //   //   mandatory: true,
  //   //   // options: ["Mr", "Mrs", "Minor", "Mis", "Dr"],
  //   //   valueRenderer: (option) => option.name,
  //   //   displayName: (option) =>
  //   //     option?.code?.charAt(0).toUpperCase() +
  //   //     option?.code?.slice(1).toLowerCase(),
  //   // },
  //   // {
  //   //   key: "fname",
  //   //   label: "First Name",
  //   //   placeholder: "Enter text",
  //   //   errorText: "required",
  //   //   type: "text",
  //   //   mandatory: true,
  //   //   isRegex: "alphaspace",
  //   // },
  //   // {
  //   //   key: "mname",
  //   //   label: "Middle Name",
  //   //   placeholder: "Enter text",
  //   //   errorText: "required",
  //   //   type: "text",
  //   //   pattern: /^\d{10}$/,
  //   //   mandatory: false,
  //   //   isRegex: "alphaspace",
  //   // },
  //   // {
  //   //   key: "lname",
  //   //   label: "Last Name",
  //   //   placeholder: "Enter text",
  //   //   errorText: "required",
  //   //   type: "text",
  //   //   mandatory: false,
  //   //   isRegex: "alphaspace",
  //   // },
  //   // {
  //   //   key: "mobileCode",
  //   //   label: "Country Code",
  //   //   placeholder: "Enter text",
  //   //   errorText: "required",
  //   //   type: "text",
  //   //   mandatory: true,
  //   //   // isNumber: true,
  //   //   isRegex: "number"
  //   // },
  //   // {
  //   //   key: "mobileNum",
  //   //   label: "Mobile Number",
  //   //   placeholder: "Enter text",
  //   //   errorText: "required",
  //   //   type: "text",
  //   //   mandatory: true,
  //   //   isRegex: "number",
  //   // },
  //   // {
  //   //   key: "email",
  //   //   label: "Email",
  //   //   placeholder: "Enter text",
  //   //   errorText: "Valid email is required",
  //   //   type: "text",
  //   //   mandatory: false,
  //   // },
  //   // {
  //   //   key: "accountType",
  //   //   label: "Account Type",
  //   //   placeholder: "Enter text",
  //   //   errorText: "required",
  //   //   type: "dropdown",
  //   //   mandatory: true,
  //   //   // options: ["Mr", "Mrs", "Minor", "Mis", "Dr"],
  //   //   valueRenderer: (option) => option.code,
  //   //   displayName: (option) => `${option.name}`,
  //   // },
  //   // {
  //   //   key: "aadharNumber",
  //   //   label: "Aadhaar Number",
  //   //   placeholder: "",
  //   //   errorText: "required",
  //   //   type: "text",
  //   //   isRegex: "aadhar",
  //   //   mandatory: accountType === "05",
  //   // },
  //   // {
  //   //   key: "pan",
  //   //   label: "PAN",
  //   //   placeholder: "Enter text",
  //   //   errorText: "Valid PAN is required",
  //   //   type: "text",
  //   //   mandatory: accountType !== "05",
  //   //   isRegex: "pan",
  //   //   isLimit: 10,
  //   // },
  //   // {
  //   //   key: "dob",
  //   //   label: "Date of Birth",
  //   //   placeholder: "Enter text",
  //   //   errorText: "required",
  //   //   type: "date",
  //   //   mandatory: true,
  //   // },
  //   //   {
  //   //     key: "ckycNo",
  //   //     label: "CKYC No",
  //   //     placeholder: "Enter text",
  //   //     errorText: "required",
  //   //     type: "text",
  //   //  //   mandatory: false,
  //   //     //isRegex: "alphanumeric"
  //   //    isRegex: "number" 
  //   //   },
  //   // {
  //   //   key: "ckycNo",
  //   //   label: "CKYC No",
  //   //   placeholder: "Enter text",
  //   //   errorText: "required",
  //   //   type: "text",
  //   //   // mandatory: true,
  //   //   isRegex: "number",
  //   // },
  //   // {
  //   //   key: "branch",
  //   //   label: "Branch",
  //   //   placeholder: "Enter text",
  //   //   errorText: "Branch Required",
  //   //   type: "dropdown",
  //   //   mandatory: true,
  //   //   // options: ["Mr", "Mrs", "Minor", "Mis", "Dr"],
  //   //   valueRenderer: (option) => option.brCode,
  //   //   displayName: (option) => `${option.brName}`,
  //   // },
  // ];
  const valueOfEmail = watch("email");


  useEffect(() => {
    if (valueOfEmail) {
      setEmailValue(true);
    } else {
      setEmailValue(false);
    }
  }, [valueOfEmail]);
  useEffect(() => {
    setEmailValue(watch("email"));
  }, [emailValue]);
  useEffect(() => {
    setValue("mobileCode", "91");
    watch("dob");
  }, []);

  return (
    <Modal
      title={"Create Customer - Individual"}
      open={open}
      toggleDrawer={toggleDrawer}
    >
      <form
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
        onSubmit={handleSubmit(submit)}
      >
        <Box
          display="flex"
          flexDirection={"column"}
          p="24px"
          sx={{ overflowY: "auto", width: "100%" }}
        >
          <Box display="flex" sx={{ width: "100%", flexWrap: "wrap" }}>
            <SelectInput
              label={"PREFIX"}
              fieldkey={"prefix"}
              errorText={formState?.errors["prefix"]?.message}
              placeholder={"Enter text"}
              control={control}
              register={register}
              mandatory={true}
              setValue={setValue}
              options={dropdownOptions["prefix"]}
              valueRenderer={(option) => option.name}
              displayName={(option) =>
                option?.code?.charAt(0).toUpperCase() +
                option?.code?.slice(1).toLowerCase()}
              watch={watch}
            />
            <TextInput
              label={"First Name"}
              fieldkey={"fname"}
              errorText={formState?.errors["fname"]?.message}
              placeholder={"Enter text"}
              control={control}
              register={register}
              mandatory={true}
              isRegex={/^[a-zA-Z\s]*$/}
              pattern={/^[A-Za-z ]{3,50}$/}
              setValue={setValue}
              value={getValues()?.["fname"]}

            />
            <TextInput
              label={"Middle Name"}
              fieldkey={"mname"}
              errorText={formState?.errors["mname"]?.message}
              placeholder={"Enter text"}
              control={control}
              register={register}
              mandatory={false}
              isRegex={/^[a-zA-Z\s]*$/}
              setValue={setValue}
              value={getValues()?.["mname"]}
            />
            <TextInput
              label={"Last Name"}
              fieldkey={"lname"}
              errorText={formState?.errors["lname"]?.message}
              placeholder={"Enter text"}
              control={control}
              register={register}
              mandatory={false}
              isRegex={/^[a-zA-Z\s]*$/}
              setValue={setValue}
              value={getValues()?.["lname"]}
            />
            <TextInput
              label={"Country Code"}
              fieldkey={"mobileCode"}
              errorText={formState?.errors["mobileCode"]?.message}
              placeholder={"Enter text"}
              control={control}
              register={register}
              mandatory={true}
              isRegex={/^[0-9]*$/}
              setValue={setValue}
              value={getValues()?.["mobileCode"]}
            />
            <TextInput
              label={"Mobile Number"}
              fieldkey={"mobileNum"}
              errorText={formState?.errors["mobileNum"]?.message}
              placeholder={"Enter text"}
              control={control}
              register={register}
              mandatory={true}
              isRegex={/^[0-9]*$/}
              setValue={setValue}
              value={getValues()?.["mobileNum"]}
              pattern={/^[6-9][0-9]{9}$/}
            />
            <TextInput
              label={"Email"}
              fieldkey={"email"}
              errorText={formState?.errors["email"]?.message}
              placeholder={"Enter text"}
              control={control}
              register={register}
              mandatory={false}
              setValue={setValue}
              value={getValues()?.["email"]}
            />
            <SelectInput
              label={"Account Type"}
              fieldkey={"accountType"}
              errorText={formState?.errors["accountType"]?.message}
              placeholder={"Enter text"}
              control={control}
              register={register}
              mandatory={true}
              setValue={setValue}
              options={dropdownOptions["accountType"]}
              valueRenderer={(option) => option.code}
              displayName={(option) => option?.name}
              watch={watch}
            />
            <TextInput
              label={"Aadhaar Number"}
              fieldkey={"aadharNumber"}
              errorText={formState?.errors["aadharNumber"]?.message}
              placeholder={"Enter text"}
              control={control}
              register={register}
              mandatory={accountType === "05"}
              setValue={setValue}
              value={getValues()?.["aadharNumber"]}
              isRegex={/^[0-9]*$/}
              isAadhar={true}
              pattern={/^[0-9]{4,4}$/}
            />
            <TextInput
              label={"PAN"}
              fieldkey={"pan"}
              errorText={formState?.errors["pan"]?.message}
              placeholder={"Enter text"}
              control={control}
              register={register}
              mandatory={accountType !== "05"}
              setValue={setValue}
              value={getValues()?.["pan"]}
              isPan={true}
            />
            <DatePicker
              label={"Date of Birth"}
              fieldkey={"dob"}
              errorText={formState?.errors["dob"]?.message}
              placeholder={"Enter text"}
              control={control}
              register={register}
              mandatory={true}
              setValue={setValue}
              maxDate={new Date()}
              error={formState?.errors["dob"]}
              value={getValues()?.["dob"]}
            />
            <TextInput
              label={"CKYC No"}
              fieldkey={"ckycNo"}
              errorText={formState?.errors["ckycNo"]?.message}
              placeholder={"Enter text"}
              control={control}
              register={register}
              mandatory={false}
              setValue={setValue}
              value={getValues()?.["ckycNo"]}
              isRegex={/^[0-9]*$/}
            />
            <SelectInput
              label={"Branch"}
              fieldkey={"branch"}
              errorText={formState?.errors["branch"]?.message}
              placeholder={"Enter text"}
              control={control}
              register={register}
              mandatory={true}
              setValue={setValue}
              options={dropdownOptions["branch"]}
              valueRenderer={(option) => option.brCode}
              displayName={(option) => option?.brName}
              watch={watch}
            />
            {/* {fields.map((item) => {
              return (
                <React.Fragment>
                  {item.type === "dropdown" && (
                    <SelectInput
                      label={item.label}
                      fieldkey={item.key}
                      errorText={formState?.errors[item.key]?.message}
                      placeholder={item.placeholder}
                      control={control}
                      register={register}
                      mandatory={item.mandatory}
                      setValue={setValue}
                      options={item.options || dropdownOptions[item.key]}
                      valueRenderer={(option) => item.valueRenderer(option)}
                      displayName={(option) => item.displayName(option)}
                      watch={watch}
                    />

                  )}
                  {item.type === "text" && item.isRegex === "alphanumeric" && (
                    <TextInput
                      label={item.label}
                      fieldkey={item.key}
                      errorText={formState?.errors[item.key]?.message}
                      placeholder={item.placeholder}
                      control={control}
                      register={register}
                      mandatory={item.mandatory}
                      isRegex={/^[a-zA-Z0-9]*$/}
                      setValue={setValue}
                      value={getValues()?.[item.key]}
                    />
                  )}

                  {item.type === "text" && item.isRegex === "alphaspace" && (
                    <TextInput
                      label={item.label}
                      fieldkey={item.key}
                      errorText={formState?.errors[item.key]?.message}
                      placeholder={item.placeholder}
                      control={control}
                      register={register}
                      mandatory={item.mandatory}
                      isRegex={/^[a-zA-Z\s]*$/}
                      setValue={setValue}
                      value={getValues()?.[item.key]}
                    />
                  )}
                  {item.type === "text" && item.isRegex === "number" && (
                    <TextInput
                      label={item.label}
                      fieldkey={item.key}
                      // errorText={item.errorText}
                      errorText={formState?.errors[item.key]?.message}
                      placeholder={item.placeholder}
                      control={control}
                      register={register}
                      mandatory={item.mandatory}
                      isRegex={item.key === "ckycNo" ? /^[0-9]{0,14}$/ : /^[0-9]*$/}
                      setValue={setValue}
                      value={getValues()?.[item.key]}
                    />
                  )}
                  {item.type === "text" && item.isRegex === "pan" && (
                    <TextInput
                      label={item.label}
                      fieldkey={item.key}
                      errorText={formState?.errors[item.key]?.message}
                      placeholder={item.placeholder}
                      control={control}
                      register={register}
                      mandatory={item.mandatory}
                      // isRegex={/^[A-Z0-9]*$/}
                      isPan={true}
                      setValue={setValue}
                      value={getValues()?.[item.key]}
                    />
                  )}
                  {item.type === "text" && item.isRegex === "aadhar" && (
                    <TextInput
                      label={item.label}
                      fieldkey={item.key}
                      errorText={formState?.errors[item.key]?.message}
                      placeholder={item.placeholder}
                      control={control}
                      register={register}
                      mandatory={item.mandatory}
                      isRegex={/^[0-9]*$/}
                      isAadhar={true}
                      setValue={setValue}
                      value={getValues()?.[item.key]}
                    />
                  )}
                  {item.type === "text" && !item?.isRegex && (
                    <TextInput
                      label={item.label}
                      fieldkey={item.key}
                      errorText={formState?.errors[item.key]?.message}
                      placeholder={item.placeholder}
                      control={control}
                      register={register}
                      mandatory={item.mandatory}
                      isNumber={item.isNumber}
                      setValue={setValue}
                      value={getValues()?.[item.key]}
                    />
                  )}
                  {item.type === "password" && (
                    <PasswordInput
                      label={item.label}
                      fieldkey={item.key}
                      errorText={formState?.errors[item.key]?.message}
                      placeholder={item.placeholder}
                      control={control}
                      register={register}
                      mandatory={item.mandatory}
                    />
                  )}
                  {item.type === "date" && (
                    <DatePicker
                      label={item.label}
                      fieldkey={item.key}
                      errorText={formState?.errors[item.key]?.message}
                      placeholder={"Enter text"}
                      control={control}
                      register={register}
                      mandatory={item.mandatory}
                      setValue={setValue}
                      maxDate={new Date()}
                      error={formState?.errors[item.key]}
                      value={getValues()?.[item.key]}
                    />
                  )}
                </React.Fragment>
              );
            })} */}
          </Box>
        </Box>
        <StickyFooter normal={true}>
          <React.Fragment>
            <Button
              onClick={() => {
                // let obj = {};
                // fields?.forEach((ele) => {
                //   obj[ele.key] = "";
                // });
                reset(fieldsKey);
              }}
              sx={{ marginRight: "8px", }}
              variant="outlined"
            >
              {selectedUser?.get("ckycUserId") ? "Reset" : "Clear"}
            </Button>
            <LoadingButton
              loading={formState.isSubmitting || isLoading}
              type={"submit"}
              size="small"
              variant="contained"
              color="primary"
              disableElevation
              disabled={!formState.isValid}
              style={{ textTransform: "none", }}
            >
              <Text>Submit</Text>
            </LoadingButton>
          </React.Fragment>
        </StickyFooter>
      </form>
    </Modal>
  );
};

export default CreateCustomerPanel;
