import { Box, Switch } from "@mui/material";
// import { DeleteOutlineIcon, EditIcon } from "@mui/base";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";

// const HeaderWrapper = (params) => {
//   return (
//     <Box display="flex" flexWrap="wrap">
//       <Text fontSize="16px" semiBold fontWeight="600">
//         {params.colDef.headerName}
//       </Text>
//     </Box>
//   );
// };

export const getColumns = ({ onToggleActive, onDeleteClick, onEditClick }) => {
  return [
    {
      field: "masterId",
      headerName: "Master ID",
      // flex: 1,
      flex: 0.8,
    },
    {
      field: "masterName",
      headerName: "Master Name",
      flex: 0.9,
      // renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "text",
      headerName: "Text",
      flex: 1,
      renderCell:(params) =>
      <span title={params.value}>{params.value?.length > 17 ? `${params.value?.substr(0,17)}...`:params.value}</span>
    
      // renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "value",
      headerName: "Value",
      flex: 0.8,
      // renderHeader: (params) => HeaderWrapper(params),
    },
    // {
    // 	field: "isDeemed",
    // 	headerName: "Deemed",
    // 	flex: 0.8,
    // 	renderCell: (params) => (params.value ? "Yes" : "No"),
    // 	// renderHeader: (params) => HeaderWrapper(params),
    // },
    {
      field: "status",
      headerName: "Status",
      flex: 0.6,
      disableExport: true,
      renderCell: (params) => {
        return (
          <Switch
            checked={params.value}
            onChange={(e) => onToggleActive(e, params.row.id)}
            defaultChecked
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      disableExport: true,
      renderCell: (params) => {
        return (
          <Box display="flex" justifyContent={"space-between"}>
            <EditIcon
              onClick={() => onEditClick(params.row.id)}
              sx={{cursor: "pointer", marginRight: "8px" }}
            />
            <DeleteOutlineIcon
              onClick={() => onDeleteClick(params.row.id)}
              sx={{ color: "red", cursor: "pointer", marginLeft: "8px" }}
            />
          </Box>
        );
      },
    },
  ];
};
