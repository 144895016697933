import React from "react";
import { Box, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQuery, useQueries } from "@tanstack/react-query";

import Text from "../Atoms/Text";
import TextInput from "../Form-Components/TextInput";
import SelectInput from "../Form-Components/SelectInput";
import StickyFooter from "../Atoms/StickyFooter";
import Modal from "../Molecules/Modal";
import PasswordInput from "../Form-Components/PasswordInput";
import { getUsers } from "../../apis/users";
import { viewBraches } from "../../apis/branches";
import { useEffect } from "react";
import { useState } from "react";

const fields = [
  {
    key: "dmsUserName",
    label: "DMS User name",
    placeholder: "Enter text",
    errorText: "Enter DMS",
    type: "dropdown",
    mandatory: true,
    valueRenderer: (option) => option.id,
    displayName: (option) => `${option.username}`,
  },
  {
    key: "fiCode",
    label: "FI Code",
    placeholder: "Enter text",
    errorText: "Enter FI Code",
    type: "text",
    mandatory: true,
    isRegex: "alphanumeric"
  },
  {
    key: "version",
    label: "Version",
    placeholder: "Enter text",
    errorText: "Enter Version",
    type: "text",
    mandatory: true,
  },
  {
    key: "instUserId",
    label: "Institution User Id",
    placeholder: "Enter id",
    errorText: "Enter Institution User Id",
    type: "text",
    mandatory: true,
    isRegex: "alphanumericspace"
  },
  // {
  // 	key: "empCode",
  // 	label: "Employee Code",
  // 	placeholder: "Enter text",
  // 	errorText: "required",
  // 	type: "text",
  // 	mandatory: true,
  // },
  {
    key: "instName",
    label: "Institution Name",
    placeholder: "Enter text",
    errorText: "Enter Institution Name",
    type: "text",
    mandatory: true,
    isRegex: "alphaspace"
  },
  {
    key: "instBranchCode",
    label: "Institution Branch Code",
    placeholder: "Enter text",
    errorText: "Enter Institution Branch Code",
    type: "text",
    mandatory: true,
    isRegex: "alphanumericspace"
  },
  {
    key: "instRegionCode",
    label: "Institution Region Code",
    placeholder: "Enter text",
    errorText: "Enter Instruction Region Code",
    type: "text",
    mandatory: true,
    isRegex: "alphanumericspace"
  },
  {
    key: "ckycBranchCode",
    label: "CKYC Branch Code",
    placeholder: "Enter text",
    errorText: "Enter CKYC Branch Code",
    type: "dropdown",
    mandatory: true,
    valueRenderer: (option) => option.brCode,
    displayName: (option) => `${option.brCode}`,
  },
  {
    key: "ckycRegionCode",
    label: "CKYC Region Code",
    placeholder: "Enter text",
    errorText: "Enter CKYC region code",
    type: "text",
    mandatory: true,
    isRegex: "alphanumericspace"
  },
  // {
  //   key: "password",
  //   label: "Password",
  //   placeholder: "********",
  //   errorText: "Minimum of 4",
  //   type: "password",
  //   mandatory: true,
  // },
];

const schema = yup
  .object()
  .shape({
    dmsUserName: yup.string().max(255).required("Required field"),
    fiCode: yup.string().max(255).required("Required field"),
    // version: yup
    //   .string()
    //   .matches(/^[a-z0-9]+$/i, "Please enter valid alphanumberic text")
    //   .required("Required field"),
    version : yup.string().max(255).required("Required field"),
    instUserId: yup.string().max(255).required("Required field"),
    // empCode: yup.string().max(255).required("Required field"),
    instName: yup.string().max(255).required("Required field"),
    ckycBranchCode: yup.string().max(255).required("Required field"),
    ckycRegionCode: yup.string().max(255).required("Required field"),
    instBranchCode: yup.string().max(255).required("Required field"),
    instRegionCode: yup.string().max(255).required("Required field"),
  //  password: yup.string().min(4, "Minimum of 4").max(255).required("Required field"),
  })
  .required();

const AddBranch = ({
  open,
  toggleDrawer,
  states,
  onSubmit,
  selectedUser,
  isLoading,
}) => {
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    control,
    formState,
    watch,
    getValues,
    error,
  } = useForm({
    defaultValues: {
      dmsUserName: selectedUser.get("dmsUserId", ""),
      fiCode: selectedUser.get("fiCode", ""),
      version: selectedUser.get("version", ""),
      instUserId: selectedUser.get("instUserId", ""),
      instName: selectedUser.get("instName", ""),
      // empCode: selectedUser.get("empCode", ""),
      instBranchCode: selectedUser.get("instBranchCode", ""),
      instRegionCode: selectedUser.get("instRegionCode", ""),
      ckycBranchCode: selectedUser.get("ckycBranchCode", ""),
      ckycRegionCode: selectedUser.get("ckycRegionCode", ""),
      password: selectedUser.get("password", ""),
    },
    resolver: yupResolver(schema),
  });
  // const [dropDownOptions, setDropDownOptions] = useState({});

  const submit = (data) => {
    onSubmit(data);
  };

  const { data: ckycUsers } = useQuery(
    ["ckycUsers", { filter: null, search: null }],
    getUsers,
    {
      manual: true,
      // onSuccess: (response) => {
      // 	setDropDownOptions({
      // 		...dropDownOptions,
      // 		dmsUserName: response.data.items,
      // 	});
      // },
    }
  );

  const { data: branches } = useQuery(
    ["branches", { filter: null, search: null }],
    viewBraches,
    {
      manual: true,
      // onSuccess: (response) => {
      // 	setDropDownOptions({
      // 		...dropDownOptions,
      // 		ckycBranchCode: response.data.items,
      // 	});
      // },
    }
  );

  const dropDownOptions = {
    dmsUserName: ckycUsers?.data?.items || [],
    ckycBranchCode: branches?.data?.items || [],
  };

  const [isFormReset, setIsFormReset] = useState(false);

  useEffect(() => {
    if (isFormReset) {
      reset();
      setIsFormReset(false);
    }
  }, [isFormReset, reset]);

  const handleReset = () => {
    setIsFormReset(true);
  };
  
  return (
    <Modal
      title={selectedUser?.get("ckycUserId") ? "Edit Map User" : "Add Map User"}
      open={open}
      toggleDrawer={toggleDrawer}>
      <form
        style={{ display: "flex", flexDirection: "column" }}
        onSubmit={handleSubmit(submit)}>
        <Box
          display="flex"
          flexDirection={"column"}
          p="24px"
          sx={{ overflowY: "auto", width: "100%" }}>
          <Box display="flex" sx={{ width: "100%", flexWrap: "wrap" }}>
            {fields.map((item) => {
              return (
                <React.Fragment>
                  {item.type === "dropdown" && (
                    <SelectInput
                      label={item.label}
                      fieldkey={item.key}
                      errorText={item.errorText}
                      placeholder={item.placeholder}
                      control={control}
                      register={register}
                      mandatory={item.mandatory}
                      setValue={setValue}
                      options={dropDownOptions[item.key]}
                      valueRenderer={(option) => item.valueRenderer(option)}
                      displayName={(option) => item.displayName(option)}
                      watch={watch}
                    />
                  )}
                  {item.type === "text" && item.isRegex === "alphanumericspace" && (
                    <TextInput
                      label={item.label}
                      fieldkey={item.key}
                      errorText={item.errorText}
                      placeholder={item.placeholder}
                      control={control}
                      register={register}
                      mandatory={item.mandatory}
                      isRegex={item.key === "instRegionCode" ? /^[a-zA-Z0-9\s]{0,10}$/ : /^[a-zA-Z0-9\s]*$/}
                      setValue={setValue}
                      value={getValues()?.[item.key]}
                     />
                  )}
                  {item.type === "text" && item.isRegex === "alphanumeric" && (
                      <TextInput
                      label={item.label}
                      fieldkey={item.key}
                      errorText={item.errorText}
                      placeholder={item.placeholder}
                      control={control}
                      register={register}
                      mandatory={item.mandatory}
                      //isRegex={/^[a-zA-Z0-9]*$/}
                      isRegex={item.key === "fiCode" ? /^[a-zA-Z0-9]{0,7}$/ : /^[a-zA-Z0-9]*$/}
                      setValue={setValue}
                      value={getValues()?.[item.key]}
                    />
                    )
                  }
                  {item.type === "text" && item.isRegex === "alphaspace" && (
                      <TextInput
                      label={item.label}
                      fieldkey={item.key}
                      errorText={item.errorText}
                      placeholder={item.placeholder}
                      control={control}
                      register={register}
                      mandatory={item.mandatory}
                      isRegex={/^[a-zA-Z\s]*$/}
                      setValue={setValue}
                      value={getValues()?.[item.key]}
                    />
                    )
                  }
                  {item.type === "text" && !item.isRegex && (
                    <TextInput
                      label={item.label}
                      fieldkey={item.key}
                      errorText={item.errorText}
                      placeholder={item.placeholder}
                      control={control}
                      register={register}
                      mandatory={item.mandatory}
                      isNumber={item?.isNumber}
                      setValue={setValue}
                      value={getValues()?.[item.key]}
                    />
                  )}
                  {item.type === "password" && (
                    <PasswordInput
                      label={item.label}
                      fieldkey={item.key}
                      errorText={item.errorText}
                      placeholder={item.placeholder}
                      control={control}
                      register={register}
                      autoComplete="new-password"
                      mandatory={item.mandatory}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </Box>
        </Box>
        <StickyFooter>
          <React.Fragment>
            <Button
              onClick={handleReset}
              sx={{ marginRight: "8px" }}
              variant="outlined">
              {selectedUser?.get("ckycUserId") ? "Reset" : "Clear"}
            </Button>
            <LoadingButton
              loading={formState.isSubmitting || isLoading}
              type={"submit"}
              size="small"
              variant="contained"
              color="primary"
              disableElevation
              style={{ textTransform: "none" }}
              disabled={fields?.filter(items => watch(items?.key) == "")?.length > 0 ? true : false}
              >
              <Text>Submit</Text>
            </LoadingButton>
          </React.Fragment>
        </StickyFooter>
      </form>
    </Modal>
  );
};

export default AddBranch;
