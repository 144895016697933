import React, { Fragment } from "react";
import { Navigate } from "react-router-dom";
import { getAllowedRoutes, isLoggedIn } from "../utils";
import { PrivateRoutesConfig } from "../config";
import MapAllowedRoutes from "./MapAllowedRoutes";

function PrivateRoutes() {
  let allowedRoutes = [];

  if (isLoggedIn()) allowedRoutes = getAllowedRoutes(PrivateRoutesConfig);
  else return <Navigate to="/login" />;

  return (
    <Fragment>
      <MapAllowedRoutes routes={allowedRoutes} isAddNotFound />
    </Fragment>
  );
}

export default PrivateRoutes;
