import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
// import dayjs from "dayjs";
import { createOrEditDistrict, bulkDownload, bulkDownloadOther, downloadUploadFile } from "../apis/districtMaster";
import { getCustomers, getDownloadZip } from "../apis/newCustomer";
import DataTable from "../components/Molecules/DataTable";
import PageHeader from "../components/Molecules/PageHeader";
import DeletePopUp from "../components/Molecules/PopupModal";
import { useGlobalContext } from "../context/GlobalContext";
import { getColumns } from "../utils/tableHeaders/newCustomers";
import CustomerDetails from "../components/Organisms/CustomerDetails";
import useDropdownOptions from "../hooks/useDropdownOptions";
// import { formatDate } from "../components/Organisms/new-customer/formUtils";
import CustomerJoruneyPanel from "../components/Organisms/CustomerJourneyPanel";
import UploadFile from "../components/Organisms/UploadFile";
import { isChecker } from "../utils";

const NewCustomers = () => {
  const [setAddUserPanel] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0)
  const [openDeletePopup, toggleDeletePopup] = React.useState(false);
  const [openCustomerJourneyPanel, toggleCustomerJourneyPanel] =
    React.useState(false);
  const {
    state: { currentUser, rowSelect },
    dispatch,
  } = useGlobalContext();
  const [selectedDistrict, setSelectedDoc] = React.useState(null);
  //const [filter, updateFilter] = React.useState({});
  const [filter, updateFilter] = React.useState({
    condition: "",
    page: 0,
    perPageSize: 5
  });
  const [search, updateSearch] = React.useState(null);
  const [docketOpen, setDocketOpen] = React.useState(false);
  const [customerId, setCustomerId] = React.useState(null);
  const [setSelectedCustomer] = React.useState();
  const { dropdownOptions } = useDropdownOptions();
  const [filters] = React.useState(null);
  const [openUpdateForm, setNewUpdateForm] = React.useState(false);
  const [toggleRemarksPanel] = React.useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabPopup, setActiveTabPopup] = useState(0);
  const [popup, setPopup] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [downloadObject, setDownloadObject] = useState({
    download: [],
    isSftp: false,
  });
  const [downloadObjectOther, setDownloadObjectOther] = useState({});
  const [isFilterValue, setIsFilterValue] = useState({ zipValue: "C", listValue: "pan" })
  const [dataTable, setDataTable] = useState([]);
  const [generateType, setGenerateType] = useState("")
  // const [customerStatusFilter, setCustomerStatusFilter] = React.useState('NC')

  // Pass this to customer details to avoid duplicate code
  let currentRole =
    JSON?.parse(window.localStorage.getItem("roles")) ||
    currentUser.role.role_name;
  currentRole = currentRole.toLowerCase();

  const pageTitle = "Bulk Download";

  const {
    data,
    refetch,
  } = useQuery(
    [
      "customers",
      {
        filter,
        search,
        filters,
        module: currentRole === "maker" || isChecker() ? activeTabPopup === 0 ?  "DOWNLOAD" : "DOWNLOAD_ZIP_GENERATED" : "DL",
        identity: activeTabPopup === 0 ? isFilterValue?.listValue : null
      },
    ],
    getCustomers,
    {
      manual: true,
      select: (response) => response.get("data", []),
      retry: false,
    }
  );

  // const {
  //   data: ZipData,
  //   refetch: ZipRefech,
  // } = useQuery(
  //   [
  //     "customers",
  //     {
  //       filter,
  //       search,
  //       filters,
  //       module: currentRole === "maker" ? "DOWNLOAD_DONE" : "DL",
  //     },
  //   ],
  //   getDownloadZip,
  //   {
  //     manual: true,
  //     select: (response) => response?.get("data", []),
  //     retry: false,
  //   }
  // );

  const { mutate: addOrUpdateDoc, isLoading } = useMutation(
    (userData) => createOrEditDistrict(userData),
    {
      onSuccess: (response) => {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.message,
            severity: response.isError ? "error" : "success",
          },
        });
        // updateFilter({});
        if (!response.isError) {
          setAddUserPanel(false);
          toggleDeletePopup(false);
          refetch();
        }
      },
      onError: (error) => {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: error.response.data.message,
            severity: "error",
          },
        });
      },
    }
  );

  const { mutate: bulkDownloadApi } = useMutation({
    mutationFn: (userData) => bulkDownload(userData),
    onSuccess: (response) => {
      if (response.isError) {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.message,
            severity: "error",
          },
        });
      } else {
        if (generateType && generateType === "zip") {
          setGenerateType("")
          const handleDownload = () => {
            downloadCSV(response?.data?.data, response?.data?.name);
          }
          handleDownload();
        }
        if (generateType && generateType === "sftp") {
          setGenerateType("")
          dispatch({
            type: "SET_TOAST",
            payload: {
              open: true,
              text: "successfully generate SFTP",
              severity: "success",
            },
          });
        }
        bulkDownloadOtherAPI(downloadObjectOther);
      }
    },
    onError: (error) => {
      dispatch({
        type: "SET_TOAST",
        payload: {
          open: true,
          text: "Process failed",
          severity: "error",
        },
      });
    }
  })

  const { mutate: bulkDownloadOtherAPI } = useMutation({
    mutationFn: (userData) => bulkDownloadOther(userData),
    onSuccess: (response) => {
      if(response.isError){
        dispatch({
          type: "SET_TOAST",
          payload: {
            open : true,
            message: response.message,
            severity: "error",
          }
        })
      }else{
        setSelectedRows([])
        refetch();
      }
    },
    onError: (error) => {
      dispatch({
        type: "SET_TOAST",
        payload: {
          open : true,
          text : error.message,
          severity: "error",
        }
      })
    }
  })
  function downloadCSV(Data, fileName) {
    const blob = new Blob([Data], { type: 'text/plain' });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    }
    dispatch({
      type: "SET_TOAST",
      payload: {
        open: true,
        text: "Successfully Downloaded",
        severity: "success",
      },
    });
  };
  const { mutate: uploadFile, isLoading: uploadingDocs, } = useMutation({
    mutationFn: (userData) => downloadUploadFile(userData),
    onSuccess: (response) => {
      if (response.isError) {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.message,
            severity: "error",
          },
        });
      } else {
        setPopup(!popup)
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: "successfully Submitted",
            severity: "success",
          },
        });
        refetch()
      }

    }
    , onError: (error) => {
      dispatch({
        type: "SET_TOAST",
        payload: {
          open: true,
          text: error.message,
          severity: "error",
        },
      });
    },
  })
  const onDeleteUser = () => {
    const requestData = {
      id: selectedDistrict.get("id"),
    };
    addOrUpdateDoc(requestData);
    setSelectedDoc(null);
  };

  const toggleDrawer = () => {
    // setNewCustomerModal(!openNewCustomerModal);
  };

  const onDocketClick = (id) => {
    // fetch the ckyc user info
    const users = data?.get("items")?.toJS();
    const user = users.find((item) => item.customerId === id);
    if (user) {
      if (
        !["Pending Mapping"].includes(user.status) &&
        currentRole === "maker"
      ) {
        setNewUpdateForm(true);
      }
    }
    setCustomerId(id);
    setDocketOpen(true);
  };

  const onToggleActive = (e, id) => {
    let checked = e.target.checked;
    let requestData = {
      id: id,
      status: checked ? 1 : 0,
    };
    requestData = { district: requestData };
    addOrUpdateDoc(requestData);
  };

  let searchTimeout;

  const searchUsers = (query) => {
    clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      updateSearch(query);
    }, 500);
  };

  const onSearch = (e) => {
    const val = e.target.value;
    searchUsers(val);
  };

  const openNewCKYCForm = () => {
    setDocketOpen(false);
  };

  const onCloseDocketForm = () => {
    setDocketOpen(false);
    setNewUpdateForm(false);
    refetch();
  };

  const onRemarksClick = (id) => {
    const allCustomers = data?.get("items")?.toJS() || [];
    setCustomerId(id);
    const customer = allCustomers.find((item) => item.customerId === id);
    setSelectedCustomer(customer || []);
    if (["On Hold", "Audited"].includes(customer.status))
      toggleRemarksPanel(true);
    else toggleCustomerJourneyPanel(true);
  };

  const showHistory = (id) => {
    const allCustomers = data?.get("items")?.toJS() || [];
    setCustomerId(id);
    const customer = allCustomers.find((item) => item.customerId === id);
    setSelectedCustomer(customer || []);
    toggleCustomerJourneyPanel(true);
  };

  React.useEffect(() => {
    // (async () => {
    //   await fetchAllStatuses();
    // })();
  }, []);

  // const rightSideActions = [
  // 	{
  // 		icon: EditIcon,
  // 		options: [
  // 			{ label: "Add New", Icon: addNewUser, onClick: toggleDrawer },
  // 			{ label: "Upload", Icon: uploadUser, EditIcon, onClick: null },
  // 		],
  // 		title: "Add New",
  // 		onClick: null,
  // 		isSplit: true,
  // 	},
  // ];
  const handleRowSelection = (selectedRows) => {
    setSelectedRows(selectedRows);
  };
  const handlePopup = () => {
    if (activeTabPopup === 0) {
      setActiveTabPopup(1);
      const clearTime = setTimeout(() => {
        refetch()
        clearTimeout(clearTime);
      },10)
    } else {
      setActiveTabPopup(0);
      const clearTime = setTimeout(() => {
        refetch()
        clearTimeout(clearTime);
      },10)

    }
    const timeOut = setTimeout(() => {
      refetch()
      // ZipRefech()
      clearTimeout(timeOut)
    }, 10)
  };
  const handleTabChange = () => {
    if (activeTab === 0) {
      setActiveTab(1);
    } else {
      setActiveTab(0);
    }
  };
  const handlerZipDownload = () => {
    setGenerateType("zip")

    const Timeout = setTimeout(() => {
      bulkDownloadApi(downloadObject)
      clearTimeout(Timeout);
    }, 10)

  }
  const handlerSftpDownload = () => {
    setGenerateType("sftp")
    const updatedObject = {
      ...downloadObject,
      isSftp: true,
    };
    const Timeout = setTimeout(() => {
      bulkDownloadApi(updatedObject)
      clearTimeout(Timeout);
    }, 10)
  }
  const handlePopupOpenClose = () => {
    setPopup(!popup);
  };
  const handlerTab = (valueTab) => {
    console.log("mydata===============", valueTab);
  }
  useEffect(() => {
    setDownloadObjectOther({ Ids: selectedRows, status: 3, type: "download_sent" });
    const updatedDownload = selectedRows.map((num) => ({
      custId: num,
      authFactorType: "01",
      identityType: isFilterValue?.zipValue,
    }));

    setDownloadObject((prevObject) => ({
      ...prevObject,
      download: updatedDownload,
    }));
  }, [selectedRows]);
  const handlerFilter = (filterValue) => {
    setIsFilterValue(filterValue)
  }
  const leftSideActions = [
    { title: "Filter By Identity", isTitle: true },
    {
      options: [
        {
          label: "Passport",
          Icon: "",
          onClick: () => handlerFilter({ zipValue: "A", listValue: "passport" }),
        },
        {
          label: "Voter ID",
          Icon: "",
          onClick: () => handlerFilter({ zipValue: "B", listValue: "voter_id" }),
        },
        {
          label: "PAN",
          Icon: "",
          onClick: () => handlerFilter({ zipValue: "C", listValue: "pan" }),
        },
        {
          label: "Driving License",
          Icon: "",
          onClick: () => handlerFilter({ zipValue: "D", listValue: "driving_licence" }),
        },
        {
          label: "UID",
          Icon : "",
          onClick : ()=>handlerFilter({ zipValue: "E", listValue: "aadhar" }),
        },
      ],
      defaultText: isFilterValue?.listValue,
      isDropdown: true,
      displayDefaultText: true,
    },
    {
      options: [
        {
          label: "Individual",
          onClick: handleTabChange,
        },
        // {
        //   label: "Legal Entities",
        //   onClick: handleTabChange,
        // },
      ],
      defaultText: "Individual",
      isDropdown: true,
      displayDefaultText: true,
    },
    {
      defaultText: "Generate ZIP",
      isDropdown: false,
      isButton: true,
      title: "Generate ZIP",
      onClick: handlerZipDownload,
      color: "contained",
      disabled: rowSelect
    },
    {
      defaultText: "Generate SFTP",
      isDropdown: false,
      isButton: true,
      title: "Generate SFTP",
      onClick: handlerSftpDownload,
      color: "contained",
      disabled: rowSelect
    },
  ]
  const leftSideOptions = [
    activeTabPopup === 1 && {
      defaultText: "Upload Response File",
      isDropdown: false,
      isButton: true,
      title: "Upload Response File",
      onClick: () => {
        handlePopupOpenClose();
      },
      color: "contained",
    }

  ].filter(Boolean);
  const rightSideActions = [
    {
      title: "Bulk Download",
      onClick: () => handlerTab("bulk"),
      isSplit: true,
    },
    {
      title: "ZIP File Generated",
      onClick: () => handlerTab("zip"),
      isSplit: true,
    },
  ];
  useEffect(() => {
    setTableData(data?.get("items")?.toJS() || []);
  }, [data, activeTabPopup])
  const onFileUpload = function (file) {
    if (file) {
      let formData = new FormData();
      formData.append(`files`, file);
      // formData.append("save", true);
      // if (selectedCustomerIds.length > 0) {
      // 	let selectedIds = selectedCustomerIds?.join();
      // 	formData.append("customerIds", selectedIds);
      // }
      uploadFile(formData);
    }
  };
  useEffect(() => {
    if (data?.get("totalCount") >= 0) {
      setTotalCount(data?.get("totalCount"));
    }
  }, [data, activeTabPopup]);
  // useEffect(() => {
  //   if (data?.get("items")?.toJS()?.length > 0) {
  //     setTableData(data?.get("items")?.toJS())
  //   }
  // }, [data?.get("items")])
  return (
    <React.Fragment>
      <PageHeader
        toggleDrawer={toggleDrawer}
        leftSideActions={leftSideActions}
        rightSideActions={rightSideActions}
        handleTabChange={handleTabChange}
        activeTabPopup={activeTabPopup}
        handlePopup={handlePopup}
        activeTab={activeTab}
        pageTitle={pageTitle}
        isSearch={true}
        searchOnChange={onSearch}
        totalCount={totalCount}
        leftSideOptions={leftSideOptions}
      />
      <Box sx={{ padding: "15px", height: "100%" }}>
        <DataTable
          loading={false}
          getRowId={(row) => row.customerId}
          pageTitle={pageTitle}
          pageSize={filter?.perPageSize}
          totalCount={totalCount}
          columns={getColumns({
            onToggleActive,
            onDocketClick,
            onRemarksClick,
            // showHistory,
            activeTab,
            pageTitle,
            activeTabPopup
          })}
          rows={tableData}
          onSelectionModelChange={handleRowSelection}
          onPageSize={(pageSize) => {
            updateFilter((fil) => ({
              ...fil,
              perPageSize: pageSize,
            }));
          }}
          onPage={(page) => {
            updateFilter((fil) => ({
              ...fil,
              page: page,
            }));
          }}
          rowsPerPageOptions={[5, 10, 25, 50]}
        />
      </Box>
      <UploadFile
        open={popup}
        toggleDrawer={() => setPopup(!popup)}
        title={"Upload Files"}
        onSubmit={onFileUpload}
        uploadingDoc={uploadingDocs}
        downloadSample={""}
        hideSampleFile={true}
        isBulk={true}
        setPopup={setPopup}
      />
      {openDeletePopup && (
        <DeletePopUp
          title="Delete Document"
          closePopup={() => toggleDeletePopup(false)}
          openDeletePopup={openDeletePopup}
          onDelete={() => onDeleteUser()}
          isLoading={isLoading}
        />
      )}
      {docketOpen && customerId && (
        <CustomerDetails
          open={docketOpen}
          closePanel={onCloseDocketForm}
          openNewCKYCForm={openNewCKYCForm}
          customerId={customerId}
          dropdownOptions={dropdownOptions}
          openUpdateForm={openUpdateForm}
          openCustomerJourenyPanel={() => toggleRemarksPanel(true)}
          closeCustomerJourenyPanel={() => toggleRemarksPanel(false)}
        />
      )}
      {openCustomerJourneyPanel && (
        <CustomerJoruneyPanel
          open={openCustomerJourneyPanel}
          toggleDrawer={() => toggleCustomerJourneyPanel(false)}
          customerId={customerId}
        />
      )}
    </React.Fragment>
  );
};

export default NewCustomers;
