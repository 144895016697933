import styled, { css } from "styled-components";

const TextWrapper = styled.p`
  cursor: ${(props) => props.cursor || "inherit"};
  word-wrap: ${(props) => props.wordWrap || "anywhere"};
  margin: ${(props) => props.margin || "0"};
  margin-top: ${(props) => props.mt || 0};
  margin-bottom: ${(props) => props.mb || 0};
  margin-left: ${(props) => props.ml || 0};
  margin-right: ${(props) => props.mr || 0};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  font-weight: ${(props) => props.fontWeight || "inherit"};
  color: ${(props) => (props.color ? props.color : "inherit")};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : "inherit"};
  ${(props) =>
    props.semiBold &&
    css`
      font-weight: 500;
    `}
  ${(props) =>
    props?.mandatory &&
    css`
      &:after {
        content: "*";
        color: red;
        font-weight: bold;
        margin-left: 4px;
      }
    `}
`;
const Text = (props) => {
  const { children, ...rest } = props;

  return <TextWrapper {...rest} title={props.title} >{children}</TextWrapper>;
};

export default Text;
