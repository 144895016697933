import React, { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import Immutable from "immutable";

import { getBranches } from "../apis/users";
import { getCountries, getStates } from "../apis/branches";
import { getAllProofData, getMasterData } from "../apis/newCustomer";
import { getCkycUsers } from "../apis/ckycUsers";
import { getUsers } from "../apis/users";
import { getDistricts, getNewDistricts, getNewPincode } from "../apis/districtMaster";
import { getDocuments } from "../apis/docMaster";
import { useGlobalContext } from "../context/GlobalContext";
import { useEffect } from "react";

const useDropdownOptions = () => {
  const {
    state: { dropdownFilters },
  } = useGlobalContext();
  const [pincodes, setPinCodes] = useState([]);
  const [currentPincodes, setCurrentPinCodes] = useState([]);

  const { data: docs, refetch: fetchDocs } = useQuery(
    ["docs", { filter: {}, search: null }],
    getDocuments,
    {
      select: (response) => response.get("data"),
      manual: true,
      enabled: false,
    }
  );

  const { data: countries, refetch: fetchCountries } = useQuery(
    ["countries"],
    getCountries,
    {
      select: (response) => response.data,
      manual: true,
      enabled: false,
    }
  );

  const { data: districts, refetch: fetchDistricts } = useQuery(
    [
      "districts",
      {
        // filter: dropdownFilters["stateCodeFilter"]
        // 	? { stateCodeFilter: dropdownFilters["stateCodeFilter"] }
        // 	: {
        // 			currentStateCodeFilter: dropdownFilters["currentStateCodeFilter"],
        // 	  },
        filter: dropdownFilters["state"]
          ? { stateCodeFilter: dropdownFilters["state"] }
          : {},
        search: null,
      },
    ],
    getDistricts,
    {
      select: (response) => response.get("data"),
      manual: true,
      enabled: false,
    }
  );

  const { data: newDistricts, refetch: fetchNewDistricts } = useQuery(
    [
      "NewDistricts",
      {
        filter: dropdownFilters["state"]
          ? { state_code: dropdownFilters["state"] }
          : {},
        search: null,
      },
    ],
    getNewDistricts,
    {
      select: (response) => response,
      manual: true,
      enabled: false,
    }
  );
  

  // const { data: pincodes, refetch: fetchPincodes } = useQuery(
  // 	[
  // 		"pincodes",
  // 		{
  // 			filter: {
  // 				pincodeFilter: dropdownFilters["pincodeFilter"],
  // 				currentPincodeFilter: true,
  // 			},
  // 			search: dropdownFilters["search"] ? dropdownFilters["search"] : null,
  // 		},
  // 	],
  // 	getDistricts,
  // 	{
  // 		select: (response) => response.get("data"),
  // 		manual: true,
  // 		enabled: false,
  // 	}
  // );

  const { data: currentDistricts, refetch: fetchCurrentDistricts } = useQuery(
    [
      "currentdistricts",
      {
        // filter: dropdownFilters["currentStateCodeFilter"]
        // 	? {
        // 			stateCodeFilter: dropdownFilters["currentStateCodeFilter"],
        // 	  }
        // 	: {},
        filter: dropdownFilters["currentState"]
          ? { stateCodeFilter: dropdownFilters["currentState"] }
          : {},
        search: null,
        search: null,
      },
    ],
    getDistricts,
    {
      select: (response) => response.get("data"),
      manual: true,
      enabled: false,
    }
  );
  const { data: currentNewDistricts, refetch: fetchCurrentNewDistricts } = useQuery(
    [
      "currentNewdistricts",
      {
        // filter: dropdownFilters["currentStateCodeFilter"]
        // 	? {
        // 			stateCodeFilter: dropdownFilters["currentStateCodeFilter"],
        // 	  }
        // 	: {},
        filter: dropdownFilters["currentState"]
          ? { state_code: dropdownFilters["currentState"] }
          : {},
        search: null,
        search: null,
      },
    ],
    getNewDistricts,
    {
      select: (response) => response,
      manual: true,
      enabled: false,
    }
  );

  const {data : newPincode, refetch : fetchNewPincode} = useQuery(
    [
      "newPincode",
      {
        filter : dropdownFilters["selectedDistrict"] ? { district_name : dropdownFilters["selectedDistrict"]} : {},
      }
    ],
    getNewPincode,
    {
      select: (response) => response,
      manual: true,
      enabled: false,
    }
  );
  const {data : currentNewPincode, refetch : fetchCurrentNewPincode} = useQuery(
    [
      "currentNewPincode",
      {
        filter : dropdownFilters["currentSelectedDistrict"] ? { district_name : dropdownFilters["currentSelectedDistrict"]} : {},
      }
    ],
    getNewPincode,
    {
      select: (response) => response,
      manual: true,
      enabled: false,
    }
  );

  console.log("use DropdownFileters for disttict ", {currentNewPincode : currentNewPincode})
  // const { data: currentPincodes, refetch: fetchCurrentPincodes } = useQuery(
  // 	[
  // 		"currentPincodes",
  // 		{
  // 			filter: dropdownFilters["currentPincodeFilter"]
  // 				? {
  // 						pincodeFilter: dropdownFilters["currentPincodeFilter"],
  // 				  }
  // 				: {},
  // 			search: dropdownFilters["currentSearch"]
  // 				? dropdownFilters["currentSearch"]
  // 				: null,
  // 		},
  // 	],
  // 	getDistricts,
  // 	{
  // 		select: (response) => response.get("data"),
  // 		manual: true,
  // 		enabled: false,
  // 	}
  // );

  const { data: states, refetch: fetchStates } = useQuery(
    ["states"],
    getStates,
    {
      select: (response) => response.data,
      manual: true,
      enabled: false,
    }
  );

  const { data: bracnhes, refetch: fetchBranches } = useQuery(
    ["branches"],
    getBranches,
    {
      select: (response) => response.data,
      manual: true,
      enabled: false,
    }
  );

  const { data: masterData, refetch: fetchMasterData } = useQuery(
    ["masterInfo"],
    getMasterData,
    {
      select: (response) => response.data,
      manual: true,
      enabled: false,
    }
  );

  const {data: allProofData, refetch: refetchAllProofData } = useQuery(
    ["allProofData"],
    getAllProofData,
    {
      select: (response) => response.data,
      manual: true,
      enabled: false,
    }
  )

  const { data: ckycUsers, refetch: fetchCkycUsers } = useQuery(
    ["allCKYCUsers", { filter: {}, search: null }],
    getCkycUsers,
    {
      select: (response) => response.get("data"),
      manual: true,
      enabled: false,
    }
  );
  const { data: users, refetch: fetchUsers } = useQuery(
    ["allUsers", { filter: {}, search: null }],
    getUsers,
    {
      select: (response) => response?.data,
      manual: true,
      enabled: false,
    }
  );
  const getMasterType = (label) => {
    const data =
      masterData &&
      masterData.length > 0 &&
      masterData.find((item) => Object.keys(item)[0] === label);

    return data ? Object.values(data)[0] : [];
  };

  const getUniqDistricts = (values) => {
    const distinctDistrctNames = [];
    const distinctDistrcts = [];
    values.forEach((item) => {
      if (!distinctDistrctNames.includes(item.districtName)) {
        distinctDistrcts.push(item);
        distinctDistrctNames.push(item.districtName);
      }
    });
    return distinctDistrcts;
  };
  const dropdownOptions = {
    prefix: getMasterType("Name Prefix"),
    accountType: getMasterType("Account Type"),
    customerBranch: bracnhes?.items || [],
    branch: bracnhes?.items || [],
    constitutionType: getMasterType("Constitution Type"),
    residentialStatus: getMasterType("Residential Status"),
    occupatationType: getMasterType("Occupation"),
    // addressProof: getMasterType("Address Proof"),
    addressProof: allProofData?.items,
    // currentAddressProof: getMasterType("Address Proof"),
    currentAddressProof: allProofData?.items,
    // relatedAddressProof: getMasterType("Address Proof"),
    relatedAddressProof: allProofData?.items,
    // AddressProof: getMasterType("Address Proof"),
    AddressProof: allProofData?.items,
    // identityProof: getMasterType("ID Proof")?.map((ele) => {
    //   if (ele.name === "Photograph") {
    //     ele.documentCode = "02";
    //     ele.documentType = 2;
    //   }
    //   if (ele.name === "Signature") {
    //     ele.documentCode = "09";
    //     ele.documentType = 9;
    //   }
    //   return ele;
    // }),
    identityProof : allProofData?.items,
    additional: getMasterType("Addition Details"),
    employeeDesignation: getMasterType("Designation"),
    personalcitizenship: countries || [],
    country: countries || [],
    docs: docs && !Array.isArray(docs.toJS()) ? docs.get("items").toJS() : [],
    relatedCountry: countries || [],
    currentCountry: countries || [],
    relatedPermCountry: countries || [],
    state: states || [],
    currentState: states || [],
    relatedState: states || [],
    relatedPermState: states || [],
    employeeName: users ? users.items : [],
    employeeNameNew:
      ckycUsers && !Array.isArray(ckycUsers.toJS())
        ? ckycUsers.get("items").toJS()
        : [],
    users: users
      ? users.items?.filter(
          (i) => i?.firstName && i?.role?.roleName === "Checker"
        )
      : [],
    ckycUsers:
      ckycUsers && !Array.isArray(ckycUsers.toJS())
        ? ckycUsers.get("items").toJS()
        : [],
    district: newDistricts && (newDistricts?.data?.length ? newDistricts?.data?.map(items => {
      return items.charAt(0).toUpperCase() + items.slice(1);
    }) : []), 
    pincode: newPincode && (newPincode?.data || []),
    currentDistrict:  currentNewDistricts && ( currentNewDistricts?.data?.length ? currentNewDistricts?.data?.map(items => {
      return items.charAt(0).toUpperCase() + items.slice(1);
    }) : []),
    currentPincode: currentNewPincode && (currentNewPincode?.data || []),
    documentRecieved: getMasterType("Documents Received"),
    fatherInitial: ["Mr", "Mrs", "Minor", "Miss", "Dr"],
    motherInitial: ["Mr", "Mrs", "Minor", "Miss", "Dr"],
    dependentType: [
      { label: "Father", value: "01" },
      { label: "Spouse", value: "02" },
    ],
    relationshipTitle : getMasterType("Related Person Type"),
  };

  const getPinCodes = () => {
    console.log("---");
    const allDistricts =
      districts && !Array.isArray(districts.toJS())
        ? districts.get("items").toJS()
        : [];
    setPinCodes(
      allDistricts?.filter(
        (item) => dropdownFilters["selectedDistrict"] === item.districtName
      ) || []
    );
  };

  const getCurrentPinCodes = () => {
    const allDistricts =
      currentDistricts && !Array.isArray(currentDistricts.toJS())
        ? currentDistricts.get("items").toJS()
        : [];
    setCurrentPinCodes(
      allDistricts?.filter(
        (item) =>
          dropdownFilters["currentSelectedDistrict"] === item.districtName
      ) || []
    );
  };

  // useEffect(() => {
  //   getPinCodes()
  // 	getCurrentPinCodes()
  // }, [])

  useEffect(() => {
    if (dropdownFilters["selectedDistrict"]) getPinCodes();
  }, [dropdownFilters["selectedDistrict"], districts]);

  useEffect(() => {
    if (dropdownFilters["currentSelectedDistrict"]) getCurrentPinCodes();
  }, [dropdownFilters["currentSelectedDistrict"], currentDistricts]);

  React.useEffect(() => {
    // const allreadyFetched = Object.keys(dropdownOptions.some(
    // 	(item) => dropdownOptions[item] !== []
    // );
    // if (!allreadyFetched)
    (async () => {
      if (!bracnhes) await fetchBranches();
      if (!countries) await fetchCountries();
      if (!states) await fetchStates();
      if (!masterData) await fetchMasterData();
      if (!allProofData) await refetchAllProofData();
      if (!ckycUsers) await fetchCkycUsers();
      if (!users) await fetchUsers();
      if (pincodes.length === 0 || dropdownFilters["selectedDistrict"])
        getPinCodes();
      if (!currentDistricts) {
        await fetchCurrentDistricts();
        fetchCurrentNewDistricts();
      }
      if (
        currentPincodes.length === 0 ||
        dropdownFilters["currentSelectedDistrict"]
      )
        getCurrentPinCodes();
      if (!docs) await fetchDocs();
    })();
  }, [dropdownFilters]);

  useEffect(() => {
    (async () => {
      if (!districts || dropdownFilters["state"]){
        await fetchDistricts();
        fetchNewDistricts();
      };
    })();
  }, [dropdownFilters["state"]]);

  useEffect(()=>{
    (async () => {
      if(dropdownFilters["selectedDistrict"]){
        await fetchNewPincode();
      }
    })();
  }, [dropdownFilters["selectedDistrict"]])
  useEffect(()=>{
    (async () => {
      if(dropdownFilters["currentSelectedDistrict"]){
        await fetchCurrentNewPincode();
      }
    })();
  }, [dropdownFilters["currentSelectedDistrict"]])

  return { dropdownOptions };
};

export default useDropdownOptions;
