import { Box } from "@mui/material";
import React, { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useMutation, useQuery } from "@tanstack/react-query";
import Immutable from "immutable";

import {
  getDistricts,
  createOrEditDistrict,
  deleteDistrict,
} from "../apis/districtMaster";
import DataTable from "../components/Molecules/DataTable";
import PageHeader from "../components/Molecules/PageHeader";
import AddDistrictMaster from "../components/Organisms/AddDistrictMaster";
import {
  deactivated,
  activate,
  selectAll,
  uploadUser,
  addNewUser,
} from "../assets/icons";
import DeletePopUp from "../components/Molecules/PopupModal";
import { useGlobalContext } from "../context/GlobalContext";
import { getColumns } from "../utils/tableHeaders/districtMaster";
import { importDistricts, getImportTemplate } from "../apis/bulkOperations";
import Backdrop from "../components/Molecules/Backdrop";
import UploadFile from "../components/Organisms/UploadFile";

const pageTitle = "District Master";

const DistrictMaster = () => {
  const [addUserPanel, setAddUserPanel] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const [openDeletePopup, toggleDeletePopup] = React.useState(false);
  const { dispatch } = useGlobalContext();
  const [selectedDistrict, setSelectedDoc] = React.useState(null);
  //const [filter, updateFilter] = React.useState({});
  const [filter, updateFilter] = React.useState({
    condition: "",
    page: 0,
    perPageSize: 5
  });
  const [totalCount, setTotalCount] = React.useState(0);
  const [search, updateSearch] = React.useState(null);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [openUploadFile, toggleUploadFile] = React.useState();
  const [formReset, setFormReset] = React.useState(false);
  const { data, refetch } = useQuery(
    ["districts", { filter, search }],
    getDistricts,
    {
      manual: true,
      select: (response) => response.get("data", []),
      retry: false,
    }
  );

  const { refetch: downloadTemplate, isLoading: downloadingTemplate } =
    useQuery(["template", { module: "districtMaster" }], getImportTemplate, {
      manual: true,
      // select: (response) => response.get("data", []),
      retry: false,
      enabled: false,
      onSuccess: async (response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        if (url) {
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "districtMaster_template");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      },
    });

  const { mutate: addOrUpdateDoc, isLoading } = useMutation(
    (userData) => createOrEditDistrict(userData),
    {
      onSuccess: (response) => {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.message,
            severity: response.isError ? "error" : "success",
          },
        });
        // updateFilter({});
        if (!response.isError) {
          setAddUserPanel(false);
          toggleDeletePopup(false);
          refetch();
        }
      },
      onError: (error) => {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: error.response.data.message,
            severity: "error",
          },
        });
      },
    }
  );

  const {
    mutate: uploadFile,
    isLoading: uploadingDocs,
    // isSuccess: stepUpdated,
  } = useMutation((userData) => importDistricts(userData), {
    onSuccess: (response) => {
      // toggleSaveData(false);
      if (response.isError) {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.message,
            severity: "error",
          },
        });
      } else {
        setFormReset(true);
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.message,
            severity: response.isError ? "error" : "success",
          },
        });
        refetch();
      }
    },
    onError: (error) => {
      dispatch({
        type: "SET_TOAST",
        payload: {
          open: true,
          text: "Something went wrong",
          severity: "error",
        },
      });
    },
  });

  const onSubmit = (data) => {
    let requestData = data;
    if (selectedDistrict?.get("id")) {
      requestData["id"] = selectedDistrict.get("id");
    }
    requestData = { district: requestData };
    addOrUpdateDoc(requestData);
  };

  const onDeleteUser = () => {
    const requestData = {
      id: selectedDistrict.get("id"),
    };
    addOrUpdateDoc(requestData);
    setSelectedDoc(null);
  };

  const toggleDrawer = () => {
    if (addUserPanel) {
      setSelectedDoc(null);
    }
    setAddUserPanel(!addUserPanel);
  };

  const onEditClick = async (id) => {
    const user = data.get("items").find((item) => item.get("id") === id);
    setSelectedDoc(user);
    toggleDrawer();
  };

  const onDeleteClick = (id) => {
    const user = data.get("items").find((item) => item.get("id") === id);
    setSelectedDoc(user);
    toggleDeletePopup(true);
  };

  const onToggleActive = (e, id) => {
    let checked = e.target.checked;
    let requestData = {
      id: id,
      status: checked ? 1 : 0,
    };
    requestData = { district: requestData };
    addOrUpdateDoc(requestData);
  };

  // const onFilter = (status) => {
  //   updateFilter(status);
  //   refetch();
  // };



  const onFilter = (status) => {
    updateFilter({ ...filter, ...status });
    refetch();
  };

  let searchTimeout;

  const searchUsers = (query) => {
    clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      updateSearch(query);
    }, 500);
  };

  const onSearch = (e) => {
    const val = e.target.value;
    searchUsers(val);
  };

  const onSave = () => {
    try {
      // toggleSaveData(true);
      let formData = new FormData();
      formData.append(`files`, selectedFile);
      formData.append("save", true);
      // if (selectedCustomerIds.length > 0) {
      // 	let selectedIds = selectedCustomerIds?.join();
      // 	formData.append("customerIds", selectedIds);
      // }
      uploadFile(formData);
    } catch (e) {
      // toggleSaveData(false);
    }
  };

  const onFileUpload = function (file) {
    if (file) {
      let formData = new FormData();
      formData.append(`files`, file);
      // formData.append("save", true);
      // if (selectedCustomerIds.length > 0) {
      // 	let selectedIds = selectedCustomerIds?.join();
      // 	formData.append("customerIds", selectedIds);
      // }
      uploadFile(formData);
    }
  };

  const rightSideActions = [
    {
      icon: EditIcon,
      options: [
        { label: "Add New", Icon: addNewUser, onClick: toggleDrawer },
        {
          label: "Upload",
          Icon: uploadUser,
          EditIcon,
          onClick: () => toggleUploadFile(!openUploadFile),
          // isFileUpload: true,
          // onFileUpload: onFileUpload,
        },
      ],
      title: "Add New",
      onClick: () => toggleDrawer(),
      isSplit: true,
    },
  ];

  const leftSideActions = [
    {
      options: [
        { label: "All Status", Icon: selectAll, onClick: () => onFilter({ statusFilter: null }) },
        {
          label: "Active",
          Icon: activate,
          onClick: () => onFilter({ statusFilter: 1 }),
        },
        {
          label: "InActive",
          Icon: deactivated,
          onClick: () => onFilter({ statusFilter: 0 }),
        },
      ],
      defaultText: "Activate",
      isDropdown: true,
    },
  ];

  useEffect(() => {
    if (data?.get("totalCount") >= 0) {
      setTotalCount(data?.get("totalCount"));
    }
  }, [data?.get("totalCount")]);
  useEffect(() => {
    if (data?.get("items")?.toJS()?.length >= 0) {
      setTableData(data?.get("items")?.toJS())
    }
  }, [data?.get("items")])
  return (
    <React.Fragment>
      <PageHeader
        toggleDrawer={toggleDrawer}
        leftSideActions={[...leftSideActions, ...rightSideActions]}
        // rightSideActions={rightSideActions}
        pageTitle={pageTitle}
        isSearch={true}
        searchOnChange={onSearch}
        totalCount={totalCount}
      />
      <Box sx={{ padding: "15px", height: "100%" }}>
        <DataTable
          loading={false}
          getRowId={(row) => row.id}
          pageSize={filter?.perPageSize}
          totalCount={totalCount}
          columns={getColumns({ onToggleActive, onDeleteClick, onEditClick })}
          rows={tableData}

          onPageSize={(pageSize) => {
            updateFilter((fil) => ({
              ...fil,
              perPageSize: pageSize,
            }));
          }}
          onPage={(page) => {
            updateFilter((fil) => ({
              ...fil,
              page: page,
            }));
          }}
        />
        {addUserPanel && (
          <AddDistrictMaster
            open={addUserPanel}
            toggleDrawer={toggleDrawer}
            onSubmit={onSubmit}
            selectedDistrict={selectedDistrict || Immutable.Map()}
            isLoading={isLoading}
          />
        )}
      </Box>
      {openDeletePopup && (
        <DeletePopUp
          title="Delete Document"
          closePopup={() => toggleDeletePopup(false)}
          openDeletePopup={openDeletePopup}
          onDelete={() => onDeleteUser()}
          isLoading={isLoading}
        />
      )}
      <Backdrop loading={uploadingDocs} />
      <UploadFile
        open={openUploadFile}
        toggleDrawer={() => toggleUploadFile(!openUploadFile)}
        title={"Upload Files"}
        onSubmit={onFileUpload}
        uploadingDoc={uploadingDocs}
        downloadSample={downloadTemplate}
        supportFile={"districtMaster"}
        formReset={formReset}
        setFormReset={setFormReset}
      />
    </React.Fragment>
  );
};

export default DistrictMaster;
