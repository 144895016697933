import React,{ useEffect, useState } from "react";

import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";

import Text from "../Atoms/Text";
import TextInput from "../Form-Components/TextInput";
import SelectInput from "../Form-Components/SelectInput";
import StickyFooter from "../Atoms/StickyFooter";
import Modal from "../Molecules/Modal";

const schema = yup
  .object()
  .shape({
    documentName: yup.string().max(255).required("First name is required"),
  //  isAccountLevel: yup.bool().required("Last name is required"),
    documentCode: yup.string().max(255).required("First name is required"),
    // identityCode: yup.string().max(255).required("Last name is required"),
    // addressProofCode: yup.string().max(255).required("First name is required"),
  })
  .required();

const AddUser = ({ open, toggleDrawer, selectedDoc, isLoading, onSubmit }) => {
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    control,
    formState,
    errors,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      documentName: selectedDoc?.get("documentName", ""),
      documentCode: selectedDoc?.get("documentCode", ""),
      identityCode: selectedDoc?.get("identityCode", ""),
      addressProofCode: selectedDoc?.get("addressProofCode", ""),
   //   isAccountLevel: selectedDoc?.get("isAccountLevel", ""),
    },
    resolver: yupResolver(schema),
  });
  const [isFormReset, setIsFormReset] = useState(false);

  useEffect(() => {
    if (isFormReset) {
      reset();
      setIsFormReset(false);
    }
  }, [isFormReset, reset]);

  const handleReset = () => {
    setIsFormReset(true);
  };
  return (
    <Modal
      title={selectedDoc?.get("id") ? "Edit Document" : "Add Document"}
      open={open}
      toggleDrawer={toggleDrawer}>
      <form
        style={{ display: "flex", flexDirection: "column" }}
        onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          flexDirection={"column"}
          p="24px"
          sx={{ overflowY: "auto", width: "100%" }}>
          <Box display="flex" sx={{ width: "100%", flexWrap: "wrap" }}>
            <TextInput
              label={"Enter Document Name"}
              fieldkey="documentName"
              errorText="Required"
              placeholder="Enter text"
              control={control}
              register={register}
              mandatory
             // isRegex={/^[a-zA-Z0-9\s]*$/}
              setValue={setValue}
              value={getValues()?.["documentName"]}
            />
            
            <TextInput
              label={"Document Code"}
              fieldkey="documentCode"
              errorText="Required"
              placeholder="Enter code"
              control={control}
              register={register}
              mandatory
              isRegex={/^[a-zA-Z0-9\s]*$/}
              setValue={setValue}
              value={getValues()?.["documentCode"]}
            />
            <TextInput
              label={"Identity Proof Code"}
              fieldkey="identityCode"
              errorText="Required"
              placeholder="Enter code"
              control={control}
              register={register}
              isRegex={/^[a-zA-Z0-9\s]*$/}
              setValue={setValue}
              value={getValues()?.["identityCode"]}
              // mandatory
            />
            <TextInput
              label={"Address Proof Code"}
              fieldkey="addressProofCode"
              errorText="Required"
              placeholder="Enter code"
              control={control}
              // mandatory
              register={register}
              isRegex={/^[a-zA-Z0-9\s]*$/}
              setValue={setValue}
              value={getValues()?.["addressProofCode"]}
            />
          </Box>
        </Box>
        <StickyFooter>
          <React.Fragment>
            <Button
              onClick={handleReset}
              sx={{ marginRight: "8px" }}
              variant="outlined">
              {selectedDoc?.get("id") ? "Reset" : "Clear"}
            </Button>
            <LoadingButton
              loading={formState.isSubmitting || isLoading}
              type={"submit"}
              size="small"
              variant="contained"
              color="primary"
              disableElevation
              style={{ textTransform: "none" }}>
              <Text>{selectedDoc?.get("id") ? "Save" : "Submit"}</Text>
            </LoadingButton>
          </React.Fragment>
        </StickyFooter>
      </form>
    </Modal>
  );
};

export default AddUser;
