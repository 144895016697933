import { Box, Tab, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useMutation, useQuery } from "@tanstack/react-query";
import Immutable, { update } from "immutable";
import { useEffect } from "react";
import DoneIcon from "@mui/icons-material/Done";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import {
  getDashboardData,
  getAutomationDashboardData,
} from "../apis/dashboard";
import DataTable from "../components/Molecules/DataTable";
import PageHeader from "../components/Molecules/PageHeader";
import AddUploadMaster from "../components/Organisms/AddUploadMaster";
import AutomationOverview from "./AutomationOverview";
import RefreshIcon from "@mui/icons-material/Refresh";

import {
  deactivated,
  activate,
  selectAll,
  uploadUser,
  addNewUser,
} from "../assets/icons";
import { getClients } from "../apis/manageClients";
import DeletePopUp from "../components/Molecules/PopupModal";
import { useGlobalContext } from "../context/GlobalContext";
import {
  getColumns,
  getCustomers,
  getResponse,
  getRecords,
  getTotals,
} from "../utils/tableHeaders/dashboard";
import { importUploadMaster, getImportTemplate } from "../apis/bulkOperations";
import Backdrop from "../components/Molecules/Backdrop";
import UploadFile from "../components/Organisms/UploadFile";
import PlainDatePicker from "../components/Form-Components/PlainDatePicker";
import Text from "../components/Atoms/Text";
import { formatDate } from "../components/Organisms/new-customer/formUtils";
import dayjs from "dayjs";

const pageTitle = "Dashboard";

const Dashboard = () => {
  const [isRefresh, setIsRefresh] = useState({});
  const [tableData, setTableData] = useState({});
  const [TabValue, setTabValue] = useState(0);
  
  const {
    state: { currentUser },
    dispatch,
  } = useGlobalContext();
  const [filter, updateFilter] = React.useState({
    condition: "",
    page: 0,
    perPageSize: 5,
  });
  const [search, updateSearch] = React.useState(null);
  const [clients, setClients] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [dashboardData, setDashboardData] = React.useState([]);
  const [showCustomRange, toggleCustomRange] = React.useState(false);
  const [currentClient, setCurrentClient] = React.useState(null);
  const [filterDate, setFilterDate] = React.useState({
    start: null,
    end: null,
  });
  const job = localStorage.getItem("job")
    ? JSON?.parse(localStorage.getItem("job"))
    : "MANUAL";

  let currentRole =
    JSON?.parse(window.localStorage.getItem("roles")) ||
    currentUser.role.role_name;

  const { data, refetch,isLoading,error } = useQuery(
    ["masters", { filter, search }],
    getDashboardData,
    {
      manual: true,
      select: (response) => response.get("data", []),
      retry: false,
      enabled: TabValue === 0,
    }
  );

  const { data: automationData, refetch: getAutomation } = useQuery(
    ["masters", { filter }],
    getAutomationDashboardData,
    {
      manual: true,
      select: (response) => response.get("data", []),
      retry: false,
      enabled: TabValue === 1,
    }
  );

  const totalData =
    (automationData &&
      automationData.get("result") &&
      automationData.get("result").toJS()) ||
    {};

  const { data: allClients, refetch: fetchClients } = useQuery(
    ["clients", { filter: {}, search: null, isDropdown: true }],
    getClients,
    {
      manual: true,
      enabled: false,
      select: (response) => response.getIn(["data", "items"]).toJS(),
      onSuccess: (response) => {
        let options = [];
        // if (response.length > 0) {
        // 	options.push({
        // 		label: "All Clients",
        // 		id: -1,
        // 		onClick: () => onFilter({ clientId: null }),
        // 	});
        // }
        response.forEach((item) => {
          options.push({
            label: item.clientName,
            id: item.id,
            onClick: () => setCurrentClient(item.id),
          });
        });
        setClients(options);
        setCurrentClient(response && response[0]?.id);
      },
    }
  );

  useEffect(() => {
    if (currentClient) {
      toggleCustomRange(false);
      updateFilter((fil) => ({
        ...fil,
        client_id: currentClient,
        condition: filter.condition,
        applicationType: filter.applicationType,
      }));
    }
  }, [currentClient]);

  useEffect(() => {
    if (currentRole && currentRole === "SuperAdmin") {
      fetchClients();
    }
  }, []);

  const onToggleActive = (e, id) => {};

  useEffect(() => {
    if (data && TabValue === 0) {
      setTotalCount(data?.get("totalCount"));
      setDashboardData(data?.get("result")?.toJS());
    }
    if (automationData && TabValue === 1) {
      setTotalCount(automationData?.get("totalCount"));
      setDashboardData(automationData?.get("result")?.toJS());
    }
  }, [
    data,
    automationData,
    TabValue,
  ]);
  
  useEffect(() => {
    if (TabValue === 0) {
      refetch();
    } else {
      getAutomation();
    }
  }, [filter, TabValue, search]);

  const onFilter = (status) => {
    let fromDate = "";
    let endDate = "";
    const today = new Date();
    if (status?.condition == "today") {
      fromDate = moment(today).format("DD-MM-YYYY");
      endDate = moment(today).format("DD-MM-YYYY");
    } else if (status?.condition === "7days") {
      const sevenDaysAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
      fromDate = moment(sevenDaysAgo.toISOString().slice(0, 10)).format(
        "DD-MM-YYYY"
      );
      endDate = moment(today.toISOString().slice(0, 10)).format("DD-MM-YYYY");
    } else if (status?.condition === "month_to_date") {
      const today = new Date();
      fromDate = moment(
        new Date(today.getFullYear(), today.getMonth(), 1)
      ).format("DD-MM-YYYY");
      endDate = moment(
        new Date(today.getFullYear(), today.getMonth() + 1, 0)
      ).format("DD-MM-YYYY");
    }

    toggleCustomRange(false);
    // updateFilter({ ...filter, ...status,fromDate,endDate });
    updateFilter((fil) => ({ ...fil, ...status, fromDate, endDate }));
    //  setDateValue({fromDate : fromDate, endDate: endDate});
  };

  

  let searchTimeout;

  // const searchUsers = (query) => {
  //   clearTimeout(searchTimeout);

  //   searchTimeout = setTimeout(() => {
  //     updateSearch(query);
  //   }, 500);
  // };

  // const onSearch = (e) => {
  //   const val = e.target.value;
  //   console.log("value===", val)
  //   searchUsers(val);
  // };

  const searchUsers = (query) => {
    clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      updateSearch(query);
    }, 500);
  };

  const onSearch = (e) => {
    const val = e.target.value;

    searchUsers(val);
  };

  const onCustomRange = () => {

    if (filterDate.end < filterDate.start) {
      dispatch({
        type: "SET_TOAST",
        payload: {
          open: true,
          text: "invalid dates are selected.. please select valid dates",
          severity: "error",
        }
      })
    
    } 
    console.log(filterDate);
    // updateFilter({
    //   ...filter,
    //   condition: {
    //     fromDate: formatDate(dayjs(filterDate.start)?.format("MM-DD-YYYY")),
    //     endDate: formatDate(dayjs(filterDate.end)?.format("MM-DD-YYYY")),
    //   },
    // });
    updateFilter((fil) => ({
      ...fil,
      condition: {
        fromDate: formatDate(dayjs(filterDate.start)?.format("MM-DD-YYYY")),
        endDate: formatDate(dayjs(filterDate.end)?.format("MM-DD-YYYY")),
      },
    }));
    // refetch();
  };

  const customDateRange = () => {
    const date = new Date();
    return (
      <Box alignItems="flex-start" display="flex" sx={{ width: "max-content" }}>
        <PlainDatePicker
          onChange={(val) => setFilterDate({ ...filterDate, start: val })}
          maxDate={date}
        />
        <Text mr="4px" ml="4px" mt="10px">
          to
        </Text>
        <PlainDatePicker
          value={filterDate.end}
          onChange={(val) => setFilterDate({ ...filterDate, end: val })}
          maxDate={new Date()}
          disabled={!filterDate.start}
          minDate={filterDate && filterDate?.start?.add("0", "day")}
        />
        <DoneIcon
          sx={{
            color: "green",
            marginLeft: "8px",
            marginRight: "6px",
            cursor: "pointer",
            border: "1px solid #ccc",
            padding: "3px",
          }}
          onClick={onCustomRange}
        />
      </Box>
    );
  };
  const handleRefresh = () => getAutomation();

  const leftSideActions = [
    isRefresh,
    ...[
      showCustomRange && {
        customComponent: true,
        component: () => customDateRange(),
      },
    ],
    {
      options: [
        {
          label: "Today",
          // Icon: selectAll,
          onClick: () => onFilter({ condition: "today" }),
        },
        {
          label: "Last 7 Days",
          // Icon: activate,
          onClick: () => onFilter({ condition: "7days" }),
        },
        {
          label: "This Month",
          // Icon: deactivated,
          onClick: () => onFilter({ condition: "month_to_date" }),
        },
        {
          label: "All Time",
          // Icon: deactivated,
          onClick: () => onFilter({ condition: "" }),
        },
        {
          label: "Custom Range",
          // Icon: deactivated,
          onClick: () => toggleCustomRange(true),
        },
      ],
      defaultText: "All Time",
      displayDefaultText: true,
      isDropdown: true,
    },

    ...[
      currentRole === "SuperAdmin" &&
        clients.length > 0 && {
          options: clients,
          isDropdown: true,
          explicit: true,
          title: "Clients",
          defaultText: clients && clients[0],
          //defaultText:"Clients",
          //displayDefaultText: true
        },
    ],
  ];
  var options = {};
  if (
    (job === "AUTOMATION" && currentRole === "Admin") ||
    (job === "AUTOMATION" && currentRole === "Maker") ||
    (job === "AUTOMATION" && currentRole === "Checker") ||
    currentRole === "SuperAdmin"
  ) {
    options = {
      title: "Automation Overview",
      onClick: null,
      isSplit: true,
      value: "auto",
    };
  }

  const rightSideActions = [
    {
      title: "Overview",
      onClick: null,
      isSplit: true,
      value: "over",
    },
  ];
  if (options.title) {
    rightSideActions.push(options);
  }

  const handleTabChange = () => {
    if (TabValue === 0) {
      setTabValue(1);
      setIsRefresh({
        defaultText: "Refresh",
        isDropdown: false,
        isButton: true,
        icon: <RefreshIcon />,
        title: "Refresh",
        onClick: handleRefresh,
        color: "contained",
      });
    } else if (TabValue === 1) {
      setTabValue(0);
      setIsRefresh({});
    }
  };

  const statuses = [
    {
      key: "CKYC Accepted",
      count:
        totalData?.statuses_counts?.find(
          (ele) => ele.status_text === "CKYC Approved"
        )?.total_count ?? 0,
      id: 1,
    },
    {
      key: "CKYC Rejected",
      count:
        totalData?.statuses_counts?.find(
          (ele) => ele.status_text === "CKYC Rejected"
        )?.total_count ?? 0,
      id: 2,
    },
    {
      key: "CKYC Uploaded",
      count:
        totalData?.statuses_counts?.find(
          (ele) => ele.status_text === "CKYC Uploaded"
        )?.total_count ?? 0,
      id: 3,
    },
    {
      key: "CKYC onHold",
      count:
        totalData?.statuses_counts?.find(
          (ele) => ele.status_text === "CKYC onhold"
        )?.total_count ?? 0,
      id: 4,
    },

    {
      key: "Probable Match",
      count:
        totalData?.statuses_counts?.find(
          (ele) => ele.status_text === "Probable Match"
        )?.total_count ?? 0,
      id: 5,
    },
  ];

  const totals = [
    {
      key: "Total customers onboardred",
      count: totalData?.onboarded_customers?.[0]?.count ?? 0,
      id: 1,
    },
    {
      key: "KYC update received from CERSAI",
      count: 0,
        // totalData?.total_zip_files_generated?.find(
        //   (ele) => ele.application_type === "03"
        // )?.total_files_generated ?? 0,
      id: 2,
    },
    {
      key: "Total ZIP Files generated and uploaded to CERSAI (New)",
      count:
        totalData?.total_zip_files_generated?.find(
          (ele) => ele.application_type === "01"
        )?.total_files_generated ?? 0,
      id: 3,
    },
    {
      key: "Total ZIP Files generated and uploaded to CERSAI (Update)",
      count:
        totalData?.total_zip_files_generated?.find(
          (ele) => ele.application_type === "03"
        )?.total_files_generated ?? 0,
      id: 4,
    },
    {
      key: "Records which are in queue to be generated as a ZIP file",
      count: totalData?.queue_generated_as_zip ?? 0,
      id: 5,
    },
    {
      key: "Total Records uploaded to CERSAI portal via SFTP",
      count: totalData?.sftp_upload ?? 0,
      id: 6,
    },
  ];

  const failedStatus = [
    {
      key: "CERSAI Search",
      count:
        totalData?.failed_count?.find((ele) => ele.action === "search")
          ?.count ?? 0,
      id: 1,
    },
    {
      key: "CERSAI Download",
      count:
        totalData?.failed_count?.find((ele) => ele.action === "download")
          ?.count ?? 0,
      id: 2,
    },
    {
      key: "ZIP Download",
      count:
        totalData?.failed_count?.find((ele) => ele.action === "zip_download")
          ?.count ?? 0,
      id: 3,
    },
    {
      key: "Failed Customer Count",
      count: totalData?.failed_customer_count ?? 0,
      id: 4,
    },
  ];

  const ckyc_records = [
    {
      key: "Total Jobs",
      count: totalData?.total_jobs ?? 0,
      id: 1,
    },
    // {
    //   key: "CKYC search",
    //   count:   totalData?.search_count?.find(ele => (
    //     ele.application_type === 1 || ele.application_type === 3
    //   ))?.is_sent_for_search_count??0,
    //   id:2
    // },
    {
      key: "CKYC Search",
      count: totalData?.search_count?.reduce(
        (accValue, currentValue) =>
          accValue + parseInt(currentValue?.is_sent_for_search_count),
        0
      ),
      id: 2,
    },
    {
      key: "CKYC Download",

      count: totalData?.download_count?.reduce(
        (accValue, currentValue) =>
          accValue + parseInt(currentValue?.is_sent_for_download_count),
        0
      ),
      id: 3,
    },
  ];

  console.log(
    "totalcount",
    data?.get("totalCount", 0),
    data?.get("result")?.toJS(),
    isLoading,error,
    dashboardData
  );
 // console.log("datatat===",data.get("result")?.toJS())
  return (
    <React.Fragment>
      <PageHeader
        toggleDrawer={null}
        leftSideActions={[...leftSideActions]}
        rightSideActions={
          currentRole === "Admin" ||
          currentRole === "SuperAdmin" ||
          currentRole === "Maker" ||
          currentRole === "Checker"
            ? rightSideActions
            : []
        }
        //   rightTabs={rightTabs}
        // leftSideActions={[]}
        currentRole={currentRole}
        TabValue={TabValue}
        //handleTabChange={handleTabChange}
        pageTitle={pageTitle}
        handleTabChange={handleTabChange}
        isSearch={true}
        searchOnChange={onSearch}
        totalCount={totalCount}
      />
      {TabValue === 0 ? (
        <Box sx={{ padding: "15px", height: "100%" }}>
          <DataTable
            loading={false}
            getRowId={(row) => row.branch_name}
            pageSize={filter?.perPageSize}
            totalCount={totalCount}
            columns={getColumns({})}
            rows={dashboardData || []}
            onPageSize={(pageSize) => {
              updateFilter((fil) => ({
                ...fil,
                perPageSize: pageSize,
                page: 0,
              }));
            }}
            onPage={(page) => {
              updateFilter((fil) => ({
                ...fil,
                page,
              }));
            }}
          />
        </Box>
      ) : (
        <Box
          m="8px"
          p="4px"
          sx={{
            border: "0.2px solid lightgray",
            borderRadius: "10px",
            height: "90%",
          }}
        >
          <Box m="8px" height="100%">
            <Box
              m="8px"
              p="4px"
              sx={{
                //: "0.2px solid lightgray",
                borderRadius: "10px",
                // height: "105%",
                width: "98%",
                display: "flex",
              }}
            >
              <Grid container rowSpacing={3} columnSpacing={{ sm: 5, md: 4 }}>
                <Grid item xs={6}>
                  <Typography>Totals</Typography>
                  <AutomationOverview
                    columns={[
                      {
                        field: "key",
                        headerName: "Totals",

                        flex: 0.7,
                        align: "left",
                      },
                      {
                        field: "count",
                        headerName: "Records",
                        flex: 0.2,
                        align: "right",
                        editable: false,
                      },
                    ]}
                    rows={totals}
                  ></AutomationOverview>
                </Grid>
                <Grid item xs={6}>
                  <Typography>Responses Received From CERSAI</Typography>
                  <AutomationOverview
                    columns={[
                      {
                        field: "key",
                        headerName: "Status",
                        flex: 0.7,
                        align: "left",
                      },
                      {
                        field: "count",
                        headerName: "Records",
                        flex: 0.3,

                        align: "right",
                        editable: false,
                      },
                    ]}
                    rows={statuses ? statuses : []}
                  ></AutomationOverview>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    Number of customers failed with automation
                  </Typography>
                  <AutomationOverview
                    columns={[
                      {
                        field: "key",
                        headerName: "Status",
                        flex: 0.7,
                        align: "left",
                      },
                      {
                        field: "count",
                        headerName: "Records",
                        flex: 0.3,

                        align: "right",
                        editable: false,
                      },
                    ]}
                    rows={failedStatus}
                  ></AutomationOverview>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    Total Records sent for CKYC search and download
                  </Typography>
                  <AutomationOverview
                    columns={[
                      {
                        field: "key",
                        headerName: "Total",
                        flex: 0.7,
                        align: "left",
                      },
                      {
                        field: "count",
                        headerName: "Records",
                        flex: 0.3,

                        align: "right",
                        editable: false,
                      },
                    ]}
                    rows={ckyc_records}
                  ></AutomationOverview>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      )}

      {/* <Backdrop loading={uploadingDocs} /> */}
    </React.Fragment>
  );
};

export default Dashboard;
