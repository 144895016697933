import React,{useState,useEffect} from "react";
import {
  Box,
  Button,
  Card,
  Drawer,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styled from "styled-components";
import { useMutation, useQuery, useQueries } from "@tanstack/react-query";

import Text from "../Atoms/Text";
import TextInput from "../Form-Components/TextInput";
import SelectInput from "../Form-Components/SelectInput";
import StickyFooter from "../Atoms/StickyFooter";
import Modal from "../Molecules/Modal";
import { getBranches, getRoles, getDesignations } from "../../apis/users";
import { getMasterData } from "../../apis/newCustomer";

const phoneRegExp = /^[6-9][0-9]{9}$/;
//const specialRegex = /^[^*|\":<>[\]{}`\\()';@&$#]+$/;
const specialRegex=/^[a-zA-Z0-9 ]+$/;
const nameRegex = /^[^*|\":<>[\]{}_`\\()';@&$#0-9]+$/;

const schema = yup
  .object()
  .shape({
    first_name: yup
      .string()
      .test("no_special", (value) => nameRegex.test(value))
      .max(255)
      .required("First name is required"),
    last_name: yup
      .string()
      .test("no_special", (value) => nameRegex.test(value))
      .max(255)
      .required("Last name is required"),
    user_name: yup
      .string()
      .test("no_special", (value) => specialRegex.test(value))
      .max(255)
      .required("User name is required"),
    email: yup.string().email().max(255).required("Valid email is required"),
    branch: yup.string().max(255).required("Branch name is required"),
    role: yup.string().max(255).required("Role is required"),
    designationId: yup.string().max(255).required(),
    empCode: yup.string().max(255).required("Required field"),
    mobile: yup
      .string()
      .test("test_mobile", "Enter valid phone number", (value) => {
        console.log(value);
        if (value?.length > 0) {
          console.log(phoneRegExp.test(value));
          return phoneRegExp.test(value);
        } else return true;
      })
      .nullable(),
  })
  .required();

const getDefaultValues = (selectedUser) => {
  return {
    first_name: selectedUser ? selectedUser.firstName : "",
    last_name: selectedUser ? selectedUser.lastName : "",
    user_name: selectedUser ? selectedUser.username : "",
    empCode: selectedUser ? selectedUser?.empCode : "",
    role: selectedUser ? selectedUser?.role?.roleId : "",
    mobile: selectedUser ? selectedUser.mobileNo : "",
    branch: selectedUser ? selectedUser?.branch?.branchId : "",
    email: selectedUser ? selectedUser.email : "",
    designationId: selectedUser ? selectedUser.designation?.designationId : "",
  };
};

const AddUser = ({ open, toggleDrawer, onSubmit, isLoading, selectedUser }) => {
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    control,
    formState,
    watch,
    getValues,
  } = useForm({
    defaultValues: getDefaultValues(selectedUser),
    resolver: yupResolver(schema),
  });

  const queries = useQueries({
    queries: [
      {
        queryKey: ["roles"],
        queryFn: getRoles,
      },
      {
        queryKey: ["branches"],
        queryFn: getBranches,
      },
    ],
  });

  const { data: masterData } = useQuery(["designationss"], getMasterData, {
    manual: true,
    select: (response) => response.data,
  });

  // const { data: designations } = useQuery(["designations"], getDesignations, {
  // 	manual: true,
  // 	select: (response) => response.data,
  // });

  
  const keys = ["first_name",
  "last_name","user_name","email","branch","role","designationId","empCode"];
 const filterKeys = keys.filter(items=>watch(items)== "")?.length;

  const getMasterType = (label) => {
    const data =
      masterData &&
      masterData.length > 0 &&
      masterData.find((item) => Object.keys(item)[0] === label);
    return data ? Object.values(data)[0] : [];
  };

  const dataLoading = queries.some((item) => item.isLoading == true);
  const [isFormReset, setIsFormReset] = useState(false);

  useEffect(() => {
    if (isFormReset) {
      reset();
      setIsFormReset(false);
    }
  }, [isFormReset, reset]);

  const handleReset = () => {
    setIsFormReset(true);
  };
  return (
    <Modal
      title={selectedUser?.id ? "Edit User" : "Add User"}
      open={open}
      toggleDrawer={toggleDrawer}>
      <form
        style={{ display: "flex", flexDirection: "column" }}
        onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          flexDirection={"column"}
          p="24px"
          sx={{ overflowY: "auto", width: "100%" }}>
          <Box display="flex" sx={{ width: "100%", flexWrap: "wrap" }}>
            <TextInput
              label={"First Name"}
              fieldkey="first_name"
              errorText={
                getValues("first_name")
                  ? "First name should not contain any special characters"
                  : "First name is required"
              }
              placeholder="First name"
              control={control}
              register={register}
              mandatory
              setValue={setValue}
              value={getValues()?.["first_name"]}
            />
            <TextInput
              label={"Last Name"}
              fieldkey="last_name"
              errorText={
                getValues("last_name")
                  ? "Last name should not contain any special characters"
                  : "Last name is required"
              }
              placeholder="Last name"
              control={control}
              register={register}
              mandatory
              setValue={setValue}
              value={getValues()?.["last_name"]}
              isRegex={/^[a-zA-Z0-9\s]*$/}
            />
            <TextInput
              label={"User Name"}
              fieldkey="user_name"
              errorText={
                getValues("user_name")
                  ? "Username should not contain any special characters"
                  : "Username is required"
              }
              placeholder="User name"
              control={control}
              register={register}
              mandatory
              setValue={setValue}
              value={getValues()?.["user_name"]}
            />
            <TextInput
              label={"Email"}
              fieldkey="email"
              errorText="Email is required"
              placeholder="Email"
              control={control}
              register={register}
              mandatory
              setValue={setValue}
              value={getValues()?.["email"]}
            />
            <SelectInput
              label={"Branch"}
              fieldkey="branch"
              errorText="Branch is required"
              placeholder="Branch"
              control={control}
              options={!dataLoading ? queries[1].data?.data?.items : []}
              register={register}
              mandatory
              setValue={setValue}
              valueRenderer={(option) => option.brId}
              displayName={(option) => option.brName}
              watch={watch}
            />
            <SelectInput
              label={"Role"}
              fieldkey="role"
              errorText="Role is required"
              placeholder="Role"
              options={
                !dataLoading
                  ? queries[0]?.data?.data?.filter(
                      (i) => i.roleName !== "SuperAdmin"
                    )
                  : []
              }
              control={control}
              register={register}
              mandatory
              setValue={setValue}
              valueRenderer={(option) => option.id}
              displayName={(option) => option.roleName}
              watch={watch}
            />
            <TextInput
              label={"Mobile"}
              fieldkey="mobile"
              errorText="Valid mobile number is required"
              placeholder="Mobile"
              control={control}
              register={register}
              setValue={setValue}
              value={getValues()?.["mobile"]}
            />
            <SelectInput
              label={"Designation"}
              fieldkey="designationId"
              errorText="Designation is required"
              placeholder="Designation"
              // options={designations?.items || []}
              options={getMasterType("Designation")}
              // options={[
              // 	{ id: 1, designation: "Manager" },
              // 	{ id: 2, designation: "Staff" },
              // 	{ id: 3, designation: "Dy. Manager" },
              // ]}
              control={control}
              register={register}
              mandatory
              setValue={setValue}
              valueRenderer={(option) => option.id}
              // displayName={(option) => option.designation}
              displayName={(option) => option.name}
              watch={watch}
            />
            <TextInput
              label={"Employee Code"}
              fieldkey="empCode"
              errorText="Employee Code is required"
              placeholder="Employee Code"
              control={control}
              register={register}
              mandatory
              setValue={setValue}
              value={getValues()?.["empCode"]}
            />
          </Box>
        </Box>
        <StickyFooter>
          <React.Fragment>
            <LoadingButton
              size="small"
              variant="outlined"
              color="primary"
              sx={{ marginRight: "8px" }}
              disableElevation
              onClick={handleReset}
              style={{ textTransform: "none" }}>
              <Text> {selectedUser?.id ? "Reset" : "Clear"}</Text>
            </LoadingButton>
            <LoadingButton
              loading={formState.isSubmitting || isLoading}
              type={"submit"}
              size="small"
              variant="contained"
              color="primary"
              disableElevation
              disabled= {filterKeys > 0? true :false}
              style={{ textTransform: "none" }}>
              <Text>Submit</Text>
            </LoadingButton>
          </React.Fragment>
        </StickyFooter>
      </form>
    </Modal>
  );
};

export default AddUser;
