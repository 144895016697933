import React from "react";
import { Radio, RadioGroup, Box, FormControlLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

import Text from "../Atoms/Text";

const CheckBox = (props) => {
  const {
    label,
    fieldkey,
    defaultValue,
    errorText,
    placeholder,
    control,
    register,
    mandatory,
    fullWidth,
    disabled,
    width,
    onChange,
    options,
    setValue,
    infoText,
  } = props;

  return (
    <Box
      display="flex"
      flexDirection="column"
      key={fieldkey}
      sx={{
        marginTop: "1rem",
        width: fullWidth ? "92%" : width || "45%",
        marginRight: "1rem",
      }}>
      <Text mb="8px">{label}</Text>
      <Box display="flex">
        <Controller
          render={({ field }) => (
            <RadioGroup
              style={{ display: "flex", flexDirection: "row" }}
              aria-label={fieldkey}
              {...register(fieldkey)}
              {...field}>
              {options.map((item) => {
                return (
                  <Box mr={"24px"}>
                    <FormControlLabel
                      disabled={disabled}
                      value={item.value}
                      control={<Radio />}
                      label={item.label}
                      onChange={(e) => setValue(fieldkey, e.target.value)}
                    />
                  </Box>
                );
              })}
            </RadioGroup>
          )}
          name={fieldkey}
          control={control}
        />
        {infoText && (
          <Box
            sx={{ opacity: "0.6" }}
            ml="12px"
            display="flex"
            alignItems="center">
            <HelpOutlineOutlinedIcon sx={{ fontSize: "14px" }} />
            <Text ml="4px">{infoText}</Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CheckBox;
