import React from "react";
import {
  DataGrid,
  GridRenderCellParams,
  GridColDef,
  useGridApiContext,
} from "@mui/x-data-grid";

export default (props) => {
  const apiRef = useGridApiContext();
  const { columns } = props;
  return columns;
};
