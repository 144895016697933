import React, { useEffect, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";

import {
  getImportTemplate,
  importNewCustomers
} from "../apis/bulkOperations";
import {
  createCustomer,
  getAllStatuses,
  getCustomers,
} from "../apis/newCustomer";
import {
  addNewUser,
  uploadUser
} from "../assets/icons";
import Backdrop from "../components/Molecules/Backdrop";
import DataTable from "../components/Molecules/DataTable";
import PageHeader from "../components/Molecules/PageHeader";
import CreateCustomerPanel from "../components/Organisms/CreateCustomerPanel";
import CustomerDetails from "../components/Organisms/CustomerDetails";
import UploadFile from "../components/Organisms/UploadFile";
import { formatDate } from "../components/Organisms/new-customer/formUtils";
import { useGlobalContext } from "../context/GlobalContext";
import useDropdownOptions from "../hooks/useDropdownOptions";
import { getColumns } from "../utils/tableHeaders/createCustomer";

const NewCustomers = () => {
  const [openFilter, toggleFilter] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const [isLegalEntityOpen, setIsLegalEntityOpen] = React.useState(false);
  const {
    state: { currentUser },
    dispatch,
  } = useGlobalContext();
  const [filter, updateFilter] = React.useState({
    condition: "",
    page: 0,
    perPageSize: 5,
  });

  const [search, updateSearch] = React.useState(null);
  const [docketOpen, setDocketOpen] = React.useState(false);
  const [customerId, setCustomerId] = React.useState(null);
  const { dropdownOptions } = useDropdownOptions();
  const [filters, setFiltes] = React.useState(null);
  const [openUploadFile, toggleUploadFile] = React.useState();
  const [formReset, setFormReset] = React.useState(false);
  // Pass this to customer details to avoid duplicate code
  let currentRole =
    JSON?.parse(window.localStorage.getItem("roles")) ||
    currentUser.role.role_name;
  currentRole = currentRole.toLowerCase();

  const pageTitle = "Customers";

  const {
    data,
    refetch,
    isSuccess: customersFetched,
  } = useQuery(["customers", { filter, search, filters }], getCustomers, {
    manual: true,
    select: (response) => response.get("data", []),
    retry: false,
  });

  const { data: allStatuses, refetch: fetchAllStatuses } = useQuery(
    ["statuses"],
    getAllStatuses,
    {
      manual: true,
      select: (resp) => resp.data,
      retry: false,
      enabled: false,
    }
  );

  const { refetch: downloadTemplate, isLoading: downloadingTemplate } =
    useQuery(["template", { module: "customerData" }], getImportTemplate, {
      manual: true,
      // select: (response) => response.get("data", []),
      retry: false,
      enabled: false,
      onSuccess: async (response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        if (url) {
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "bulk_customers_template");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      },
    });

  const {
    mutate: uploadFile,
    isLoading: uploadingDocs,
    // isSuccess: stepUpdated,
  } = useMutation((userData) => importNewCustomers(userData), {
    onSuccess: (response) => {
      // toggleSaveData(false);
      if (response.isError) {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.message,
            severity: "error",
          },
        });
      } else {
        setFormReset(true);
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.message,
            severity: response.isError ? "error" : "success",
          },
        });
        refetch();
        // setSelectedFile(null);

        // fetchCustomerInfo();
        // handleNext();
      }
    },
    onError: (error) => {
      dispatch({
        type: "SET_TOAST",
        payload: {
          open: true,
          text: "Something went wrong",
          severity: "error",
        },
      });
    },
  });

  const { mutate: createNewCustomer, isLoading } = useMutation(
    (userData) => createCustomer(userData),
    {
      onSuccess: (response) => {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.message,
            severity: response.isError ? "error" : "success",
          },
        });
        if (!response.isError) {
          toggleFilter(false);
          setIsLegalEntityOpen(false);
          refetch();
        }
      },
      onError: (error) => {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: error.response.data.message,
            severity: "error",
          },
        });
      },
    }
  );

  const onSubmit = (data) => {
    let reqData = {
      requestFields: {
        office: [
          {
            CKYC_NO: data.ckycNo,
            ACC_TYPE: data.accountType,
            custBranch: data.branch,
            acntTypeDetails: [],
            CONSTI_TYPE: data.type,
          },
        ],
        personal: [
          {
            PREFIX: data.prefix,
            FNAME: data.fname,
            MNAME: data.mname,
            LNAME: data.lname,
            // FULLNAME: data.mobileCode,
            MOB_CODE: data.mobileCode,
            MOB_NUM: data.mobileNum,
            EMAIL: data.email,
            aadharNum: data.aadharNumber,
            
            PAN: (data.pan).toUpperCase(),
            dependents: {
              DOB: data.dob,
            },
            entityDetails: {
              ENTITY_CONSTITUTION_TYPE: data?.constitutionType,
              DATE_OF_INCORPORATION: data?.dateIncorporation,
              PLACE_OF_INCORPORATION: data?.placeIncorporation,
              GST_REGISTRATION_NUMBER: data?.registernumber,
              TIN_ISSUING_COUNTRY: "",
              DATE_OF_COMMENCEMENT_OF_BUSSINESS: "",
              COUNTRY_OF_INCORPORATION: "",
            },
          },
        ],
        kycVerification: [
          {
            DOC_SUB: "",
            KYC_DATE: "",
            KYC_NAME: "",
            KYC_EMPCODE: "",
            KYC_DESIGNATION: "",
            KYC_BRANCH: "",
            ORG_CODE: "",
            ORG_NAME: "",
            REMARKS: "",
            DEC_DATE: "",
            DEC_PLACE: "",
          },
        ],
      },
    };
    createNewCustomer(reqData);
  };

  const toggleDrawer = () => {
    // setNewCustomerModal(!openNewCustomerModal);
  };

  const onDocketClick = (id) => {
    // fetch the ckyc user info
    // setCustomerId(id);
    // setDocketOpen(true);
  };

  const onToggleActive = (e, id) => {
    // let checked = e.target.checked;
    // let requestData = {
    // 	id: id,
    // 	status: checked ? 1 : 0,
    // };
    // requestData = { district: requestData };
    // addOrUpdateDoc(requestData);
  };

  // const onFilter = (status) => {
  //   updateFilter(status);
  //   refetch();
  // };

  const onFilter = (status) => {
    updateFilter({ ...filter, ...status });
    refetch();
  };

  let searchTimeout;

  const searchUsers = (query) => {
    clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      updateSearch(query);
    }, 500);
  };

  const onSearch = (e) => {
    const val = e.target.value;
    searchUsers(val);
  };

  const openNewCKYCForm = () => {
    setDocketOpen(false);
  };

  const onCloseDocketForm = () => {
    setDocketOpen(false);
    refetch();
  };

  React.useEffect(() => {
    (async () => {
      await fetchAllStatuses();
    })();
  }, []);

  const onFileUpload = function (file) {
    if (file) {
      let formData = new FormData();
      formData.append(`files`, file);
      // formData.append("save", true);
      // if (selectedCustomerIds.length > 0) {
      // 	let selectedIds = selectedCustomerIds?.join();
      // 	formData.append("customerIds", selectedIds);
      // }
      uploadFile(formData);
    }
  };

  const getAllStatusDropdownValues = () => {
    let options = [];
    if (allStatuses) {
      options.push({
        label: "All Status",
        icon: null,
        onClick: () => onFilter(null),
      });
      allStatuses.forEach((item) => {
        const status =
          currentRole === "maker"
            ? [2, 3, 6, 10].includes(item.id)
            : [4, 11, 10, 6, 7].includes(item.id);
        if (status) {
          const newOption = {
            label: item.status,
            icon: null,
            onClick: () => onFilter({ status: item.id }),
          };
          options.push(newOption);
        }
      });
    }
    return options;
  };

  const leftSideActions = [
    {
      icon: EditIcon,
      options: [
        {
          label: "Add New/Individual",
          Icon: addNewUser,
          onClick: () => toggleFilter(true),
        },
        // {
        //   label: "Add New/Legal Entity",
        //   Icon: addNewUser,
        //   onClick: () => setIsLegalEntityOpen(true),
        // },
        {
          label: "Upload",
          Icon: uploadUser,
          EditIcon,
          onClick: () => toggleUploadFile(true),
        },
      ],
      title: "Add New",
      onClick: toggleDrawer,
      isSplit: true,
    },
  ];
  const rightSideActions = [
    {
      title: "Individual",
      onClick: null,
      isSplit: true,
    },
    // {
    //   title: "Legal Entities",
    //   onClick: null,
    //   isSplit: true,
    // },
  ];

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    if (activeTab === 0) {
      setActiveTab(1);
    } else {
      setActiveTab(0);
    }
  };

  const [totalCount, setTotalCount] = React.useState(0);
  useEffect(() => {
    if (totalCount === 0 && data?.get("totalCount") >= 0) {
      setTotalCount(data?.get("totalCount"));
    }
  }, [data?.get("totalCount")]);
  useEffect(() => {
    if (data?.get("items")?.toJS()?.length >= 0) {
      setTableData(data?.get("items")?.toJS())
    }
  }, [data?.get("items")])

  // useEffect(()=>{
  //   if(activeTab === 0) {
  //     setTableData(data?.get("items")?.toJS()?.filter(item => item?.CONSTI_TYPE === "01"))
  //   }else{
  //     setTableData(data?.get("items")?.toJS()?.filter(item => item?.CONSTI_TYPE === "02"))
  //   }
  // },[activeTab, tableData, data?.get("items")?.toJS()])

  return (
    <React.Fragment>
      <PageHeader
        toggleDrawer={null}
        leftSideActions={leftSideActions}
        rightSideActions={rightSideActions}
        pageTitle={pageTitle}
        isSearch={true}
        searchOnChange={onSearch}
        totalCount={totalCount}
        handleTabChange={handleTabChange}
        activeTab={activeTab}
      />
      <Box sx={{ padding: "15px", height: "100%" }}>
        <DataTable
          loading={false}
          getRowId={(row) => row.customerId}
          pageSize={filter?.perPageSize}
          totalCount={totalCount}
          columns={getColumns({ onToggleActive, onDocketClick, activeTab })}
          rows={tableData}
          onPageSize={(pageSize) => {
            updateFilter((fil) => ({
              ...fil,
              perPageSize: pageSize,
              page: 0,
            }));
          }}
          onPage={(page) => {
            updateFilter((fil) => ({
              ...fil,
              page: page,
            }));
          }}
        />
        {openFilter && (
          <CreateCustomerPanel
            open={openFilter}
            toggleDrawer={() => toggleFilter(false)}
            onSubmit={onSubmit}
            filters={filters}
            // selectedDistrict={selectedDistrict || Immutable.Map()}
            isLoading={isLoading}
            statuses={allStatuses}
            dropdownOptions={dropdownOptions}
          />
        )}
        {/* {isLegalEntityOpen && (
          <CreateCustomerPanelForLegalEntity
            open={isLegalEntityOpen}
            toggleDrawer={() => setIsLegalEntityOpen(false)}
            onSubmit={onSubmit}
            filters={filters}
            // selectedDistrict={selectedDistrict || Immutable.Map()}
            isLoading={isLoading}
            statuses={allStatuses}
            dropdownOptions={dropdownOptions}
          />
        )} */}
      </Box>
      {/* {openDeletePopup && (
				<DeletePopUp
					title="Delete Document"
					closePopup={() => toggleDeletePopup(false)}
					openDeletePopup={openDeletePopup}
					onDelete={() => onDeleteUser()}
					isLoading={isLoading}
				/>
			)} */}
      {docketOpen && customerId && (
        <CustomerDetails
          open={docketOpen}
          closePanel={onCloseDocketForm}
          openNewCKYCForm={openNewCKYCForm}
          customerId={customerId}
          dropdownOptions={dropdownOptions}
        />
      )}
      <Backdrop loading={uploadingDocs} />
      <UploadFile
        open={openUploadFile}
        toggleDrawer={() => toggleUploadFile(!openUploadFile)}
        title={"Upload Files"}
        onSubmit={onFileUpload}
        uploadingDoc={uploadingDocs}
        downloadSample={downloadTemplate}
        supportFile={"createCustomer"}
        formReset={formReset}
        setFormReset={setFormReset}
      />
    </React.Fragment>
  );
};

export default NewCustomers;
