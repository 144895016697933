import { Box } from "@mui/material";
import React from "react";
import Text from "../../Atoms/Text";
import { hideElements } from "./PreviewDetails";
import PreviewUploadSection from "./PreviewUploadSection";
const SubdivsElements = ({
  element,
  fullWidthColoumns,
  dropdownOptions,
  values,
  renderLabel,
  renderValue,
  fromMinor,
  files,
}) => {
  const isFullWidthRequired = fullWidthColoumns.includes(element.key);
  if (
    (element.key === "kycNumber" && values["applicationType"] === "01") ||
    hideElements.includes(element.key)
  )
    return null;
  if (element?.key === "displayName") {
    return (
        <Text
          fontSize="16px"
          mt={"25px"}
          mb={"8px"}
          fontWeight="500"
          style={{
            width: "100%",
          }}
        >
          {element?.displayName}
        </Text>
    );
  }

  if (element?.key === "minorUploadSection") {
    return (
      <React.Fragment>
        <Text
          fontSize="16px"
          mt={"25px"}
          mb={"8px"}
          fontWeight="500"
          style={{
            width: "100%",
          }}
        >
          {element?.displayName}
        </Text>
        <Box style={{ width: "100%" }}>
          <PreviewUploadSection files={files} dropdownOptions={dropdownOptions} uploadPrevType={"minor"}/>
        </Box>

      </React.Fragment>
    )
  }

  return (
    <React.Fragment>

    <Box width={isFullWidthRequired ? "30%" : "30%"} display="flex">
      <Box
        width={isFullWidthRequired ? "50%" : "50%"}
        display="flex"
        flexWrap="wrap"
        mt="16px"
        justifyContent={"flex-end"}
      >
        <Text fontSize="14px" color="#6C6C6C" fontWeight="500">
          {renderLabel(element, dropdownOptions, values)}
        </Text>
      </Box>
      <Box
        width={isFullWidthRequired ? "50%" : "50%"}
        display="flex"
        flexWrap="wrap"
        mt="16px"
        ml="12px"
        justifyContent={"flex-start"}
      >
        <Text
          style={{ overflowWrap: "break-word" }}
          ml={"8px"}
          fontSize="14px"
          fontWeight="500"
        >
          {renderValue(element, values)}
        </Text>
      </Box>
    </Box>

    </React.Fragment>
  );
};

export default SubdivsElements;
