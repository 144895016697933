import React, { useEffect } from "react";
import { Box, TextField, Select, MenuItem, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from "../Form-Components/TextInput";
import SelectInput from "../Form-Components/SelectInput";
import DatePicker from "../Form-Components/DatePicker";
import { ErrorMessage } from "@hookform/error-message";

const idTypes = [
  {
    idType: "PAN",
    ckycinfokey: "pan",
    label: "Pan Card",
    name: "Pan Card Number",
    disabled: false,
    validation: {
      test: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
      regex: true,
      message: "Valid Pan Number Required",
    },
  },
  {
    idType: "PASSPORT",
    label: "Passport",
    name: "Passport Number",
    disabled: true,
    validation: {
      test: /^[A-Za-z]{1}[0-9]{7}$/,
      regex: true,
      message: "Valid Passport Number Required",
    },
  },
  {
    idType: "DRIVING_LICENSE",
    label: "Driving License",
    name: "Driving License Number",
    disabled: true,
    validation: {
      test: /^[A-Z]{2}[0-9]{13}$/,
      regex: true,
      message: "Valid Driving License Number Required",
    },
  },
  {
    idType: "VOTER_ID",
    label: "Voter Id",
    name: "Voter Id Number",
    disabled: true,
    validation: {
      test: /^[A-Za-z]{3}[0-9]{7}$/,
      regex: true,
      message: "Valid Voter Id Number Required",
    },
  },
  {
    idType: "NREGA",
    label: "NREGA",
    name: "NREGA Number",
    disabled: true,
    validation: {
      test: /^\d{2}\/\d{4}\/\d{5}$/,
      regex: true,
      message: "Valid NREGA Number Required",
    },
  },
  {
    idType: "CKYC_NO",
    ckycinfokey: "CKYC_NO",
    label: "CKYC NO",
    name: "CKYC Number",
    disabled: true,
    validation: {
      test: /^\d{6}\w{4}\d{1}$/,
      regex: true,
      message: "Valid CKYC Number Required",
    },
  },
  {
    idType: "POSESSION_OF_AADHAAR",
    ckycinfokey: "aadharNum",
    label: "Aadhaar",
    name: "Last four digits of aadhaar",
    disabled: true,
    validation: {
      test: /^\d{4}$/,
      regex: true,
      message: "Only last 4 digits are Required",
    },
  },
];

const authTypes = [
  {
    label: "DOB",
    value: "1",
  },
  {
    label: "Pincode",
    value: "2",
  },
  {
    label: "Mobile",
    value: "3",
  },
];

const formatDob = (value) => {
  return dayjs.isDayjs(value)
    ? dayjs(value).format("DD-MM-YYYY")
    : `${value?.split("-")[0]}-${value?.split("-")[1]}-${value?.split("-")[2]}`;
};

function CersaiSearch(props) {
  const {
    onSearch,
    fetchingData,
    setSearchDOB,
    searchDob,
    panNumber,
    customerInfo,
    hasCkycNo,
    downloadData,
    authTypeSelected,
    setAuthTypeSelected,
    setAuthType,
    mobileNumber,
    pincode,
  } = props;

  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: { errors },
    watch,
    error,
    trigger,
    getValues,
  } = useForm({
    defaultValues: {
      dateOfBirth: searchDob ? searchDob : null,
      searchType: "PAN",
      number: panNumber?.trim(),
      authType: "1",
      pincode: "",
      mobile: "",
      fullName: "",
      // pan: panNumber,
    },
  });
  const dobWatch = watch("dateOfBirth", searchDob);
  const searchTypeId = watch("searchType", "PAN");
  const watchNumber = watch("number");
  const currentSearchType = idTypes.find((i) => i.idType === searchTypeId);
  const disableSearchButton =
    (customerInfo?.job_type === "AUTOMATION" && customerInfo?.count < 2) ||
    errors?.dateOfBirth?.message ||
    errors?.number?.message;
  useEffect(() => {
    if (isKeyExists(currentSearchType?.ckycinfokey)) {
      let value = customerInfo[currentSearchType?.ckycinfokey];
      if (searchTypeId === "POSESSION_OF_AADHAAR" && value?.length >= 4) {
        value = value.trim().slice(-4);
      }
      setValue("number", value?.trim());
      trigger("dateOfBirth");
      watch("dateOfBirth");
    } else {
      setValue("number", "");
      setValue("fullName", "");
      setValue("gender", "");
    }
    const timeout = setTimeout(() => {
      trigger("number");
    }, 200);
    return () => {
      clearTimeout(timeout);
    };
  }, [searchTypeId]);

  const isKeyExists = (key) => {
    if (!key) return false;
    if (key === "PERM_PIN") {
      return customerInfo?.address["current/permenantAddress"]?.PERM_PIN
        ? true
        : false;
    }
    return customerInfo && customerInfo[key] ? true : false;
  };

  useEffect(() => {
    if (customerInfo?.PAN) {
      setValue("searchType", "PAN");
    } else if (customerInfo?.aadharNum) {
      setValue("searchType", "POSESSION_OF_AADHAAR");
    } else {
      setValue("searchType", "PAN");
    }
  }, []);
  useEffect(() => {
    trigger("number");
  }, [watchNumber]);

  useEffect(() => {
    if (dobWatch) {
      const dob = formatDob(
        dayjs.isDayjs(dobWatch)
          ? dayjs(dobWatch).format("DD-MM-YYYY")
          : dobWatch
      );
      // setSearchDOB(dob);
    }
  }, [dobWatch]);

  useEffect(() => {
    setValue("number", panNumber?.trim());
  }, [panNumber]);

  useEffect(() => {
    if (searchDob) {
      setValue("dateOfBirth", formatDob(searchDob));
      setValue("idtype", "pan");
      setValue("fullName", customerInfo["name"]);
      setValue("gender", customerInfo?.dependents["GENDER"]);
    }
    setValue("authType", "1");
  }, []);

  const submit = (data) => {
    console.log("cersai search  is selected ", { data: data });
    if (hasCkycNo) {
      downloadData(data);
    } else {
      let aadharNumber;
      if (searchTypeId === "POSESSION_OF_AADHAAR") {
        aadharNumber = `${data.number}|${data.fullName}|${data.dateOfBirth}|${data.gender}`;
      }
      const object = {
        idtype: currentSearchType.idType,
        idNumber: aadharNumber
          ? window.btoa(aadharNumber)
          : window.btoa(data.number),
      };
      onSearch({ search: object });
    }
  };

  const handleReset = () => {
    setValue("fullName", "");
    setValue("gender", "");
  };

  const selectedAuthType = watch("authType");

  useEffect(() => {
    setAuthTypeSelected((prev) => ({
      ...prev,
      [selectedAuthType]: false,
      selectedAuthType,
    }));
    setAuthType(selectedAuthType);
    if (selectedAuthType === "1") {
      setValue("dateOfBirth", formatDob(searchDob));
    } else if (selectedAuthType === "2") {
      setValue(
        "pincode",
        pincode && pincode["current/permenantAddress"]?.PERM_PIN
      );
    } else if (selectedAuthType === "3") {
      setValue("mobile", mobileNumber);
    }
  }, [selectedAuthType]);

  return (
    <Box my="16px" width="60%">
      <form onSubmit={handleSubmit(submit)}>
        <Box display="flex">
          <SelectInput
            width={"25%"}
            label={"Auth Type"}
            fieldkey={"authType"}
            errorText={"Required"}
            control={control}
            register={register}
            mandatory
            setValue={setValue}
            value={getValues()?.authType}
            // disabled
            options={authTypes}
            valueRenderer={(option) => option.value}
            displayName={(option) => option.label}
            watch={watch}
          />
          {Object.values(authTypeSelected)?.filter((ele) => ele === true)
            ?.length === 3 && (
            <div className="error">
              You have tried all three auth types. Please try after sometime.
            </div>
          )}

          {!hasCkycNo && (
            <>
              <SelectInput
                width={"25%"}
                label={"Search"}
                fieldkey={"searchType"}
                errorText={"Required"}
                control={control}
                register={register}
                mandatory
                setValue={setValue}
                // disabled
                options={idTypes}
                valueRenderer={(option) => option.idType}
                displayName={(option) => option.label}
                watch={watch}
              />
              <Box width={"25%"}>
                <TextInput
                  width={"100%"}
                  label={currentSearchType?.name}
                  fieldkey={"number"}
                  // errorText={
                  //   errors?.number?.message ||
                  //   currentSearchType?.validation?.message ||
                  //   "Valid Number required"
                  // }
                  errorText={
                    errors?.number?.message
                      ? `Valid ${currentSearchType?.name} Required`
                      : "Valid Number required"
                  }
                  control={control}
                  register={register}
                  mandatory
                  disabled={isKeyExists(currentSearchType?.ckycinfokey)}
                  setValue={setValue}
                  isPan={getValues()?.["searchType"] === "PAN"}
                  pattern={
                    idTypes.find(
                      (ele) => ele.idType === getValues()?.["searchType"]
                    )?.validation?.test
                  }
                  value={getValues()?.["number"]}
                  isAadhar={
                    watch("searchType") === "POSESSION_OF_AADHAAR"
                      ? true
                      : false
                  }
                />

                <ErrorMessage
                  errors={errors}
                  name="pan"
                  render={({ message }) => (
                    <div style={{ color: "red", marginTop: "12px" }}>
                      {error
                        ? `Valid ${currentSearchType?.name} Required`
                        : null}
                    </div>
                  )}
                />
              </Box>
            </>
          )}
        </Box>
        <Box mt="22px" display="flex">
          {!hasCkycNo && (
            <>
              <TextInput
                width={"25%"}
                label={"Full Name"}
                fieldkey={"fullName"}
                errorText={"Required"}
                control={control}
                register={register}
                mandatory={searchTypeId === "POSESSION_OF_AADHAAR"}
                setValue={setValue}
                value={getValues()?.["fullName"]}
              />
              <Box width={"25%"} sx={{ marginRight: "16px" }}>
                {selectedAuthType === "1" && (
                  <DatePicker
                    width={"100%"}
                    label={"DOB"}
                    fieldkey={"dateOfBirth"}
                    errorText={errors?.dateOfBirth?.message || "Required"}
                    control={control}
                    disabled={isKeyExists("dob")}
                    register={register}
                    setValue={setValue}
                    mandatory
                    value={searchDob}
                  />
                )}
                {selectedAuthType === "2" && (
                  <TextInput
                    width={"100%"}
                    label={"Pincode"}
                    fieldkey={"pincode"}
                    errorText={"Required"}
                    control={control}
                    disabled={isKeyExists("PERM_PIN")}
                    register={register}
                    mandatory
                    setValue={setValue}
                    value={getValues()?.["pincode"]}
                  />
                )}
                {selectedAuthType === "3" && (
                  <TextInput
                    width={"100%"}
                    label={"Mobile"}
                    fieldkey={"mobile"}
                    errorText={"Required"}
                    control={control}
                    disabled={isKeyExists("MOB_NUM")}
                    register={register}
                    mandatory
                    setValue={setValue}
                    value={getValues()?.["mobile"]}
                  />
                )}
                <ErrorMessage
                  errors={errors}
                  name="dateOfBirth"
                  render={({ message }) => (
                    <div style={{ color: "red", marginTop: "12px" }}>
                      {message}
                    </div>
                  )}
                />
              </Box>
              <SelectInput
                width={"25%"}
                label={"Gender"}
                fieldkey={"gender"}
                errorText={"Required"}
                control={control}
                register={register}
                setValue={setValue}
                options={[
                  { id: 1, type: "Male", code: "M" },
                  { id: 2, type: "Female", code: "F" },
                  { id: 3, type: "Other", code: "T" },
                ]}
                valueRenderer={(option) => option.code}
                displayName={(option) => option.type}
                mandatory={searchTypeId === "POSESSION_OF_AADHAAR"}
                watch={watch}
              />
            </>
          )}
          <Box
            display="flex"
            mb="4px"
            alignItems="flex-end"
            justifyContent="center"
          >
            <Button
              sx={{ marginRight: "24px" }}
              variant="contained"
              size="medium"
              type="submit"
              disabled={fetchingData || disableSearchButton}
              color="primary"
            >
              {fetchingData
                ? hasCkycNo
                  ? "Downloading..."
                  : "Searching..."
                : hasCkycNo
                ? "Download"
                : "Search"}
            </Button>
            {!hasCkycNo && (
              <Button variant="outlined" size="medium" onClick={handleReset}>
                Clear
              </Button>
            )}
          </Box>
        </Box>
      </form>
    </Box>
  );
}

export default CersaiSearch;
