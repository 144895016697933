import React, { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import Text from "../Atoms/Text";
import { formatDate } from "../Organisms/new-customer/formUtils";
import moment from "moment";

dayjs.extend(customParseFormat);

const TextInput = (props) => {
  const {
    label,
    fieldkey,
    defaultValue,
    errorText,
    placeholder,
    control,
    register,
    mandatory,
    fullWidth,
    disabled = false,
    width,
    setValue,
    error,
    value,
    maxDate,
    minDate,
    getValues,
    format,
    setDateValue,
  } = props;
  const [validationError, setValidationError] = useState("");

  useEffect(() => {
    if (getValues && !getValues(fieldkey)) {
      setValue(fieldkey, new Date());
    }
    // setValue(fieldkey, new Date());
  }, []);
  console.log();
  const handleDateChange = (date) => {
    setDateValue &&
      setDateValue(moment(new Date(date)).format(format ?? "DD-MM-YYYY"));
    const day = dayjs(date).date();
    const month = dayjs(date).month() + 1;

    if (!day || !month) {
      setValidationError("Invalid date");
    } else {
      setValidationError("");
    }
  };

  const date =
    new Date(
      moment(value, format ?? "DD-MM-YYYY").format("YYYY-MM-DD")
    )?.toString() !== "Invalid Date"
      ? new Date(moment(value, format ?? "DD-MM-YYYY").format("YYYY-MM-DD"))
      : new Date(
          moment(value, format ?? "MM-DD-YYYY").format("YYYY-MM-DD")
        )?.toString() !== "Invalid Date"
      ? new Date(moment(value, format ?? "MM-DD-YYYY").format("YYYY-MM-DD"))
      : null;

  const onKeyDown = (e) => {
    e.preventDefault();
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      key={fieldkey}
      sx={{
        marginTop: "1rem",
        width: fullWidth ? "92%" : width || "45%",
        marginRight: "1rem",
      }}
    >
      <Text mandatory={mandatory} mb="4px">
        {label}
      </Text>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Controller
          name={fieldkey}
          control={control}
          render={({ field, value: fieldVal, onChange }) => (
            <DesktopDatePicker
              openTo="day"
              {...register(fieldkey, {
                required: mandatory ? `${label} is required` : false,
              })}
              disabled={disabled}
              onChange={(newValue) => {
                console.log(
                  moment(new Date(newValue)).format(format ?? "DD-MM-YYYY")
                );
                field.onChange(
                  moment(new Date(newValue)).format(format ?? "DD-MM-YYYY")
                );
                // setValue(fieldkey, dayjs(newValue).format("DD-MM-YYYY"));;
                // setValue(fieldkey, newValue);
                handleDateChange(newValue);
              }}
              inputFormat="DD-MM-YYYY"
              format="DD-MM-YYYY"
              size="small"
              maxDate={maxDate}
              value={date}
              minDate={minDate}
              renderInput={(params) => <TextField size="small" {...params} onKeyDown={onKeyDown}/>}
              emptyLabel="custom label" //<--- custom placeholder when date is null
            />
          )}
        />
      </LocalizationProvider>
      {validationError && (
        <Text
          color="#d32f2f"
          mt="4px"
          fontSize="0.75rem"
          style={{ letterSpacing: "0.03333em" }}
          ml="16px"
        >
          {validationError}
        </Text>
      )}
      {error && (
        <Text
          color="#d32f2f"
          mt="4px"
          fontSize="0.75rem"
          style={{ letterSpacing: "0.03333em" }}
          ml="16px"
        >
          {errorText}
        </Text>
      )}
    </Box>
  );
};

export default TextInput;
