import React from 'react'
import { Box } from "@mui/material"
import SelectInput from "../../Form-Components/SelectInput";
import TextInput from "../../Form-Components/TextInput";
import RadioGroup from "../../Form-Components/RadioGroup";
import DatePicker from "../../Form-Components/DatePicker";
const MinorFlowRelatedPersonDetails = (props) => {
    const { index, formProps, files, dropdownOptions, handleFieldValue, setFromDateValue, fromDateValue, panFormCondition } = props
    const { control, register, setValue, getValues, watch } = formProps;
  return (
    <Box display="flex" flexWrap="wrap">
         <RadioGroup
                    width={"100%"}
                    label={""}
                    fieldkey={`minorDetails[${index}].relatedFatherSpouseFlag`}
                    // errorText={item.errorText}
                    // placeholder={item.placeholder}
                    control={control}
                    register={register}
                    setValue={setValue}
                    options={[
                      { label: "Father", value: "01" },
                      { label: "Spouse", value: "02" },
                    ]}
                  // mandatory={item.mandatory}
                  />
                  <Box display="flex" width="100%">
                    <SelectInput
                      label={"Related Person Father Prefix/Spouse Name Prefix"}
                      fieldkey={`minorDetails[${index}].fatherSpousePrefix`}
                      errorText="This Field  is required"
                      placeholder="State"
                      control={control}
                      register={register}
                      mandatory={true}
                      setValue={setValue}
                      options={["Mr", "Mrs", "Minor", "Mis", "Dr"]}
                      valueRenderer={(option) => option}
                      displayName={(option) => option}
                      width="15%"
                      watch={watch}
                    />
                  </Box>
                  <TextInput
                    label={`Reated person Father's First Name/ Spouse First Name`}
                    // label={`${fatherSpouse} First Name`}
                    fieldkey={`minorDetails[${index}].fFirstName`}
                    errorText="This Field  is required"
                    placeholder={"Enter text"}
                    control={control}
                    register={register}
                    mandatory={true}
                    width="30%"
                    setValue={setValue}
                    value={getValues()?.minorDetails?.[index]?.fFirstName}
                  />
                  <TextInput
                    label={`Related Person Father's Middle Name / Spouse Middle Name`}
                    // label={`${fatherSpouse} Middle Name`}
                    fieldkey={`minorDetails[${index}].fMiddleName`}
                    errorText="This Field  is required"
                    placeholder={"Enter text"}
                    control={control}
                    register={register}
                    mandatory={false}
                    width="30%"
                    setValue={setValue}
                    value={getValues()?.minorDetails?.[index]?.fMiddleName}
                  />
                  <TextInput
                    label={`Reated person Father's Last name / Spouse Last Name`}
                    // label={`${fatherSpouse} Last Name`}
                    fieldkey={`minorDetails[${index}].fLastName`}
                    errorText="This Field  is required"
                    placeholder={"Enter text"}
                    control={control}
                    register={register}
                    mandatory={false}
                    width="30%"
                    setValue={setValue}
                    value={getValues()?.minorDetails?.[index]?.fLastName}
                  />
                  <Box display="flex" width="100%">
                    <SelectInput
                      label={"Related Person Mother Name Prefix"}
                      fieldkey={`minorDetails[${index}].motherPrefix`}
                      errorText="This Field  is required"
                      placeholder="State"
                      control={control}
                      register={register}
                      mandatory={false}
                      setValue={setValue}
                      options={["Mr", "Mrs", "Minor", "Mis", "Dr"]}
                      valueRenderer={(option) => option}
                      displayName={(option) => option}
                      width="15%"
                      watch={watch}
                    />
                  </Box>
                  <TextInput
                    label={"Related Person Mother's First Name"}
                    fieldkey={`minorDetails[${index}].mfirstName`}
                    errorText="This Field  is required"
                    placeholder={"Enter text"}
                    control={control}
                    register={register}
                    mandatory={false}
                    width="30%"
                    setValue={setValue}
                    value={getValues()?.minorDetails?.[index]?.mfirstName}
                  />
                  <TextInput
                    label={"Related Person Mother's Middle Name"}
                    fieldkey={`minorDetails[${index}].mMiddleName`}
                    errorText="This Field  is required"
                    placeholder={"Enter text"}
                    control={control}
                    register={register}
                    width="30%"
                    setValue={setValue}
                    value={getValues()?.minorDetails?.[index]?.mMiddleName}
                  />
                  <TextInput
                    label={"Related Person Mother's Last Name"}
                    fieldkey={`minorDetails[${index}].mLastName`}
                    errorText="This Field  is required"
                    placeholder={"Enter text"}
                    control={control}
                    register={register}
                    width="30%"
                    setValue={setValue}
                    value={getValues()?.minorDetails?.[index]?.mLastName}
                  />
                  <SelectInput
                    label={"Gender"}
                    fieldkey={`minorDetails[${index}].minorGender`}
                    errorText="Gender is required"
                    placeholder="State"
                    control={control}
                    register={register}
                    mandatory={true}
                    setValue={setValue}
                    options={[
                      { id: "M", name: "Male" },
                      { id: "F", name: "Female" },
                      { id: "T", name: "Transgender" },
                    ]}
                    valueRenderer={(option) => option.id}
                    displayName={(option) => `${option.name}`}
                    width="30%"
                    watch={watch}
                  />
                  <DatePicker
                    width={"30%"}
                    label={"Date of Birth"}
                    fieldkey={`minorDetails[${index}].minorDob`}
                    errorText="Date of Birth is required"
                    placeholder={"Enter text"}
                    control={control}
                    register={register}
                    mandatory={true}
                    format={"DD-MM-YYYY"}
                    setValue={setValue}
                    getValues={getValues}
                    maxDate={new Date()}
                    // value={getValues()?.minorDetails?.[index]?.minorDob}
                    value={getValues()?.minorDetails?.[index]?.minorDob || fromDateValue}
                    setDateValue={setFromDateValue}
                  />
                  <SelectInput
                    label={"Form-60 Finished"}
                    fieldkey={`minorDetails[${index}].minorForm16Finished`}
                    errorText=""
                    placeholder="State"
                    control={control}
                    register={register}
                    // mandatory={
                    //   !panFormCondition?.filter(
                    //     (item, conditionIndex) =>
                    //       conditionIndex === index ?? item?.formValue
                    //   )?.[0]?.formValue
                    // }
                    mandatory={true}
                    setValue={setValue}
                    options={[
                      { id: "Y", name: "Yes" },
                      { id: "N", name: "No" },
                    ]}
                    valueRenderer={(option) => option?.id}
                    displayName={(option) => option?.name}
                    width="30%"
                    watch={watch}
                    // disabled={
                    //   panFormCondition?.filter(
                    //     (item, conditionIndex) =>
                    //       conditionIndex === index ?? item?.formValue
                    //   )?.[0]?.formValue
                    // }
                    onChangeValue={handleFieldValue}
                  />
                  <TextInput
                    label={"Pan"}
                    fieldkey={`minorDetails[${index}].minorPan`}
                    errorText="This Field  is required"
                    placeholder={"Enter text"}
                    control={control}
                    register={register}
                    mandatory={
                      !panFormCondition?.filter(
                        (item, conditionIndex) =>
                          conditionIndex === index ?? item?.panValue
                      )?.[0]?.panValue
                    }
                    width="30%"
                    setValue={setValue}
                    value={
                      !panFormCondition?.filter(
                        (item, conditionIndex) =>
                          conditionIndex === index ?? item?.panValue
                      )?.[0]?.panValue ? 
                      getValues()?.minorDetails?.[index]?.minorPan : ""}
                    onChangeValue={handleFieldValue}
                    disabled={
                      panFormCondition?.filter(
                        (item, conditionIndex) =>
                          conditionIndex === index ?? item?.panValue
                      )?.[0]?.panValue
                    }
                  />

                  <SelectInput
                    label={"Nationality"}
                    fieldkey={`minorDetails[${index}].nationality`}
                    errorText="Nationality is required"
                    placeholder="State"
                    control={control}
                    register={register}
                    mandatory={false}
                    setValue={setValue}
                    options={dropdownOptions && dropdownOptions["country"]}
                    valueRenderer={(option) => option.code}
                    displayName={(option) => option.name}
                    width="30%"
                    watch={watch}
                  />
    </Box>
  )
}

export default MinorFlowRelatedPersonDetails