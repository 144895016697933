import React from "react";
import { Box, TextField, Button,FormHelperText } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Text from "../Atoms/Text";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import { passwordStrength } from "check-password-strength";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import styled from "styled-components";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { setPassword, changePassword, logout } from "../../apis/auth";
import { useGlobalContext } from "../../context/GlobalContext";

const strengthColor = {
  "Too weak": { color: "#d32f2f", width: "10%", text: "Weak", status: "error" },
  Weak: { color: "#d32f2f", width: "25%", text: "Weak", status: "error" },
  Medium: {
    color: "#ed6c02",
    width: "75%",
    text: "Average",
    status: "warning",
  },
  Strong: {
    color: "#2e7d32",
    width: "100%",
    text: "Strong",
    status: "success",
  },
};

const StyledStrengthMeter = styled.div`
  height: 5px;
  width: ${(props) => strengthColor[props.strength.value].width};
  transition: 500ms width;
  background: ${(props) => strengthColor[props.strength.value].color};
  border-radius: 4px;
`;

const StyledOutlinedInput = styled(OutlinedInput)`
  border: 2px solid red !important;
`;

const defaultOptions = [
  {
    id: 0,
    value: "Too weak",
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    value: "Weak",
    minDiversity: 2,
    minLength: 6,
  },
  {
    id: 2,
    value: "Medium",
    minDiversity: 4,
    minLength: 8,
  },
  {
    id: 3,
    value: "Strong",
    minDiversity: 4,
    minLength: 10,
  },
];

const getQueryString = (field, url) => {
  var href = url ? url : window.location.href;
  var reg = new RegExp("[?&]" + field + "=([^&#]*)", "i");
  var string = reg.exec(href);
  return string ? string[1] : null;
};

export default function ResetPassword({ resetNewPassword, ...props }) {
  const [showPassword, setShowPassword] = React.useState({
    pwd: false,
    confirmPwd: false,
    oldPwd: false,
  });
  const [passwordText, setPasswordText] = React.useState({
    pwd: "",
    confirmPwd: "",
    oldPwd: "",
  });
  const [strength, setStrength] = React.useState(null);
  const [passWordMatched, SetPasswordMatched] = React.useState(false);
  const { dispatch, currentUser } = useGlobalContext();
  const [error, setError] = React.useState({ text: "", isError: false });

  const navigate = useNavigate();

  const { refetch: userLogout } = useQuery(["logout"], logout, {
    manual: true,
    select: (response) => response.data,
    retry: false,
    enabled: false,
    onSuccess: () => {
      window.localStorage.clear();
      dispatch({
        type: "TOGGLE_IS_LOGGEDIN",
      });
      navigate("/login");
    },
  });

  const { mutate: onChangePassword, isLoading: changingPassword } = useMutation(
    (id) => changePassword(id),
    {
      onSuccess: (response) => {
        console.log("response ====>", response)
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.data.message,
            severity: response?.data?.isError ? "error" : "success",
          },
        });
        if (!response?.data?.isError) {
          userLogout();
        }
      },
      onError: (error) => {
        console.log("change====>", error)
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: "Something went wrong in our side, Please try again later",
            severity: "error",
          },
        });
      },
    }
  );

  const { mutate: onSetPassword, isLoading: resetingPassword } = useMutation(
    (id) => setPassword(id),
    {
      onSuccess: (response) => {
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: response.data.message,
            severity: response?.data?.isError ? "error" : "success",
          },
        });
        if (response?.data?.isError === false) navigate("/login");
      },
      onError: (error) => {
        console.log("hwereeeeeee=>",error)
        dispatch({
          type: "SET_TOAST",
          payload: {
            open: true,
            text: "Something went wrong in our side, Please try again later",
            severity: "error",
          },
        });
      },
    }
  );

  const onNewPasswordChange = (e) => {
    const val = e.target.value;
   
   
    const bothSame =
      passwordText.oldPwd &&
      passwordText.oldPwd?.trim() === e.target.value?.trim();
    setError({
      text: "Old Password and New Password should not be same",
      isError: bothSame,
    });
    setPasswordText({ ...passwordText, pwd: val });
    if (val) {
      const score = passwordStrength(val, defaultOptions);
      setStrength(score);
      if (passwordText.confirmPwd) {
        SetPasswordMatched(val === passwordText.confirmPwd);
      }
    }
  };

  const onConfirmPwdChange = (e) => {
    const val = e.target.value;
    setPasswordText({ ...passwordText, confirmPwd: val });
    SetPasswordMatched(val === passwordText.pwd);
  };

  // const onOldPasswordChange = (e) => {
  //   const val = e.target.value;
  //   setPasswordText({ ...passwordText, oldPwd: val });
  // };

  const onSubmit = (e) => {
    e.preventDefault();

    if (passwordText.oldPwd?.trim() === passwordText.pwd?.trim()) {
      setError({
        text: "Old Password and New Password should not be same",
        isError: true,
      });
      dispatch({
        type: "SET_TOAST",
        payload: {
          open: true,
          text: "Old Password and New Password should not be same",
          severity: "error",
        },
      });
      return;
    }
   else if (passwordText.pwd?.trim() !== passwordText.confirmPwd?.trim()) {
      setError({
        text: "Confirm Password and New Password not matched",
        isError: true,
      });
      dispatch({
        type: "SET_TOAST",
        payload: {
          open: true,
          text: "Confirm Password and New Password not matched",
          severity: "error",
        },
      });
      return;
    }
    else if (passwordText.pwd?.trim().length < 8 || !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/g.test(passwordText.pwd?.trim())) {
      setError({
        text: "New Password should be at least 8 characters long,one upper case and onelower case,one number",
        isError: true,
      });
      dispatch({
        type: "SET_TOAST",
        payload: {
          open: true,
          text: "New Password should be at least 8 characters long,one upper case and onelower case,one number",
          severity: "error",
        },
      });
      return;
    }
    if (!resetNewPassword) {
      
     
      const requestData = {
        newPassword: passwordText.pwd,
        token: getQueryString("token"),
      };
      onSetPassword(requestData);
    } 
    else {
       setError({
         text: "",
         isError: false,
       });
      const requestData = {
        id: JSON.parse(localStorage.getItem("id")),
        oldPassword: passwordText.oldPwd,
        password: passwordText.pwd,
        is_pwd_reset: true,
        token: getQueryString("q"),
      };
      onChangePassword(requestData);
    }
    e.preventDefault();
  };

  React.useEffect(() => {
    if (resetNewPassword) {
      if (!getQueryString("q")) {
        userLogout();
      }
    }
  }, []);
  return (
    <React.Fragment>
      <Box mt={3} mb={2}>
        <Text semiBold color="#494E59" fontSize="18px">
          {resetNewPassword ? "Set your password" : "Reset your password?"}
        </Text>
      </Box>
      <form onSubmit={onSubmit}>
        {resetNewPassword && (
          <Box display="flex" mt={"16px"} flexDirection="column">
            <Text mb="4px">Old Password</Text>
            <OutlinedInput
              focues
              id="email"
              defaultValue=""
              size="small"
              type={showPassword.oldPwd ? "text" : "password"}
              textFieldProps={{ shrink: false }}
              onChange={(e) => {
                const bothSame =
                  passwordText.pwd &&
                  passwordText.pwd?.trim() === e.target.value?.trim();
                setError({
                  text: "Old Password and New Password should not be sameeeee",
                  isError: bothSame,
                });
                
                setPasswordText({
                  ...passwordText,
                  oldPwd: e.target.value,
                });
              }}
              placeholder="password"
              color={strength && strengthColor[strength.value].status}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setShowPassword({
                        ...showPassword,
                        oldPwd: !showPassword.oldPwd,
                      })
                    }
                    // onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {!showPassword.oldPwd ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>
        )}
        <Box display="flex" mt={"16px"} flexDirection="column">
          <Text mb="4px">New Password</Text>
          <OutlinedInput
            focues
            id="email"
            defaultValue=""
            size="small"
            type={showPassword.pwd ? "text" : "password"}
            textFieldProps={{ shrink: false }}
            onChange={onNewPasswordChange}
            placeholder="password"
            color={strength && strengthColor[strength.value].status}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() =>
                    setShowPassword({ ...showPassword, pwd: !showPassword.pwd })
                  }
                  // onMouseDown={handleMouseDownPassword}
                  edge="end">
                  {!showPassword.pwd ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <Box mt={"4px"} alignItems="center" display="flex">
            <InfoOutlinedIcon
              sx={{
                fontSize: "12px",
                color: strength && strengthColor[strength.value].color,
              }}
            />
            <Text
              color={strength && strengthColor[strength.value].color}
              ml={"4px"}
              fontSize="10px">
              Min 8 characters, with upper, lowercase, number, symbol
            </Text>
          </Box>
          {error.isError && (
                <Box mt="4px" alignItems="center" display="flex">
                  <InfoOutlinedIcon
                    sx={{
                      fontSize: "12px",
                      marginTop: "2px",
                    }}
                    color="error"
                  />
                  <FormHelperText error id="accountId-error">
                    <Text ml="4px" fontSize="10px">
                      {error.text}
                    </Text>
                  </FormHelperText>
                </Box>
              )}
          {strength && (
            <Box
              mt={"8px"}
              alignItems="center"
              justifyContent="space-between"
              display="flex">
              <Box width="85%">
                <StyledStrengthMeter strength={strength} />
              </Box>
              <Text
                color={strengthColor[strength.value].color}
                ml={"4px"}
                fontSize="10px">
                {strengthColor[strength.value].text}
              </Text>
            </Box>
          )}
        </Box>
        <Box display="flex" flexDirection="column" sx={{ marginTop: "16px" }}>
          <Text mb="4px">Confirm Password</Text>
          <OutlinedInput
            id="email"
            defaultValue=""
            size="small"
            type={showPassword.confirmPwd ? "text" : "password"}
            onChange={onConfirmPwdChange}
            placeholder="password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() =>
                    setShowPassword({
                      ...showPassword,
                      confirmPwd: !showPassword.confirmPwd,
                    })
                  }
                  // onMouseDown={handleMouseDownPassword}
                  edge="end">
                  {!showPassword.confirmPwd ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </Box>
        {passwordText.confirmPwd.length > 0 && (
          <Box mt={"4px"} alignItems="center" display="flex">
            {passWordMatched && (
              <CheckCircleOutlineIcon
                sx={{
                  fontSize: "12px",
                  color: strength && strengthColor[strength.value].color,
                }}
              />
            )}
            {!passWordMatched && (
              <CancelOutlinedIcon
                sx={{
                  fontSize: "12px",
                  color: "red",
                }}
              />
            )}
            <Text
              color={
                passWordMatched
                  ?    strength && strengthColor[strength.value].color
                  : "red"
              }
              ml={"4px"}
              fontSize="10px">
              {passWordMatched
                ? "Password matched successfully"
                : "Password doesn't matches the above"}
            </Text>
          </Box>
        )}

       {console.log("reset pwd",resetingPassword)}
        <LoadingButton
          loading={resetingPassword ||  changingPassword}
          type={"submit"}
          size="medium"
          variant="contained"
          color="primary"
          disabled={!passWordMatched}
          disableElevation
          fullWidth
          style={{ textTransform: "none", marginTop: "16px" }}>
          <Text>{resetNewPassword ? "Set Password" : "Request Password"}</Text>
        </LoadingButton>
      </form>
    </React.Fragment>
  );
}
