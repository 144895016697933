import React, { useEffect } from "react";
import styled from "styled-components";
import { Box } from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Badge from "@mui/material/Badge";
import { Outlet } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import Text from "../components/Atoms/Text";
import SidePanel from "../components/Molecules/SidePanel";
import logoOriginal from "../assets/images/forgo.png";
import AccountMenu from "../components/Organisms/AccountMenu";
import Backdrop from "../components/Molecules/Backdrop";
import { getUser } from "../apis/users";
import { useGlobalContext } from "../context/GlobalContext";

const capitalizeFirstLetter = (string) => {
  return string?.length > 0
    ? string.charAt(0).toUpperCase() + string.slice(1)
    : string;
};

const defaultPages = {
  admin: "/dashboard",
  maker: "/dashboard",
  checker: "/dashboard",
  superadmin: "/dashboard",
};
const Layout = () => {
  const navigate = useNavigate();
  const user = localStorage.getItem("id");
  const {
    state: { currentUser, lastLogin },
    dispatch,
  } = useGlobalContext();

  const firstName = currentUser
    ? capitalizeFirstLetter(currentUser?.first_name)
    : "";
  const lastName = currentUser
    ? capitalizeFirstLetter(currentUser?.last_name)
    : "";
  const role = currentUser ? currentUser?.role?.role_name : "";
  const branch = currentUser ? currentUser?.branch?.branch_name : "";
  const clientName = currentUser ? currentUser?.client?.client_name : "";

  useQuery(["user", { id: user }], getUser, {
    retry: false,
    staleTime: 1 * 60 * 60,
    enabled: user ? true : false,
    onSuccess: (response) => {
      dispatch({
        type: "SET_CURRENT_USER",
        payload: response.users,
      });
    },
  });

  React.useEffect(() => {
    if (window.location.pathname === "/") {
      const role =
        (window.localStorage.getItem("roles") &&
          JSON.parse(window.localStorage.getItem("roles"))) ||
        currentUser.role.role_name;
      if (role || currentUser.role) {
        navigate(defaultPages[role.toLowerCase()]);
      }
    }
  }, []);

  // useEffect(() => {
  // 	console.log(currentUser);
  // 	if (currentUser?.is_pwd_reset === false) {
  // 		dispatch({
  // 			type: "SET_TOAST",
  // 			payload: {
  // 				open: true,
  // 				text: "Please change you password to continue",
  // 				severity: "warning",
  // 			},
  // 		});
  // 		navigate("/change-password");
  // 	}
  // }, [currentUser]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        background: "#EEEEEE",
        width: "100%",
        height: "100vh",
        // zIndex: 10001,
      }}>
      <Box position="sticky" style={{top: "0px", zIndex: 11}}>
        <Box
          m={"12px"}
          p={"20px"}
          display="flex"
          style={{
            background: "#FBFBFF",
            padding: "16px",
            borderRadius: "20px",
          }}
          justifyContent="space-between">

          <Box display="flex" justifyContent="flex-center" alignItems="center">
            <Box p="10px">
              <img
                src={logoOriginal}
                width="80px"
                style={{ objectFit: "contain", background: "#FBFBFF" }}
                alt="logo original"
              />
            </Box>
            <Box
              mr="12px"
              ml="2px"
              style={{ borderLeft: "1px solid #BEBDBE", height: "8px" }}
            />
            <Box display="flex" justifyContent="flex-center" alignItems="center">
              <Text fontSize="14px">FORGO CKYC PORTAL</Text>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection={"row"}
            justifyContent="flex-center"
            alignItems={"center"}>
            <Box display="flex" flexDirection={"row"} alignItems="center">
              {/* {currentUser?.lastLogin && ( */}
              <Box
                sx={{ backgroundColor: "#EAEAEC", borderRadius: "4px" }}
                px={"8px"}
                py="8px"
                display="flex"
                mr={"38px"}
                alignItems="center"
                flexDirection="row">
                <AccessTimeIcon
                  sx={{
                    color: "#E30000",
                    fontSize: "12px",
                    marginRight: "4px",
                  }}
                />
                <Text semiBold fontSize="10px">{`Last login: ${moment(
                  lastLogin
                ).format("lll")}`}</Text>
              </Box>
              {/* )} */}
              <Badge
                sx={{
                  ".MuiBadge-badge": {
                    backgroundColor: "red",
                  },
                }}
                color="secondary"
                variant="dot">
                <NotificationsNoneIcon />
              </Badge>
              {currentUser && (
                <Box mr={"8px"} style={{ textAlign: "end" }} ml={"30px"}>
                  <Text fontSize="18px">{`${firstName || ""} ${lastName || ""
                    }`}</Text>
                  {(role || branch) && (
                    <Box mt="6px" display="flex" justifyContent={"flex-end"}>
                      <Box mt="2px">
                        <Text fontSize="12px">{`${role} ${branch ? ` / ${branch}` : ""
                          }`}</Text>
                        <Text mt="4px" fontSize="12px">
                          {clientName}
                        </Text>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
              {/* <AccountCircleIcon /> */}
              <AccountMenu />
            </Box>
          </Box>

        </Box>
      </Box>
      <Box mt="0px" display="flex" flexGrow="1">
        <SidePanel />
        <Box
          display={"flex"}
          flexGrow="1"
          ml="12px"
          mr="12px"
          width={"100%"}
          style={{ width: "100%" }}>
          <Box
            style={{
              borderRadius: "10px",
              background: "#FBFBFF",
              width: "100%",
              display: "flex",
              height: "100%",
              flexDirection: "column",
            }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
      <Backdrop />
    </Box>
  );
};

export default Layout;
