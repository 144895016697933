import React ,{ useEffect,useState } from "react";
import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { getStates } from "../../apis/branches";
import { useMutation, useQuery, useQueries } from "@tanstack/react-query";

import Text from "../Atoms/Text";
import TextInput from "../Form-Components/TextInput";
import SelectInput from "../Form-Components/SelectInput";
import StickyFooter from "../Atoms/StickyFooter";
import Modal from "../Molecules/Modal";
import { getPicklists } from "../../apis/uploadMaster";

const schema = yup
  .object()
  .shape({
    masterId: yup.string().max(255).required("First name is required"),
    value: yup.string().required("Last name is required"),
    text: yup.string().max(255).required("First name is required"),
    // isDeemed: yup.string().max(255).required("Last name is required"),
  })
  .required();

const AddUploadMaster = ({
  open,
  toggleDrawer,
  selectedMaster,
  isLoading,
  onSubmit,
  picklistTypes,
}) => {
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState,
    errors,
    setValue,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      masterId: selectedMaster?.get("masterId", ""),
      value: selectedMaster?.get("value", ""),
      text: selectedMaster?.get("text", ""),
      // isDeemed: selectedMaster?.get("isDeemed", ""),
    },
    resolver: yupResolver(schema),
  });

  
  // const handleReset = () => {
  //   console.log("mnasa clicked")
  //   reset({
  //     masterId: "",
  //     value: "",
  //     text: "",
  //   });
  // };
 // console.log('reset',reset())
 const [isFormReset, setIsFormReset] = useState(false);

 useEffect(() => {
   if (isFormReset) {
     reset();
     setIsFormReset(false);
   }
 }, [isFormReset, reset]);

 const handleReset = () => {
   setIsFormReset(true);
 };
  return (
    <Modal
      title={
        selectedMaster?.get("id")
          ? "Edit Upload Master"
          : "Create Upload Master"
      }
      open={open}
      toggleDrawer={toggleDrawer}>
      <form
        style={{ display: "flex", flexDirection: "column" }}
        onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          flexDirection={"column"}
          p="24px"
          sx={{ overflowY: "auto", width: "100%" }}>
          <Box display="flex" sx={{ width: "100%", flexWrap: "wrap" }}>
            <SelectInput
              label={"Master Name"}
              fieldkey="masterId"
              errorText="Picklist is required"
              placeholder="Picklist"
              control={control}
              register={register}
              mandatory
              setValue={setValue}
              options={picklistTypes?.items || []}
              valueRenderer={(option) => option?.id}
              displayName={(option) => option?.masterName}
              watch={watch}
            />
            <TextInput
              label={"Text"}
              fieldkey="text"
              errorText="Required"
              placeholder="Enter Text"
              control={control}
              register={register}
              mandatory
              isRegex={/^[a-zA-Z\s]*$/}
              setValue={setValue}
              value={getValues()?.["text"]}
            />
            <TextInput
              label={"Value"}
              fieldkey="value"
              errorText="Required"
              placeholder="Enter text"
              control={control}
              register={register}
              mandatory
              isRegex={/^[a-zA-Z0-9]*$/}
              setValue={setValue}
              value={getValues()?.["value"]}
            />
            {/* <SelectInput
							label={"Deemed"}
							fieldkey="isDeemed"
							errorText="Deemed is required"
							placeholder="Deemed"
							control={control}
							register={register}
							mandatory
							setValue={setValue}
							options={[
								{ id: 1, name: "Yes" },
								{ id: 0, name: "No" },
							]}
							valueRenderer={(option) => option?.id}
							displayName={(option) => option?.name}
						/> */}
          </Box>
        </Box>
        <StickyFooter>
          <React.Fragment>
            <Button
              onClick={handleReset}
              sx={{ marginRight: "8px" }}
              variant="outlined">
            {/* {selectedMaster?.id ? "Reset" : "Clear"} */}
            { selectedMaster?.get("id") ? "Reset" : "Clear"}
            </Button>
            <LoadingButton
              loading={formState.isSubmitting || isLoading}
              type={"submit"}
              size="small"
              variant="contained"
              color="primary"
              disableElevation
              style={{ textTransform: "none" }}>
              <Text>Submit</Text>
            </LoadingButton>
          </React.Fragment>
        </StickyFooter>
      </form>
    </Modal>
  );
};

export default AddUploadMaster;
