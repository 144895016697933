import axios from "axios";
import * as CryptoJS from "crypto-js";
import Swal from "sweetalert2";
import TokenService from "../utils/TokenService";
// let BASE_URL = "https://54.176.145.203/api";
// if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
// 	BASE_URL = "https://54.176.145.203/api";
// }https://ckycuat.syntizen.app
// https://api.ckycuat.in/api
// const baseApiUrl = ["https://ckyc.syntizen.app"].includes(
// 	window.location.origin
// )
// 	? "https://ckyc.syntizen.app/api"
// 	:"https://api.ckycuat.in/api";
// const axiosInstance = axios.create({
// 	baseURL: baseApiUrl,
// 	withCredentials: false,
// });
const baseApiUrl = process.env.REACT_APP_API_URL;
const axiosInstance = axios.create({
	baseURL: baseApiUrl,
	withCredentials: false,
});

axiosInstance.defaults.headers.common["Content-Type"] = "application/json";

axiosInstance.interceptors.request.use(
	(config) => {
		const token = TokenService.getLocalAccessToken();
		if (token) {
			config.headers["Authorization"] = "Token " + token;
		}
		config.headers["decryption"] = true;
		// if(config.url ==='/customers/downloadZip' || config.url ==='/static/downloadTemplate?module=customerData' || config.url ==='/static/downloadTemplate?module=uploadMaster' || config.url ==='static/downloadTemplate?module=districtMaster'){
		// 	config.headers["decryption"] = true;
		// }	  
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

function decryptAES256CBC(ciphertextBase64, key, iv) {
	const ciphertext = CryptoJS.enc.Base64.parse(ciphertextBase64);
  
	const decrypted = CryptoJS.AES.decrypt({
	  ciphertext: ciphertext
	}, CryptoJS.enc.Utf8.parse(key), {
	  iv: CryptoJS.enc.Utf8.parse(iv),
	  mode: CryptoJS.mode.CBC,
	  padding: CryptoJS.pad.Pkcs7
	});
  
	return decrypted.toString(CryptoJS.enc.Utf8);
  }


axiosInstance.interceptors.response.use(
	(res) => {
		// if(res?.config?.url !== '/customers/downloadZip' && res?.config?.url !== '/static/downloadTemplate?module=customerData' && res?.config?.url !== '/static/downloadTemplate?module=uploadMaster' && res?.config?.url !== 'static/downloadTemplate?module=districtMaster'){
		// 	res.data = JSON.parse(decryptAES256CBC(res?.data,'hcxilkqbbhczfeultgbskdmaunivmfuo','ryojvlzmdalyglrj'))
		// }			
		console.log("rressonse",res.data);
		return res; 
	},
	async (err) => {
		const originalConfig = err.config;
		// if(originalConfig.url !== '/customers/downloadZip' && originalConfig.url !== '/static/downloadTemplate?module=customerData' && originalConfig?.url !== '/static/downloadTemplate?module=uploadMaster' && originalConfig?.url !== 'static/downloadTemplate?module=districtMaster'){
		// 	err.response.data = JSON.parse(decryptAES256CBC(err?.response?.data,'hcxilkqbbhczfeultgbskdmaunivmfuo','ryojvlzmdalyglrj'))
		// }
		if (originalConfig.url !== "/users/login" && err.response) {
			// Access Token was expired
			if (
				(err.response.status === 401 ||
					(err.response.status === 500 &&
						err?.response?.data?.errors?.message === "jwt expired")) &&
				!originalConfig._retry
			) {
				window.localStorage.clear();

				Swal.fire({
					title:
						'<p style="font-size:16px">Session expired. Please login to continue</p>',
					// timer: 2000,
					// showCancelButton: true,
					confirmButtonText: "Login",
					confirmButtonColor: "#3f81f4",
					allowOutsideClick: () => {
						window.location = window.location.origin;
					},
				}).then((result) => {
					if (result.isConfirmed) {
						window.location = window.location.origin;
					}
				});

				//  originalConfig._retry = true;

				// try {
				// 	const rs = await instance.post("/auth/refreshtoken", {
				// 		refreshToken: TokenService.getLocalRefreshToken(),
				// 	});

				// 	const { accessToken } = rs.data;
				// 	TokenService.updateLocalAccessToken(accessToken);

				// 	return instance(originalConfig);
				// } catch (_error) {
				// 	return Promise.reject(_error);
				// }
			}
		}

		return Promise.reject(err);
	}
);

export default axiosInstance;
