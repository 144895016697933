import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import Text from "../../Atoms/Text";
import CustomerAccordon from "../../Molecules/CustomerAccordon";
import { getPrefix, renderLabel } from "../new-customer/utils";
import PreviewUploadSection from "./PreviewUploadSection";
import SubdivsElements from "./SubdivsElements";
import moment from "moment";
import { displayAadhaar } from "../../../utils";

export const hideElements = [
  "proofOfAddresIfOthers",
  "-1",
  "title",
  "fatherInitial",
  "motherInitial",
  "dependentType",
  "currentProofOfAddresIfOthers",
  "sameAsPermanant",
  "customerNumber",
  "customerBranch",
];

const PreviewDetails = (props) => {
  const { steps, values, files, minorFiles, dropdownOptions, getMappedLabel } =
    props;
    

  const fullWidthColoumns = ["line1", "line2"];
  const formatValues = {
    personalFirstName: `${values["title"] || ""}  ${
      values["personalFirstName"]
    }`,
    father: `${getPrefix(values["fatherInitial"]) || ""}  ${values["father"]}`,
    motherFirstName: `${getPrefix(values["motherInitial"] || "")}  ${
      values["motherFirstName"]
    }`,
  };
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  // const handleFileClick = (file) => {
  //   setSelectedFile(file);
  //   setPreviewOpen(true);
  // };
  const handleClosePreview = () => {
    setSelectedFile(null);
    setPreviewOpen(false);
  };
  const renderValue = (element, vals) => {
    const employeeNameID = vals?.employeeName;
    const employeeDesignationID = vals?.employeeDesignation;
    const employeeNameNewOption = dropdownOptions["employeeNameNew"];
    const employeeDesignationNewOption = dropdownOptions["employeeDesignation"];
    const emplValue = employeeNameNewOption?.filter(
      (eleValue) => eleValue?.dmsUserId == employeeNameID
    );
    const desigValue = employeeDesignationNewOption?.filter(
      (eleValue) => eleValue?.id == employeeDesignationID
    );
    const relatedEmplValue = employeeNameNewOption?.filter(item => item.dmsUserId === vals["kycVerificationName"])
    // const relatedEmplDesignValue = employeeDesignationNewOption?.filter(item => item.id === vals["kycVerificationDesignation"])
    const docuReceived = dropdownOptions["documentRecieved"]?.filter(item => item.code === vals["typeOfDocSubmitted"]);
    if (element.type === "date") {
      if(vals[element.key]){
        return moment(vals[element.key], "DD-MM-YYYY").format("DD-MM-YYYY")
        
      }else{
        return vals[element.key] ?? "-";
      }
    }
    if (element.key === "applicationType") {
      if (vals[element.key] === "01") {
        return "New";
      } else {
        return "Update";
      }
    }
    if (formatValues[element.key]) {
      return formatValues[element.key];
    }
    if (element.key === "addDelFlag") {
      if (vals[element.key] === "01") {
        return "Addition of Related Person";
      } else if (vals[element.key] === "02") {
        return "Deletion of Related Person";
      }
    } else if (element.key === "minorGender") {
      if (vals[element.key] === "M") {
        return "Male";
      } else if (vals[element.key] === "F") {
        return "Female";
      } else if (vals[element.key] === "T") {
        return "Transgender";
      }
    } else if (element.key === "minorForm16Finished") {
      if (vals[element.key] === "Y") {
        return "Yes";
      } else if (vals[element.key] === "N") {
        return "No";
      }
    }else if(element.key === "typeOfDocSubmitted"){
      return docuReceived?.[0]?.name
    }else if(element.key === "kycVerificationName"){
      return relatedEmplValue?.[0]?.dmsUserName
    }else if(element.key === "kycVerificationDesignation"){
      return vals["kycVerificationDesignation"];
    }else if (element.key === "employeeName") {
      if (!isNaN(employeeNameID)) {
        return emplValue?.[0]?.dmsUserName || "-";
      } else {
        return vals?.employeeName;
      }
    } else if (element.key === "employeeDesignation") {
      if (!isNaN(employeeDesignationID)) {
        return desigValue?.[0]?.name || "-";
      } else {
        return vals?.employeeDesignation;
      }
    } else if (element.key === "relatedAddressProofIdNumber" || element.key === "addressProofIdNumber" || element.key === "currentAddressProofIdNumber"){
      if(["01", "10"].includes(vals.relatedAddressProof) || ["01", "10"].includes(vals.addressProof) || ["01", "10"].includes(vals.currentAddressProof)){
        return displayAadhaar(vals[element.key])
      }else{
        return vals[element.key]
      }
    }else if(element.key === "pesonalAadhar"){
      return displayAadhaar(vals[element.key])
    }else {
      return vals[element?.key] ? getMappedLabel(element, vals) : "-";
    }
  };
  console.log("preview", { minorFiles });
  return (
    <Box>
      <Box mt={"16px"} mb={"20px"}>
        {steps?.map((item, index) => {
          return (
            ![4, 5].includes(index) && (
              <Box my="16px">
                <CustomerAccordon title={item.label}>
                  {item.displayName && (
                    <Text
                      fontSize="16px"
                      mt={"8px"}
                      mb={"8px"}
                      fontWeight="500"
                    >
                      {item.displayName}
                    </Text>
                  )}
                  <Box display="flex" flexWrap="wrap">
                    {item?.formElements?.map((element) => {
                      const isFullWidthRequired = fullWidthColoumns.includes(
                        element.key
                      );
                      if (
                        (element.key === "kycNumber" &&
                          values["applicationType"] === "01") ||
                        hideElements.includes(element.key)
                      )
                        return null;
                      return (
                        <Box
                          width={isFullWidthRequired ? "30%" : "30%"}
                          display="flex"
                        >
                          <Box
                            width={isFullWidthRequired ? "50%" : "50%"}
                            display="flex"
                            flexWrap="wrap"
                            mt="16px"
                            justifyContent={"flex-end"}
                          >
                            <Text
                              fontSize="14px"
                              color="#6C6C6C"
                              fontWeight="500"
                            >
                              {renderLabel(element, dropdownOptions, values)}
                            </Text>
                          </Box>
                          <Box
                            width={isFullWidthRequired ? "50%" : "50%"}
                            display="flex"
                            flexWrap="wrap"
                            mt="16px"
                            ml="12px"
                            justifyContent={"flex-start"}
                          >
                            <Text ml={"8px"} fontSize="14px" fontWeight="500">
                              {renderValue(element, values)}
                            </Text>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                  {values.accountType === "05" &&
                  values.minorDetails?.length > 0 &&
                  item?.subDivs?.label === "Related Person Details"
                    ? values.minorDetails?.map((minor, minorIndex) => (
                        <React.Fragment>
                          <Text
                            fontSize="16px"
                            mt={"8px"}
                            mb={"8px"}
                            fontWeight="500"
                          >
                            {item?.subDivs?.label}
                          </Text>
                          <Box display="flex" flexWrap="wrap">
                            {item?.subDivs?.formElements?.map((element) => (
                              <Box my="8px" px="8px" style={{ width: "100%" }}>
                                <CustomerAccordon title={element?.label}>
                                  <Box display="flex" flexWrap="wrap">
                                    {element?.formElements?.map((element) => (
                                      <SubdivsElements
                                        element={element}
                                        dropdownOptions={dropdownOptions}
                                        values={minor}
                                        fullWidthColoumns={fullWidthColoumns}
                                        renderLabel={renderLabel}
                                        renderValue={renderValue}
                                        fromMinor={true}
                                        files={minorFiles?.filter(
                                          (ele) =>
                                            ele.related_person_index ===
                                            `RP${minorIndex}`
                                        )}
                                        relatedPersonIndex={minorIndex}
                                      />
                                    ))}
                                  </Box>
                                </CustomerAccordon>
                              </Box>
                            ))}
                          </Box>
                        </React.Fragment>
                      ))
                    : item?.subDivs && (
                        <React.Fragment>
                          <Text
                            fontSize="16px"
                            mt={"8px"}
                            mb={"8px"}
                            fontWeight="500"
                          >
                            {item?.subDivs?.label}
                          </Text>
                          <Box display="flex" flexWrap="wrap">
                            {item?.subDivs?.formElements?.map((element) => (
                              <SubdivsElements
                                element={element}
                                dropdownOptions={dropdownOptions}
                                values={values}
                                fullWidthColoumns={fullWidthColoumns}
                                renderLabel={renderLabel}
                                renderValue={renderValue}
                              />
                            ))}
                          </Box>
                        </React.Fragment>
                      )}
                </CustomerAccordon>
              </Box>
            )
          );
        })}

        <Box my="16px">
          <CustomerAccordon title={"Upload"}>
            <PreviewUploadSection
              files={files}
              dropdownOptions={dropdownOptions}
              uploadPrevType={"prevUpload"}
            />
          </CustomerAccordon>
        </Box>

        {/* Preview Dialog */}
        <Dialog open={previewOpen} onClose={handleClosePreview}>
          <DialogTitle>
            <Text>File Preview</Text>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClosePreview}
              aria-label="close"
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                marginRight: "3px",
              }}
            >
              <CancelIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {selectedFile && (
              <img src={selectedFile.fileUrl} alt="File Preview" />
            )}
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};

export default PreviewDetails;
