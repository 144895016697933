import axiosInstance from "./axios";
import Immutable from "immutable";

// export const getDashboardData = async ({ queryKey }) => {
//   const [_key, { filter, search, perPageSize }] = queryKey;
//   const pageSize = perPageSize || 10;
//   try {
//     let baseUrl = `/customers/dashboard/?perPage=500&offset=0`;
//     let filtered = baseUrl;
//     if (filter && Object.keys(filter)?.length > 0) {
//       delete filter.page;
//       delete filter.perPageSize;
//       if(typeof filter["condition"] === "object"){
//         delete filter?.endDate
//         delete filter?.fromDate
//       }
//       const keys = Object.keys(filter);
     
//       console.log(keys);
//       keys
//         .filter((i) => i)
//         .forEach((item) => {
//           if (typeof filter[item] === "object") {
//             const newObject = filter[item];
//             console.log(typeof filter[item]);
//             Object.keys(newObject).forEach((i) => {
//               filtered = `${filtered}&${i}=${newObject[i]}`;
//             });
//           } else if (!["", null, undefined].includes(filter[item]))
//             filtered = `${filtered}&${item}=${filter[item]}`;
//         });
//     }
//     if (![null, "", undefined].includes(search))
//       filtered = `${filtered}&search=${search}`;

//     const response = await axiosInstance.get(filtered);
//     return Immutable.fromJS(response.data);
//   } catch (e) {}
// };
// export const getDashboardData = async ({ queryKey }) => {
//   const [_key, { filter, search, perPageSize }] = queryKey;
//   const pageSize = perPageSize || 10;
//   try {
//     let baseUrl = `/customers/dashboard/?perPage=500&offset=0`;
//     let filtered = baseUrl;
//     if (filter && Object.keys(filter)?.length > 0) {
//       const keys = Object.keys(filter);
//       console.log(keys);
//       keys
//         .filter((i) => i)
//         .forEach((item) => {
//           if (typeof filter[item] === "object") {
//             const newObject = filter[item];
//             console.log(typeof filter[item]);
//             Object.keys(newObject).forEach((i) => {
//               filtered = `${filtered}&${i}=${newObject[i]}`;
//             });
//           } else if (!["", null, undefined].includes(filter[item]))
//             filtered = `${filtered}&${item}=${filter[item]}`;
//         });
//     }
//     if (![null, "", undefined].includes(search))
//       filtered = `${baseUrl}&search=${search}`;

//     const response = await axiosInstance.get(filtered);
//     return Immutable.fromJS(response.data);
//   } catch (e) {}
// };

export const getDashboardData = async ({ queryKey }) => {
  const [_key, { filter, search }] = queryKey;
  const pageSize = filter.perPageSize || 10;
  console.log("pagesize",pageSize)
  const fils = { ...filter };
  try {
    let baseUrl = `/customers/dashboard?perPage=${pageSize}&offset=${filter.page ? filter.page : 0}`;
    let filtered = baseUrl;
    if (fils && Object.keys(fils)?.length > 0) {
      delete fils.page;
      delete fils.perPageSize;
      const keys = Object.keys(fils);
      keys
        .filter((i) => i)
        .forEach((item) => {
          if (typeof filter[item] === "object") {
            const newObject = filter[item];
            Object.keys(newObject).forEach((i) => {
              filtered = `${filtered}&${i}=${newObject[i]}`;
            });
          } else if (!["", null, undefined].includes(filter[item]))
            filtered = `${filtered}&${item}=${filter[item]}`;
        });
    }
    if (![null, "", undefined].includes(search))
      filtered = `${baseUrl}&search=${search}`;

    const response = await axiosInstance.get(filtered);
    return Immutable.fromJS(response.data);
  } catch (e) {}
};

export const getAutomationDashboardData = async ({ queryKey }) => {
  const [_key, { filter, search, perPageSize }] = queryKey;
  const pageSize = perPageSize || 10;
  const currentRole =  JSON?.parse(window.localStorage.getItem("roles"));
  try {
   
   
    var baseUrl = `/customers/automationDashboard?perPage=500&offset=0`;
    let filtered = baseUrl;
    console.log("filter",filter);
    if (filter && Object.keys(filter)?.length > 0) {
      delete filter.page;
      delete filter.perPageSize;
      if(typeof filter["condition"] === "object"){
        delete filter?.endDate
        delete filter?.fromDate
      }
      const keys = Object.keys(filter);
      keys
        .filter((i) => i)
        .forEach((item) => {
          if (typeof filter[item] === "object") {

            const newObject = filter[item];
            console.log(typeof filter[item]);
            Object.keys(newObject).forEach((i) => {
              filtered = `${filtered}&${i}=${newObject[i]}`;
            });
          } else if (!["", null, undefined].includes(filter[item]))
            filtered = `${filtered}&${item}=${filter[item]}`;
        });
    }
    if (![null, "", undefined].includes(search))
      filtered = `${baseUrl}&search=${search}`;

    const response = await axiosInstance.get(filtered);
    return Immutable.fromJS(response.data);
  } catch (e) {}
};
