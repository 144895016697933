import Immutable from "immutable";
import axiosInstance from "./axios";
import { isMaker } from "../utils";

export const getCkycUsers = async ({ queryKey }) => {
	const [_key, { filter, search }] = queryKey;
	const pageSize = filter.perPageSize || 10;
	const fils = { ...filter };
	let baseUrl = `/ckycUsers/list/?perPage=${pageSize}&offset=${filter?.page ? filter?.page : 0}`;
	delete fils.page;
	delete fils.perPageSize;
	delete fils.condition;
	if(isMaker()){
		baseUrl = "/ckycUsers/list/?perPage=500&offset=0&statusFilter=1";
	}
	let filtered = baseUrl;

	if (!isMaker() && filter?.statusFilter!= null && filter?.statusFilter >=0 && Object.keys(filter).length > 0) {
		let val = Object.keys(fils);
		filtered = `${baseUrl}&${val}=${filter[val]}`;
	}
	if (!isMaker() && ![null, "", undefined].includes(search))
		filtered = `${baseUrl}&search=${search}`;

	const response = await axiosInstance.get(filtered);
	return Immutable.fromJS(response.data);
};
export const getStates = async () => {
	const response = await axiosInstance.get("/ckycUsers/getStates");
	return Immutable.fromJS(response.data);
};

export const getCities = async ({ queryKey }) => {
	const [_key, { state }] = queryKey;
	try {
		const response = await axiosInstance.get(
			`/ckycUsers/getCities?stateName=${state}`
		);
		return response.data;
	} catch (e) {}
};

export const deleteCkycUser = async (data) => {
	const response = await axiosInstance.delete(`/ckycUsers/delete`, data);
	return response.data;
};

export const addOrEditCkycUser = async (data) => {
	let response;
	if (data.ckycUserId && !data?.isDeleted)
		response = await axiosInstance.put(`/ckycUsers/edit`, data);
	else if (data.isDeleted) {
		response = await axiosInstance.delete(`/ckycUsers/delete`, {
			data: { ckycUserId: data.ckycUserId },
		});
	} else response = await axiosInstance.post(`/ckycUsers/create`, data);
	if(!response?.data?.isError && (data?.status === 1 || data?.status === 0)){
		response.data["message"] = response.data["message"]?.replace("updated",data?.status ? "enabled" : "disabled");
	}
	return response.data;
};

export const editBranch = async (data) => {
	const response = await axiosInstance.post(`/ckycUsers/edit`, data);
	return response.data;
};
