import React from "react";

const initialState = {
  authUser: null,
  popUp: {
    open: false,
    severity: "success",
    text: "",
  },
  openPasswordChangeModal: false,
  currentUser: {
    id: null,
    role: null,
  },
  lastLogin: Date.now(),
  isLoggedin: false,
  dropdownFilters: {},
  rowSelect: true,
};

const StateContext = React.createContext(undefined);

const stateReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER": {
      return {
        ...state,
        authUser: action.payload,
      };
    }
    case "SET_TOAST": {
      return {
        ...state,
        popUp: action.payload,
      };
    }
    case "SET_LAST_LOGIN": {
      return {
        ...state,
        lastLogin: action.payload,
      };
    }
    case "TOGGLE_PASSWORD_MODAL": {
      return {
        ...state,
        openPasswordChangeModal: action.payload.openPasswordChangeModal,
      };
    }
    case "TOGGLE_IS_LOGGEDIN": {
      return {
        ...state,
        isLoggedin: !state.isLoggedin,
      };
    }
    case "SET_CURRENT_USER": {
      const { payload } = action;
      return {
        ...state,
        currentUser: { ...state.currentUser, ...payload },
      };
    }
    case "SET_DROPDOWN_FILTER": {
      const { payload } = action;
      if (Object.keys(payload).length === 0)
        return { ...state, dropdownFilters: {} };
      else
        return {
          ...state,
          dropdownFilters: { ...state.dropdownFilters, ...payload },
        };
    }
    case "SET_ROW_SELECT" : {
      return {
        ...state,
        rowSelect: action.payload.rowSelect,
      };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(stateReducer, initialState);
  const value = { state, dispatch };
  return (
    <StateContext.Provider value={value}>{children}</StateContext.Provider>
  );
};

const useGlobalContext = () => {
  const context = React.useContext(StateContext);

  if (context) {
    return context;
  }

  throw new Error(`useStateContext must be used within a StateContextProvider`);
};

export { GlobalContextProvider, useGlobalContext };
