import React, { Fragment } from "react";
import { Route, Routes as Switch, Navigate } from "react-router-dom";
import ForgetForm from "../components/Login/ForgetForm";
import LoginForm from "../components/Login/LoginForm";
import ResetPassword from "../components/Login/ResetPassword";
import { Login } from "../Pages";
import Layout from "../Pages/Layout";
// import PopUp from "../components/Molecules/PopUp";

function PublicRoutes() {
  return (
    <Fragment>
      <Switch>
        <Route path="/" element={<Login />}>
          <Route path="/login" element={<LoginForm />} />
          <Route path="/forget" element={<ForgetForm />} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/" element={<LoginForm />} />
        </Route>
        <Route path="/home" element={<Layout />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Switch>
    </Fragment>
  );
}

export default PublicRoutes;
