import moment from "moment";

// import { DeleteOutlineIcon, EditIcon } from "@mui/base";
import Text from "../../components/Atoms/Text";
import { RenderCKYCHoldStatus } from "../../components/Atoms/RenderCKYCStatus";

const statusColor = {
  "Pending Mapping": "#8D8D8D",
  "In Progress": "#5B58FF",
  "On Hold": "#EE8E53",
  "CKYC Rejected": "#EA0000",
  "CKYC Found": "green",
  Audited: "green",
};

export const renderCKYCStatus = (value) => {
  return value ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        color: statusColor[value] || "#FF4D4D",
        fontSize: "14px",
      }}
    >
      <div
        style={{
          marginRight: "6px",
          height: "6px",
          width: "6px",
          borderRadius: "6px",
          backgroundColor: statusColor[value] || "#FF4D4D",
        }}
      />
      {value}
    </div>
  ) : (
    ""
  );
};

export const getColumns = ({
  onDocketClick,
  onToggleActive,
  onRemarksClick,
  showHistory,
  activeTab,
  pageTitle,
  activeTabPopup,
  isUploadCustomers
}) => {

  const columns = [
    {
      field: "docketNo",
      headerName: "Docket No",
      // flex: 1,
      flex: 0.7,
      renderCell: (params) => (
        <Text
          cursor="pointer"
          color="#0400E1"
          fontSize="14px"
          semiBold
          fontWeight="400"
          onClick={() => {
            onDocketClick(params.row.customerId);
          }}
        >
          {params.value}
        </Text>
      ),
    },
    {
      field: "customerNumber",
      headerName: "Customer No",
      flex: 0.9,
      // renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "customerOpenDate",
      headerName: "Customer opening date",
      flex: 1,
      renderCell: (params) => {
        const date = params.row.customerOpenDate;
        if (date) {
          return moment(date).format("lll");
        }
        return "";
      },
    },
    {
      field: activeTab === 0  || isUploadCustomers ? "name" : "entity_name",
      headerName: activeTab === 0 || isUploadCustomers? "Name" : "Name of Entity",
      flex: activeTab === 0 ? 0.7 : 0.9,
      renderCell:(params) =>
      <span title={params.value}>{params.value?.length > 8 ? `${params.value?.substr(0,8)}...`:params.value}</span>
    
      // renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: activeTab === 0 || isUploadCustomers ? "dob" : "DATE_OF_INCORPORATION",
      headerName: activeTab === 0 || isUploadCustomers ? "DOB" : "Date Of Incorporation",
      flex: activeTab === 0 ? 0.6 : 1.2,
      
      // renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field:
        pageTitle?.includes("Bulk") && activeTab === 1
          ? "Registration_number"
          : "pan",
      headerName:
        pageTitle?.includes("Bulk") && activeTab === 1
          ? "Registration number"
          : "Pan No",
      flex: pageTitle?.includes("Bulk") && activeTab === 1 ? 1 : 0.7,
      // renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: "mobileNumber",
      headerName: "Mobile No",
      flex: 0.7,
      renderCell: (params) => {
        //     const mobileCode = params.row?.MOB_CODE || "";
        const mobile = params.row?.MOB_NUM || "";
        return `${mobile}`;
      },
      // renderHeader: (params) => HeaderWrapper(params),
    },
    {
      field: pageTitle?.includes("Bulk") ? "status_remark" : "status",
      headerName: pageTitle?.includes("Bulk") ? "Search Remarks" : "Status",
      flex: pageTitle?.includes("Bulk") ? 1 : 1,
      
      renderCell: (params) =>
       (
        <RenderCKYCHoldStatus
          value={pageTitle?.includes('Bulk') ? params.row.status_remark : params.row.status}
          error={params.row.error}
          onRemarksClick={() => onRemarksClick(params.row.customerId)}
          showHistory={() => showHistory(params.row.customerId)}
          noHistoryIcon={pageTitle?.includes("Bulk") ? true : false}
        />
      ),
    },
    // {
    // 	field: "active",
    // 	headerName: "Active",
    // 	flex: 0.6,
    // 	disableExport: true,
    // 	renderCell: (params) => {
    // 		return (
    // 			<Switch
    // 				checked={params.value}
    // 				onChange={(e) => onToggleActive(e, params.row.id)}
    // 				defaultChecked
    // 			/>
    // 		);
    // 	},
    // },
    // {
    // 	field: "actions",
    // 	headerName: "Actions",
    // 	sortable: false,
    // 	disableExport: true,
    // 	renderCell: (params) => {
    // 		return (
    // 			<Box display="flex" justifyContent={"space-between"}>
    // 				<EditIcon
    // 					onClick={() => onEditClick(params.row.id)}
    // 					sx={{ marginRight: "4px", cursor: "pointer", marginRight: "8px" }}
    // 				/>
    // 				<DeleteOutlineIcon
    // 					onClick={() => onDeleteClick(params.row.id)}
    // 					sx={{ color: "red", cursor: "pointer", marginLeft: "8px" }}
    // 				/>
    // 			</Box>
    // 		);
    // 	},
    // },
  ];
  
  // if(isUploadCustomers){
  //   columns.splice(3,2, {
  //     field: "dob",
  //     headerName: "DOB",
  //     flex: 0.87
  //   })
  // }
  if (pageTitle?.includes("Bulk")) {

    const customerOpenDateIndex = columns.findIndex(
      (column) => column.field === "customerOpenDate"
    );

    if (customerOpenDateIndex !== -1) {
      columns.splice(customerOpenDateIndex, 1);
    }
    columns.splice(1, 0, {
      field: "branch_code",
      headerName: "Branch Code",
      flex: 0.8,
    });
    if (activeTab === 0) {
      columns.splice(5, 0, {
        field: "aadhar",
        headerName: "Aadhar No",
        flex: 0.7,
      });
    }
    if (pageTitle?.includes("Bulk") && activeTabPopup === 1) {
      columns.splice(4, 0, {
        field: "CKYC_NO",
        headerName: "CKYC No",
        flex: 0.7,
      });
      // const statusRemarksIndex = columns.length - 1;
      // if (statusRemarksIndex !== -1) {
      //   console.log(statusRemarksIndex, "index status");
      //   columns.splice(statusRemarksIndex, 1);
      // }
    }
  }

  return columns;
};
