import React from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import Text from "../Atoms/Text";

const CustomerAccordon = (props) => {
  const { title, children, icon, width, mandatory = false } = props;
  return (
    <Accordion
     defaultExpanded={true}
      sx={{
        width: width || "inherit",
        "& .MuiAccordionSummary-root.Mui-expanded": {
          minHeight: "48px",
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
          margin: "14px",
        },
      }}
      style={{
        borderRadius: "8px",
        border: "0 !important",
        boxShadow: "none",
      }}>
      <AccordionSummary
        sx={{
          background: "#FFDCDD",
          border: "1px solid #F4F4F4",
          borderRadius: "8px",
        }}
        expandIcon={<ExpandMoreIcon />}>
        <Text mr={"8px"} mandatory={mandatory} fontSize="16px" semiBold>
          {title}
        </Text>
        {icon && (
          <WarningAmberIcon sx={{ color: "#EE8E53", fontSize: "18px" }} />
        )}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default CustomerAccordon;
