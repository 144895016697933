import * as React from "react";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import CustomPagination from "./CustomPagination";
import RenderColumns from "./RenderColumns";
import Tooltip from '@mui/material/Tooltip';

export default function DataTable(props) {
  const {
    columns,
    rows,
    processRowUpdate,
    handleProcessRowUpdateError,
    loading,
    getRowId,
    pageTitle,
    onPageSize,
    onPage,
    onSelectionModelChange,
    totalCount,
    isNoCheckBox,
    rowsPerPageOptions
  } = props;
  const [pageSize, setPageSize] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const apiRef = useGridApiRef();
  const actionConditions = ['status', 'actions','col9']

  const CustomNoRowsOverlay = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading ? "Fetching data..." : "There are no records to display"}
      </div>
    );
  };

  return (
    <div style={{ display: "flex", height: "100%" }}>
      {/* <Button onClick={() => console.log(apiRef.current)}>clikc</Button> */}
      <div style={{ display: "flex", flexGrow: 1 }}>
        {console.log("coulmns",columns)}
        <DataGrid
          // sx={{}}
          autoHeight={true}
          checkboxSelection={isNoCheckBox ? false : true}
          
          apiRef={apiRef}
          sx={{
            "& .MuiDataGrid-columnSeparator": {
              opacity: 0,
              visibility: "hidden",
            },
            "& .MuiDataGrid-columnHeaders": {
              border: "1px solid #E5E5E5 !important",
              borderRadius: "10px",
              height: "42px",
              minHeight: "42px !important",
              outline: "none !important",
              fontWeight: 500,
              background: "rgba(63,129,244,.09)",
            },
            "&.MuiDataGrid-root": {
              border: "none",
              overflowX: "scroll",
            },
            "& .MuiDataGrid-sortIcon, .MuiDataGrid-filterIcon": {
              visibility: "visible",
              opacity: "1 !important",
              fontSize: "14px",
            },
            "& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell,  .MuiDataGrid-cell:focus ":
              {
                outline: "none !important",
                border: "none",
                justifyContent: "center",
              },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: "14px",
              fontWeight: 500,
            },
            "&. MuiDataGrid-row": {
              overflowX: "scroll",
              OverflowY: "hidden",
            },
            "& .MuiDataGrid-cell": {
              border: "none",
              justifyContent: "center",
            },
            "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
              background: "inherit",
              boxShadow: "none",
              // padding: "4px",
            },
            "& .MuiDataGrid-row.Mui-hovered": {
              background: "#F1F1FF",
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              justifyContent: "center",
            },
            overflowX: "scroll",
          }}
         // rows={rows?.map((ele, index)=>({...ele, id:index}))}
        rows={rows}
          // columns={columns}
        
         columns={  columns.map((column) => ({
        
          ...column, // Spread the original column object here
          renderCell: (params) => {
            // if (!actionConditions.some(el => column.field.includes(el))) {
            //   return <div title={params.value}>{params.value}</div>;
            // } else {
              return column.renderCell ? column.renderCell(params) :<div title={params.value}>{params.value}</div>;
          //  }
          },
         
        }))}
          columnSelectorIcon={false}
          
          components={{
            Pagination: CustomPagination,
            ColumnMenu: RenderColumns,
            NoRowsOverlay: CustomNoRowsOverlay,
          }} 
          componentsProps={{
            pagination: {
              rowsPerPageOptions: rowsPerPageOptions,
            },
          }}
          getRowId={getRowId}
          disableColumnFilter
          olumnMenuIcon={false}
          disableColumnSelector
          disableColumnMenu
          disableSelectionOnClick
          pageSize={pageSize}
          page={page}
          onPageSizeChange={(newPageSize) => {
            setPageSize(newPageSize);
           onPageSize && onPageSize(newPageSize);
          }}
          onPageChange={(newPage) => {
            setPage(newPage);
            onPage && onPage(newPage);
          }}
          loading={loading}
          rowsPerPageOptions={rowsPerPageOptions ? rowsPerPageOptions : [5, 10, 25, 50, 100]}
          pagination
          experimentalFeatures={{ newEditingApi: true }}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          // {...data}
          initialState={{
            pinnedColumns: { right: ["actions"] },
          }}
          onSelectionModelChange={onSelectionModelChange}
          rowCount={totalCount}
          paginationMode="server"
        />
      </div>
    </div>
  );
}
