import React from "react";
import { Checkbox, TextField, Box, FormControlLabel } from "@mui/material";
import { Controller } from "react-hook-form";

import Text from "../Atoms/Text";

const CheckBox = (props) => {
  const {
    label,
    fieldkey,
    defaultValue,
    errorText,
    placeholder,
    control,
    register,
    mandatory,
    fullWidth,
    disabled,
    width,
    onChange,
    setValue,
    onChangeValue = () => {},
  } = props;

  return (
    <Box
      display="flex"
      flexDirection="row"
      key={fieldkey}
      sx={{
        marginTop: "1rem",
        width: fullWidth ? "92%" : width || "45%",
        marginRight: "1rem",
      }}>
      <Controller
        render={({ field, fieldState: { isTouched, error } }) => (
          <FormControlLabel
            control={
              <Checkbox
                // defaultValue={}
                // defaultChecked={data.hasPhone}
                color="primary"
                onChange={(e) => (
                  setValue(fieldkey, e.target.checked),
                  onChangeValue()
                  )}
                checked={field.value}
              />
            }
            label={label}
          />
        )}
        name={fieldkey}
        control={control}
        // defaultValue={defaultValue}
      />
    </Box>
  );
};

export default CheckBox;
