import fileDownload from "js-file-download";
import { formatDate } from "./formUtils";
import { allElements } from "./formElements";
import { getNewDistricts } from "../../../apis/districtMaster";

export const displayUserGeneralDetails = (customerInfo, renderCKYCStatus) => {
  return [
    {
      label: "Docket No",
      key: "docketNo",
    },
    {
      label: "Customer Name",
      value: `${customerInfo?.FNAME || ""} ${customerInfo?.MNAME || ""} ${customerInfo?.LNAME || ""}`,
      key: "father",
    },
    {
      label: "Date of Birth",
      key: "pesonalDob",
    },
    {
      label: "Aadhaar Number",
      key: "pesonalAadhar",
    },
    {
      label: "Pan Number",
      key: "personaPan",
    },
    ...(customerInfo?.status
      ? [
        {
          label: "CKYC Status",
          value: renderCKYCStatus(customerInfo?.status),
        },
      ]
      : []),
    {
      label: "Customer Bank",
      value: customerInfo?.customerBank ?? "",
      key: "customerBank",
    },
    {
      label: "Customer Branch",
      value: customerInfo?.customerBranch,
      key: "customerBranch",
    },
    {
      label: "Customer Number",
      value: customerInfo?.customerNumber ?? "",
      key: "customerNumber",
    },
    {
      label: "Account Number",
      value: customerInfo?.acntNum ?? "",
      key: "acntNum",
    },
    {
      label: "CKYC No",
      value: customerInfo?.CKYC_NO,
      key: "kycNumber",
    },
    // {
    // 	label: "IFSC Code",
    // 	value: "",
    // 	key: "ifscCode",
    // },
  ];
};

export const base64ToFile = async (base64, type) => {
  let res = await fetch(base64);
  res = await res.blob();
  let objectURL = URL.createObjectURL(res);
  // return res;
  const buf = await res.arrayBuffer();
  const file = new File([buf], "capture_camera.jpeg", {
    type: type,
  });
  return file;
};

export const urltoFile = async (url, filename, mimeType) => {
  try {
    let res = await fetch(url);
    res = await res.arrayBuffer();
    const file = new File([res], filename, { type: mimeType });
    fileDownload(file, filename, mimeType);
  } catch (e) {
    //handle error
  }
};

export const headers = [
  "Account Details",
  "Personal Details",
  "Proof of Address",
  "Current Address",
  "KYC Verification",
  "Applicant Declaration",
  // "residentialStatus",
  // "personalcitizenship",
  // "occupatationType",
];

const prefix = ["Mr", "Mrs", "Minor", "Mis", "Dr"];
export const getPrefix = (id) => (parseInt(id) ? prefix[id - 1] || "" : id);

export const renderLabel = (element, dropdownOptions, values) => {
  let fatherSpouse =
    dropdownOptions &&
    dropdownOptions["dependentType"].find(
      (item) => item.value === values["dependentType"]
    );
  if (!fatherSpouse) fatherSpouse = "Father Name";
  else fatherSpouse = fatherSpouse.label;
  return element.key === "father" ? fatherSpouse?.toLowerCase()?.includes("name") ? fatherSpouse : fatherSpouse + " Name" : element.label;
};

export const getImageName = (item, dropdownOptions) => {
  const addressFiles = dropdownOptions["addressProof"] || [];
  const identitiyProofs = dropdownOptions["identityProof"] || [];
  const files = [...addressFiles, ...identitiyProofs];
  let proof = files.find(
    (img) =>
      img.id === parseInt(item.documentType) ||
      parseInt(img.documentCode) === parseInt(item.documentType) ||
      parseInt(img.documentCode) === parseInt(item.documentType)
  );
  
  if (proof) {
    return proof.documentName;
  }
  return "";
};

const getElement = (selected) =>
  allElements.find((item) => item.key === selected.key);

export const getMappedValue = ({
  selected,
  label,
  isMisMatched = false,
  dropdownOptions,
  customerInfo,
  recentlyUpdate = null,
}) => {
  let value = label;
  const element = getElement(selected);
  if (!element) return "-";
  let dropdown = dropdownOptions[element.key] || element?.options;
  if(element.key === "nationality"){
    dropdown = dropdownOptions["country"]
  }

  if (dropdownOptions && customerInfo && element) {
    if (element.type === "dropdown" && dropdown) {
      const dropdownValue = dropdown.find((item) =>
        [item?.key, `${item?.id}`, `${item?.code}`, item?.name].includes(
          `${label}`
        )
      );
      value =
        (dropdownValue && dropdownValue?.name) ||
        dropdownValue?.type ||
        label ||
        "-";
    } else {
      value = label;
    }
  }
  if (element?.type === "date") {
    // There is issues related to date format. DatePicker giving the mm-dd-yyy and accepting same
    // so had to format across apps
    if (typeof label === "object") {
      value = "";
    } else if (recentlyUpdate?.includes(element.key) || !isMisMatched) {
      value = typeof label === "object" ? "" : formatDate(label, "DD-MM-YYYY");
    }
  }
  if (value === "") return "-";
  return value;
};




// export const useNewDistrictsByState = (state) => {
//   const { data: newDistricts } = useQuery(
//     [
//       "NewDistricts",
//       {
//         filter: state ? { state_code: state } : {},
//         search: null,
//       },
//     ],
//     getNewDistricts,
//     {
//       select: (response) => response,
//       manual: true,
//       enabled: !!state,
//     }
//   );

//   return newDistricts;
// };
