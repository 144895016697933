import { isMaker } from "../utils";
import axiosInstance from "./axios";
import Immutable from "immutable";

export const createOrEditDocument = async (document) => {
	let response = null;
	try {
		if (document?.id)
			response = await axiosInstance.post(`/documents/delete`, document);
		else if (document.document.id)
			response = await axiosInstance.post("/documents/update", document);
		else response = await axiosInstance.post("/documents", document);
		if(!response?.data?.isError && (document?.document?.status === 1 || document?.document?.status === 0)){
			response.data["message"] = response.data["message"]?.replace("updated",document?.document?.status ? "enabled" : "disabled");
		}
		return response.data;
	} catch (e) {}
};

export const getRoles = async () => {
	const response = await axiosInstance.get("/roles");
	return response.data;
};

export const getBranches = async () => {
	const response = await axiosInstance.get(
		"/branches/list?perPage=25&offset=0"
	);
	return response.data;
};

export const getDocuments = async ({ queryKey }) => {
	const [_key, { filter, search }] = queryKey;
	const pageSize = filter.perPageSize || 10;
	const fils = { ...filter };
	try {
		let baseUrl = `/documents/?perPage=${pageSize}&offset=${filter?.page ? filter.page : 0}`;
		delete fils.page;
		delete fils.perPageSize;
		delete fils.condition;
		if(isMaker()){
			baseUrl = "/documents/?perPage=500&offset=0&statusFilter=1";
		}
		let filtered = baseUrl;
		if (!isMaker() && filter?.statusFilter != null && filter?.statusFilter >= 0 && Object.keys(filter).length > 0) {
			let val = Object.keys(fils);
			filtered = `${baseUrl}&${val}=${filter[val]}`;
		}
		if (!isMaker() && ![null, "", undefined].includes(search))
			filtered = `${baseUrl}&search=${search}`;

		const response = await axiosInstance.get(filtered);
		return Immutable.fromJS(response.data);
	} catch (e) {
		console.log(e);
	}
};

export const getDocument = async (id) => {
	const response = await axiosInstance.get(`/documents/${id.queryKey[1]}`);
	return response.data;
};

