export function isLoggedIn() {
  /*
   * Note:
   *  This app assume if local storage have roles it means
   *  user is authenticated you can update this logic as per your app.
   */
  return !!localStorage.getItem("roles");
}

export function isArrayWithLength(arr) {
  return Array.isArray(arr) && arr.length;
}

const intersection = (a, b) => {
  return a.filter((x) => {
    return b.some((y) => {
      return Object.is(x, y);
    });
  });
};

export function getAllowedRoutes(routes) {
  const role = localStorage.getItem("roles");
  const is_pwd_reset = localStorage.getItem("is_pwd_reset") + "";

  const roles = role ? [JSON.parse(role)?.toUpperCase()] : [];
  return routes.filter(({ permission, path }) => {
    if (!permission) return true;
    else if (!isArrayWithLength(permission)) return true;
    else return intersection(permission, roles).length && is_pwd_reset == "true";
  });
}
export function isSuperAdmin() {
  const role = localStorage.getItem("roles");
  return role === `"SuperAdmin"`
}
export function isChecker() {
  const role = localStorage.getItem("roles");
  return role === `"Checker"`
}
export function isMaker() {
  const role = localStorage.getItem("roles");
  return role === `"Maker"`
}
export const addressByIdentityMandatoryArray = [3, 4, 5, 6];

export function displayAadhaar(value) {
  const valueLength = value?.length;
  if (valueLength >= 4) {
    const lastFourCharacters = value.slice(-4);
    return "XXXX-XXXX-" + lastFourCharacters;
  } else {
    return `-`
  }
}
