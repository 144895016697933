import { isMaker } from "../utils";
import axiosInstance from "./axios";
import Immutable from "immutable";

export const createOrEditUploadMaster = async (uploadMaster) => {
	let response = null;
	try {
		if (uploadMaster?.id)
			response = await axiosInstance.post(
				`/upload_master/delete`,
				uploadMaster
			);
		else if (uploadMaster.uploadMaster.id)
			response = await axiosInstance.post(
				"/upload_master/update",
				uploadMaster
			);
		else
			response = await axiosInstance.post(
				"/upload_master/create",
				uploadMaster
			);
			if(!response?.data?.isError && (uploadMaster?.uploadMaster?.status === 1 || uploadMaster?.uploadMaster?.status === 0)){
				response.data["message"] = response.data["message"]?.replace("updated",uploadMaster?.uploadMaster?.status ? "enabled" : "disabled");
			}
		return response.data;
	} catch (e) {}
};

export const getMasters = async ({ queryKey }) => {
	const [, { filter, search }] = queryKey;
	const pageSize = filter.perPageSize || 10;
	const fils = { ...filter };
	// const pageSize = perPageSize || 10;
	try {
		let baseUrl = `/upload_master/list/?perPage=${pageSize}&offset=${filter?.page ? filter?.page : 0}`;
		delete fils.page;
		delete fils.perPageSize;
		delete fils.condition;
		if(isMaker()){
		   baseUrl = `/upload_master/list/?perPage=500&offset=0&statusFilter=1`;
		}
		let filtered = baseUrl;

		if (!isMaker() && ((filter?.statusFilter !== null && filter?.statusFilter >=0) || filter.masterId) && Object.keys(filter).length > 0) {
			const keys = Object.keys(fils);
			keys.forEach((item) => {
				if (!["", null, undefined].includes(filter[item]))
					filtered = `${filtered}&${item}=${filter[item]}`;
			});
		}
		if (!isMaker() && ![null, "", undefined].includes(search))
			filtered = `${baseUrl}&search=${search}`;

		const response = await axiosInstance.get(filtered);
		return Immutable.fromJS(response.data);
	} catch (e) {}
};

export const getDistrict = async (id) => {
	const response = await axiosInstance.get(`/districts/${id.queryKey[1]}`);
	return response.data;
};

export const getPicklists = async (id) => {
	const response = await axiosInstance.get(`/upload_master/picklist_types`);
	return response.data;
};

