import React, { memo } from "react";
import NotFound from "../components/Molecules/NotFound";
import { Routes as Switch, Route } from "react-router-dom";

function MapAllowedRoutes({ routes, basePath, isAddNotFound }) {
  return (
    <Switch>
      {routes.map((route) => {
        const {
          path,
          component: Component,
          children,
          title,
          permission,
          ...rest
        } = route;
        return children ? (
          <Route {...rest} key={path} path={path} element={<Component />}>
            {children.map((props) => {
              const { component: ChildComponent, ...rest } = props;
              return (
                <Route
                  {...rest}
                  path={props.path}
                  element={<ChildComponent {...rest} />}
                />
              );
            })}
          </Route>
        ) : (
          <Route
            {...rest}
            key={path}
            path={path}
            element={<Component children={children} {...rest} />}
          />
        );
      })}
      <Route path="*" element={<NotFound />} />
      {/* {isAddNotFound && <Route><div>Note Found</div></Route>} */}
    </Switch>
  );
}

export default memo(MapAllowedRoutes);
